import {Button, Col, Container, Row} from "reactstrap";
import authSlice, {
    isPartiallyLoggedOutSelector, isSamlUserSelector,
    isUserAcquiredIn,
    showPartialLoginSelector,
    usernameSelector
} from "../store/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import React, { useEffect, useState } from 'react'
import '../pages/Login.css';
import {checkValidEmail} from "../helpers/helpers";
import axios from "axios";
import * as Constants from "../constants/Constants";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import {useTranslation} from "react-i18next";
import patientsSlice from "../store/slices/patients";
import {reportAction} from "../utils/axios";


export default function IdleLogin(props){
    const dispatch = useDispatch();
    let username = useSelector(usernameSelector);
    let isSamlUser = useSelector(isSamlUserSelector);
    let isUserLoggedIn = useSelector(isUserAcquiredIn);
    let showPartialLogin = useSelector(showPartialLoginSelector);
    let isPartiallyLoggedOut = useSelector(isPartiallyLoggedOutSelector);
    let isIdle = props.isIdle;
    const [password, updatePassword] = useState("");
    const [showPassword, updateShowPassword] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [passwordError, updatePasswordError] = useState("");
    const [currentTime, updateCurrentTime] = useState(0);
    const {t} = useTranslation();

    // if (isStaleLogin){
    //     dispatch(authSlice.actions.setPartialLogout());
    // }

    useEffect(() => {
        const interval = setInterval(() => updateCurrentTime(Math.floor(new Date().getTime()/1000)), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    // TODO logic seems complex, needs to be simplified
    if (!showPartialLogin && !isIdle) return <></>;

    if (localStorage.getItem("MRI_STATUS") === "UPLOADING"){
        return <></>;
    }

    if (isUserLoggedIn && isIdle){
        if ((props.isIdle + 30 - Math.floor(new Date().getTime()/1000)) < 1){
            if (isSamlUser) {
                dispatch(authSlice.actions.setLogout());
                dispatch(patientsSlice.actions.clearData());
                toast.info(t("you_have_been_logged_out_due_to_inactivity"));
            }
            if (!isPartiallyLoggedOut) {
                reportAction("AUTO_ACTION_LOG_OUT", undefined, undefined);
                setTimeout(() => {
                    dispatch(authSlice.actions.setLogout());
                    dispatch(authSlice.actions.setRedirectAfterLogin(Constants.PATIENT_LIST));
                    dispatch(patientsSlice.actions.clearData());
                    toast.info(t("you_have_been_logged_out_due_to_inactivity"));
                }, 1000);
            }
        }
    }
    else{
        if (!(showPartialLogin && isUserLoggedIn)){
            return <></>;
        }
    }

    function logout(){
        updateIsLoading(true);
        dispatch(authSlice.actions.setLogout());
        dispatch(patientsSlice.actions.clearData());
        window.location.reload();
    }

    function performLogin(){
        if(!password || password.length < 1){
            updatePasswordError(t("password_empty_message"));
            toast.error(t("password_empty_message"));
            return;
        }
        updateIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_AUTH_API_URL}${Constants.LOGIN_API}`,
                {username: username, password: password})
            .then((res) => {
                updateIsLoading(false);
                if (res.error){
                    updatePasswordError(t(res.error));
                    return;
                }
                dispatch(
                    authSlice.actions.setAuthTokens({
                        ...res.data.message
                    })
                );
                setTimeout(() => window.location.reload(), 500);
            })
            .catch((err) => {
                updatePasswordError(t(err.toString()));
                updateIsLoading(false);
            });
    }

    if (isIdle && !currentTime){
        updateCurrentTime(Math.floor(new Date().getTime()/1000));
    }

    // else{
    //     if (!isPartiallyLoggedOut) {
    //
    //     }
    //     // setTimeout(() => updateCurrentTime(Math.floor(new Date().getTime()/1000)), 1000);
    // }


    return <div style={{position:"fixed", width:"100vw", height:"100vh", background: "#2D4175CA", color:"var(--background-color)", zIndex: 2000, display: "block"}}>
        <div style={{position:"fixed", zIndex:2001, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"100vw", height:"100vh", textAlign:"center", backdropFilter:"blur(4px) grayscale(70%) brightness(60%)"}}>
            {isPartiallyLoggedOut ?
              <Container>
                  <Row style={{fontSize:"16px", marginTop:"40px"}}>
                      <Col>
                          {t("you_are_logged_in_as")}
                      </Col>
                  </Row>
                  <Row style={{fontSize:"26px"}}>
                      <Col>{username}</Col>
                  </Row>
                  <Row style={{justifyContent:"center", marginTop:"30px"}}>
                      <Col xs={4} style={{display: "flex", flexDirection:"row", width:"100%", justifyContent:"center"}}>
                          <input className={passwordError ? "incorrect-input-field" : "input-field"} style={{paddingRight:"80px", position:"relative", left:"40px"}}
                                 type={showPassword ? "text": "password"} placeholder={t("enter_your_password")} value={password}
                                 onChange={(e) => updatePassword(e.target.value)} />
                          <span style={{position: "relative", cursor:"pointer",
                              right:"10px", color:"var(--text-color)",
                              visibility:password.length > 0 ? "visible": "hidden",
                              fontSize:"12px",
                              lineHeight:"16px", alignSelf:"center", justifySelf:"center", width:"40px"}}
                                onClick={() => updateShowPassword(!showPassword)} >
                        {showPassword ? "Hide": "Show"}
                        </span>
                          <span onClick={() => {
                              updatePassword("");
                              updatePasswordError("");
                          }} style={{position: "relative", right:"-20px", visibility: password.length > 0 ? "visible": "hidden", cursor:"pointer", alignSelf:"center", justifySelf:"center", marginRight:"40px"}}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                      fill={passwordError ? "var(--wave-clay)" : "var(--background-color)"}/>
                            </svg>
                        </span>
                      </Col>
                  </Row>
                  <Row style={{justifyContent:"center", position:"relative", left:"-10px", height:"10px"}}>
                      <Col xs={4} style={{textAlign:"center"}}>
                          <span style={{color:`var(--wave-clay)`, fontWeight:"400", fontSize:"14px", lineHeight:"22px", marginTop:"30px", minHeight:"22px", maxWidth:"max(25vw, 375px)"}}>{passwordError}</span>
                      </Col>
                  </Row>
                  <Row style={{justifyContent:"center", position:"relative", left:"-10px"}}>
                      <Col xs={4}>
                          <Button onClick={performLogin} style={{width:"108%", position:"relative", left:"-10px", flexGrow:"1", height:"44px", marginTop:"30px", fontWeight:"600", fontSize:"14px", lineHeight:"22px", color:"white", background: checkValidEmail() || (checkValidEmail() && password.length > 0) ? "var(--bs-primary)": "var(--border-color-faint)", borderRadius:"100px", boxShadow:"none"}} variant="light">
                              <span style={{padding:"0 20px"}}>{t("login")}</span><HashLoader color="var(--background-color)" loading={isLoading} size={20} />
                          </Button>

                      </Col>
                  </Row>
                  <Row style={{justifyContent:"center", position:"relative", left:"-10px", height:"10px", marginTop:"10px"}}>
                      <Col xs={4} style={{textAlign:"center"}}>
                          <Link onClick={logout} to={Constants.LOGIN} style={{color:"var(--background-color)"}}>
                            <span style={{fontWeight:"400", fontSize:"14px", lineHeight:"22px", marginTop:"30px", minHeight:"22px", maxWidth:"max(25vw, 375px)", textDecoration:"underline", cursor:"pointer"}}>
                                {t("log_in_with_a_different_account")}
                            </span>
                          </Link>
                      </Col>
                  </Row>
              </Container>
              :
              <Container style={{textAlign:"start", justifyContent:"center", width:"792px", minHeight:"248px", background: "var(--background-color)", color:"var(--text-color)", boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius: "10px", fontSize:"14px", lineHeight:"22px", fontWeight: 400, padding:"50px"}}>
                  <Row style={{marginBottom:"10px"}}>
                      <Col style={{fontWeight: 600, fontSize:"22px", lineHeight:"30px"}}>
                          Session Timeout
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          You are being timed out due to inactivity. Please choose “Stay Logged In” to continue your sesion or “Log Out” if you are done. Otherwise, you will be automatically logged out in <span style={{fontWeight: 600}}>{props.isIdle + 30 - Math.floor(new Date().getTime()/1000)}</span> seconds.
                      </Col>
                  </Row>
                  <Row style={{fontWeight:600, alignItems:"start", justifyContent:"center", marginTop:"30px"}}>
                      <Col>
                          <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: "var(--bs-primary)", color: "var(--background-color)", fontWeight:600}}>{t("Stay Signed In")}</Button>
                      </Col>
                  </Row>
              </Container>}
        </div>
    </div>
}