import React, {useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import Button from "@restart/ui/Button";
import HashLoader from "react-spinners/HashLoader";
import { fetcher, reportAction } from '../../utils/axios'
import * as Constants from "../../constants/Constants";
import {toast} from "react-toastify";


export default function MessagePatientCard(props) {
    const {t} = useTranslation();
    const [message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    function sendMessage(){
        if (message.length < 1 || isLoading){
            return;
        }
        setLoading(true);
        reportAction("ACTION_MESSAGED_PATIENT", props.patient.uuid);
        // todo add base64 for message?
        var data = JSON.stringify({
            query: `mutation ($message: String!, $patient_id: String!) {
                  message_create(receiver: $patient_id, message: $message) {
                      uid
                      message
                      sender{
                      uuid
                      }
                      status
                      updated
                  }
                }`,
            variables: {
                patient_id: props.patient.uuid,
                message
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
            .then((response) => {
                console.log("Got Response");
                setLoading(false);
                if(response.errors || !response.data.message_create){
                    console.debug("Got Error", response.errors);
                    toast.error(t("Unable to send Message"));
                    return;
                }
                setMessage("");
                props.updateMessages();
                props.closeMessageBox();
                toast.info(t("Message sent successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Unable to send Message."));
            });
    }

    return <Container style={{
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400,
        minWidth: "350px",
        background: "var(--background-color)",
        padding:0,
        borderRadius: "10px",
        boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)",
        textAlign: 'center',
        color: "var(--text-color)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }}>
        <div style={{height: 0, position: "relative", left:0, top:"175px"}}><HashLoader color="#4676F4" loading={isLoading} size={25} /></div>
        <div style={{
            margin: 0,
            textAlign: "center",
            marginBottom: "20px",
            borderRadius: "10px",
        }}>
            <Row style={{padding:"20px"}}>
                <Col style={{textAlign: "start"}}>
                    {t("Using the box below, write a direct message to the patient. The patient will not be able to reply to the message.")}
                </Col>
            </Row>
            <Row style={{padding:"0 20px"}}>
                <Col>
                    <textarea style={{minHeight:"175px", width:"100%", outline:0, border: "1px solid var(--border-color-fainter)", borderRadius:"10px", padding:"10px", margin: "0", resize:"none"}} placeholder={"Type Message..."} value={message} onChange={(e) => !isLoading ? setMessage(e.target.value): ""} />
                </Col>
            </Row>
            <div style={{background: "var(--background-color-faint)", height:"1px", width:"100%", margin:"20px 0", padding:0}} />
            <Row style={{fontWeight:600, alignItems:"center", justifyContent:"center", position: "relative", bottom:"0px", padding:"0 20px"}}>
                <Col xs={6}>
                    <Button onClick={() => props.closeMessageBox()} variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", border:"1px solid var(--border-color-faint)", background:"var(--background-color)", textAlign:"center", marginLeft:"0", color:"var(--text-color-faint)", fontWeight:600}}>{t("Cancel")}</Button>
                </Col>
                <Col xs={6}>
                    <Button onClick={sendMessage} variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: message ? "var(--bs-primary)": "var(--border-color-faint)", color: "var(--background-color)", fontWeight:600}}>{t("Send")}</Button>
                </Col>
            </Row>
        </div>
    </Container>
}