import React, { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import { installViewer } from '@mattlashey/viewer'
import {toast} from 'react-toastify';

const containerId = 'ohif-mri-tti'
const componentRenderedOrUpdatedCallback = function() {
    // console.log('OHIF Viewer rendered/updated');
};


class MriViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HarComponent: this.props.t("Loading...")
        }
        // TODO Translations
    }

    componentDidMount() {
        let careportal_web_url = process.env.REACT_APP_API_URL
        if (this.props.auth && this.props.auth.careportal_web_url)
            careportal_web_url = this.props.auth.careportal_web_url
        installViewer(
            {
                showStudyList: false,
                routerBasename: '/mri-viewer',
                httpErrorHandler: error => {
                    toast.error(this.props.t("Please contact the admin"));
                },
                servers: {
                    dicomWeb: [
                        {
                            name: 'OHIF MRI Viewer',
                            wadoUriRoot: `${careportal_web_url}/wado/${this.props.auth.token}`,
                            qidoRoot: `${careportal_web_url}/dicom-web/${this.props.auth.token}`,
                            wadoRoot: `${careportal_web_url}/dicom-web/${this.props.auth.token}`,
                            qidoSupportsIncludeField: true,
                            imageRendering: 'wadors',
                            thumbnailRendering: 'wadors'
                        },
                    ],
                },
            },
            containerId,
            componentRenderedOrUpdatedCallback
        );
    }

    render = () => {
        return <div style={{background: "black", height:"100vh"}} id={containerId} />;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(MriViewer)));








