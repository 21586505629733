import './Login.css';
import React from 'react';
import * as Constants from '../constants/Constants';
import axios from "axios";
import {connect} from "react-redux";
import {instanceOf} from 'prop-types';
import {Container, Row, Col} from "reactstrap";
import {withRouter} from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import {Button} from 'react-bootstrap';
import debounce from 'lodash.debounce';
import {Cookies, withCookies} from 'react-cookie';
import {withTranslation} from "react-i18next";
import LogoAndBackground from "../components/WaveLogoBlueBack";

class ForgotPassword extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            isLoaded: false,
            username: "",
            password: "",
            usernameError: false,
            passwordError: false,
            showPassword: false,
            secondPartOfLogin: false,
            samlUser: false,
            successMessage: ""
        };
        this.updateUsername = this.updateUsername.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            return;
        }
        if (this.state.username === "" || !this.checkValidEmail() || this.state.samlUser) {
            this.setState({isLoaded: true, secondPartOfLogin: false});
            return;
        }
    }

    handlePasswordReset = () => {
        if (!this.state.username || this.state.username.length < 1) {
            this.setState({error: this.props.t("username_cannot_be_empty")});
            return;
        }
        if (!this.checkValidEmail()) {
            return;
        }

        axios
            .post(`${process.env.REACT_APP_AUTH_API_URL}${Constants.FORGOT_PASSWORD_API}`,
                {username: this.state.username})
            .then((res) => {
                this.setState({isLoaded: true, successMessage: this.props.t("password_reset_request_success_message")});
            })
            .catch((err) => {
                this.setState({error: err.toString(), isLoaded: true});     // todo error message
            });
    };

    checkValidEmail() {
        if (this.state.username === "") {
            return false;
        }
        let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.state.username)) {
            debounce(() => this.setState({
                error: this.props.t("please_enter_a_valid_email_"),
                usernameError: true
            }), 500);
            return false;
        } else {
            debounce(() => this.setState({error: "", usernameError: false}), 500);
            return true;
        }
    }

    updateUsername(event) {
        if (this.state.secondPartOfLogin) {
            return;
        }
        if (event.target.value === "") {
            this.setState({error: "", usernameError: false, username: ""});
        } else {
            this.setState({username: event.target.value});
        }
    }

    render = () => {
        const t = this.props.t;
        return (
            <Container className="login-container">
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>
                <Row style={{padding: "0", margin: "0"}}>
                    <Col xs={6} style={{paddingRight: "0"}}>
                        <LogoAndBackground/>
                    </Col>
                    <Col xs={6} style={{
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>
                        <div style={{
                            position: "absolute",
                            alignSelf: "center",
                            justifySelf: "center",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            color: "var(--text-color)"
                        }}>
                            <span style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                lineHeight: "30px",
                                color: "var(--text-color)",
                                alignSelf: "center"
                            }}>{t("request_to_change_password")}</span>
                            <span style={{
                                color: `var(--wave-clay)`,
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "22px",
                                marginTop: "30px",
                                minHeight: "22px",
                                maxWidth: "max(25vw, 375px)"
                            }}>{this.state.error}</span>
                            <span style={{
                                color: `var(--bs-primary)`,
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "22px",
                                marginTop: "30px",
                                minHeight: "22px",
                                maxWidth: "max(25vw, 375px)"
                            }}>{this.state.successMessage}</span>
                            <label style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22px",
                                color: "var(--text-color)",
                                alignSelf: "start",
                                marginTop: "10px"
                            }}>{t("username")} ({t("email")})</label>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <input type="email" autoFocus
                                       className={this.state.usernameError ? "incorrect-input-field" : "input-field"}
                                       placeholder={t("enter_your_username")}
                                       value={this.state.username}
                                       onChange={this.updateUsername}
                                       onBlur={() => setTimeout(function () {
                                           this.checkValidEmail()
                                       }.bind(this), 200)}/>
                                <span onClick={() => this.setState({
                                    username: "",
                                    usernameError: false,
                                    secondPartOfLogin: false,
                                    error: "",
                                    samlUser: false
                                })} style={{
                                    position: "absolute",
                                    right: "20px",
                                    visibility: this.state.username.length > 0 ? "visible" : "hidden",
                                    cursor: "pointer"
                                }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                    fill={this.state.usernameError ? "var(--wave-clay)" : "var(--border-color)"}/>
                            </svg>
                        </span>
                            </div>
                            <Button onClick={() => {
                                this.setState({error: "", isLoaded: false});
                                this.handlePasswordReset();
                            }}
                                    style={{
                                        width: "100%",
                                        flexGrow: "1",
                                        height: "44px",
                                        marginTop: "30px",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "white",
                                        background: this.checkValidEmail() ? "var(--bs-primary)" : "var(--border-color-faint)",
                                        borderRadius: "100px",
                                        boxShadow: "none"
                                    }} variant="light">
                                {t("send_reset_password_link")}
                            </Button>
                            <Button onClick={() => this.props.history.push(Constants.LOGIN)}
                                    style={{
                                        width: '100%',
                                        flexGrow: '1',
                                        height: '44px',
                                        marginTop: '30px',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '22px',
                                        color: 'var(--bs-primary)',
                                        background: 'var(--background-color)',
                                        borderRadius: '100px',
                                        boxShadow: 'none'
                                    }} variant="light">
                                {this.props.t('Click here to login')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withCookies(withRouter(connect(mapDispatchToProps)(ForgotPassword))));