import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Row, Col, Container} from "reactstrap";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import { connect } from 'react-redux'
import BackButton from '../BackButton'
import EsReportWaveIcon from '../../assets/icons/EsReportWaveIcon.svg'
import * as helpers from '../../helpers/helpers'
import PageNotFound from '../../pages/PageNotFound'
import * as Constants from '../../constants/Constants'
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download.svg'
import EsWaveReport from './EsWaveReport'
import { fetcher } from '../../utils/axios'
import { toast } from 'react-toastify'
import CheckInCard from './CheckInEs'
import ReactToPrint from 'react-to-print'
import HashLoader from 'react-spinners/HashLoader'
import ChltReport from './ChltReport'
import CheckedInCard from './CheckedInCard'
import FactPReport from './FactPReport'
import { PATIENT_STATUS } from '../../constants/Constants'

function getWeeklyReport(weekNumber){
    return {
        uid: ("test-report-"+(weekNumber+1)),
        action: "SYMPTOMS_REVIEW",
        status: "CREATED",
        due: Math.floor(Date.now()/1000) + 259200,
        data: JSON.stringify({week: (weekNumber+1), due_window: 259200}),
        week_title: String((weekNumber+1)).padStart(2, '0'),
        symptoms: {
            pain : { key: "pain", title: "Pain", prefix: "S", suffix: "2", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            sad_feelings : { key: "sad_feelings", title: "Sad Feelings or unhappy feelings", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            nausea : { key: "nausea", title: "Nausea", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            skin_changes : { key: "skin_changes", title: "Skin Changes", prefix: "S", suffix: "0", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            vomiting : { key: "vomiting", title: "Vomiting", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            constipation : { key: "constipation", title: "Constipation", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            dizziness : { key: "dizziness", title: "Dizziness", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            headache : { key: "headache", title: "Headache", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            chills : { key: "chills", title: "Chills (shivering or shaking chills)", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            fatigue : { key: "fatigue", title: "Fatigue (tiredness or lack of energy)", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            urination_problems : { key: "urination_problems", title: "Urination Problems", prefix: "S", suffix: "0", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            numbness_tingling_hands_feet : { key: "numbness_tingling_hands_feet", title: "Numbness/Tingling in the hands and feet", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            diarrhea : { key: "diarrhea", title: "Diarrhea (loose or watery stools)", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            weakness : { key: "weakness", title: "Weakness in the arms and legs", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            sleeping_problems : { key: "sleeping_problems", title: "Sleeping Problems", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
            anxiety : { key: "anxiety", title: "Anxiety", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
        }
    }
}
const STUBBED_REPORT = Array.from(Array(5).keys()).map((t, k) => getWeeklyReport(k))

// bayes weekly report
class WeeklyReport extends React.Component {
    componentRef = null;
    printButtonRef = null;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            searching: false,
            searchText: "",
            selected: false,
            fetchData: true,
            showCheckIn: false,
            overlay: false,
            overlayDark: false,
            showCheckInButton: false,
            report: {},
            patient: {},
            testReport: false,
            pageTitle: "",
            printing: false,
            reportList: [],
            showReportWeeks: false,
            showReportWeeksButton: false,
            selectedWeek: {uid: "", checked_in_at: null},
        };
    }
    reactToPrintContent = () => {
        return this.componentRef;
    };

    componentDidMount() {
        if (this.state.isLoaded) {
            return;
        }
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            return;
        }

        if(this.state.fetchData){
            this.setState({fetchData: false});
            if (this.props.match.params.reportId.includes("test-report-") && process.env.NODE_ENV === "development"){
                let reportId = this.props.match.params.reportId.substring(12);
                if (!reportId || String(parseInt(reportId)) === "NaN"){
                    reportId = Math.floor(Math.random()*12)
                }
                if (typeof reportId === "string"){
                    reportId = parseInt(reportId);
                }
                let testReports = Array.from(Array(reportId).keys()).map((t, k) => getWeeklyReport(k))
                this.setState({reportList: testReports, report: testReports[reportId-1], testReport: true, isLoaded: true, pageTitle: "Weekly Symptom Review"});
                return
            }
            var data = JSON.stringify({
                query: `query {
                 patientProfile(uuid: "${this.props.match.params.patientIdentifier}"){
                 first_name
                 last_name
                 active_from
            tasks{
                uid
                action
                status
                data
                user_data
                properties
                due
                created_at
            }
        }
              }`,
                // variables: {
                //     // patientId: this.props.match.params.patientIdentifier
                // }
            });
            fetcher(Constants.GRAPHQL_API, data)
              .then((response) => {
                  console.debug("response", response)
                  if(response.errors || !response.data || !response.data.patientProfile){
                      this.setState({error: this.props.t("unable_to_load_the_report"), isLoaded: true});
                      toast.error(this.props.t("unable_to_load_the_report"));
                      return;
                  }
                  let pageTitle = "Weekly Symptom Review";
                  let reportList = [];
                  let report = null;
                  if(response.data && response.data.patientProfile.tasks && response.data.patientProfile.tasks.length > 0){
                      reportList = response.data.patientProfile.tasks.filter(r => r.action === "SYMPTOMS_REVIEW")
                      report = response.data.patientProfile.tasks.filter(r => r.uid === this.props.match.params.reportId)
                      if (report.length > 0){
                          report = report[0]
                      }
                      else{
                          report = {}
                          toast.error(this.props.t("Invalid Report"))
                      }
                  }
                  let patient = {}
                  if (response.data.patientProfile){
                      patient = Object.assign( {}, response.data.patientProfile)
                      if (patient.tasks)
                        delete patient.tasks
                  }
                  if (!reportList || response.data.patientProfile?.tasks.length < 1 || !report){
                      this.setState({isLoaded: true, report, reportList, error: this.props.t("Report Not Found")});
                      toast.error(this.props.t("Report Not Found"));
                  }
                  let showCheckInButton = false
                  let showReportWeeksButton = false
                  if (report && report.action === "SYMPTOMS_REVIEW"){
                      let properties = report.properties && typeof report.properties === "string" ? JSON.parse(report.properties) : (report.properties ? report.properties : {})
                      if (Boolean(properties.checked_in_at)){
                          showCheckInButton = false
                      }
                      else if(helpers.isCurrentReport(report.due)){
                          showCheckInButton = true
                      }
                      if (!showCheckInButton) showReportWeeksButton = true
                      // showCheckInButton = !Boolean(data.checked_in_at) && helpers.isCurrentReport(report.due)
                  }

                  if (report && report.action === "CHLT_30") pageTitle = "CHLT-30"
                  if (report && report.action === "FACT_P") pageTitle = "FACT-P"

                  this.setState({isLoaded: true, report, reportList, showCheckInButton, patient, pageTitle, showReportWeeksButton});
              })
              .catch((err) => {
                  console.debug(err, "es report error")
                  this.setState({error: this.props.t("unable_to_load_the_report"), isLoaded: true});
                  toast.error(this.props.t("unable_to_load_the_report"));
              });

        }
    }

    getReportWeeks(t){
        let reportWeeks = {}
        this.state.reportList.forEach(r => {
            let properties = JSON.parse(r.properties ? r?.properties: "{}")
            // if (properties.checked_in_at){
            //     let satPair = helpers.getSaturdayPair(properties.checked_in_at, 6)
            //     reportWeeks[(![null, undefined].includes(properties.week) ? (t("Week")+ " " + String(properties.week).padStart(2, "0") + ": "): "" ) + helpers.getDateRange(Math.floor(satPair[1].getTime()/1000))] = r
            // }
            let satPair = helpers.getSaturdayPair(r.due, 6)
            reportWeeks[(![null, undefined].includes(properties.week) ? (t("Week")+ " " + String(properties.week).padStart(2, "0") + ": "): "" ) + helpers.getDateRange(Math.floor(satPair[1].getTime()/1000))] = r
        })
        return reportWeeks
    }

    getSelectedWeek(t){
        try{
            if (!this.state.report) return ""
            let properties = JSON.parse(this.state.report?.properties ? this.state.report?.properties: "{}")
            if (!properties) return ""
            let satPair = helpers.getSaturdayPair(this.state.report?.due, 6)
            return (![null, undefined].includes(properties.week) ? (t("Week")+ " " + String(properties.week).padStart(2, "0") + ": "): "" ) + helpers.getDateRange(Math.floor(satPair[1].getTime()/1000))
        }
        catch (err){
            console.error(err);
        }
        return ""
    }


    hideOverlay() {
        if (!this.state.isLoaded) return
        this.setState({
            showCheckIn: false,
            showReportWeeks: false,
            overlay: false,
            overlayDark: false,
        })
    }

    render = () => {
        function isReportCheckedIn(report){
            let props = report?.properties
            if (props && typeof props === "string"){
                try{
                    let properties = JSON.parse(props)
                    return Boolean(properties.checked_in_at)
                }
                catch (err){

                }
            }
            return false
        }

        let reportWeeks = this.getReportWeeks(this.props.t)
        let selectedWeek = this.getSelectedWeek(this.props.t)
        let components = helpers.getComponentsFromJwt(this.props.auth.token)
        if (!components.bs_weekly_report){
            return <PageNotFound />
        }
        return (
            <Container style={{
                display: "flex",
                alignContent: "center",
                justifySelf: "center",
                paddingTop:"50px",
                flexDirection: "column",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                width:"892px",
                paddingBottom: "50px"
            }}>
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%", zIndex:81}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>
                <div onClick={() => this.hideOverlay()} style={{
                    position: "fixed",
                    width: "100%",
                    height: "100vh",
                    background: `rgba(0, 0, 0, ${this.state.overlayDark ? 0.5 : 0.05})`,
                    left: 0,
                    top: 0,
                    display: this.state.overlay || !this.state.isLoaded ? "block" : "none",
                    zIndex: 80
                }}/>
                <Row style={{marginRight: "0", marginLeft: "0", width: "792px", maxWidth: "792px"}}>
                    <Col className="page-tittle" style={{
                        paddingLeft: "0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "start"
                    }}>
                        <span style={{
                            fontSize: "18px",
                            lineHeight: "26px",
                            cursor: "pointer",
                            margin: "0 10px",
                            color: 'var(--text-color)',
                            display:"flex",
                            flexDirection:"row",
                            justifyContent:"start",
                            alignItems:"center"
                        }}>
                            <BackButton />
                            {this.props.t(this.state.pageTitle)}
                        </span>
                    </Col>
                    <Col xs={4} style={{display:"flex", flexDirection:"row-reverse"}}>
                        {this.state.isLoaded ? <ReactToPrint
                          content={this.reactToPrintContent}
                          onBeforePrint={() => this.setState({ printing: true })}
                          onAfterPrint={() => this.setState({printing: false})}
                          documentTitle="PRO - WAVE1 Report"
                          trigger={() => <Button
                            ref={(ref) => this.printButtonRef = ref}
                            variant="light" style={{
                                width:"50px", height:"50px",
                              marginRight: 0, borderRadius: '50%', background: 'var(--background-color)'
                          }}>
                              <DownloadIcon style={{ position: 'relative', top: '-3px' }} fill={'var(--text-color)'}
                                            stroke={'var(--text-color)'}/>
                          </Button>}
                        /> : ""}
                        {this.state.showCheckInButton ? <Button onClick={() => {
                            this.setState({
                                showCheckIn: !this.state.showCheckIn,
                                overlay: !this.state.showCheckIn
                            });
                        }} className="white-buttons" variant="light" style={{
                            minHeight: '44px',
                            padding: '6px 27px',
                            display: 'flex'
                        }}>
                            {this.props.t('Complete Check In')}
                        </Button> : (this.state.isLoaded && this.state.showReportWeeksButton ? <Button onClick={() => {
                            this.setState({
                                showReportWeeks: !this.state.showReportWeeks,
                                overlay: !this.state.showReportWeeks
                            });
                        }} className="white-buttons" variant="light" style={{
                            minHeight: '44px',
                            padding: '6px 27px',
                            display: 'flex'
                        }}>
                            {selectedWeek}
                        </Button>: "") }
                        <div className="groups-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            left: `-50px`,
                            top: "64px",
                            display: this.state.showReportWeeks ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "157px", top: "-42px"}}/>
                            <div style={{
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <div style={{minWidth:"100%", display:'flex', flexDirection:"column", background:"var(--background-color)", borderRadius:"10px", overflowY:"scroll", minHeight:"max-content"}}>
                                    {Object.keys(reportWeeks).map((rw, i) => (
                                      <div onClick={() => {this.props.history.push(Constants.PATIENT_STATUS + '/' + this.props.match.params.patientIdentifier + Constants.BES_REPORT + '/' + reportWeeks[rw].uid); window.location.reload()}} key={i} style={{
                                          cursor: "pointer",
                                          background: selectedWeek === rw ? "var(--border-color-faintest)" : "var(--background-color)",
                                          minHeight:"50px", borderBottom:"1px solid var(--border-color-faintest)",
                                          display:"flex", flexDirection:"row", alignItems:"center",
                                          justifyContent:'center', textAlign:'center', fontWeight:500, fontSize:"15px"}}>{rw}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="groups-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            left: `-67px`,
                            top: "64px",
                            display: this.state.showCheckIn ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "142px", top: "-42px"}}/>
                            <div style={{
                                background: "var(--background-color)",
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <CheckInCard showOverlay={() => this.setState({overlayDark:true})} location_code={this.props.auth.account?.user?.location_code} taskUid={this.state.report ? this.state.report.uid: ""} closeBox={(refresh) => {this.hideOverlay(); if (refresh) this.props.history.push(Constants.TASKS) }} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <div ref={(ref) => {this.componentRef = ref}} style={{padding:"0 0 0 7px", margin:0}}>
                    {this.state.report && this.state.report.action === "SYMPTOMS_REVIEW" && (isReportCheckedIn(this.state.report)) ? <CheckedInCard report={this.state.report} />: ""}
                    {this.state.report && this.state.report.action === "SYMPTOMS_REVIEW" ? <EsWaveReport ref={(ref) => {this.componentRef = ref}}
                                  reports={this.state.reportList} report={this.state.report} patient={this.state.patient} testReport={this.state.testReport} /> : ""}
                    {this.state.report && this.state.report.action === "CHLT_30" ? <ChltReport report={this.state.report} patient={this.state.patient} ref={(ref) => {this.componentRef = ref}} />: ""}
                    {this.state.report && this.state.report.action === "FACT_P" ? <FactPReport report={this.state.report} patient={this.state.patient} ref={(ref) => {this.componentRef = ref}} />: ""}
                </div>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(WeeklyReport)));