import axios from "axios";
import {fetcher} from "./axios";
import * as Constants from "../constants/Constants";
import {toast} from "react-toastify";

export async function uploadToS3({
                                     fileType,
                                     fileContents,
                                 }: {
    fileType: string;
    fileContents: File;
}, progressCallback: Function | null, folder_name:string="as_report") {
    const presignedPostUrl = await getPresignedPostUrl(fileType, fileContents?.name ? fileContents?.name : "MRI", folder_name);

    if (!presignedPostUrl){
        return Promise.reject("Unable to upload");
    }
    await axios.put(presignedPostUrl!.url, fileContents, {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: (progressEvent) => {
            progressCallback?.(progressEvent)
        }
    })
        .then((response) => {
            return Promise.resolve("File Uploaded Successfully");
        })
        .catch((err) => {
            return Promise.reject("Unable to upload");
        })

    return Promise.resolve(presignedPostUrl!.fields.key);
}

export async function getPresignedPostUrl(fileType: string, fileName: string, folder="user_images") {
    var data = JSON.stringify({
        query: `mutation {
          file_store(folder: "${folder}", content_type: "${fileType}", file_name:"${fileName}"){
              url
              file
          }
        }`,
        variables: {}
    });
    return fetcher(Constants.GRAPHQL_API, data, undefined)
        .then((response) => {
            if (response.errors) {
                console.debug("Got Error", response.errors);
                return Promise.reject("Unable to upload");
            }
            if (response.data && response.data.file_store){
                return Promise.resolve({url: response.data.file_store.url, fields: {key: response.data.file_store.file}});
            }
        })
        .catch((err) => {
            return Promise.reject("Unable to upload");
        });

}