import {useTranslation} from "react-i18next";

export default function TipBlock(){
    const {t} = useTranslation();
    let studyStatuses = [
      {
        title: "In Screening",
        description: "Patients who have not yet completed the screening questionnaire"
      },
      {
        title: "Screen Failure",
        description: "Patients who complete the screening questionnaire but do not qualify"
      },
      {
        title: "Active W#",
        description: "Patients who are enrolled and their current week number in the study"
      },
      {
        title: "Complete",
        description: "Patients who have completed all 12 weeks of the study"
      },
      {
        title: "Discontinued",
        description: "Patients who have withdrawn or been discontinued from the study"
      }
    ]
    return <div style={{textAlign:"start", color:"var(--text-color)", fontWeight:400, padding:"20px", background:"var(--background-color)", minWidth:"154px", maxWidth:"320px", minHeight:"94px", borderRadius:"10px", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)"}}>
      {studyStatuses.map((s, i) => <div key={i} style={{padding:"0", marginBottom: (i === studyStatuses.length-1) ? 0: "10px"}}>
      <span style={{fontWeight:600}}>{t(s.title)}</span>: <span>{t(s.description)}</span>
      </div>)}
    </div>
}