import './WaveAsSummaryReport.css'
import React from 'react'
import * as Constants from '../../constants/Constants'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Container } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download.svg'
import ChariteLogoPng from '../../assets/icons/bayde_logos/charite_logo.png'
import VivantesLogoPng from '../../assets/icons/bayde_logos/vivantes_logo.png'
import { fetcher, reportAction } from '../../utils/axios'
import StageForm from '../../components/AsJourneyReport/StageForm'
import { toast } from 'react-toastify'
import HashLoader from 'react-spinners/HashLoader'
import { withTranslation } from 'react-i18next'
import MessagePatientCard from '../../components/PatientProfile/MessagePatient'
import AsAssessmentOverview from '../../components/PatientProfile/AsAssessmentOverview'
import AsAssessmentStatusRow from '../../components/PatientProfile/AsAssessmentStatusRow'
import * as helpers from '../../helpers/helpers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactToPrint from 'react-to-print';
import { Base64 } from 'js-base64';
import ReactDOMServer from 'react-dom/server';
import { getFormattedDate } from '../../helpers/helpers'
import PractitionerCard from '../PractitionerCard'

class AsJourneySummaryReport extends React.Component {
  componentRef = null
  printButtonRef = null

  constructor (props) {
    super(props)
    let urlQueryParams = new URLSearchParams(this.props.location.search)
    this.state = {
      error: this.props.t('Unable to load the Page'),
      isLoaded: false,
      pageNumber: 1,
      numPages: null,
      report: null,
      showAsJourneyList: false,
      showOverlay: false,
      fetchData: true,
      defaultAsJourneyList: !this.props.removeAsJourneyList,
      messagePatient: this.props.messagePatientButton,
      showMessagePatientCard: false,
      reportList: [],
      colorTheme: urlQueryParams.get('theme') ? urlQueryParams.get('theme') : 'light',
      asJourney: { stages: [{}, {}, {}, {}] },
      uploadProgress: 0,
      mriUid: null, mriProgress: 0, mriFileSize: 1,
      patient: { uuid: this.props.match.params.patientIdentifier },
      showPrintVersion: false,
      uploadStartTime: 0
    }
    this.hideOverlay = this.hideOverlay.bind(this)
    this.getInputs = this.getInputs.bind(this)
    this.fileUploadRef = React.createRef()
    this.patientDataRef = React.createRef()
    this.expertUrologistOneRef = React.createRef()
    this.expertUrologistTwoRef = React.createRef()
    this.expertRadiologistRef = React.createRef()
    this.AsJourneyPrintRef = React.createRef()
  }

  componentDidMount () {
    localStorage.setItem('MRI_STATUS', 'NA')
    if (this.state.isLoaded) {
      return
    }
    reportAction('VIEWED_AS_JOURNEY', this.props.match.params.patientIdentifier)
    this.componentDidUpdate()
  }

  downloadFile(text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', text);
    pom.setAttribute('download', "report.pdf");

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }
  }

  componentDidUpdate () {
    if (this.state.isLoaded) {
      return
    }
    if (this.state.fetchData) {
      this.setState({ fetchData: false })
      this.getMriInfo()
      var data = JSON.stringify({
        query: `query ($patient_id: String!) {
                patient(id: $patient_id){
                    uuid
                    first_name
                    last_name
                    photo
                    gender
                    birthdate
                    age
                    autoimmune
                    neurological
                    cancer
                    marital_status
                    education
                    kids
                    relatives
                    chronic_conditions
                    care{
                        expert_radiologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                        expert_urologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                    }
                    as_decision_status{
                        title
                        status
                    }
                    tx_status{
                        title
                        status
                        as_year
                    }
                 
          }
                          journeys(patient_id: $patient_id) {
                              uid
                              state
                              locked
                              as_decision_phase
                              organization_id
                              authors{
                                uuid
                                first_name
                                last_name
                                photo
                                account_type
                                speciality
                                campus{
                                    name
                                }
                                contact{
                                    contact_type
                                    phone
                                    email
                                    address{
                                        first_line
                                        unit
                                        zipcode
                                        city
                                    }
                                }
                              }
                              inputs{
                                  key
                                  value
                                  updated_at
                                  author
                                  form_uid
                              }
                              stages{
                                  uid
                                  title
                                  status
                                  editor
                                  editor_type
                                  active
                                  forms{
                                    uid
                                    hide
                                    hide_save_button
                                    submit_key
                                    sub_title
                                    status
                                    structure
                                    authors
                                    updated_at
                                    inputs
                                    task{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                    }
                                  }
                                  tasks{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                        action
                                    }
                              }
                          }
                        }`,
        variables: {
          patient_id: this.props.match.params.patientIdentifier
        }
      })
      fetcher(Constants.GRAPHQL_API, data)
        .then((response) => {
          if (!response.data || !response.data.journeys) {
            console.debug('Got Error', response.errors)
            this.setState({ error: this.props.t('Unable to load the Page'), isLoaded: true })
            toast.error(this.props.t('Unable to load the Page'))
            return
          }
          let patient
          let personal_data
          if (response.data.patient) {
            patient = { ...this.state.patient, ...response.data.patient, ...response.data.patient.care }
            let ed = []
            if (patient.cancer) {
              ed.push(patient.cancer)
            }
            if (patient.neurological) {
              ed.push(patient.neurological)
            }
            if (patient.autoimmune) {
              ed.push(patient.autoimmune)
            }
            personal_data = {
              pd_birthdate_gender: (patient.birthdate ? (helpers.getFormattedBirthdate(patient.birthdate, this.props.t) + ` (${helpers.getAge(patient.birthdate)} ${this.props.t('yrs')}),`) : '') + this.props.t(patient.gender ? patient.gender : 'Male'),
              pd_age_gender: (patient.age ? (patient.age + ', ') : '') + this.props.t(patient.gender ? patient.gender : 'Male'),
              pd_comorbid_conditions: patient.chronic_conditions ? patient.chronic_conditions.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join('; ') : '',
              pd_existing_diseases: ed.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join('; '),
              pd_family_status: ((patient.marital_status ? this.props.t(patient.marital_status) : '') + (![undefined, null].includes(patient.kids) ? `; ${patient.kids} ${this.props.t(`child${patient.kids > 1 ? 'ren' : ''}`)}` : '')),
              pd_family_history: patient.relatives ? (`${this.props.t('Prostate Cancer')} - ` + patient.relatives.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join(',')) : '',
            }
          }
          let assessment = null
          response.data.journeys.forEach(j => {
            j.stages.map(stage => {
              stage.forms.map(form => {
                form.form = JSON.parse(form.structure)
              })
            })
            if (j.uid === this.props.match.params.asJourneyId) assessment = j

          })
          if (!assessment) throw Error('Invalid Report ID')
          if (patient.expert_urologist) assessment.expert_urologist = patient.expert_urologist.uuid
          if (patient.expert_radiologist) assessment.expert_radiologist = patient.expert_radiologist.uuid
          let inputs = {}
          let inputs_updated_at = {}
          if (assessment.inputs && assessment.inputs.length > 0) {
            assessment.inputs.forEach(inp => {
              inputs[inp.key] = inp.value
              inputs_updated_at[inp.key] = inp.updated_at
            })
          }
          let refs = [this.patientDataRef, this.expertUrologistOneRef, this.expertRadiologistRef, this.expertUrologistTwoRef]
          for (let o = 0; o < 4; o++) {
            assessment.stages[o].ref = refs[o]
          }
          if (inputs.psa_s_prostate_volume_0 && inputs.psa_s_psa_level_0) {
            try {
              let psa = Array.from(inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= '9'.charCodeAt(0) && '0'.charCodeAt(0) <= s.charCodeAt(0)) || s === '.')
              if (psa.length > 0) {
                let vol = Array.from(inputs.psa_s_prostate_volume_0).filter(s => (s.charCodeAt(0) <= '9'.charCodeAt(0) && '0'.charCodeAt(0) <= s.charCodeAt(0)) || s === '.')
                vol = vol.join('')
                psa = psa.join('')
                inputs.psa_s_prostate_density_0 = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6)
                // props.inputs.psa_s_prostate_volume_0 = parseFloat(vol);
              }
            } catch (error) {
              console.debug('System Error')
            }
          }
          inputs["img_material_dce_b_value"] = "N/A"
          inputs["img_material_t2w_b_value"] = "N/A"
          inputs["img_material_t1wpool_b_value"] = "N/A"
          inputs["img_material_t2w_temporal_resolution"] = "N/A"
          inputs["img_material_dwi_temporal_resolution"] = "N/A"
          inputs["img_material_t1wpool_temporal_resolution"] = "N/A"
          if (inputs.brd_cancer_core_length && inputs.brd_core_length){
            inputs.brd_cancer_core_ratio = Math.floor((parseInt(inputs.brd_cancer_core_length) / parseInt(inputs.brd_core_length)) * 100);
          }
          if (inputs?.re_eval_mp_mri_base_line_mri_prostate_volume && inputs.psa_s_psa_level_0){
            try{
              let psa = Array.from(inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
              if (psa.length > 0) {
                let vol = Array.from(`${inputs.re_eval_mp_mri_base_line_mri_prostate_volume}`).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
                vol = vol.join("")
                psa = psa.join("")
                inputs.re_eval_mp_mri_mr_prostate_density = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6);
              }
            }
            catch (error){
              console.debug("System Error");
            }
          }
          this.setState({
            isLoaded: true,
            patient: patient,
            asJourney: assessment,
            asJourneyList: response.data.journeys, ...inputs, ...personal_data,
            inputs_updated_at
          })
          let bodyContent = Base64.encode(this.getFormattedReportHTML(this.state.patient, this.state.asJourney, this.props.t, {
            isLoaded: true,
            patient: patient,
            asJourney: assessment,
            asJourneyList: response.data.journeys, ...inputs, ...personal_data,
            inputs_updated_at
          }))
          this.getReportPDF(bodyContent);
          // setTimeout(this.printButtonRef.click(), 500);
        })
        .catch((err) => {
          console.debug(err)
          this.setState({ error: this.props.t('Unable to load the Page.'), isLoaded: true })
          toast.error(this.props.t('Unable to load the Page.'))
        })

    }
  }

  getReportPDF(bodyContent){
    var data = JSON.stringify({
      query: `mutation($content: String!){
                        pdf_create(page_size:"A4" content: $content)
                    }
            `,
      variables: {
        content: bodyContent
      }
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((res) => {
        if(res?.data?.pdf_create){
          this.downloadFile(res?.data?.pdf_create)
        }
        else
          toast.error(this.props.t("Something went wrong"));
      })
      .catch((err) => {
        console.debug(err)
        this.setState({ error: this.props.t('Unable to load the Page.'), isLoaded: true })
        toast.error(this.props.t('Unable to load the Page.'))
      })
  }

  openReportListOverlay (toggle) {
    if (toggle) {
      this.setState({
        showAsJourneyList: !this.state.showAsJourneyList,
        showOverlay: !this.state.showAsJourneyList
      })
      return
    }
    this.setState({ showAsJourneyList: true, showOverlay: true })
  }

  uploadMri (mriFile, mriTaskUid) {
    if (!this.state.isLoadedMri) {
      toast.info('Please wait')
    }
    this.setState({ isLoadedMri: false, uploadStartTime: Math.floor(Date.now() / 1000), mriFileSize: mriFile.size })
    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + this.props.auth.token)
    var formdata = new FormData()
    localStorage.setItem('MRI_STATUS', 'UPLOADING')
    formdata.append('operations', `{\"query\":\"mutation ($file: Upload!, $user_id: String!, $mri_task_uid: String!) { mri_upload(mri_file: $file, uuid: $user_id, mri_task_uid: $mri_task_uid){status}}\",\"variables\":{\"file\":null, \"user_id\":\"${this.props.match.params.patientIdentifier}\",\"mri_task_uid\":\"${mriTaskUid}\"}}`)
    formdata.append('map', '{"0": ["variables.file"]}')
    formdata.append('0', mriFile, '')

    fetcher(Constants.GRAPHQL_API, formdata, {
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        this.setState({ uploadProgress: progress })
      }
    })
      .then(result => {
        if (result.errors) {
          throw Error(result.errors)
        }
        setTimeout(function () {
          localStorage.setItem('MRI_STATUS', 'UPLOADED')
        }, 5000)
        this.setState({ isLoadedMri: true, uploadStartTime: 0, uploadProgress: 0, mriStatus: 'upload_processing' })
        toast.info('MRI uploaded successfully.')
        this.getMriInfo()

      })
      .catch(error => {
        console.error(error)
        setTimeout(function () {
          localStorage.setItem('MRI_STATUS', 'UPLOAD_FAILED')
        }, 5000)

        this.setState({ isLoadedMri: true })
        toast.error('Unable to upload, please contact admin')
      })
  }

  getMriInfo () {
    let data = JSON.stringify({
      query: `mutation ($user_id: String!) {
                        resource_access(uuid: $user_id, resource_type:"mri"){
                            mris{
                                uid
                                status
                                error
                                created
                                progress
                            }
                        }
                    }`,
      variables: { user_id: this.props.match.params.patientIdentifier }
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        if (!response.errors && response.data && response.data.resource_access) {
          if (response.data.resource_access.mris && response.data.resource_access.mris.length > 0) {
            let mri = response.data.resource_access.mris.find(m => m.status === 'upload_success')
            if (mri) {
              this.setState({
                mriUid: mri.uid,
                pd_uploads_mri_upload_date: mri.created,
                mriStatus: mri.status,
                mriError: mri.error
              })
              return
            }
            let statusObjMri = response.data.resource_access.mris.find(m => m.uid === '')
            if (statusObjMri) {
              this.setState({
                mriStatus: statusObjMri.status,
                mriError: statusObjMri.error,
                mriProgress: statusObjMri.progress
              })
              if (statusObjMri.status === 'upload_processing') {
                setTimeout(() => this.getMriInfo(), 5000)
              }
            }
          }
        } else {
          // throw Error("Error retrieving patient information");
        }
      })
      .catch((err) => {
        console.debug('Error getting mri', err)
        this.setState({ isLoadedMri: true })
        // toast.error("Error retrieving patient information.");
      })
  }

  getInputs (inputs) {
    let result = []
    let keys = inputs ? inputs : Object.keys(this.state.asJourney.inputs ? this.state.asJourney.inputs : {})
    Object.keys(this.state).forEach(item => {
      if (keys.includes(item)) {
        result.push({ key: item, value: this.state[item] })
      }
    })
    return result
  }

  hideOverlay () {
    this.setState({ showAsJourneyList: false, showOverlay: false })
  }

  reactToPrintContent = () => {
    return this.componentRef
  }

  getFormattedReportHTML(patient, journey, t, state){
    let lastUpdatedTime = 0;
    if (journey.inputs && journey.inputs.length > 0)
      journey.inputs.forEach(input => {
        if (input.updated_at && input.updated_at > lastUpdatedTime){
          lastUpdatedTime = input.updated_at;
        }
      })
    let statuses = {}
    if (journey && state?.isLoaded)
    journey?.stages.forEach(s => {
      if (s && s.forms){
        s?.forms.forEach(frm => {
          let formInputs = frm.inputs
          let formLastUpdatedAt = 0;
          formInputs.forEach(f => {
            if (state?.[f] && state?.inputs_updated_at?.[f] && state.inputs_updated_at[f] > formLastUpdatedAt){
              if (!["dummy_key", "psa_s_date_of_test_0", "psa_s_prostate_density_0"].includes(f))
                formLastUpdatedAt = state.inputs_updated_at[f];
            }
          })
          let status = t(frm.status);
          if ("pd_" === frm.submit_key && frm.status === "Submitted By Patient" && state.asJourney?.created_at){
            status = t("Submitted") + " " + getFormattedDate(state.asJourney?.created_at, " DD MMM YY ", t) + " / " + t("Patient")
          }
          else if ("psa_s_" === frm.submit_key && frm.status === "Submitted By Patient" && formLastUpdatedAt > 0){
            status = t("Submitted") + " " + getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) + " / " + t("Patient")
            formLastUpdatedAt = 0;
          }
          else if (["pd_uploads_"].includes(frm.submit_key) && formLastUpdatedAt > 0){
            let status_part_one = "Submitted"
            let pd_inputs = []
            if (state?.asJourney?.authors && state?.asJourney?.inputs){
              pd_inputs = state?.asJourney?.inputs.filter(i => ["biopsy", "mri_report"].includes(i.key));
              if (pd_inputs.length > 0 )
                frm.authors = pd_inputs.filter(i => state?.asJourney?.authors.find(k => k.uuid === i.author)).map(i => i.author)
              status = "Updated"
            }
            if (frm.status === "Submitted By Patient"){
              status_part_one = "Submitted"
            }
            status = t(status_part_one) + " " + getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) + ((!frm.authors || frm.authors.length < 1 || (pd_inputs.length > 0 && frm.authors.length !== pd_inputs.length)) ? " / Patient " : "")
            formLastUpdatedAt = 0;
          }
          else if (["iief_ipss_s_"].includes(frm.submit_key)){
            let status_part_one = "Submitted"
            let i_inputs = state.asJourney?.inputs.filter(i => i.key.includes("iief_") || i.key === "iief" || i.key === "ips")
            let i_authors = []
            if (i_inputs.length > 0){
              i_inputs.forEach(f => {
                if (f?.updated_at > formLastUpdatedAt){
                  if (!["dummy_key", "psa_s_date_of_test_0", "psa_s_prostate_density_0"].includes(f))
                    formLastUpdatedAt = f?.updated_at;
                }
              })
              i_authors = i_inputs.filter(i => Boolean(i.author)).map(i => i.author)
              if (i_authors.length > 0){
                status_part_one = "Updated"
              }
            }
            if (frm.status !== "Submitted By Patient"){
              status_part_one = "Incomplete"
            }
            status = t(status_part_one) + " " + (formLastUpdatedAt > 0 ? getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t): "") + (frm.status !== "Submitted By Patient" ? "": (" / " + t("Patient")))
            formLastUpdatedAt = 0;
          }

          let authors = ""
          if (frm.authors && frm.authors.length > 0){
            let formAuthors = journey.authors.filter(a => frm.authors.includes(a.uuid));
            authors = `${formAuthors.map((author, i) => 
              `${author.last_name}, ${author.first_name.slice(0, 1)} ${( i !== frm.authors.length-1) ? "/": ""}`
            )
      }`;
          }
          if(frm?.submit_key)
            statuses[frm?.submit_key] = `${status}${formLastUpdatedAt > 0 ? getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) : ""}${frm.status && frm.status.toLowerCase().includes("patient") && authors !== "" ? " /" : (authors !== "" ? ` /`: "")}${authors}`
        })

      }

    })
    return `
<!DOCTYPE html>
<html lang="en">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap" rel="stylesheet">
<style>
    body{
        margin:0;
        padding:0;
    }
    .page{
        font-family: 'Work Sans', sans-serif;
        font-size: 10px;
        color: #191919;
        min-height: 841px;
        height:100vh;
        margin-bottom: 20px;
        background-color: white;
        page-break-after: always !important;
    }
    @media screen {
        div.page{
            width:700px;
        }
        div.div-footer {
            border-top: 2px solid #F3F3F3;
            margin-top: 20px;
        }
    }
    @media print {
        div.div-footer {
            position: fixed;
            bottom: 0;
            left:0;
            border-top: 2px solid #F3F3F3;
            margin-top: 20px;
        }
    }
    .bordered-table{
        border: 1px solid #191919;
        margin-right: 0;
        padding-right: 0;
        border-spacing: 0 !important;
        border-collapse: collapse;
    }
    .td-header{
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        background: #F3F3F3;
        border-right: 1px solid #191919;
        padding: 5px;
    }
    tr{
        border-bottom: 1px solid #191919;
    }
    .td-column{
        padding: 5px;
        width: 50%
    }
    .section{
        padding-left:15px;
        padding-right:15px;
    }
    .section-title{
        padding-left:15px;
        padding-right:15px;
        height: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        margin-top: 16px;
        margin-bottom: 5px;
        line-height: 20px;
        display:flex;
        flex-direction: row;
        align-items: center;
    }
    .section-status{
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
    }
    .section-header{
        padding:15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
    @media print {
        @page {
            size: A4 portrait;
            margin: 15px 0 0 0;
        }
    }
</style>
<head>
    <meta charset="UTF-8">
    <title>${t("Full Report")} ${t("for")} ${patient?.last_name + (patient?.first_name ? ", ": "") + patient?.first_name}</title>
</head>
<body style="">
<div class="page">
    <div class="section" style="display: flex;flex-direction: row; height:48px; border-bottom: 1px solid #F3F3F3">
        <div style="align-self: start; flex:1; display:flex; flex-direction: row; align-items: center; height:100%">
            <span style="font-style: normal;
            height: 20px;
            padding: 0 5px;
            font-weight: 600;
            line-height: 16px;
            background: #F3F3F3;
            border: 1px solid #191919;
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-radius: 2px;
            box-sizing: border-box;">
                ${t(patient?.tx_status?.title)}</span>
        </div>
        <div style="margin:20px 0 20px 20px; display: flex; flex-direction: row-reverse; flex:2;">
            <div style="align-self: center">
                <span style="font-style: normal; font-weight: 600; white-space: nowrap">${journey.state === "COMPLETED" ? t("Completed") + " " + helpers.getFormattedDate(lastUpdatedTime, " DD MMM YY "): (t(journey.state ? t(journey.state) : t('In Progress')))}</span>
                <span style="padding-left:5px;display:${patient?.care?.expert_radiologist || patient?.care?.expert_urologist ? "": "none"}"> ${t("By")} </span>
                <span style="padding-left:5px;padding-right:5px;display:${patient?.care?.expert_urologist ? "": "none"}">${helpers.getPractitionerName(patient?.care?.expert_urologist?.first_name, patient?.care?.expert_urologist?.last_name)}</span>
                <span>/</span>
                <span style="padding-left:5px;display:${patient?.care?.expert_radiologist ? "": "none"}"> ${helpers.getPractitionerName(patient?.care?.expert_radiologist?.first_name, patient?.care?.expert_radiologist?.last_name)} </span>
            </div>
        </div>
    </div>
    <div style="font-style: italic;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      padding:15px;
      border-bottom: 1px solid #F3F3F3;">
        ${t("Participation Agreement")}: ${t("The AS recommendation is a supplementary assessment of active surveillance requested by the patient (not a service of a second medical opinion)")}.
    </div>
    <div class="section-header">
        ${t('Patient Input')}
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Patient Data")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["pd_"] ? statuses["pd_"]: ""}</div>
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1;">
            <tbody>
                <tr>
                    <td class="td-header td-column" style="width: 25%">${t("Age & Gender")}</td>
                    <td class="td-column">${state?.pd_age_gender ? state?.pd_age_gender : ""}</td>
                </tr>
                 <tr>
                    <td class="td-header td-column" style="width: 25%">${t('Comorbid Conditions')}</td>
                    <td class="td-column">${state?.pd_comorbid_conditions ? state?.pd_comorbid_conditions : ""}</td>
                </tr>
                <tr>
                    <td class="td-header" style="width: 25%">${t('Existing Diseases')}</td>
                    <td style="padding:5px">${state.pd_existing_diseases ? state.pd_existing_diseases : ""}</td>
                </tr>
                <tr>
                    <td class="td-header td-column" style="width: 25%">${t('Family Status')}</td>
                    <td class="td-column">${state?.pd_family_status ? state?.pd_family_status : ""}</td>
                </tr>
                 <tr>
                    <td class="td-header" style="width: 25%">${t('Family History')}</td>
                    <td style="padding:5px">${state.pd_family_history ? state.pd_family_history : ""}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t('IIEF + IPSS Scores')}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["iief_ipss_s_"] ? statuses["iief_ipss_s_"]: ""}</div>
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1;margin-right: 7px">
            <tbody>
                 ${[
                              {
                                "title": "IIEF Total Score",
                                "value": state?.iief_total_score ? (state?.iief_total_score + " / 75"): ""
                              },
                              {
                                "title": "IIEF Erectile Function",
                                "value": state?.iief_erectile_function ? (state?.iief_erectile_function + " / 30"): ""
                              },
                              {
                                "title": "IIEF Orgasmic Function",
                                "value": state?.iief_orgasmic_function ? (state?.iief_orgasmic_function + " / 10"): ""
                              },
                              {
                                "title": "IIEF Sexual Desire",
                                "value": state?.iief_sexual_desire ? (state?.iief_sexual_desire + " / 10"): ""
                              },
                              {
                                "title": "IIEF Intercourse Satisfaction",
                                "value": state?.iief_intercourse_satisfaction ? (state?.iief_intercourse_satisfaction + " / 15"): ""
                              },
                              {
                                "title": "IIEF Overall Satisfaction",
                                "value": state?.iief_overall_satisfaction ? (state?.iief_overall_satisfaction + " / 10"): ""
                              }
                            ].map((tr, k) => 
                              `<tr>
                                  <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                                  <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                                </tr>`
                            ).join("\n")}
            </tbody>
        </table>
        <div style="flex: 1;">
          <table class="bordered-table" style="width:100%; padding-left: 5px">
              <tbody>
                   ${[
                        {title:"IPSS Total Score*", value: state?.iief_ipss_s_total_score ? (state?.iief_ipss_s_total_score + " / 35"): ""},
                        {title:"Quality of Life Score", value: state?.iief_ipss_s_quality_of_life_score}
                      ].map((tr, k) => 
                                `<tr style="row-span: 1">
                                    <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                                    <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                                  </tr>`
                              ).join("\n")}
              </tbody>
          </table>
          <div style="padding-top:20px;
                      font-style: italic;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 16px;">
            * ${t("IPSS Total Score")}
            <ul>
              <li>0-7 ${t("Mildly Symptomatic")}</li>
              <li>8-19 ${t("Moderately Symptomatic")}</li>
              <li>20-35 ${t("Severely Symptomatic")}</li>
            </ul>
          </div>
        </div>
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t('PSA Sequence')}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["psa_s_"] ? statuses["psa_s_"]: ""}</div>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('PSA Level')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_0 ? state?.psa_s_psa_level_0: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_1 ? state?.psa_s_psa_level_1: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_2 ? state?.psa_s_psa_level_2: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_3 ? state?.psa_s_psa_level_3: ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Date of Test')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_0 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_0), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_1 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_1), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_2 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_2), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_3 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_3), 'DD MMM YY', t) : ''}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Prostate Volume')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_0 ? (state.psa_s_prostate_volume_0 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_1 ? (state.psa_s_prostate_volume_1 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_2 ? (state.psa_s_prostate_volume_2 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_3 ? (state.psa_s_prostate_volume_3 + " mL") : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Prostate Density')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_0 ? (state.psa_s_prostate_density_0 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_1 ? (state.psa_s_prostate_density_1 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_2 ? (state.psa_s_prostate_density_2 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_3 ? (state.psa_s_prostate_density_3 + " ng/mL²") : ""}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t('Uploads')}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["pd_uploads_"] ? statuses["pd_uploads_"]: ""}</div>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 33%">${t('Biopsy Report')}</td>
                <td style="padding:5px; border-right: 1px solid #191919;">${state?.pd_uploads_biopsy_report_date ? (t("Submitted") + " " + helpers.getFormattedDate(state?.inputs_updated_at?.biopsy, "DD MMM YY", t)): ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 33%">${t('MRI Report')}</td>
                <td style="padding:5px; border-right: 1px solid #191919;">${state?.pd_uploads_mri_report_date ? (t("Submitted") + " " + helpers.getFormattedDate(state?.inputs_updated_at?.mri_report, "DD MMM YY", t)): ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 33%">${t('MRI')}</td>
                <td style="padding:5px; border-right: 1px solid #191919;">${state?.pd_uploads_mri_upload_date ? (t("Submitted") + " " + helpers.getFormattedDate(state?.pd_uploads_mri_upload_date, "DD MMM YY", t)): ""}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="page">
    <div class="section-header">
        ${t('Expert Urologist')}
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Biopsy Report Data")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["brd_"] ? statuses["brd_"]: ""}</div>
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1; margin-right: 7px">
            <tbody>
                ${[
                  {title: "Date of Biopsy", value: state?.brd_date_of_biopsy  ? helpers.getFormattedDate(state?.brd_date_of_biopsy, "DD MMM YY", t): ""},
                  {title: "Imaging Guided Type", value: state?.brd_imaging_guided_type},
                  {title: "Gleason Score", value: state?.brd_gleason_score},
                  {title: "ISUP Score", value: state?.brd_isup_score},
                  {title: "Number of Cores", value: state?.brd_number_of_cores},
                ].map((tr, k) =>
                  `<tr style="row-span: 1">
                                                <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                                                <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                                              </tr>`
                ).join("\n")}
            </tbody>
        </table>
        <table class="bordered-table" style="flex: 1;">
            <tbody>
                ${[
                    {title: "Number of Positive Cores", value: state?.brd_number_of_positive_cores},
                    {title: "Core Length", value: state?.brd_core_length ? (state?.brd_core_length+ " mm"): ""},
                    {title: "Cancer Core Length", value: state?.brd_cancer_core_length ? (state?.brd_cancer_core_length + " mm"): ""},
                    {title: "Cancer Core Ratio", value: ![null, undefined, ""].includes(state?.brd_cancer_core_ratio) ? (state?.brd_cancer_core_ratio + " %"): ""},
                    {title: "Intraductal PCa", value: state?.brd_intraductal_pca}
                ].map((tr, k) =>
                  `<tr style="row-span: 1">
                                                <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                                                <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                                              </tr>`
                ).join("\n")}
            </tbody>
        </table>
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Recommendation")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["expert_urologist_decision_one"] ? statuses["expert_urologist_decision_one"]: ""}</div>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 33%">
                    ${state?.expert_urologist_decision_one ? 
                                  `${patient.last_name}, ${patient.first_name} ${state?.expert_urologist_decision_one?.toLowerCase() === "yes" ? t("is a candidate for Active Surveillance protocol.") : t("is not a candidate for Active Surveillance protocol.")}`
                                 : ""}
                </td>
            </tr>
            <tr>
                <td class="td-column" style="width: 33%">
                    <b>${t('Comments')}:</b> ${state?.expert_urologist_decision_one_comments ? state?.expert_urologist_decision_one_comments : ""}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    
    <div class="section-header">
        ${t('Expert Radiologist')}
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("MRI Quality Report")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["mqr_"] ? statuses["mqr_"]: ""}</div>
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1; margin-right: 7px">
            <tbody>
                ${[
                    {title: "Date of MRI", value: state?.date_of_mp_mri  ? helpers.getFormattedDate(state?.date_of_mp_mri, "DD MMM YY", t): ""},
                    {title: "Scan Type", value: state?.mqr_scan_type},
                    {title: "Default Value", value: state?.default_value_img_material}
                  ].map((tr, k) =>
                    `<tr style="row-span: 1">
                      <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                      <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                    </tr>`
                  ).join("\n")}
            </tbody>
        </table>
        <table class="bordered-table" style="flex: 1;">
            <tbody>
                ${[
                    {title: "Field Strength", value: state?.mqr_field_strength},
                    {title: "Endorectal Coli", value: state?.mqr_endorectal_coli},
                    {title: "Antispasmodic", value: state?.mqr_antispasmodic}
                  ].map((tr, k) =>
                    `<tr style="row-span: 1">
                        <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                        <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                      </tr>`
                    ).join("\n")}
            </tbody>
        </table>
    </div>
    <div class="section" style="display: flex; flex-direction: row; margin-top: 15px">
        <table style="flex: 1;">
            <tbody>
                <th style="row-span: 1">
                ${[
                  'T2w', "DWI", "DCE", "T1w-Pool"
                ].map((tr, k) =>
                  `<td class="td-header td-column" style="width: 18%; border-left: 1px solid #191919; border-top: 1px solid #191919">${tr}</td>`
                ).join("\n")}
                </th>
                ${[
                  "Orientation", "FOV", "Layer Thickness", "In-plane Resolution", "b-Value", "Temporal Resolution", "Contrast", "SNR"
                ].map((tr, k) =>
                    `<tr style="row-span: 1">
                      <td class="td-header td-column" style="width: 33%; border-left: 1px solid #191919">${t(tr)}</td>
                      <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${t(state?.["img_material_t2w_" + tr.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()] || state?.default_value_img_material)}</td>
                      <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${t(state?.["img_material_dwi_" + tr.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()] || state?.default_value_img_material)}</td>
                      <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${state?.mqr_scan_type && state?.mqr_scan_type.toLowerCase() === "biparametric" ? t("N/A") : t(state?.["img_material_dce_" + tr.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()] || state?.default_value_img_material)}</td>
                      <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${t(state?.["img_material_t1wpool_" + tr.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()] || state?.default_value_img_material)}</td>
                    </tr>`
                  ).join("\n")}
                <tr style="row-span: 1">
                    <td class="td-header td-column" style="width: 33%; border-left: 1px solid #191919">${t("Comments")}</td>
                    <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${state?.img_material_t2w_comments}</td>
                    <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${state?.img_material_dwi_comments}</td>
                    <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${state?.img_material_dce_comments}</td>
                    <td class="td-column" style="width: 18%; border-right: 1px solid #191919">${state?.img_material_t1wpool_comments}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="page">
    <div class="section-header">
        ${t('Expert Radiologist')} ${t("Continued")}
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Reevaluation of MRI")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["re_eval_mp_mri_"] ? statuses["re_eval_mp_mri_"]: ""}</div>
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1; margin-right: 7px">
            <tbody>
                ${[
                  {title: "Baseline MRI (before inclusion to AS) - Prostate Volume", value: state?.re_eval_mp_mri_base_line_mri_prostate_volume ? (state?.re_eval_mp_mri_base_line_mri_prostate_volume + " mL") : "" },
                  {title: "mr Prostate Density", value: state?.re_eval_mp_mri_mr_prostate_density ? (state?.re_eval_mp_mri_mr_prostate_density + " ng/mL²") : "" },
                  {title: "Number of PI-RADS Lesions", value: state?.number_of_pi_rads_lesions},
                  {title: "Primary PI-RADS", value: state?.primary_pi_rads}
                ].map((tr, k) =>
                  `<tr style="row-span: 1">
                      <td class="td-header td-column" style="width: 67%">${t(tr.title)}</td>
                      <td class="td-column">${tr?.value ? tr?.value : ""}</td>
                    </tr>`
                ).join("\n")}
            </tbody>
        </table>
    </div>
    <div class="section" style="display: flex; flex-direction: row; margin-top: 15px">
        <table style="flex: 1;">
            <tbody>
                <th style="row-span: 1">
                ${['MR PI-RADS Score (2.1) - L1', "MR PI-RADS Score (2.1) - L2", "MR PI-RADS Score (2.1) - L3", "MR PI-RADS Score (2.1) - L4"].slice(0, parseInt(state?.number_of_pi_rads_lesions || "0")).map((tr, k) =>
                  `<td class="td-header td-column" style="width: 18%; border-left: 1px solid #191919; border-top: 1px solid #191919">${t(tr)}</td>`
                ).join("\n")}
                </th>
                ${[
                    "Zone", "Diameter", "Series No.", "DWI", "T2", "DCE", "PI-RADS", "Extraprostatic Expansion (T3a)", "Seminal Vesicle Infiltration"
                  ].map((tr, k) =>
                    `<tr style="row-span: 1">
                      <td class="td-header td-column" style="width: 33%; border-left: 1px solid #191919; border-right: 1px solid #191919;">${t(tr)}</td>
                      ${['mr_pi_score_l1_', "mr_pi_score_l2_", "mr_pi_score_l3_", "mr_pi_score_l4_"].slice(0, parseInt(state?.number_of_pi_rads_lesions || "0")).map((r, l) => `<td style="border-right:1px solid #191919; padding: 0 5px;"
                                        className={'summary-table-cell'}>
                                    ${tr === "DCE" && state?.mqr_scan_type && state?.mqr_scan_type.toLowerCase() === "biparametric" ? t("N/A") : t(state?.["re_eval_mp_mri_part_two_" + r + tr.replaceAll("-", "_").replaceAll(" ", "_").replaceAll("/", "_").replaceAll("(", "").replaceAll(")", "").toLowerCase()])}
                                    ${tr === "Diameter" ? " mm": ""}
                                  </td>`).join("\n")}
                    </tr>`
                    ).join("\n")}
            </tbody>
        </table>
    </div>
    
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Recommendation")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["expert_radiologist_decision"] ? statuses["expert_radiologist_decision"]: ""}</div>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 33%">
                    ${state?.expert_radiologist_decision ?
      `${patient.last_name}, ${patient.first_name} ${state?.expert_radiologist_decision?.toLowerCase() === "yes" ? t("is a candidate for Active Surveillance protocol.") : t("is not a candidate for Active Surveillance protocol.")}`
      : ""}
                </td>
            </tr>
            <tr>
                <td class="td-column" style="width: 33%">
                    <b>${t('Comments')}:</b> ${state?.expert_radiologist_decision_comments ? state?.expert_radiologist_decision_comments : ""}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    
    <div class="section-header">
        ${t('Expert Urologist')}
    </div>
    <div class="section-title">
        <div style="flex:1;text-align: start">${t("Recommendation")}</div>
        <div class="section-status" style="flex:1;text-align: end">${statuses["expert_urologist_decision_two"] ? statuses["expert_urologist_decision_two"]: ""}</div>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 33%">
                    ${state?.expert_urologist_decision_two ?
      `${patient.last_name}, ${patient.first_name} ${state?.expert_urologist_decision_two?.toLowerCase() === "yes" ? t("is a candidate for Active Surveillance protocol.") : t("is not a candidate for Active Surveillance protocol.")}`
      : ""}
                </td>
            </tr>
            <tr>
                <td class="td-header" style="width: 33%">
                    <b>${t('Comments')}:</b> ${state?.expert_urologist_decision_two_comments ? state?.expert_urologist_decision_two_comments : ""}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

</body>
</html>
`
  }

  render = () => {
    return <div style={{overflow:"scroll", alignContent:'center', justifyContent:'center', display:"flex", background:"white", height:"100vh"}}>
      <ReactToPrint
        content={this.reactToPrintContent}
        documentTitle="report"
        trigger={() => <Button
          ref={(ref) => this.printButtonRef = ref}
          style={{
          marginRight: 0,
          display: 'none'
        }}>
        </Button>}
      />
      <div style={{width:"700px"}}>
        <span ref={(ref) => {this.componentRef = ref}} dangerouslySetInnerHTML={{__html: this.getFormattedReportHTML(this.state.patient, this.state.asJourney, this.props.t, this.state)}} />
      </div>
    </div>
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    auth: dispatch.auth,
  }
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(AsJourneySummaryReport)))