import {Col, Row} from "reactstrap";
import * as helpers from "../../helpers/helpers";
import PractitionerCard from "../PractitionerCard";
import React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'
import * as Constants from "../../constants/Constants";
import { useHistory } from 'react-router-dom';
import Button from '@restart/ui/Button';


export default function AsAssessmentStatusRow(props) {
    const {t} = useTranslation();
    let history = useHistory();
    let patient = props.patient ? (props.patient.care ? props.patient: {...props.patient, care:{}}) : {care: {}}
    let lastUpdatedTime = 0;
    if (props.assessment.inputs && props.assessment.inputs.length > 0)
        props.assessment.inputs.forEach(input => {
            if (input.updated_at && input.updated_at > lastUpdatedTime){
                lastUpdatedTime = input.updated_at;
            }
        })
    if (props.assessment?.inputsUpdates && Object.values(props.assessment?.inputsUpdates).length > 0)
        Object.keys(props.assessment.inputsUpdates).forEach(inp => {
            if (["survey_id", "answers"].includes(inp) && props.assessment.inputsUpdates[inp] && props.assessment.inputsUpdates[inp] > lastUpdatedTime){
                lastUpdatedTime = props.assessment.inputsUpdates[inp];
            }
        })

    let fullStatus = <>
        <span style={{width:"10px"}} />
        {props.assessment.state === "COMPLETED" ? <span
            style={{paddingLeft: "5px", fontWeight:600, whiteSpace:"nowrap"}}>{t("Completed") + " "}{helpers.getFormattedDate(lastUpdatedTime, " DD MMM YY ")}</span> :
          <span style={{fontWeight:600, whiteSpace:"nowrap"}}>{t(props.assessment.state ? t(props.assessment.state) : t('In Progress'))}</span>}
        {patient.care.expert_radiologist || patient.care.expert_urologist ?
          <span style={{paddingLeft: "5px"}}> {t("By_")} </span> : ""}
        {patient.care.expert_urologist ? <span className={"person-card-container"}
                                               style={{textDecoration: "underline", paddingLeft: "5px", paddingRight: "5px", cursor: "default"}}>
                                {helpers.getPractitionerName(patient.care.expert_urologist.first_name, patient.care.expert_urologist.last_name)}
            <div style={{
                position: "relative",
                height: 0,
                background: "transparent",
                left: "-386px"
            }}>
                                    <div className="person-card-container-tool-tip"
                                         style={{background: "transparent", border: 0, zIndex: 200}}>
                                        <PractitionerCard person={patient.care.expert_urologist}/>
                                    </div>
                                </div>
                            </span> : ""}
        {patient.care.expert_radiologist && patient.care.expert_urologist ? "/": ""}
        {patient.care.expert_radiologist ?
          <span style={{paddingLeft: "5px"}}><span className={"person-card-container"}
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "default"
                                                   }}> {helpers.getPractitionerName(patient.care.expert_radiologist.first_name, patient.care.expert_radiologist.last_name)}
              <div style={{
                  position: "relative",
                  height: 0,
                  background: "transparent",
                  left: "-386px"
              }}>
                                    <div className="person-card-container-tool-tip"
                                         style={{background: "transparent", border: 0, zIndex: 200}}>
                                        <PractitionerCard person={patient.care.expert_radiologist}/>
                                    </div>
                                </div>
                            </span></span> : ""}
    </>
    return <Row style={{padding: 0}}>
        <Col xs={6} style={{margin: 0, padding: 0, textAlign:"start", display:"flex", flexDirection: "row"}}>
            {props.patient.tx_status ? helpers.getTxStatus(props.patient.tx_status, t, null, props.asStatusColor, props.boldText) : ""}
            {props.patient && !props.hidePatientName ? <span style={{alignSelf:"center", marginLeft:"5px", fontWeight:600, fontSize:"14px", lineHeight:"22px"}}>{t('Assessment') + " " + t('for') + " " + props.patient.last_name + ', ' + props.patient.first_name}</span>: ""}
            {props.hidePatientName && !props.hideExpansionArrow ? fullStatus: ""}
        </Col>
        <Col xs={6} style={{
            textAlign: "end",
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "end",
            justifyItems: "end"
        }}>
            <span>{props.assessment.status}</span>
            {props.hidePatientName && !props.hideExpansionArrow ? "": fullStatus}
            {props.showReportButtons ? <Button onClick={() => history.push(Constants.PATIENT_STATUS + "/" + props.patientIdentifier + Constants.AS_JOURNEY + "/" + props.assessment.uid)}
                                                                                         variant="light" style={{minWidth:"116px", height:"32px", marginLeft:"15px", borderRadius:"100px", border:"0", background:"var(--background-color-faintest)", textAlign:"center", color:"var(--text-color)", fontWeight:400}}>
                {t("Full Report")}
                <svg style={{marginLeft:"10px", position:"relative", top:"-1px" }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.23072 10.5353C3.97472 10.5353 3.71872 10.4373 3.52372 10.2423C3.13272 9.85234 3.13272 9.21934 3.52372 8.82834L6.35172 6.00034L3.52372 3.17134C3.13272 2.78134 3.13272 2.14734 3.52372 1.75734C3.91472 1.36734 4.54672 1.36734 4.93772 1.75734L8.47272 5.29334C8.86372 5.68334 8.86372 6.31634 8.47272 6.70734L4.93772 10.2423C4.74272 10.4373 4.48672 10.5353 4.23072 10.5353Z" fill="var(--border-color-fainter)"/>
                </svg>
            </Button> : ""}
            {props.showReportButtons && ["COMPLETED", "FINALDECISION"].includes(props.assessment.state) ? <Button onClick={() => history.push(Constants.PATIENT_STATUS + "/" + props.patientIdentifier + Constants.AS_JOURNEY + "/" + props.assessment.uid + Constants.AS_JOURNEY_SUMMARY)}
                                                                                         variant="light" style={{minWidth:"116px", height:"32px", marginLeft:"15px", borderRadius:"100px", border:"0", background:"var(--background-color-faintest)", textAlign:"center", color:"var(--text-color)", fontWeight:400}}>
                {t("Summary")}
                <svg style={{marginLeft:"10px", position:"relative", top:"-1px" }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.23072 10.5353C3.97472 10.5353 3.71872 10.4373 3.52372 10.2423C3.13272 9.85234 3.13272 9.21934 3.52372 8.82834L6.35172 6.00034L3.52372 3.17134C3.13272 2.78134 3.13272 2.14734 3.52372 1.75734C3.91472 1.36734 4.54672 1.36734 4.93772 1.75734L8.47272 5.29334C8.86372 5.68334 8.86372 6.31634 8.47272 6.70734L4.93772 10.2423C4.74272 10.4373 4.48672 10.5353 4.23072 10.5353Z" fill="var(--border-color-fainter)"/>
                </svg>
            </Button> : ""}
            <div onClick={props.onClick} style={{alignSelf: "center", display: props.hideExpansionArrow ? "none": ""}}>
                <svg style={{
                    alignSelf: "center",
                    marginLeft: "10px",
                    marginRight: "10px",
                    padding: 0,
                    cursor: "pointer",
                    transform: `rotate(${props.expansions[props.assessment.uid] ? "90deg" : "0deg"})`
                }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M4.23072 10.5353C3.97472 10.5353 3.71872 10.4373 3.52372 10.2423C3.13272 9.85234 3.13272 9.21934 3.52372 8.82834L6.35172 6.00034L3.52372 3.17134C3.13272 2.78134 3.13272 2.14734 3.52372 1.75734C3.91472 1.36734 4.54672 1.36734 4.93772 1.75734L8.47272 5.29334C8.86372 5.68334 8.86372 6.31634 8.47272 6.70734L4.93772 10.2423C4.74272 10.4373 4.48672 10.5353 4.23072 10.5353Z"
                          fill="var(--border-color-fainter)"/>
                </svg>
            </div>
        </Col>
    </Row>;
}

AsAssessmentStatusRow.propTypes = {
    assessment: PropTypes.any,
    onClick: PropTypes.func,
    expansions: PropTypes.shape({})
};