import HighRiskIcon from '../assets/icons/HighRisk.svg';
import WaitAndWatchIcon from '../assets/icons/WaitAndWatch.svg';
import {useTranslation} from "react-i18next";

export default function TipBlock(){
    const {t} = useTranslation();
    return <div style={{fontWeight:400, padding:"20px", background:"var(--background-color)", minWidth:"154px", minHeight:"94px", borderRadius:"10px", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)"}}>
            <div style={{display:"flex", flexDirection:"row", padding:"0", alignItems:"center", marginBottom:"10px"}}>
            <img src={HighRiskIcon} alt="risk status" style={{height:"22px", width:"22px", marginRight:"10px"}}/>{t("risk_priority")}
            </div>
            <div style={{display:"flex", flexDirection:"row", padding:"0", alignItems:"center"}}>
            <img src={WaitAndWatchIcon} alt="risk status" style={{height:"22px", width:"22px", marginRight:"10px"}}/>{t("watch_wait")}
            </div>
    </div>
}