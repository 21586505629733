import './LanguageSelector.css';
import React, {useState} from "react";
import {getAllLanguages} from "../helpers/helpers";
import {useDispatch, useSelector} from "react-redux";
import authSlice, {isUserAcquiredIn, languageSelector} from "../store/slices/auth";
import { fetcher, reportAction } from '../utils/axios'
import * as Constants from "../constants/Constants";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import HashLoader from "react-spinners/HashLoader";

export default function LanguageSelector(props) {
    const allSupportedLanguages = getAllLanguages();
    let isUserLoggedIn = useSelector(isUserAcquiredIn);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const changeLanguageHandler = (lang) =>
    {
        localStorage.setItem("lng", lang);
        i18n.changeLanguage(lang);
    }
    const [showLanguageSelection, setShowLanguageSelection] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    let langSelected = useSelector(languageSelector);
    function setLanguage(lang){
        updateIsLoading(true);
        if (isUserLoggedIn){
            reportAction("ACTION_CHANGED_LANGUAGE");
            var data = JSON.stringify({
                query: `mutation{
                        user_modify(language:"${lang}"){
                            language
                        }
                    }`,
                variables: {}
            });
            fetcher(Constants.GRAPHQL_API, data)
                .then((response) => {
                    if(response.errors || !response.data.user_modify){
                        console.debug("Got Error", response.errors);
                        toast.error(t("unable_to_change_language"));
                        updateIsLoading(false);
                        return;
                    }
                    dispatch(authSlice.actions.setLanguage(lang));
                    changeLanguageHandler(lang);
                    updateIsLoading(false);
                    setShowLanguageSelection(false);
                    toast.info(t("language_changed_successfully"));
                })
                .catch((err) => {
                    updateIsLoading(false);
                    setShowLanguageSelection(false);
                    toast.error(t("unable_to_change_language"));
                });
        }
        else{
            localStorage.setItem("language_selected_timestamp",`${Date.now()}`)
            dispatch(authSlice.actions.setLanguage(lang));
            changeLanguageHandler(lang);
            setShowLanguageSelection(false);
            updateIsLoading(false);
        }

    }

    return <div style={{background: "transparent"}}>
        <div style={{height: 0, position: "relative", left: "-110px", top: "50px"}}><HashLoader color="#4676F4"
                                                                                            loading={isLoading}
                                                                                            size={25}/></div>
        <div onClick={() => setShowLanguageSelection(!showLanguageSelection)} style={{
            width: "44px",
            height: "44px",
            borderRadius: "50%",
            background: props.mainSelectionBackground,
            alignItems: "center",
            alignContent: "center",
            display: "flex"
        }}>
            {allSupportedLanguages[langSelected] ?
                <img className={"lng-img-selected"} src={allSupportedLanguages[langSelected].icon} alt="icn"/> :
                <img className={"lng-img-selected"} src={allSupportedLanguages["en-us"].icon} alt="icn"/>}
        </div>
        <div onMouseEnter={() => setShowLanguageSelection(true)}
             className="language-selection-container" style={{
            position: "relative",
            background: "var(--background-color)",
            width: "0",
            height: "0",
            right: `190px`,
            top: "25px",
            display: showLanguageSelection ? "block" : "none",
            zIndex: 100
        }}>
            <div className="triangle-with-shadow"
                 style={{position: "absolute", left: "186px", top: "-42px"}}/>
            <div style={{
                background: "var(--background-color)",
                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
                width: "242px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    justifyItems: "center",
                    width: "100%"
                }}>
                    {Object.values(allSupportedLanguages).map((lang, i) => (
                        <div onClick={() => setLanguage(lang.key)}
                            key={i} style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            height: "62px",
                            padding: "24px 20px",
                            borderTopLeftRadius: i === 0 ? "10px" : "",
                            borderTopRightRadius: i === 0 ? "10px" : "",
                            borderBottomLeftRadius: i === Object.keys(allSupportedLanguages).length - 1 ? "10px" : "",
                            borderBottomRightRadius: i === Object.keys(allSupportedLanguages).length - 1 ? "10px" : "",
                            background: langSelected === lang.key ? "var(--lightest-gray)": "",
                            borderBottom: i < Object.keys(allSupportedLanguages).length - 1 ? "1px solid var(--border-color-faintest)" : ""
                        }}>
                            <img className={"lng-img"} src={lang.icon} alt="icn"/>
                            <span style={{paddingLeft: "10px", alignSelf: "center", cursor:"pointer"}}>{lang.title}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
}

// function convertl(inps){
//     for (key in inps){
//         inps[key] = (Array.from(inps[key]).map(t => t !== " " ? "$": " ")).join("");
//     }
//     return inps;
// }