import Button from "@restart/ui/esm/Button";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import './AddPatientForm.css';
import Select from 'react-select';
import {useHistory} from "react-router-dom";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";

const reactSelectStyles = {
    container: (base, state) =>({
        ...base,
        width:"100%"
    }),
    control: (base, state)=>({
        ...base,
        border: "1px solid var(--border-color-fainter)",
        borderRadius: "10px",
        height:"52px",
        outline:0,
        boxShadow: "none",
    }),
    menu: base => ({
      ...base,
      outline:0,
      zIndex:110
    }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "var(--bs-primary)" : "var(--background-color)",
        opacity: state.isFocused ? 0.9: 1,
        color: state.isSelected ? "var(--background-color)": "var(--text-color)",
        zIndex:110
    }),
    indicatorSeparator: (base, state)=>({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "var(--text-color)"
    })
  };

  const reactSelectStylesAddNewGroup = {
    ...reactSelectStyles,
    singleValue: (base, state)=>({
        ...base,
        color:"var(--text-color-faintest)"
    }),
  };

export default function EditGroups(){
    const {t} = useTranslation();
    const [addNewGroup, setAddNewGroup] = useState(false);
    const [availableGroups, setAvailableGroups] = useState([
        {value: "Foreman S Patients", label: "Foreman S Patients"}, 
        {value: "Cunnigham C Patients", label: "Cunnigham C Patients"}, 
        {value: "Group 3", label: "Group 3"}, 
        {value: "Group 4", label: "Group 4"}, 
        {value: "Group 5", label: "Group 5"}, 
        {value: "Group 6", label: "Group 6"}]);
    const [groupList, setGroupList] = useState([availableGroups[0], availableGroups[1]]);
    const history = useHistory();

    function updateGroupSelection(t, l){
        let i, index;
        let groupExists = false;
        for(i=0; i<groupList.length; i++){
            if(groupList[i].value === t.value){
                groupExists = true;
                index = i;
            }
        }
        if (groupExists){
            if(index === l){
                return;
            }
            else{
                toast.info(t("group_already_exists"));
            }
        }
        else{
            let newList = [...groupList];
            newList[l] = t;
            setGroupList(newList);
        }

    }

    function removeGroup(l){
        let newList = [...groupList];
        newList.splice(l, 1);
        setGroupList(newList);
    }

    function addGroup(t){
        if (t.value === "Select Group"){
            return;
        }
        setAddNewGroup(false);
        updateGroupSelection(t, groupList.length);
    }

    return <Container style={{background:"transparent", width:"792px", paddingTop:"40px", color:"var(--text-color)"}}>
    <Row style={{marginBottom:"30px", alignItems:"center"}}>
        <Col xs={1}>
        <Button onClick={() => history.goBack()} className="white-buttons" variant="light" style={{width:"44px", height:"44px", padding:"8px", textAlign:"center", border: "0", marginLeft:"0"}}>
                <svg style={{transform:"rotate(90deg)", cursor:"pointer"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--border-color)"/>
                </svg>
            </Button> 
        </Col>
        <Col xs={2} style={{fontWeight:600, lineHeight:"26px", fontSize:"18px"}}>{t("groups")}</Col>
    </Row>
    <Container style={{fontWeight:400, lineHeight:"22px", fontSize:"14px", padding:"50px", background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
        <Row style={{fontWeight:600, marginBottom:"10px"}}>{t("groups")}</Row>
        <Row style={{marginBottom:"20px"}}>{t("add_or_remove_the_patient_to_any_of_these_groups_")}</Row>
        
        {groupList.map((grp, i) => (
            <Row key={i} style={{marginBottom: "20px", width:"100%"}}>
                <Col xs={11} style={{paddingLeft:0, paddingRight:"5px"}}>
                    <Select styles={reactSelectStyles}
                            value={[grp]}
                            options={availableGroups}
                            onChange={(t) => updateGroupSelection(t, i)}        
                        />    
                </Col>
                <Col xs={1} style={{paddingLeft:"5px", paddingRight:"5px", display:"flex", flexDirection:"row", justifyContent:"center", justifyItems:"center", alignContent:"center", alignItems:"center"}}>
                    <svg onClick={() => removeGroup(i)} style={{cursor:"pointer"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="9" stroke="var(--border-color)" strokeWidth="2"/>
                        <line x1="7" y1="10.2" x2="13" y2="10.2" stroke="var(--border-color)" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </Col>
            </Row>
        ))}
        <Row style={{marginBottom: "20px", width:"100%", display: addNewGroup ? "flex": "none"}}>
                <Col xs={11} style={{paddingLeft:0, paddingRight:"5px"}}>
                    <Select styles={reactSelectStylesAddNewGroup}
                            value={[{value: "Select Group", label: "Select Group"}]}
                            options={availableGroups}
                            onChange={(t) => addGroup(t)}        
                        />    
                </Col>
                <Col xs={1} style={{paddingLeft:"5px", paddingRight:"5px", display:"flex", flexDirection:"row", justifyContent:"center", justifyItems:"center", alignContent:"center", alignItems:"center"}}>
                    <svg onClick={() => setAddNewGroup(false)} style={{cursor:"pointer"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="9" stroke="var(--border-color)" strokeWidth="2"/>
                        <line x1="7" y1="10.2" x2="13" y2="10.2" stroke="var(--border-color)" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </Col>
            </Row>
        
        <Row style={{marginBottom:"50px"}}>
            <Col xs={4} style={{paddingLeft:0, paddingRight:"5px"}}>
            <Button onClick={() => setAddNewGroup(true)} variant="light" style={{minWidth:"150px", fontWeight:600, height:"44px", border:0, borderRadius:"100px", background:"var(--background-color-faint)", marginLeft:"0", color:"var(--border-color)", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
            <svg style={{marginRight:"10px"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.477 0 0 4.478 0 10C0 15.522 4.477 20 10 20C15.522 20 20 15.522 20 10C20 4.478 15.522 0 10 0ZM13 9.196H11V7.196C11 6.643 10.553 6.196 10 6.196C9.447 6.196 9 6.643 9 7.196V9.196H7C6.447 9.196 6 9.643 6 10.196C6 10.749 6.447 11.196 7 11.196H9V13.196C9 13.749 9.447 14.196 10 14.196C10.553 14.196 11 13.749 11 13.196V11.196H13C13.553 11.196 14 10.749 14 10.196C14 9.643 13.553 9.196 13 9.196ZM10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18C5.588 18 2 14.411 2 10C2 5.589 5.588 2 10 2Z" fill="var(--border-color)"/>
            </svg>
            Add Group
            </Button>
            </Col>
        </Row>
        <hr style={{background:"var(--border-color-fainter)", width:"115%", position: "relative", left:"-50px", marginBottom:"40px"}} />
        <Row style={{fontWeight:600, alignItems:"center", justifyContent:"center", position: "relative", bottom:"0px"}}>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", border:"1px solid var(--border-color-faint)", background:"var(--background-color)", textAlign:"center", marginLeft:"0", color:"var(--text-color-faint)"}}>{t("Cancel")}</Button>
            </Col>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: "var(--bs-primary)", color: "var(--background-color)"}}>{t("save")}</Button>
            </Col>
        </Row>
    </Container>
    </Container>
}