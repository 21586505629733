import { Col, Row, Container } from 'reactstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as helpers from '../../helpers/helpers'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import patPDFEnglish from '../../assets/PROWAVE1 Patient Instruction Sheet BLANK_English.pdf'
import patPDFSpanish from '../../assets/PROWAVE1 Patient Instruction Sheet BLANK_Spanish.pdf'

export default function PatientOnboardingFormEs (props) {
  const { t, i18n } = useTranslation()
  const [numPages, setNumPages] = useState('')

  const reactStyles = {
    "react-pdf__Page__canvas": (base, state) =>({
      ...base,
      fontWeight: 600
    })
  };

  return <Container style={{
    padding: "20px",
    margin: 0,
    width: '1200px',
    background: 'transparent',
    color: 'var(--text-color)'
  }}>
    <Document
      style={{margin:0, padding:0}}
      file={i18n.language === "es" ? patPDFSpanish : patPDFEnglish}
      onLoadSuccess={({numPages}) => setNumPages(numPages)}>
      {[...Array(numPages)].map((pg, i) => (
        <Page style={reactStyles} key={i} pageNumber={i + 1} width={800} canvasBackground="#FFFFFF">
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"102px", fontSize:"30px", fontWeight:600, letterSpacing:"36px"}}>{i === 0 ? props.code.substring(0,1): ""}</span></div>
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"150px", fontSize:"30px", fontWeight:600, letterSpacing:"36px"}}>{i === 0 ? props.code.substring(1,2): ""}</span></div>
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"198px", fontSize:"30px", fontWeight:600, letterSpacing:"36px"}}>{i === 0 ? props.code.substring(2,3): ""}</span></div>
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"247px", fontSize:"30px", fontWeight:600, letterSpacing:"36px"}}>{i === 0 ? props.code.substring(3,4): ""}</span></div>
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"296px", fontSize:"30px", fontWeight:600, letterSpacing:"36px"}}>{i === 0 ? props.code.substring(4,5): ""}</span></div>
          <div style={{position:"absolute"}}><span style={{position:"relative", top:"-466px", left:"345px", fontSize:"30px", fontWeight:600, letterSpacing:"32px"}}>{i === 0 ? props.code.substring(5): ""}</span></div>
        </Page>
      ))}
    </Document>
  </Container>
}