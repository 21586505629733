import './SearchPatient.css';
import {ReactComponent as SearchIcon} from "../../assets/icons/Search.svg";
import HashLoader from "react-spinners/HashLoader";
import React, { useState } from 'react'
import * as PropTypes from "prop-types";
import {fetcher} from "../../utils/axios";
import * as Constants from "../../constants/Constants";
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next'

export default function SearchPatients(props) {
    let [searchCache, setSearchCache] = useState({});
    let [loading, setLoading] = useState(false);
    let dbse = debounce(getSearchResults, 500);
    const {t} = useTranslation();
    function searchPatients(e){
        props.setState({searchText: e.target.value})
        if (e.target.value.length > 2){
            props.setState({isSearchLoading: true});
            dbse(e.target.value);
        }
    }

    function getSearchResults(searchText) {
        if (searchCache[searchText]) {
            props.setState({ isSearchLoading: false });
            setLoading(false);
            return;
        }
        var data = JSON.stringify({
            query: `query {
        patients(limit: 20, offset: 0, search: "${searchText}"){
            count
            data{
                uuid
                first_name
                last_name
                photo
                care{
                  mrn
                }
            }
      }
    }`,
            variables: {}
        });
        setLoading(true)
        fetcher(Constants.GRAPHQL_API, data)
            .then((response) => {
                // replace group list with group list from data
                console.debug("got search results", response);
                if (response.errors) {
                    props.setState({
                        isSearchLoading: false,
                        searchResults: [],
                        emptySearchResults: true,
                        searchError: t("No results found"),  // TODO change this to actual error later
                        searchResultCount: 0,
                    });
                    return;
                }
                if (response.data && response.data.patients && response.data.patients.count > 0) {
                    let i;
                    let preparedPatientList = [];
                    for (i = 0; i < response.data.patients.data.length; i++) {
                        preparedPatientList.push({...response.data.patients.data[i], ...response.data.patients.data[i].care});
                    }
                    setSearchCache({ ...searchCache, [searchText]: [preparedPatientList, response.data.patients.count] })
                    props.setState({
                        isSearchLoading: false, searchError: null,
                        searchResults: preparedPatientList,
                        searchResultCount: response.data.patients.count,
                    });
                } else {
                    props.setState({
                        isSearchLoading: false,
                        searchResults: [],
                        emptySearchResults: true,
                        searchError: t("No results found"),
                        searchResultCount: 0,
                    });
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.message === "Network Error") {
                    props.setState({
                        searchError: (err.message + ", " + t("Please contact the admin")),
                        isSearchLoading: false,
                        patientList: null
                    });
                    return;
                }
                props.setState({
                    searchError: t("Server Error") + " " + t("Please contact Admin"),
                    isSearchLoading: false,
                    patientList: null
                });
            });
    }
    if (searchCache[props.searchText] && loading)
        setLoading(false)
    return <>
        <div style={{
            display: props.searching ? "flex" : "none",
            justifyItems: "end",
            alignItems: "end",
            alignContent: "end",
            borderRadius: "20px",
            background: "var(--background-color)",
            boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
            zIndex: 110
        }}>
            <SearchIcon style={{
                marginLeft: "15px",
                width: "18px",
                height: "18px",
                alignSelf: "center",
                top: "-1px",
                display: props.searchText || props.searching ? "block" : "none"
            }} fill={"var(--background-color)"} stroke={"var(--text-color)"}/>
            <input autoFocus
                   className={props.searchText.length > 0 || props.searching ? "top-search-input-focused" : "top-search-input"}
                   onFocus={props.onFocus}
                   value={props.searchText}
                   ref={props.ref}
                   onChange={(e) => searchPatients(e)}/>
            <span onClick={() => props.setState({
                searching: false,
                searchText: "",
                showOverlay: false,
                showColumnSettings: false,
                showFilters: false,
                searchResults: []
            })}
                  style={{
                      position: "relative",
                      cursor: "pointer",
                      right: 0,
                      padding: "11px 24px 11px 0",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "--dark-gray",
                      display: props.searchText || props.searching ? "" : "none",
                      marginLeft: "10px"
                  }}>{t("Cancel")}</span>
        </div>
        <div onMouseEnter={props.onFocus}
             className="groups-container" style={{
            position: "relative",
            background: "var(--background-color)",
            width: "0",
            height: "0",
            right: `472px`,
            top: "64px",
            display: props.searchText.length > 0 && props.searching ? "block" : "none",
            zIndex: 100
        }}>
            <div className="triangle-with-shadow"
                 style={{position: "absolute", left: "215px", top: "-42px"}}/>
            <div style={{
                background: "var(--background-color)",
                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
                width: "470px",
                minHeight: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start"
            }}>
                <div style={{height: 0, position: "relative", left: "50%", top: "50px"}}><HashLoader
                    color="#4676F4" loading={loading} size={30}/></div>
                {props.searchError ? <div
                    style={{width: "100%", textAlign: "center", marginTop:"10px"}}>{props.searchError}</div> : ""}
                {props.searchResults && !loading && !props.searchError ?
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        position: "relative",
                        left: "10px",
                        padding: "5px 10px"
                    }}>{t("Found")} {(searchCache[props.searchText] && searchCache[props.searchText][1] ? searchCache[props.searchText][1] : 0)} {t("results")}</div> : ""}
                {(searchCache[props.searchText] && searchCache[props.searchText][0] ? searchCache[props.searchText][0] : []).map( (g, j) =>
                    <div key={j} className='search-patients-name-list'
                         onClick={() => props.openPatient(g)} style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "start",
                        justifyItems: "center",
                        width: "100%",
                        padding: "20px 25px",
                        cursor: "pointer"
                    }}>
                        <div style={{
                            width: "22px",
                            height: "22px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            display: Boolean(g.photo) && props.patientSearchPhotoErrors.includes(g.uuid) ? "none" : "flex"
                        }}>
                            <svg width="22" height="22" viewBox="0 0 100 100" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                                      fill="var(--border-color-two)"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M59.64 67.5H40.3575C37.4025 67.5 35 65.095 35 62.14C35 55.4475 40.445 50 47.14 50H52.8575C59.5525 50 65 55.4475 65 62.14C65 65.095 62.595 67.5 59.64 67.5ZM50 32.5C52.755 32.5 55 34.7425 55 37.5C55 40.2575 52.755 42.5 50 42.5C47.2425 42.5 45 40.2575 45 37.5C45 34.7425 47.2425 32.5 50 32.5ZM56.155 45.325C58.48 43.4925 60 40.6875 60 37.5C60 31.9775 55.5225 27.5 50 27.5C44.4775 27.5 40 31.9775 40 37.5C40 40.6875 41.5175 43.4925 43.8425 45.325C35.9525 46.8625 30 53.8025 30 62.14C30 67.8625 34.6375 72.5 40.3575 72.5H59.64C65.36 72.5 70 67.8625 70 62.14C70 53.8025 64.045 46.8625 56.155 45.325Z"
                                      fill="var(--background-color)"/>
                            </svg>
                        </div>
                        <img className="patient-photo"
                             src={Boolean(g.photo) && props.patientSearchPhotoErrors.includes(g.uuid) ? g.photo : ""}
                             style={{
                                 display: Boolean(g.photo) && props.patientSearchPhotoErrors.includes(g.uuid) ? "" : "none",
                                 width: "22px",
                                 maxHeight: "22px",
                                 minHeight: "22px",
                                 height: "22px",
                                 borderRadius: "50%",
                                 objectFit: "cover"
                             }} alt="" onLoad={() => props.patientSearchPhotoError(g.uuid)}/>
                        <span style={{
                            justifySelf: "center",
                            paddingLeft: "10px"
                        }}>{Array.from((g.last_name ? (g.last_name + (g.first_name ? ", ": "")) : "") + (g.first_name ? g.first_name : "")).map((k, i) => (
                            <span key={i}
                                  style={{color: Array.from(props.searchText.toLowerCase()).includes(k.toLowerCase()) ? "var(--bs-primary)" : ""}}>{k}</span>
                        ))}</span>
                        {(g.mrn ? (
                            <span style={{paddingLeft: "4px"}}> (MRN {Array.from(g.mrn).map((k, i) => (
                                <span key={i}
                                      style={{color: Array.from(props.searchText.toLowerCase()).includes(k.toLowerCase()) ? "var(--bs-primary)" : ""}}>{k}</span>
                            ))})</span>) : "")}
                    </div>
                )}

            </div>
        </div>
    </>;
}


SearchPatients.propTypes = {
    searching: PropTypes.any,
    searchText: PropTypes.any,
    onFocus: PropTypes.func,
    ref: PropTypes.func,
    loading: PropTypes.any,
    searchError: PropTypes.any,
    searchResults: PropTypes.any,
    searchResultCount: PropTypes.any,
};