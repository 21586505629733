import "../App.scss";
import React, { useState } from 'react'
import PageError from "../pages/PageError";
import { reportAction } from '../utils/axios';
import { v4 as uuidv4 } from 'uuid';
import {connect} from "react-redux";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorCode:"", sessionId: null, lastReportedLogin: 0};
    }

    reportSession = (sessionId) => {
        if (this.props?.auth && this.props?.auth?.refresh) {
            console.debug("reporting session", sessionId);
            reportAction('ACTIVE_SESSION_' + sessionId, null, null)
        }
        else {
            console.debug("not reporting session as user not logged in", sessionId);
        }
        setTimeout(() => this.reportSession(sessionId), 30000)
    }

    componentDidMount () {
        if (!this.state.sessionId){
            let sessionId = uuidv4()
            this.setState({ sessionId:sessionId })
            this.reportSession(sessionId);
        }

    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        let errorId = uuidv4()
        console.error(error);
        console.log("error id: ", errorId)
        this.setState({errorCode: errorId})
        reportAction("CARE_PORTAL_ERROR_" + errorId, undefined, error.stack)
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <PageError referenceCode={this.state.errorCode?.substring(24, 36) || "076f38ea8c0b"} />
        }
    return this.props.children;
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}
export default connect(mapDispatchToProps)(ErrorBoundary);