import React, { useState } from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import HashLoader from 'react-spinners/HashLoader'
import { fetcher, reportAction } from '../../utils/axios'
import * as Constants from '../../constants/Constants'
import { toast } from 'react-toastify'
import Select, { components } from 'react-select'
import 'react-phone-number-input/style.css'
import { useSelector } from 'react-redux'
import { languageSelector, userSelector } from '../../store/slices/auth'
import * as helpers from '../../helpers/helpers'
import PractitionerCard from '../PractitionerCard'
import SurveyTaskCompletionCard from './SurveyTaskCompletion'

const reactSelectStyles = {
  container: (base, state) => ({
    ...base,
    width: '100%',
    background: 'var(--background-color-faintest)'
  }),
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 0,
    outline: 0,
    boxShadow: 'none',
    background: 'var(--background-color-faintest)'
  }),
  menu: base => ({
    ...base,
    outline: 0,
    zIndex: 110
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? 'var(--border-color-fainter)' : 'var(--background-color-faintest)',
    color: 'black',
    opacity: state.isFocused ? 0.9 : 1,
    zIndex: 1000
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: 'var(--text-color)'
  })
}

export default function CheckInCard (props) {
  function getStartDate(){
    let satPair = helpers.getSaturdayPair(Math.floor(Date.now()/1000), 6, true)
    return Math.floor(satPair[0].getTime()/1000)
  }
  function getEndDate(){
    let satPair = helpers.getSaturdayPair(Math.floor(Date.now()/1000), 6, true)
    return Math.floor(satPair[1].getTime()/1000)
  }
  function getCurrentWeekDateRange(){
    let satPair = helpers.getSaturdayPair(Math.floor(Date.now()/1000), 6)
    // let week = helpers.getDateRange(Math.floor(satPair[0].getTime()/1000))
    let dates = helpers.getSaturdayPair(Math.floor(satPair[0].getTime()/1000), 6)
    dates = [helpers.getUTCFormattedDate(dates[0].getTime()/1000), helpers.getUTCFormattedDate(dates[1].getTime()/1000)]
    let dateRange = helpers.getFormattedDate(dates[0], "DD MMM", (lang) => {return "en"})
    if (helpers.getFormattedDate(dates[0], "YYYY") !== helpers.getFormattedDate(dates[1], "YYYY"))
      dateRange += helpers.getFormattedDate(dates[0], "YY", (lang) => {return "en"})
    dateRange += " - "
    dateRange += helpers.getFormattedDate(dates[1], "DD MMM YY", (lang) => {return "en"})
    return dateRange;
  }

  function prepareQuery() {
    let start = getStartDate()
    let end = getEndDate()
    let week = getCurrentWeekDateRange()
    return JSON.stringify({
      query: `
            query {
                checkIns(organization_uid: "c0c4e5d1-1fb0-4278-9af1-3a7ab20bdd54", start_date: ${start}) {
                    uid
                    data
                    user_data
                    assignee
                    due
                    properties
                    action
                    status
                    created_at
                    updated_at
                    patient{
                        first_name
                        last_name
                        birthdate
                        age
                    }
                }
                 orgSurvey(location: "${props.location_code}", week: "${week}"){
                      week
                      action
                      data
                      created_at
                  }
            }
            `,
      variables: {
      }
    });
  }

  function checkUsefulnessSurvey(){
    setCheckingUsefulnessSurvey(true);
    fetcher(Constants.GRAPHQL_API, prepareQuery())
      .then((response) => {
        setCheckingUsefulnessSurvey(false)
        setLoading(false)
        if (surveyCheckLoaded) return;
        setSurveyCheckLoaded(true)
        if (response.errors || !response.data.orgSurvey || !response.data.checkIns) {
          console.error('Got Error', response.errors)
          reportAction("CARE_PORTAL_ERROR_FEEDBACK_SURVEY", null, "Error getting survey-check info")
          return
        }
        let completedAllCheckIns = false
        if(response.data.checkIns && response.data.checkIns.length > 0){
          try{
            let incompleteCheckIns = response.data.checkIns.filter(patient => !(patient.properties && JSON.parse(patient.properties).checked_in_at))
            if (incompleteCheckIns?.length === 1) completedAllCheckIns = true
          }
          catch (err){
            reportAction("CARE_PORTAL_ERROR_FEEDBACK_SURVEY", null, "Error filtering CheckIns for " + props?.location_code)
          }
        }
        if(completedAllCheckIns && response.data.orgSurvey.length < 1 && !isTaskCompletionSurveyAvailable){
          console.debug("showing Feedback Survey after check-In");
          reportAction("PLATFORM_FEEDBACK_SURVEY_DISPLAYED", null)
          setIsTaskCompletionSurveyAvailable(true)
        }
        else{
          console.debug("Will not be showing feedback Survey as there are more surveys");
        }
      })
      .catch((err) => {
        console.error('Got Error', err)
      })
  }

  const { t } = useTranslation()
  let lang = useSelector(languageSelector)
  let user = useSelector(userSelector)
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [language, setLanguage] = useState(lang === 'de' ? 'German' : 'English')
  const [ecogStatus, setEcogStatus] = useState('')
  const [intendedFollowUp, setIntendedFollowUp] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isTaskCompletionSurveyAvailable, setIsTaskCompletionSurveyAvailable] = useState(false)
  const [showTaskCompletionSurvey, setShowTaskCompletionSurvey] = useState(false)
  const [checkingUsefulnessSurvey, setCheckingUsefulnessSurvey] = useState(false)
  const [surveyCheckLoaded, setSurveyCheckLoaded] = useState(false)
  if (localStorage.getItem(getCurrentWeekDateRange()+props.location_code) !== "COMPLETED" && !checkingUsefulnessSurvey && !surveyCheckLoaded) {
    checkUsefulnessSurvey();
  }
  let ecogOptions = [
    { code: '0', label: <span><b style={{color:"var(--bs-primary)"}}>0</b> — {t('Fully Active, able to carry on all pre-disease performance without restriction')}</span> },
    { code: '1', label: <span><b style={{color:"var(--bs-primary)"}}>1</b> — {t("Restricted in physically strenuous activity but ambulatory and able to carry out work of a light or sedentary nature, e.g., light house work, office work")}</span> },
    { code: '2', label: <span><b style={{color:"var(--bs-primary)"}}>2</b> — {t("Ambulatory and capable of all selfcare but unable to carry out any work activities; up and about more than 50% of waking hours")}</span> },
    { code: '3', label: <span><b style={{color:"var(--bs-primary)"}}>3</b> — {t("Capable of only limited selfcare; confined to bed or chair more than 50% of waking hours.")}</span> },
    { code: '4', label: <span><b style={{color:"var(--bs-primary)"}}>4</b> — {t("Completely disabled; cannot carry on any selfcare; totally confined to bed or chair")}</span> },
    { code: '5', label: <span><b style={{color:"var(--bs-primary)"}}>5</b> — {t("Dead")}</span> },
  ]

  let intendedFollowUpOptions = [
    "No immediate follow-up",
    "Call the patient to follow-up",
    "Schedule in-person follow-up with the patient.",
    "Share the patient’s status with a team member.",
    "Advise the patient to visit a clinic or seek immediate medical attention.",
    "Other"
]

  function updateSelection (opt) {
    setEcogStatus(opt.code)
  }

  function checkValidEmail () {
    if (!email) {
      return false
    }
    let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/
    if (!re.test(email)) {
      toast.error(t('please_enter_a_valid_email_'))
      return false
    } else {
      return true
    }
  }
  function isIntendedFollowUpSelected(showMessage=false){
    let optionSelected = false
    Object.keys(intendedFollowUp).forEach(u => {
      if (intendedFollowUp[u]){
        optionSelected = true
      }
    })

    if (!optionSelected){
      if (showMessage)
        toast.error("Please select at least option for field - \"Intended Follow-Up\"");
      return false
    }
    return true
  }

  function sendMessage () {
    if (isLoading || checkingUsefulnessSurvey) {
      return
    }

    if (!isIntendedFollowUpSelected()){
      return
    }

    setLoading(true)
    var data = JSON.stringify({
      query: `
      mutation {
    check_in_patient(uid: "${props.taskUid ? props.taskUid: ""}", intended_follow_up: "${Object.keys(intendedFollowUp).filter(t => intendedFollowUp[t]).join("; ")}"${ecogStatus ? `, ecog_status: "${ecogStatus}"`: ""}${message ? `, comment: "${message}"`: ""}){
        uid
        status
        action
        data
        user_data
        properties
    }       
}`,
      variables: {
      }
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        if (!checkingUsefulnessSurvey)
          setLoading(false)
        if (response.errors || !response.data.check_in_patient) {
          console.error('Got Error', response.errors)
          toast.error(t('Unable to send Message'))
          setLoading(false)
          return
        }
        if (response.data.check_in_patient.status !== "CHECKED_IN"){
          setLoading(false)
          toast.error(t('Unable to process request'))
        }
        else{
          setMessage('')
          setEcogStatus('')
          if (!isTaskCompletionSurveyAvailable)
            props.closeBox(true)
          else {
            setShowTaskCompletionSurvey(true);
            props.showOverlay(true)
          }
          toast.info(t('Patient Chcked In'))
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(t('Unable to process request'))
      })
  }

  function updateIntendedFollowUp(option){
    if (option !== "No immediate follow-up" && Boolean(intendedFollowUp["No immediate follow-up"])){
      return;
    }
    if (option === "No immediate follow-up" && !Boolean(intendedFollowUp[option])){
      let intFu = {}
      intendedFollowUpOptions.forEach(opt => {
        intFu[opt] = false
      })
      intFu["No immediate follow-up"] = true
      setIntendedFollowUp(intFu);
      return;
    }
    setIntendedFollowUp({...intendedFollowUp, [option]: !Boolean(intendedFollowUp[option])})
  }

  return <Container style={{
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    minWidth: '350px',
    background: 'var(--background-color)',
    padding: 0,
    borderRadius: '10px',
    boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)',
    textAlign: 'center',
    color: 'var(--text-color)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }}>
    <div style={{ height: 0, position: 'relative', left: 0, top: '175px' }}><HashLoader color="#4676F4"
                                                                                        loading={isLoading} size={25}/>
    </div>
    <div style={{
      position: "absolute",
      width: "0",
      height: "0",
      background: "var(-background-color)",
      left: "50%",
      top: "50%",
      display: showTaskCompletionSurvey ? "block" : "none",
      zIndex: 2111
    }}>
      <div style={{position:"relative", left:"-300px", top:"100px"}}>
        <SurveyTaskCompletionCard location={props.location_code} closeBox={(refresh) => {setShowTaskCompletionSurvey(false); props.closeBox(true);} } />
      </div>

    </div>
    <div style={{
      margin: 0,
      textAlign: 'center',
      marginBottom: '20px',
      borderRadius: '10px',
    }}>
      <Row style={{padding:"20px"}}>
        <Col style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center'
        }}>
                            <span
                              style={{ fontWeight: 600 }}>{helpers.getFormattedDate(Date.now()/1000, 'DD MMM YY - hh:mm A')}</span>
          <span style={{ padding: '0 5px' }}>|</span>
          <span> <span className={'person-card-container'}
                                      style={{
                                        textDecoration: 'underline',
                                        cursor: 'default',
                                        padding: '7px 0'
                                      }}>{helpers.getPractitionerName(user.first_name, user.last_name)}
            <div style={{
              position: 'relative',
              height: 0,
              background: 'transparent',
              left: '-86px'
            }}>
                                        <div className="person-card-container-tool-tip"
                                             style={{ background: 'transparent', border: 0, zIndex: 200 }}>
                                            <PractitionerCard person={user}/>
                                        </div>
                                    </div>
                                </span>
            </span>
        </Col>
      </Row>
      <Row style={{ padding: '0 20px', textAlign: 'start', marginBottom: '10px' }}>
        <Col>
          <span style={{ fontWeight: 400 }}>{t('Indicate how you plan to respond to this Symptom Review, then select \"Submit\".')}</span>
        </Col>
      </Row>
      <Row style={{ padding: '0 20px', marginBottom: '20px' }}>
          {intendedFollowUpOptions.map((option, as_i) => (
            <Row key={as_i} style={{
              textAlign: 'start',
              alignSelf: 'flex-start',
              justifySelf: 'start',
              padding: '5px 10px',
              margin:0
            }}>
                        <span onClick={(e) => updateIntendedFollowUp(option)} style={{
                          cursor: 'pointer',
                          fontWeight: 400,
                          color: Boolean(intendedFollowUp[option]) ? 'var(--background-color)' : 'var(--text-color-faint)',
                          padding: '4px 13px',
                          borderRadius: '100px',
                          border: '1px solid var(--border-color-faint)',
                          height: '50px',
                          background: option !== "No immediate follow-up" && Boolean(intendedFollowUp["No immediate follow-up"]) ? 'var(--background-color-faintest)' : Boolean(intendedFollowUp[option]) ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                          wordBreak: 'keep-all',
                          lineHeight: "15px",
                          fontSize:"13.5px",
                          display: 'flex',
                          flexDirection: 'row',
                          alignContent: 'center',
                          alignItems:"center"
                        }}>
                            <div style={{
                              height: '22px',
                              minHeight:"22px",
                              minWidth:"22px",
                              width: '22px',
                              borderRadius: '11px',
                              fontWeight: '600',
                              fontSize: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              textAlign: 'center',
                              color: 'var(--background-color)',
                              border: `2px solid ${Boolean(intendedFollowUp[option]) ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                              alignSelf: 'center',
                              marginRight: '5px'
                            }}>
                              </div>
                          {t(option)}
                        </span>
            </Row>
          ))}
      </Row>
      <Row style={{ padding: '0 20px', marginBottom: '20px' }}>
        <Col>
          <Select styles={reactSelectStyles}
                  isSearchable={false}
                  value={ecogStatus ? [{ value: ecogStatus, label: ecogOptions.find(e => e.code === ecogStatus).label }] : ''}
                  components={{
                    Placeholder: ({ children, ...props }) => (
                      <components.Placeholder {...props}>
                        <span style={{color:"var(--bs-primary)", fontWeight:600}}>{t('Select ECOG Status')}</span> ({t("Optional")})
                      </components.Placeholder>
                    )
                  }}
                  options={ecogOptions}
                  onChange={(temp) => updateSelection(temp)}
          />
        </Col>
        {ecogStatus ? <Col xs={1}
                           onClick={() => setEcogStatus('')}
                           style={{
                             cursor:"pointer",
                             justifyContent: 'center',
                             alignContent: 'center',
                             alignSelf: 'center',
                             paddingLeft: 0,
                             color: 'var(--wave-clay)'
                           }}>
          <span style={{ fontSize: '19px', fontWeight: 600 }}>x</span>
        </Col>: ""}
      </Row>
      <Row style={{ padding: '0 20px', marginBottom: '20px' }}>
        <Col>
          <textarea style={{
            minHeight: '174px',
            width: '100%',
            outline: 0,
            border: '1px solid var(--border-color-fainter)',
            borderRadius: '10px',
            padding: '10px',
            margin: '0',
            resize: 'none'
          }} placeholder={t('Type Comment') + ' (' + t("Optional") +')...'} value={message}
                 onChange={(e) => !isLoading ? setMessage(e.target.value) : ''}/>
        </Col>
      </Row>
      <div style={{
        background: 'var(--background-color-faint)',
        height: '1px',
        width: '100%',
        margin: '20px 0',
        padding: 0
      }}/>
      <Row style={{
        fontWeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        bottom: '0px',
        padding: '0 20px'
      }}>
        <Col xs={6}>
          <Button onClick={() => props.closeBox()} variant="light" style={{
            minWidth: '150px',
            height: '44px',
            padding: '8px',
            borderRadius: '100px',
            border: '1px solid var(--border-color-faint)',
            background: 'var(--background-color)',
            textAlign: 'center',
            marginLeft: '0',
            color: 'var(--text-color-faint)',
            fontWeight: 600
          }}>{t('Cancel')}</Button>
        </Col>
        <Col xs={6}>
          <Button onClick={sendMessage} variant="light" style={{
            minWidth: '150px',
            height: '44px',
            padding: '8px',
            borderRadius: '100px',
            textAlign: 'center',
            marginLeft: '0',
            border: "0",
            background: isIntendedFollowUpSelected() && !checkingUsefulnessSurvey ? 'var(--bs-primary)': "var(--border-color-faint)",
            color: "var(--background-color)",
            fontWeight: 600
          }}>{t('Send')}</Button>
        </Col>
      </Row>
    </div>
  </Container>
}