import './WaveReport.css';
import React from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Container } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DotMenu from '../assets/icons/DotMenu.svg';
import {withTranslation} from "react-i18next";

class CareTeam extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          error: null,
          isLoaded: false,
          pageNumber: 1,
          numPages: null,
          showAddPhysicianList: false,
          showOverlay: false,
          addNotificationList: [
            {title: "Cunnigham, C", id: 1}, 
            {title: "Foreman, C", id: 2}, 
            {title: "Foreman, L", id: 3}, 
            {title: "Foreman, S", id: 4}
          ],
          physicianList: [
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"}, 
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"}, 
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"}, 
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"}, 
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"},  
            {title: "Christine Cunnigham, MD", id: 2, speciality: "Internal Medicine", campus: "Cedar Sinai - Beverly Grove", phone: "(310) 963-2060", email: "mhellercreative@gmail.com", address: "123 Wilshire Blvd #1000\nLos Angeles, CA 90024"}, 
          ]
      };
      this.hideOverlay = this.hideOverlay.bind(this);
  }

  componentDidMount(){
      if(this.state.isLoaded){
          return;
        }
      this.componentDidUpdate();
  }
  
  componentDidUpdate() {
    if(this.state.isLoaded){
      return;
    }
    
    this.setState({isLoaded: true});
  }

  openPhysicianList(toggle){
    if(toggle){
    this.setState({showAddPhysicianList: !this.state.showAddPhysicianList, showOverlay: !this.state.showAddPhysicianList});
    return      
    }
    this.setState({showAddPhysicianList: true, showOverlay: true});
  }

  hideOverlay(){
    this.setState({showAddPhysicianList: false, showOverlay: false})
  }

  updateGroupSelection(temp, l){
    let i, index;
    let groupExists = false;
    for(i=0; i<this.state.physicianList.length; i++){
        if(this.state.physicianList[i].value === temp.value){
            groupExists = true;
            index = i;
        }
    }
    if (groupExists){
        if(index === l){
            return;
        }
        else{
            toast.info(this.props.t("symptom_already_exists"));
        }
    }
    else{
        let newList = [...this.state.physicianList];
        newList[l] = temp;
        this.setState.physicianList({physicianList: newList});
    }

}

  removeGroup(l){
    let newList = [...this.state.physicianList];
    newList.splice(l, 1);
    this.setState({physicianList: newList});
  }

  addGroup(temp){
    if (temp.value === "Select Group"){
        return;
    }
    this.setState({addNewSymptom: false})
    this.updateGroupSelection(temp, this.state.physicianList.length);
  }

  render = () => {
  return (
    <Container style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontWeight:400, fontSize:"14px", lineHeight:"22px"}}>
        <div onClick={this.hideOverlay} style={{position:"fixed", width:"100%", height:"100vh", background:"rgba(0, 0, 0, 0.05)", left:0, top:0, display: this.state.showOverlay ? "block": "none", zIndex:80}}/>
        <Row style={{marginRight: "0", marginLeft:"0", maxWidth:"calc(max(min(100vw, 1800px), 1440px))", width:"900px", marginTop:"40px", marginBottom:"30px"}}>
            <Col className="page-tittle" style={{paddingLeft:"0", display:"flex", flexDirection:"row", alignItems:"start", justifyContent:"start"}}>
                <Button onClick={() => this.props.history.goBack()} className="white-buttons-round-wr" variant="light" style={{marginLeft:"0"}}>
                    <svg style={{transform:"rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--border-color)"/>
                    </svg>
                </Button> 
                <span style={{alignSelf:"center", justifySelf:"center", marginLeft:"15px", fontSize:"18px", lineHeight:"26px"}}>{this.props.t("care_team")}</span>
            </Col>
            <Col style={{display: this.state.selectionList ? "None" : "flex", flexDirection:"row", textAlign:"end", justifyContent:"flex-end", paddingRight:"5px"}}>
                <Button onMouseEnter={() => this.openPhysicianList(false)} onClick={() => this.openPhysicianList(true)} 
                    className="white-buttons-wr" variant="light" style={{padding:"6px 27px"}}>
                    {this.props.t("add_physician")}
                    <svg style={{justifySelf:"center", alignSelf:"center", marginLeft:"10px"}} width="14" height="14" viewBox="0 1 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.46466 4.23025C1.46466 3.97425 1.56266 3.71825 1.75766 3.52325C2.14766 3.13225 2.78066 3.13225 3.17166 3.52325L5.99966 6.35125L8.82866 3.52325C9.21866 3.13225 9.85266 3.13225 10.2427 3.52325C10.6327 3.91425 10.6327 4.54625 10.2427 4.93725L6.70666 8.47225C6.31666 8.86325 5.68366 8.86325 5.29266 8.47225L1.75766 4.93725C1.56266 4.74225 1.46466 4.48625 1.46466 4.23025Z" fill="var(--border-color)"/>
                    </svg>
                </Button>
                <div onMouseEnter={() => this.setState({showAddPhysicianList: true})} onMouseLeave={this.hideOverlay} 
                        style={{position:"relative", background:"var(--background-color)", width:"0", height:"0", left: `-292px`, top: "64px", display: this.state.showAddPhysicianList ? "block": "none",zIndex:100}}>
                    <div className="triangle-with-shadow" style={{position:"absolute", left:"123px", top:"-42px"}} />
                    <div style={{background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px", width:"300px", display:"flex", flexDirection:"column", justifyContent:"start", alignItems:"start"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"start", alignItems:"start", justifyItems:"center", width:"100%"}}>
                            <Col style={{minHeight:"52px", display:"flex", flexDirection:"column", alignItems:"center", width:"100%", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
                                {this.state.addNotificationList.map((rpt, j) => (
                                    <div onClick={() => {this.hideOverlay();}} key={j} className="page-list-titles" style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%", padding:"15px 20px", margin: 0, cursor:"pointer"}}>
                                        {rpt.title}
                                    </div>
                                ))}
                            </Col>
                        </div>
                    </div>  
                </div>
            </Col>
        </Row>
        <Container style={{maxWidth:"calc(max(min(100vw, 1800px), 1440px))", width:"900px", fontWeight:400, lineHeight:"22px", fontSize:"14px"}}>
            <Row>
            {this.state.physicianList.map((person, i) => (
                <Col xs={6} style={{padding: i % 2 === 0 ? "0px 10px 20px 0px": "0px 0px 20px 10px"}}>
                    <div style={{background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
                        <div style={{borderBottom:"1px solid var(--border-color-faintest)", padding:"20px", flexDirection:"row", display:"flex"}}>
                            <img src={"https://alrigh.com/wp-content/uploads/2020/06/10-Midoriya-Izuku-profile-picture-1024x1018.jpg"} style={{width:"22px", height:"22px", alignSelf:"center", objectFit:"cover", borderRadius:"50%", marginRight:"10px"}} alt="" />
                            <span style={{fontWeight:600}}>{person.title}</span>
                            <div style={{width:"100%", flex:1, justifyContent:"end", justifyItems:"end", display:"flex"}}>
                                <img src={DotMenu} style={{width:"22px", height:"22px", transform:"rotate(90deg)"}} alt=""/>
                            </div>
                        </div>
                        <Row style={{padding:"20px 20px 10px 20px"}}>
                            <Col>
                                {this.props.t("speciality")}
                            </Col>
                            <Col style={{textAlign:"end"}}>
                                {person.speciality}
                            </Col>
                        </Row>
                        <Row style={{padding:"10px 20px"}}>
                            <Col>
                                {this.props.t("campus")}
                            </Col>
                            <Col style={{textAlign:"end"}}>
                                {person.campus}
                            </Col>
                        </Row>
                        <Row style={{padding:"10px 20px"}}>
                            <Col>
                                {this.props.t("contact")}
                            </Col>
                            <Col style={{textAlign:"end"}}>
                                {person.phone}<br />{person.email}
                            </Col>
                        </Row>
                        <Row style={{padding:"10px 20px 20px 20px"}}>
                            <Col>
                                {this.props.t("address")}
                            </Col>
                            <Col style={{textAlign:"end"}}>
                                {person.address}
                            </Col>
                        </Row>
                    </div>
                </Col>
            ))}
            </Row>
        </Container>
    </Container>
  );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      auth: dispatch.auth,
  };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(CareTeam)));