import Button from "@restart/ui/esm/Button";
import { Container, Row, Col } from "reactstrap";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function PageNotFound(props){
    const history = useHistory();
    const {t} = useTranslation();
    return <Container style={{background:"transparent", width:"792px", minHeight:"590px", paddingTop:"40px", color:"var(--text-color)"}}>
        <Row style={{marginBottom:"30px", alignItems:"center"}}>
            <Col xs={1}>
                <Button onClick={() => history.goBack()} className="white-buttons" variant="light" style={{width:"44px", height:"44px", padding:"8px", textAlign:"center", border: "0", marginLeft:"0"}}>
                    <svg style={{transform:"rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--border-color)"/>
                    </svg>
                </Button>
            </Col>
            <Col style={{fontWeight:600, lineHeight:"26px", fontSize:"18px"}}>{t("Page not found")}</Col>
        </Row>
        <Container style={{fontWeight:400, lineHeight:"22px", fontSize:"14px", padding:"50px", background:"var(--background-color)", minHeight:"590px", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
            <Row>
                {/*TODO Translations*/}
                <Col>{t("You may not have access to the page, or the page does not exists.")}</Col>
            </Row>
            <Row style={{marginTop:"20px"}}>
                <Col>{t("Please contact the admin")}{props.referenceCode ? <>, {t("reference code")}: <u style={{textTransform:"uppercase"}}>{props.referenceCode}</u></>: ""}</Col>
            </Row>
        </Container>
    </Container>
}