import Button from "@restart/ui/esm/Button";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import './AddPatientForm.css';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AddPatientForm(props){
    const [isFormValid, setFormValidation] = useState(true);
    const history = useHistory();
    const {t} = useTranslation();
    return <Container style={{background:"transparent", width:"792px", minHeight:"590px", paddingTop:"40px", color:"var(--text-color)"}}>
    <Row style={{marginBottom:"30px", alignItems:"center"}}>
        <Col xs={1}>
        <Button onClick={() => history.goBack()} className="white-buttons" variant="light" style={{width:"44px", height:"44px", padding:"8px", textAlign:"center", border: "0", marginLeft:"0"}}>
                <svg style={{transform:"rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--text-color)"/>
                </svg>
            </Button> 
        </Col>
        <Col xs={2} style={{fontWeight:600, lineHeight:"26px", fontSize:"18px"}}>{t("add_patient")} </Col>
    </Row>
    <Container style={{fontWeight:400, lineHeight:"22px", fontSize:"14px", padding:"50px", background:"var(--background-color)", minHeight:"590px", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
        <Row style={{fontWeight:600, marginBottom:"10px"}}>{t("new_patient_form")}</Row>
        <Row style={{marginBottom:"40px"}}>{t("complete_the_form_to_invite_your_patient_to_wave_app_and_add_them_to_the_care_portal_")}</Row>
        <Row style={{fontWeight:600, marginBottom:"10px", justifyContent:"start"}}>
            <Col style={{paddingLeft:0, paddingRight:"5px"}}>{t("first_name")}</Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}}>{t("last_name")}</Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}}>{t("phone_number")} <span style={{fontWeight:400}}>{t("mobile_")}</span></Col>
        </Row>
        <Row style={{marginBottom:"10px"}}>
            <Col style={{paddingLeft:0, paddingRight:"5px"}}><input type="text" placeholder={t("enter_first_name")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}}><input type="text" placeholder={t("enter_last_name")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}}><input type="tel" placeholder={t("enter_phone_number")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
        </Row>
        <Row style={{fontWeight:600, marginBottom:"10px", justifyContent:"start"}}>
            <Col style={{paddingLeft:0, paddingRight:"5px"}} xs={8}>{t("email")}</Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}} xs={4}>{t("mrn")}</Col>
        </Row>
        <Row style={{marginBottom:"20px"}}>
            <Col xs={8} style={{paddingLeft:0, paddingRight:"5px"}}><input type="email" placeholder={t("enter_email")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
            <Col xs={4} style={{paddingLeft:"5px", paddingRight:"5px"}}><input type="text" placeholder={t("enter_mrn")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
        </Row>

        <Row style={{fontWeight:600, marginBottom:"10px", justifyContent:"start"}}>
            <Col style={{paddingLeft:0, paddingRight:"5px"}} xs={4}>Registration Code <span style={{fontWeight:400}}>(Optional)</span></Col>
            <Col style={{paddingLeft:"5px", paddingRight:"5px"}} xs={8}>Group <span style={{fontWeight:400}}>(Optional)</span></Col>
        </Row>
        <Row style={{marginBottom:"60px"}}>
            <Col xs={4} style={{paddingLeft:0, paddingRight:"5px"}}><input type="text" placeholder={t("enter_email")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
            <Col xs={8} style={{paddingLeft:"5px", paddingRight:"5px"}}><input type="text" placeholder={t("enter_mrn")} style={{height:"52px", width:"100%", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px", padding:"15px 20px"}} /></Col>
        </Row>
        <hr style={{background:"var(--border-color-fainter)", width:"115%", position: "relative", left:"-50px", marginBottom:"40px"}} />
        <Row style={{fontWeight:600, alignItems:"center", justifyContent:"center", position: "relative", bottom:"0px"}}>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", border:"1px solid var(--border-color-faint)", background:"var(--background-color)", textAlign:"center", marginLeft:"0", color:"var(--text-color-faint)"}}>{t("Cancel")}</Button>
            </Col>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: "var(--border-color-fainter)", color: isFormValid ? "var(--background-color)": "var(--text-color-faint)"}}>{t("send_invite")}</Button>
            </Col>
        </Row>
    </Container>
    </Container>
}