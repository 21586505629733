import './WaveReport.css';
import React from 'react';
import * as Constants from '../constants/Constants';
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Container } from "reactstrap";
import * as helpers from '../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from 'react-bootstrap';
import {ReactComponent as DownloadIcon} from '../assets/icons/Download.svg';
import {fetcher} from "../utils/axios";
import {toast} from "react-toastify";
import HashLoader from "react-spinners/HashLoader";
import {withTranslation} from "react-i18next";
import MessagePatientCard from "../components/PatientProfile/MessagePatient";

class WaveReport extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          error: this.props.t("unable_to_load_the_report"),
          isLoaded: false,
          pageNumber: 1,
          numPages: null,
          report: null,
          showReportList: false,
          showOverlay: false,
          fetchData: true,
          defaultReportsList: !this.props.removeReportsList,
          messagePatient: this.props.messagePatientButton,
          showMessagePatientCard: false,
          reportList: []
      };
      this.hideOverlay = this.hideOverlay.bind(this);
  }

  componentDidMount(){
      if(this.state.isLoaded){
          return;
        }
      this.componentDidUpdate();
  }
  
  componentDidUpdate() {
    if(this.state.isLoaded){
      return;
    }

    if(this.state.fetchData){
        this.setState({fetchData: false});
        var data = JSON.stringify({
            query: `query ($file_ids: [String], $patientId: String) {
                  file(ids: $file_ids){
                      url
                  }
                  reports(user_id: $patientId, limit: 8){
                        data{
                            start_date
                            end_date
                            url
                        }
                  }
              }`,
            variables: {
                file_ids: ["reports/" + this.props.match.params.reportId],
                patientId: this.props.match.params.patientIdentifier
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
            .then((response) => {
                if(response.errors || !response.data || !response.data.file || response.data.file.length < 1){
                    this.setState({error: this.props.t("unable_to_load_the_report"), isLoaded: true});
                    toast.error(this.props.t("unable_to_load_the_report"));
                    return;
                }
                let reportList = [];
                if(response.data && response.data.reports && response.data.reports.data){
                    reportList = response.data.reports.data;
                }
                this.setState({isLoaded: true, report: response.data.file[0].url, reportList});
            })
            .catch((err) => {
                this.setState({error: this.props.t("unable_to_load_the_report"), isLoaded: true});
                toast.error(this.props.t("unable_to_load_the_report"));
            });

    }
  }

  openReportListOverlay(toggle){
    if(toggle){
    this.setState({showReportList: !this.state.showReportList, showOverlay: !this.state.showReportList});
    return      
    }
    this.setState({showReportList: true, showOverlay: true});
  }

  hideOverlay(){
    this.setState({showReportList: false, showOverlay: false})
  }

  render = () => {
    let selectedReport = this.state.reportList?.find(r => r.url.includes(this.props.match.params.reportId))
    if (!selectedReport){
      selectedReport = this.state.reportList?.[0]
    }
  return (
    <Container style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontWeight:400, fontSize:"14px", lineHeight:"22px"}}>
        <div style={{height:0, position: "absolute", left:"50%", top:"50%"}}><HashLoader color="#4676F4" loading={!this.state.isLoaded} size={35} /></div>
        <div onClick={this.hideOverlay} style={{position:"absolute", width:"100%", height:"100vh", background:"rgba(0, 0, 0, 0.05)", left:0, top:0, display: this.state.showOverlay ? "block": "none", zIndex:80}}/>
        <Row style={{marginRight: "0", marginLeft:"0", maxWidth:"calc(max(min(100vw, 1800px), 1440px))", width:"900px", marginTop:"40px", marginBottom:"30px"}}>
            <Col className="page-tittle" style={{paddingLeft:"0", display:"flex", flexDirection:"row", alignItems:"start", justifyContent:"start"}}>
                <Button onClick={() => this.props.history.goBack()} className="white-buttons-round-wr" variant="light" style={{marginLeft:"0"}}>
                    <svg style={{transform:"rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--border-color)"/>
                    </svg>
                </Button> 
                <span style={{alignSelf:"center", justifySelf:"center", marginLeft:"15px", fontSize:"18px", lineHeight:"26px"}}>{this.props.reportTitle ? this.props.t(this.props.reportTitle) : this.props.t("wave_reports")}</span>
            </Col>
            <Col style={{display: this.state.selectionList ? "None" : "flex", flexDirection:"row", textAlign:"end", justifyContent:"flex-end", paddingRight:"5px"}}>
                <Button href={"/#addcomment"} variant="light" style={{display: "none"}}>
                    {this.props.t("add_comment")}
                </Button>
                {this.state.messagePatient ? <Button onClick={() => this.setState({showMessagePatientCard: !this.state.showMessagePatientCard})} className={"white-buttons-wr"} style={{
                    color: "var(--text-color)",
                    background: "var(--background-color)",
                    fontWeight: 600,
                    padding: "11px 24px",
                    alignSelf: "end",
                    justifySelf: "end",
                    height: "44px",
                }}>{this.props.t("Message Patient")}</Button>: ""}
                <div onMouseEnter={() => this.setState({showMessagePatientCard: true})}
                     className="message-patient-container" style={{
                    position: "relative",
                    background: "var(--background-color)",
                    width: "0",
                    height: "0",
                    right: `283px`,
                    top: "64px",
                    display: this.state.showMessagePatientCard ? "block" : "none",
                    zIndex: 100
                }}>
                    <div className="triangle-with-shadow"
                         style={{position: "absolute", left: "165px", top: "-42px"}}/>
                    <MessagePatientCard patient={{uuid: this.props.match.params.patientIdentifier}} closeMessageBox={() => this.setState({showMessagePatientCard: false})} />
                </div>
                {this.state.defaultReportsList ? <Button onMouseEnter={() => this.openReportListOverlay(false)}
                         onClick={() => this.openReportListOverlay(true)}
                         className={this.state.isLoaded && this.state.reportList.length > 0 ? "white-buttons-wr" : ""}
                         variant="light" style={{
                    padding: "6px 27px",
                    display: this.state.isLoaded && this.state.reportList.length > 0 ? "" : "none"
                }}>
                    {this.props.t("date")}:<span style={{
                    fontWeight: 400,
                    marginLeft: "5px",
                    width: "max-content"
                }}>{helpers.getReportDate(this.state.reportList && selectedReport ? [selectedReport]: null, this.props.t)}</span>
                    <svg style={{justifySelf: "center", alignSelf: "center", marginLeft: "10px"}} width="14" height="14"
                         viewBox="0 1 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.46466 4.23025C1.46466 3.97425 1.56266 3.71825 1.75766 3.52325C2.14766 3.13225 2.78066 3.13225 3.17166 3.52325L5.99966 6.35125L8.82866 3.52325C9.21866 3.13225 9.85266 3.13225 10.2427 3.52325C10.6327 3.91425 10.6327 4.54625 10.2427 4.93725L6.70666 8.47225C6.31666 8.86325 5.68366 8.86325 5.29266 8.47225L1.75766 4.93725C1.56266 4.74225 1.46466 4.48625 1.46466 4.23025Z"
                              fill="var(--border-color)"/>
                    </svg>
                </Button>: ""}
                <div onMouseEnter={() => this.setState({showReportList: true})} onMouseLeave={this.hideOverlay} 
                        style={{position:"relative", background:"var(--background-color)", width:"0", height:"0", left: `-292px`, top: "64px", display: this.state.showReportList ? "block": "none",zIndex:100}}>
                    <div className="triangle-with-shadow" style={{position:"absolute", left:"123px", top:"-42px"}} />
                    <div style={{background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px", width:"300px", display:"flex", flexDirection:"column", justifyContent:"start", alignItems:"start"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"start", alignItems:"start", justifyItems:"center", width:"100%"}}>
                            <Col style={{minHeight:"52px", display:"flex", flexDirection:"column", alignItems:"center", width:"100%", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
                                {this.state.reportList.map((rpt, j) => (
                                    <div onClick={() => {this.props.history.push(`${Constants.PATIENT_STATUS}/${this.props.match.params.patientIdentifier}${Constants.REPORT}/${rpt.url.substring(rpt.url.lastIndexOf("/")+1)}`); this.setState({isLoaded: false, fetchData: true}); this.hideOverlay();}} key={j} className={`page-list-titles ${this.props.match.params.reportId === rpt.url.substring(rpt.url.lastIndexOf("/")+1) ? "page-list-titles-selected": ""}`} style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%", padding:"15px 20px", margin: 0, cursor:"pointer"}}>
                                        {helpers.getReportDate([rpt], this.props.t)}
                                    </div>
                                ))}
                            </Col>
                        </div>
                    </div>  
                </div>
                <Button href={this.state.report} className={this.state.isLoaded && this.state.reportList.length > 0 ? "white-buttons-round-wr": ""} variant="light" style={{marginRight:0, display: this.state.isLoaded && this.state.reportList.length > 0 ? "": "none"}} download>
                    <DownloadIcon style={{position:"relative", top:"-3px"}} fill={"var(--text-color)"} stroke={"var(--text-color)"} />
                </Button> 
            </Col>
        </Row>
        {this.state.isLoaded && this.state.report ? <Row style={{marginRight: "0", marginLeft:"0", maxWidth:"calc(max(min(100vw, 1800px), 1440px))", marginBottom:"20px"}}>
            <Document
                file={this.state.report}
                onLoadSuccess={({numPages}) => this.setState({numPages})}>
                {[...Array(this.state.numPages)].map((pg, i) => (
                    <Page key={i} pageNumber={i + 1} width={900} canvasBackground="#FFFFFF"/>
                ))}
            </Document>
        </Row> :
            <Row style={{height:"300px", width:"100%"}}>
                {this.state.isLoaded ?
                <Col style={{fontSize:"18px", textAlign:"center", alignContent:"center", marginTop:"150px"}} >
                    {this.state.error}
                </Col>
                : ""}
            </Row>
        }
    </Container>
  );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      auth: dispatch.auth,
  };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(WaveReport)));