import {Col, Row} from "reactstrap";
import * as Constants from "../../constants/Constants";
import React from "react";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next'


export default function AsAssessmentOverview(props){
    const {t} = useTranslation();
    let history = useHistory();
    function getBackgroundColorForStatus(status){
        if(!status) status = "";
        switch(status.toLowerCase()){
            case "incomplete":
            case "":
                return "var(--light-orange)";
            case "completed":
                return "var(--background-color)";
            case "inprogress":
            case "in progress":
                return "var(--light-yellow)"
        }
    }
    function getStageAnswerToDisplay(value){
        var bgStyle = {
            minWidth: "73px",
            maxWidth: "73px",
            color: `var(--text-color)`,
            borderRadius: "2px",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            padding: "2px 8px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            width: "73px",
            textAlign: "center",
            height: "26px",
            marginTop:"10px",
            alignSelf:"center"
        }
        if (value && value.toLowerCase() === "yes"){
            bgStyle.backgroundColor = 'rgba(var(--wave-green-rgb), 0.5)';
        }
        else if (value && value.toLowerCase() === "no"){
            bgStyle.backgroundColor = 'rgba(var(--wave-clay-rgb), 0.5)';
        }
        else{
            return "";
        }
        return <span style={bgStyle}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`
              }}>{value ? t(value): ""}</span>
            </span>
    }
    const refs = props.refs || {};
    let assessment = {
        uid: props.assessment.uid,
        patient_input: {
            status: props.assessment.stages[0] && props.assessment.stages[0].status ? props.assessment.stages[0].status : "INCOMPLETE",
            psa_sequence: {title: "PSA Sequence", href: refs.patientDataRef},
            iief_scores: {title: "IIEF + IPSS Scores", href: refs.patientDataRef},
            biopsy_report: {title: "Biopsy Report", href: refs.fileUploadRef},
            mri_upload: {title: "MRI Upload", href: refs.fileUploadRef}
        },
        expert_urologist_decision_one: {
            status: props.assessment.stages[1] && props.assessment.stages[1].status ? props.assessment.stages[1].status : "INCOMPLETE",
            biopsy_report_data: {title: "Biopsy Report Data", href: refs.expertUrologistOneRef},
            recommendation: {title: "Recommendation", href: refs.expertUrologistOneRef}
        },
        expert_radiologist: {
            status: props.assessment.stages[2] && props.assessment.stages[2].status ? props.assessment.stages[2].status : "INCOMPLETE",
            mri_quality_report: {title: "MRI Quality Report", href: refs.expertRadiologistRef},
            mri_re_evaluation: {title: "MRI Re-Evaluation", href: refs.expertRadiologistRef},
            recommendation: {title: "Recommendation", href: refs.expertRadiologistRef}
        },
        expert_urologist_decision_two: {
            status: props.assessment.stages[3] && props.assessment.stages[3].status ? props.assessment.stages[3].status : "INCOMPLETE",
            final_decision: {title: "Final Decision", href: refs.expertUrologistTwoRef}
        }};
    let hideOverView = props.hideOverView;
    return <Row
        onClick={() => assessment.uid ? history.push(Constants.PATIENT_STATUS + "/" + props.patientIdentifier + Constants.AS_JOURNEY + "/" + assessment.uid) : ""}
        style={{
        background: props.backgroundColor ? props.backgroundColor :"var(--background-color-faintest)",
        display: hideOverView ? "None" : "",
        marginTop: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        height: "212px",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400
    }}>
        <Col xs={3} style={{
            alignSelf: "start",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            margin: 0,
            padding: 0
        }}>
            <div style={{
                position: "relative",
                left: "0%",
                top: "12px",
                border: "0px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "11px"
            }}/>
            <div style={{
                background: getBackgroundColorForStatus(assessment.patient_input.status),
                border: assessment.patient_input && assessment.patient_input.status && assessment.patient_input.status.toLowerCase() === "completed" ? "1px solid var(--border-color-two)": "",
                borderRadius: "11px",
                width: "22px",
                height: "22px",
                alignSelf: "center",
                color: assessment.patient_input && assessment.patient_input.status && assessment.patient_input.status.toLowerCase() === "completed" && props.colorTheme === "dark" ? "var(--lightest-gray)" :"var(--darkest-gray)"
            }}>{assessment.patient_input && assessment.patient_input.status && assessment.patient_input.status.toLowerCase() === "completed" ? "✓": "1"}
            </div>
            <div style={{
                position: "relative",
                left: "50%",
                top: "-10px",
                border: assessment.patient_input && assessment.patient_input.status && assessment.patient_input.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "11px"
            }}/>
            <span style={{fontWeight: 600}}>{t("Patient Input")}</span>
            {Object.keys(assessment.patient_input || []).map((key, i) => (
                typeof assessment.patient_input[key] === "object" ? <span
                    onClick={() => props.goToRef ? props.goToRef("patientDataRef"): ""}
                    key={i} style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}>{t(assessment.patient_input[key].title)};</span> : ""
            ))}
        </Col>
        <Col xs={3} style={{
            alignSelf: "start",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            margin: 0,
            padding: 0
        }}>
            <div style={{
                position: "relative",
                left: "0",
                top: "12px",
                border: assessment.patient_input && assessment.patient_input.status && assessment.patient_input.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "0px"
            }}/>
            <div style={{
                background: getBackgroundColorForStatus(assessment.expert_urologist_decision_one.status),
                border: assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" ? "1px solid var(--border-color-two)": "",
                borderRadius: "11px",
                width: "22px",
                height: "22px",
                alignSelf: "center",
                color: assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" && props.colorTheme === "dark" ? "var(--lightest-gray)" :"var(--darkest-gray)"
            }}>{assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" ? "✓": "2"}
            </div>
            <div style={{
                position: "relative",
                left: "50%",
                top: "-12px",
                border: assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "11px"
            }}/>
            <span style={{fontWeight: 600}}>{t("Expert Urologist")}</span>
            {Object.keys(assessment.expert_urologist_decision_one || []).map((key, i) => (
                typeof assessment.expert_urologist_decision_one[key] === "object" ? <span
                    onClick={() => props.goToRef ? props.goToRef("expertUrologistOneRef"): ""}
                    key={i} style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}>{t(assessment.expert_urologist_decision_one[key].title)};</span> : ""
            ))}
            {assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" ? (getStageAnswerToDisplay(props.assessment.inputs['expert_urologist_decision_one'] ? props.assessment.inputs['expert_urologist_decision_one']: "")): ""}
        </Col>
        <Col xs={3} style={{
            alignSelf: "start",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            margin: 0,
            padding: 0
        }}>
            <div style={{
                position: "relative",
                left: "0",
                top: "12px",
                border: assessment.expert_urologist_decision_one && assessment.expert_urologist_decision_one.status && assessment.expert_urologist_decision_one.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "0px"
            }}/>
            <div style={{
                background: getBackgroundColorForStatus(assessment.expert_radiologist.status),
                border: assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" ? "1px solid var(--border-color-two)": "",
                borderRadius: "11px",
                width: "22px",
                height: "22px",
                alignSelf: "center",
                color: assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" && props.colorTheme === "dark" ? "var(--lightest-gray)" :"var(--darkest-gray)"
            }}> {assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" ? "✓": "3"}
            </div>
            <div style={{
                position: "relative",
                left: "50%",
                top: "-12px",
                border: assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "11px"
            }}/>
            <span style={{fontWeight: 600}}>{t("Expert Radiologist")}</span>
            {Object.keys(assessment.expert_radiologist || []).map((key, i) => (
                typeof assessment.expert_radiologist[key] === "object" ? <span key={i}
                    onClick={() => props.goToRef ? props.goToRef("expertRadiologistRef"): ""}
                    style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}>{t(assessment.expert_radiologist[key].title ? assessment.expert_radiologist[key].title : (assessment.expert_radiologist[key].sub_title ? assessment.expert_radiologist[key].sub_title: ""))};</span> : ""
            ))}
            {assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" ? (getStageAnswerToDisplay(props.assessment.inputs['expert_radiologist_decision'] ? props.assessment.inputs['expert_radiologist_decision']: "")): ""}
        </Col>
        <Col xs={3} style={{
            alignSelf: "start",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            margin: 0,
            padding: 0
        }}>
            <div style={{
                position: "relative",
                left: "0",
                top: "12px",
                border: assessment.expert_radiologist && assessment.expert_radiologist.status && assessment.expert_radiologist.status.toLowerCase() === "completed" ? "1px solid var(--border-color-fainter)": "1px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "0px"
            }}/>
            <div style={{
                background: getBackgroundColorForStatus(assessment.expert_urologist_decision_two.status),
                border: assessment.expert_urologist_decision_two && assessment.expert_urologist_decision_two.status && assessment.expert_urologist_decision_two.status.toLowerCase() === "completed" ? "1px solid var(--border-color-two)": "",
                borderRadius: "11px",
                width: "22px",
                height: "22px",
                alignSelf: "center",
                color: assessment.expert_urologist_decision_two && assessment.expert_urologist_decision_two.status && assessment.expert_urologist_decision_two.status.toLowerCase() === "completed" && props.colorTheme === "dark" ? "var(--lightest-gray)" :"var(--darkest-gray)"
            }}>{assessment.expert_urologist_decision_two && assessment.expert_urologist_decision_two.status && assessment.expert_urologist_decision_two.status.toLowerCase() === "completed" ? "✓": "4"}
            </div>
            <div style={{
                position: "relative",
                left: "50%",
                top: "-12px",
                border: "0px dashed var(--border-color-fainter)",
                width: "calc(50% - 11px)",
                marginLeft: "11px"
            }}/>
            <span style={{fontWeight: 600}}>{t("Expert Urologist")}</span>
            {Object.keys(assessment.expert_urologist_decision_two || []).map((key, i) => (
                typeof assessment.expert_urologist_decision_two[key] === "object" ? <span key={i}
                    onClick={() => props.goToRef ? props.goToRef("expertUrologistTwoRef"): ""}
                    style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}>{t(assessment.expert_urologist_decision_two[key].title)};</span> : ""
            ))}
            {assessment.expert_urologist_decision_two && assessment.expert_urologist_decision_two.status && assessment.expert_urologist_decision_two.status.toLowerCase() === "completed" ? (getStageAnswerToDisplay(props.assessment.inputs['expert_urologist_decision_two'] ? props.assessment.inputs['expert_urologist_decision_two']: "")): ""}
        </Col>
    </Row>
}