import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const fallbackLng = 'en';
const availableLanguages = ['en-us', 'de', "es"];

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        nsSeparator:":::",
        debug: false,
        fallbackLng: fallbackLng,
        preload: ["es"],
        lng: localStorage.getItem("lng", "en-us"),
        detection: {
            checkWhitelist: true, // options for language detection
        },
        whitelist: availableLanguages,
        react: {
            useSuspense: false
        }
    });

export default i18n;