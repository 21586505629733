export const RISK_PORTAL = "/risk-portal";
export const PATIENT_LIST = "/patient-list";
export const PROFILE = "/profile";
export const TASKS = "/tasks";
export const SETTINGS = "/settings";
export const NOTIFICATIONS = "/notifications";
export const SURVEYS = "/surveys";
export const STAFF_ACCOUNTS = "/staff-accounts";
export const ACTIVITY_LOGS = "/activity-logs";
export const PATIENT_METRICS = "/patient-metrics";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const CREATE_ACCOUNT = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const ADD_PATIENT = "/add_patient";
export const SCREEN_PATIENT = "/screen_patient";
export const CHECK_IN = "/check-in";
export const GROUPS = "/groups";
export const DEMOGRAPHICS = "/demographics";
export const ALLERGIES = "/allergies";
export const INTERVENE = "/intervene";
export const MRI_VIEWER = "/mri-viewer";
export const MRI_VIEWER_V3 = "/mri-viewer/v3";
export const PATIENT_STATUS = "/patient";
export const STATUS = "/status";
export const RISK_PROFILE = "/risk-profile";
export const HELP_AND_RESOURCES = "/help-and-resources";
export const REPORT = "/report";
export const VIEW_REPORT = "/view-report";
export const AS_JOURNEY = "/as-journey";
export const AS_JOURNEY_SUMMARY = "/summary";
export const AS_JOURNEY_FULL_REPORT_PRINT = "/full-report";
export const B_REPORT = "/b-report";
export const BES_REPORT = "/es-report";
export const CARE_TEAM = "/care-team";
export const CONTACT = "/contact";
export const MEDICAL_BILLING = "/medical-billing";

export const LOGIN_API = "/api/accounts/login";
export const ACCOUNT_UPDATE_API = "/api/accounts/update";
export const FORGOT_PASSWORD_API = "/api/accounts/forgot";
export const RESET_PASSWORD_API = "/api/accounts/reset";
export const REFRESH_TOKEN_API = "/api/token/refresh";
export const REGISTER_API = "/api/accounts/register";
export const GRAPHQL_API = "/careportal";


export const PATIENT_RISK_STATUS = {
    NO_RISK: "No Risk",
    WAIT_AND_WATCH: "Watch/Wait",
    HIGH_RISK: "Risk Priority",
}