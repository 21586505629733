import React from "react";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class MriViewerV3 extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state =
      {
        HarComponent: this.props.t("Loading...")
      };

  }

  render = () =>
  {
    const remoteUrl = this.props.auth?.careportal_web_url || process.env.REACT_APP_API_URL;
    if(!remoteUrl)
      throw new Error('An error occurred while trying to read the environment variable for the API_URL.');

    const ohifViewerUrl = process.env.REACT_APP_OHIF_VIEWER_URL;
    const searchParams = new URLSearchParams(document.location.search);
    let dataObj =
      {
        token: this.props.auth.token,
        url: remoteUrl
      };
    let data = window.btoa( JSON.stringify(dataObj));
    let uids = searchParams.get('StudyInstanceUIDs');
    const url = `${ohifViewerUrl}/viewer?StudyInstanceUIDs=${uids}&data=${data}`;

    return <>
      <style>{".App { overflow: hidden; background-color: black; } "}</style>
      <iframe
        src={url}
        style={{
          height: "99vh",
          width: "99vw",
          overflow: "hidden"
        }}
        ></iframe>
    </>;
  }
}

const mapDispatchToProps = (dispatch) => { return { auth: dispatch.auth } };
export default withTranslation()(withRouter(connect(mapDispatchToProps)(MriViewerV3)));
