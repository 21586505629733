import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import SideNavBar from './common/SideNavBar';
import store, {persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
import PatientList from "./pages/PatientList";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/logout";
import RegisterPage from "./pages/Register";
import PatientProfile from "./pages/PatientProfile";
import WaveReport from "./pages/WaveReport";
import AsJourneyReport from "./pages/AsJourney";
import AsJourneyReportPrint from "./pages/AsJourneyPrint";
import HelpAndResources from "./pages/HelpAndResources";
import AddPatientForm from './components/AddPatientForm';
import EditGroups from './components/EditGroups';
import InterveneForm from './components/Intervene';
import WeeklyReport from './components/WeeklyReports/WeeklyReport';
import AsJourneySummaryReport from './components/WeeklyReports/WaveAsSummaryReport';
import AsJourneySummaryReportNew from './components/WeeklyReports/WaveAsSummaryReportNew';
import AsJourneyFullReportReport from './components/WeeklyReports/WaveAsFullReport';
import AsJourneyFullReportReportNew from './components/WeeklyReports/WaveAsFullReportNew';
import ErrorBoundary from './common/ErrorBoundary';
import * as Constants from "./constants/Constants";
import {ToastContainer} from 'react-toastify';
import IdleTimer from 'react-idle-timer';
import ReactGA from "react-ga4";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import EditDemographics from './components/EditDemographics';
import EditAllergies from './components/EditAllergies';
import CareTeam from './pages/CareTeam';
import {useEffect, useState} from "react";
import IdleLogin from "./components/IdleLogin";
import {useTranslation} from "react-i18next";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import MyProfile from "./pages/MyProfile";
import PageNotFound from "./pages/PageNotFound";
import MriViewer from "./pages/MriViewer";
import MriViewerV3 from "./pages/MriViewerV3";
import StaffAccounts from "./pages/StaffAccounts";
import TaskList from "./pages/TaskList";
import PatientMetrics from "./pages/PatientMetrics";
import ViewReport from './pages/ViewReport'
import ActivityLogs from './pages/ActivityLogs'
import ScreenPatient from './components/PatientList/ScreenPatient'
import { AS_JOURNEY_FULL_REPORT_PRINT, BES_REPORT } from './constants/Constants'

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
function App() {
    const [isIdle, updateIdle] = useState(0);       // this one logs out user
    const [isGaReported, setIsGaReported] = useState(false);
    const [reRender, updateRerender] = useState(Date.now());
    // const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    function handleOnIdle(event) {
        updateIdle(Math.floor(new Date().getTime()/1000));
    }

    if (!isGaReported) {
        ReactGA.send({hitType: "pageview", "page": window.location.pathname + window.location.search});
        setIsGaReported(true);
    }
    const {t, ready} = useTranslation();
    let marginLeftApp = !window.location.href.includes(Constants.MRI_VIEWER) && !window.location.href.includes(Constants.AS_JOURNEY_FULL_REPORT_PRINT+"/print") && !window.location.href.includes(Constants.AS_JOURNEY_SUMMARY+"/print")
    let showSideBar = !window.location.href.includes(Constants.MRI_VIEWER) && !window.location.href.includes(Constants.RESET_PASSWORD) && !window.location.href.includes(Constants.AS_JOURNEY_FULL_REPORT_PRINT+"/print") && !window.location.href.includes(Constants.AS_JOURNEY_SUMMARY+"/print")
    // useEffect(() => {
    //     document.documentElement.setAttribute("data-theme", 'dark');
    // }, ['dark']);
    if (ready)
        return (
            <div style={{background: "var(--background-color-faint)"}}>
                <Provider store={store}>
                    <PersistGate persistor={persistor} loading={null}>
                        <ToastContainer style={{zIndex: 3000}} position="bottom-right" newestOnTop={true}
                                        hideProgressBar closeOnClick icon={false} closeButton={false}/>
                        <Router>
                            <ErrorBoundary>
                                {!window.location.href.includes(Constants.RESET_PASSWORD) ?
                                  <IdleLogin isIdle={isIdle} t={t}/> : ""}
                                {showSideBar ? <SideNavBar reRender={reRender} t={t}/> : ""}
                                <div className="App"
                                     style={{marginLeft: marginLeftApp ? "" : "0px"}}>
                                    <div>
                                        <IdleTimer
                                            timeout={process.env.NODE_ENV === "development" ? (1000 * 60 * 15 - 30) : (1000 * 60 * 10 - 30)}
                                            onAction={() => {
                                                updateRerender(Date.now());
                                                updateIdle(false);
                                            }}
                                            onIdle={handleOnIdle}
                                            debounce={250}
                                            t={t}
                                        />
                                    </div>
                                    <Switch>
                                        <Route exact path={Constants.CREATE_ACCOUNT} component={RegisterPage}/>
                                        <Route exact path={Constants.FORGOT_PASSWORD} component={ForgotPassword}/>
                                        <Route exact path={Constants.RESET_PASSWORD + "/:resetToken"}
                                               component={ResetPassword}/>
                                        <ProtectedRoute path={Constants.MRI_VIEWER_V3} component={MriViewerV3}/>
                                        <ProtectedRoute path={Constants.MRI_VIEWER} component={MriViewer}/>
                                        <ProtectedRoute exact path={Constants.LOGIN} component={LoginPage}/>
                                        <ProtectedRoute exact path="/" component={PatientList}/>
                                        <ProtectedRoute exact path={Constants.PROFILE} component={MyProfile}/>
                                        <ProtectedRoute exact path={Constants.ADD_PATIENT}
                                                        render={() => <AddPatientForm t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.SCREEN_PATIENT}
                                                        render={() => <ScreenPatient t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.GROUPS}
                                                        render={() => <EditGroups t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.DEMOGRAPHICS}
                                                        render={() => <EditDemographics t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.ALLERGIES}
                                                        render={() => <EditAllergies t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.CARE_TEAM}
                                                        render={() => <CareTeam t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId"}
                                                        render={() => <AsJourneyReport t={t}
                                                                                       messagePatientButton={true} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId/print"}
                                                        render={() => <AsJourneyReportPrint t={t}
                                                                                       messagePatientButton={true} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId" + Constants.AS_JOURNEY_SUMMARY}
                                                        render={() => <AsJourneySummaryReport t={t}
                                                                                              messagePatientButton={false} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId" + Constants.AS_JOURNEY_SUMMARY+"/print"}
                                                        render={() => <AsJourneySummaryReportNew t={t}
                                                                                              messagePatientButton={false} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId" + Constants.AS_JOURNEY_FULL_REPORT_PRINT}
                                                        render={() => <AsJourneyFullReportReport t={t}
                                                                                              messagePatientButton={false} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.AS_JOURNEY + "/:asJourneyId" + Constants.AS_JOURNEY_FULL_REPORT_PRINT + "/print"}
                                                        render={() => <AsJourneyFullReportReportNew t={t}
                                                                                              messagePatientButton={false} />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.REPORT + "/:reportId"}
                                                        render={() => <WaveReport t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.VIEW_REPORT}
                                                        render={() => <ViewReport t={t}/>}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.B_REPORT + "/:reportId"}
                                                        render={() => <WaveReport t={t}
                                                                                  removeReportsList={true}
                                                                                  messagePatientButton={true}
                                                                                  reportTitle={"Biopsy Report"}
                                                        />}/>
                                        <ProtectedRoute exact
                                                        path={Constants.PATIENT_STATUS + "/:patientIdentifier" + Constants.BES_REPORT + "/:reportId"}
                                                        render={() => <WeeklyReport t={t} />}/>
                                        <ProtectedRoute exact path={Constants.LOGOUT} render={() => <LogoutPage t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.STAFF_ACCOUNTS}
                                                        render={() => <StaffAccounts t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.PATIENT_METRICS}
                                                        render={() => <PatientMetrics t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.PATIENT_LIST}
                                                        render={() => <PatientList t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.TASKS} render={() => <TaskList t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.HELP_AND_RESOURCES}
                                                        render={() => <HelpAndResources t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.ACTIVITY_LOGS}
                                                        render={() => <ActivityLogs t={t}/>}/>
                                        <ProtectedRoute exact path={Constants.INTERVENE}
                                                        render={() => <InterveneForm t={t}/>}/>
                                        <ProtectedRoute
                                            path={Constants.PATIENT_STATUS + "/:patientIdentifier/:patientPageType"}
                                            render={() => <PatientProfile t={t}/>}/>
                                        {/* default patient/PATIENT_ID/status */}
                                        <ProtectedRoute path="*" component={PageNotFound}/>
                                    </Switch>
                                </div>
                            </ErrorBoundary>
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        );
    else return (<div>{t("loading")}...</div>)
}

export default App;
