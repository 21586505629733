import { Col, Row, Container } from 'reactstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as helpers from '../../helpers/helpers'
import PractitionerCard from '../PractitionerCard'

export default function CheckedInCard (props) {
  const { t } = useTranslation()
  let properties = props.report && props.report.properties ? (typeof props.report.properties === "string" ? JSON.parse(props.report.properties): props.report.properties) : {}
  let data = props.report && props.report.data ? (typeof props.report.data === "string" ? JSON.parse(props.report.data): props.report.data) : {}
  let dateSubmitted = properties.checked_in_at ? properties.checked_in_at : ""
  let user = properties.checked_by ? properties.checked_by : {}

  let ecogOptions = {
    '0': { code: '0', label: <span><b style={{color:"var(--bs-primary)"}}>0</b> — {t('Fully Active, able to carry on all pre-disease performance without restriction')}</span> },
    '1': { code: '1', label: <span><b style={{color:"var(--bs-primary)"}}>1</b> — {t("Restricted in physically strenuous activity but ambulatory and able to carry out work of a light or sedentary nature, e.g., light house work, office work")}</span> },
    '2': { code: '2', label: <span><b style={{color:"var(--bs-primary)"}}>2</b> — {t("Ambulatory and capable of all selfcare but unable to carry out any work activities; up and about more than 50% of waking hours")}</span> },
    '3': { code: '3', label: <span><b style={{color:"var(--bs-primary)"}}>3</b> — {t("Capable of only limited selfcare; confined to bed or chair more than 50% of waking hours.")}</span> },
    '4': { code: '4', label: <span><b style={{color:"var(--bs-primary)"}}>4</b> — {t("Completely disabled; cannot carry on any selfcare; totally confined to bed or chair")}</span> },
    '5': { code: '5', label: <span><b style={{color:"var(--bs-primary)"}}>5</b> — {t("Dead")}</span> },
}

  return <Container style={{
    borderRadius: '10px',
    padding: 0,
    margin: "0 0 5px 0",
    width: '792px',
    maxWidth: '792px',
    background: 'var(--background-color)',
    color: 'var(--text-color)'
  }}>
    <Row style={{ padding: '10px 50px 5px 50px', margin: '0' }}>
        <Col style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          padding:0,
        }}>
                            <span
                              style={{ fontWeight: 600 }}>{helpers.getFormattedDate(dateSubmitted, 'DD MMM YY - hh:mm A')}</span>
          <span style={{ padding: '0 5px' }}>|</span>
          <span> <span className={'person-card-container'}
                       style={{
                         textDecoration: 'underline',
                         cursor: 'default',
                         padding: '7px 0'
                       }}>{helpers.getPractitionerName(user.first_name, user.last_name)}
            <div style={{
              position: 'relative',
              height: 0,
              background: 'transparent',
              left: '-86px'
            }}>
                                        <div className="person-card-container-tool-tip"
                                             style={{ background: 'transparent', border: 0, zIndex: 200 }}>
                                            <PractitionerCard person={user}/>
                                        </div>
                                    </div>
                                </span>
            </span>
        </Col>
    </Row>
    <Row style={{ padding: '0px 50px 5px 50px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 600,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px',
          marginRight:"5px"
        }}>
          {t("Intended Follow-up")}:
        </span>
        {data.intended_follow_up ? data.intended_follow_up.split("; ").map(p => t(p)).join("; "): ""}
      </Col>
    </Row>
    <Row style={{ padding: '0px 50px 5px 50px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 600,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px',
          marginRight:"5px"
        }}>
          {t("ECOG Status")}:
        </span>
        {data.ecog_status && ecogOptions[data.ecog_status] ? ecogOptions[data.ecog_status].label: ""}
      </Col>
    </Row>
    <Row style={{ padding: '0px 50px 5px 50px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 600,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px',
          marginRight:"5px"
        }}>
          {t("Comment")}:
        </span>
        {data.comment ? data.comment: ""}
      </Col>
    </Row>
  </Container>
}