import LoginBackground from "../assets/backgrounds/login.png";
import React from "react";
import {useTranslation} from "react-i18next";


export default function LogoAndBackground(props) {
    const {t} = useTranslation();
    return <div
        style={{padding: "0", margin: "0", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
        <img src={LoginBackground} style={{
            objectFit: "cover",
            height: "100vh",
            width: "100%",
            padding: "0",
            margin: "0",
            backgroundColor: "var(--bs-primary)"
        }} alt="login"/>
        <div className="wave-logo-login-container" style={{
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyItems: "center"
        }}>
            <svg width="152" height="152" viewBox="-2 -2 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="path-animate" fillRule="evenodd" clipRule="evenodd"
                      d="M144.996 76.7471C144.595 114.511 113.858 145 76 145C38.0609 145 7.27335 114.38 7.00181 76.5051C11.5528 78.188 14.5977 81.8169 17.8386 86.109C18.1294 86.4943 18.4246 86.8905 18.7253 87.2942L18.7263 87.2955C22.7629 92.7144 27.8177 99.5 37.2432 99.5C42.5166 99.5 46.852 96.8615 50.4312 93.5798C53.9398 90.3628 57.1203 86.1503 60.0593 82.2577L60.1716 82.109C63.2419 78.0427 66.0656 74.3218 69.0562 71.5798C72.0209 68.8615 74.7328 67.5 77.5135 67.5C80.2942 67.5 83.0061 68.8615 85.9708 71.5798C88.9614 74.3218 91.7851 78.0427 94.8555 82.109L94.9677 82.2577L94.9677 82.2578C97.9067 86.1503 101.087 90.3628 104.596 93.5798C108.175 96.8615 112.51 99.5 117.784 99.5C127.72 99.5 132.724 92.0313 136.508 86.3834L136.798 85.9505C139.655 81.6941 141.973 78.4242 144.996 76.7471ZM144.679 69.297C138.142 71.3691 134.315 77.0802 131.276 81.6167L130.986 82.0495C126.751 88.3586 123.7 92.5 117.784 92.5C115.003 92.5 112.291 91.1385 109.326 88.4202C106.336 85.6782 103.512 81.9573 100.442 77.891L100.33 77.7423C97.3906 73.8498 94.2101 69.6372 90.7015 66.4202C87.1223 63.1385 82.7868 60.5 77.5135 60.5C72.2402 60.5 67.9048 63.1385 64.3255 66.4202C60.817 69.6372 57.6364 73.8497 54.6974 77.7423L54.5852 77.891C51.5148 81.9573 48.6912 85.6782 45.7005 88.4202C42.7359 91.1385 40.024 92.5 37.2432 92.5C31.4278 92.5 28.4565 88.561 23.8212 82.416L23.4249 81.891C19.7428 77.0144 15.166 71.3156 7.32352 69.2752C10.7041 34.3233 40.1612 7 76 7C111.846 7 141.308 34.3347 144.679 69.297ZM152 76C152 117.974 117.974 152 76 152C34.0264 152 0 117.974 0 76C0 34.0264 34.0264 0 76 0C117.974 0 152 34.0264 152 76Z"
                      fill="var(--background-color)"/>
            </svg>
            <span style={{
                alignSelf: 'center',
                marginTop: "30px",
                justifySelf: "center",
                textAlign: "center",
                fontSize: "30px",
                lineHeight: "41px",
                fontWeight: "400",
                color: "var(--background-color)"
            }}>{t("welcome_to_wave_")}</span>
        </div>
        <div style={{
            display: "none",
            position: "absolute",
            left: "100px",
            alignSelf: "flex-end",
            textAlign: "start",
            width: "720px",
            bottom: "25px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "22px",
            color: "white"
        }}>
            {t("terms_of_service")} | {t("privacy_policy")}
        </div>
    </div>
}
