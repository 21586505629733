import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store';
import authSlice from '../store/slices/auth';
import * as Constants from '../constants/Constants';
import * as helpers from '../helpers/helpers';
import patientsSlice from "../store/slices/patients";

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosService.interceptors.request.use(async (config) => {
    const {token, careportal_web_url} = store.getState()!.auth;
    if (token !== null) {
        // @ts-ignore
        config.headers.Authorization = 'Bearer ' + token;
        // @ts-ignore
        // console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    }
    if (careportal_web_url){
        config.baseURL = careportal_web_url;
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore
        // console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        // if (err.response)
        //     console.debug(
        //         '[Response]',
        //         err.config.baseURL + err.config.url,
        //         err.response.status,
        //         err.response.data
        //     );
        // else
        //     console.debug('[Response]', err);
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    if(store.getState().auth) {
        return Promise.reject("User not logged in");
    }
    const {refresh, token} = store.getState().auth;
    if (Boolean(refresh)) {
        return axios
            .post(
                `${Constants.REFRESH_TOKEN_API}`,
                {
                    refresh: refresh,
                    token: token,
                },
                {
                    baseURL: process.env.REACT_APP_AUTH_API_URL
                }
            )
            .then((resp) => {
                const {token} = resp.data.message;
                store.dispatch(
                    authSlice.actions.setAuthTokens(resp.data.message)
                );
                failedRequest.response.config.headers.Authorization = 'Bearer ' + token;
            })
            .catch((err) => {
                // console.debug("ERROR", err);
                if (err.response && err.response.status === 401) {
                    store.dispatch(authSlice.actions.setLogout());
                    store.dispatch(patientsSlice.actions.clearData());
                }
                return Promise.reject(err);
            });
    } else {
        store.dispatch(authSlice.actions.setLogout());
        store.dispatch(patientsSlice.actions.clearData());
        return Promise.reject("Failed as there is no refresh token");
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export function fetcher(url: string, query: string| any, requestOptions:object| undefined) {
    return axiosService.post(url, query, requestOptions).then((res) => res.data);
}

export function reportAction(action: string, patientId: string | undefined, message: string | undefined) {
    var data = JSON.stringify({
        query: `mutation ($action: String!) {
            store_event(action: $action${patientId ? `, patient_id: "${patientId}"`: ""}${message ? `, message: "${encodeURIComponent(message)}"`: ""}) {
                uid,
                action,
                created_at,
                updated_at,
                role,
                user{
                    uuid
                },
                patient{
                    uuid
                }
            }
        }`,
        variables: {action}
    });
    axiosService.post(Constants.GRAPHQL_API, data);
}

export function fetchUserSettings(forceReq: boolean) {
    const {account, token} = store.getState()!.auth;
    if (!forceReq && account !== null) {
        return Promise.resolve(account.user);
    }
    if (token !== null) {
        let uuid;
        try {
            // @ts-ignore
            uuid = helpers.parseJwt(token).uid;
        } catch (error) {
            return new Promise<String>((resolve, reject) => {
                reject("Invalid Token")
            });
            // TODO Report Error ? Google Analytics
        }
        var query = JSON.stringify({
            query: `query{
                user {
                    uuid
                    first_name
                    last_name
                    suffix
                    prefix
                    doc_type
                    speciality
                    account_type
                    location_code
                    language
                    photo
                    registration_code{
                        code
                        uid
                        updated_at
                        created_at
                    }
                    campus{
                        name
                    }
                    contact{
                        contact_type
                        phone
                        email
                        address{
                            first_line
                            unit
                            zipcode
                            city
                        }
                    }
                    organization{
                        uid
                        name
                        style
                        logo
                    }
                  }
                organization{
                    uid
                    name
                    style
                    logo
                    help_and_resources{
                        title
                        description
                        updated_at
                        file
                    }
                    registration_codes{
                        code
                        hcp{
                            first_name
                            last_name
                            uuid
                            account_type
                        }
                    }
                }
            }`,
            variables: {}
        });
        return axiosService.post(Constants.GRAPHQL_API, query).then((res) => {
            return res.data.data;
        })
            .catch((err) => {
                console.debug("Error Occured getting user info", err);
                if (err.message === "Network Error") {
                    return err.message;
                }
                return "Error, Please contact Admin";
            });
    }
    return Promise.resolve("User not Logged In");
}

export default axiosService;