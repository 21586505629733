import Moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/es'
import ReactGA from 'react-ga4'
import EnUsIcon from '../assets/icons/locales/lang-en-us-icon.svg'
import DeIcon from '../assets/icons/locales/lang-de-icon.svg'
import EsIcon from '../assets/icons/locales/lang-es-icon.svg'
import mood0 from '../assets/icons/mood_icons/mood_0.svg'
import mood1 from '../assets/icons/mood_icons/mood_1.svg'
import mood2 from '../assets/icons/mood_icons/mood_2.svg'
import mood3 from '../assets/icons/mood_icons/mood_3.svg'
import mood4 from '../assets/icons/mood_icons/mood_4.svg'
import React from 'react'
import Select, { components } from 'react-select'
import { ReactComponent as EditIcon } from '../assets/icons/Edit.svg'


const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
          <EditIcon style={{height: "15px", width:"15px"}} />
      </components.DropdownIndicator>
    );
};

const reactSelectStyles = {
    container: (base, state) =>({
        ...base,
        maxHeight:"30px",
        height:"30px",
        width:"max-content",
        alignContent:"center",
        color: "var(--wave-blue)",
        margin:0,
        padding:0,
        background: "transparent",
    }),
    ValueContainer: (provided, state) => ({
        ...provided
    }),
    singleValue: (provided, state) => ({
        ...provided
    }),
    IndicatorsContainer: (provided, state) => ({
        ...provided,
        padding: 0,
        margin:0,
        height:"30px",
        color:"red",
        minHeight:"30px",
    }),
    control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        padding: 0,
        height:"30px",
        minHeight:"30px",
        backgroundColor: "transparent"
    }),
    Control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        margin:0,
        padding:0,
        minHeight:"30px",
        backgroundColor: "transparent"
    }),
    menu: base => ({
        ...base,
        outline:0,
        zIndex:110,
        minHeight:"30px",
        width:"max-content",
        textAlign:"start",
    }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "var(--border-color-fainter)" :"var(--background-color-faintest)",
        color: "var(--text-color)",
        opacity: state.isFocused ? 0.9: 1,
        zIndex:110
    }),
    indicatorSeparator: (base, state)=>({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "var(--text-color-faint)",
    })
};

/**
 * Function to get report title from dates
 * return result with possible formats as
 * "MMM DD YYYY" - start date and end date are same
 * "MMM DD - DD YYYY" - start date and end date within same month and year
 * "MMM DD - MMM DD YYYY" - start date and end date within same year
 * "MMM DD YYYY - MMM DD YYYY"
 * @param  {[{start_date: Number, end_date: Number}]} values      report Object
 * @return {String}                 result
 */
export function getReportDate(values, t=null) {
    if (!values || values.length < 1) {
        return "";
    }
    let final_out = "";
    let utcSeconds_start = values[0].start_date;
    let utcSeconds_end = values[0].end_date;
    let d_start = new Date(0);
    let d_end = new Date(0);
    d_start.setUTCSeconds(utcSeconds_start);
    d_end.setUTCSeconds(utcSeconds_end);
    if (t) Moment.locale(t("locale-for-date"))
    let d_start_date = Moment.utc(d_start).format("DD");
    let d_end_date = Moment.utc(d_end).format("DD");
    let d_start_month = Moment.utc(d_start).format("MMM");
    let d_end_month = Moment.utc(d_end).format("MMM");
    let d_start_year = Moment.utc(d_start).format("YYYY");
    let d_end_year = Moment.utc(d_end).format("YYYY");
    final_out += d_start_month + " " + d_start_date;
    if (d_start === d_end) {
        final_out += " " + d_start_year;
    } else {
        if (d_start_year !== d_end_year) {
            final_out += " " + d_start_year;
        }
        if (d_start_month !== d_end_month) {
            final_out += " - ";
            final_out += d_end_month + " " + d_end_date + " ";
        } else if (d_start_date !== d_end_date) {
            final_out += " - ";
            final_out += d_end_date + " ";
        } else {
            final_out += ", ";
        }
        final_out += " " + d_end_year;
    }
    return final_out;
}


/**
 * Function to Format Date Object or UNIX-TIMESTAMP with the given format
 * If Date Format is not provided, default "MMM DD, YYYY - HH:mm A" is used.
 * @param  {Object, Number} dt      Date object or Unix Timestamp (epoch)
 * @param  {String} dateFormat      Date Format
 * @param  {Function} t             TFunction form i18n library
 * @return {String}                 result
 */
export function getFormattedDate(dt, dateFormat, t=null) {
    try{
        // if no format provided, use default
        if (!Boolean(dateFormat)) {
            dateFormat = "MMM DD, YYYY - HH:mm A";
        }
        var dtc = new Date(0);
        if (typeof dt === "number") {
            dtc.setUTCSeconds(dt);
        } else {
            dtc = dt;
        }
        if (t){
            Moment.locale(t("locale-for-date"))
        }
        if (!dtc){
            return "Invalid date"
        }
        let out = Moment(dtc).format(dateFormat);
        out = out.replace("ene", "Ene")
        out = out.replace("feb", "Feb")
        out = out.replace("mar", "Mar")
        out = out.replace("abr", "Abr")
        out = out.replace("may", "May")
        out = out.replace("jun", "Jun")
        out = out.replace("jul", "Jul")
        out = out.replace("ago", "Ago")
        out = out.replace("sep", "Sep")
        out = out.replace("oct", "Oct")
        out = out.replace("nov", "Nov")
        out = out.replace("dec", "Dec")
        return out.replaceAll(".", "");
    }
    catch (err){
    }
    return "Invalid date";
}

export function getUTCFormattedDate(dt) {
    try{
        var dtc = new Date(0);
        dtc.setUTCSeconds(dt);
        return String(dtc.getUTCMonth() + 1).padStart(2, "0") + "/" + dtc.getUTCDate() + "/" + dtc.getUTCFullYear()
    }
    catch (err){
    }
    return "Invalid date";
}

export function getTimeToDisplay(timeInSeconds, t) {
    if (timeInSeconds < 60){
        return "~1 " + t("minute")
    }
    if (timeInSeconds / 60 < 60){
        return `${Math.floor(timeInSeconds / 60)} ${Math.floor(timeInSeconds / 60) <= 1 ? t("minute") : t("minutes")}`
    }
    if (timeInSeconds / (60*60) < 24){
        return `${Math.floor(timeInSeconds / (60*60))} ${Math.floor(timeInSeconds / (60*60)) <= 1 ? t("hour") : t("hours")}`
    }
    else{
        return `${Math.floor(timeInSeconds / (60*60*24))} ${Math.floor(timeInSeconds / (60*60*24)) <= 1 ? t("day") : t("days")}`
    }
}

/**
 * Function to limit characters on patient Name and add … at the end
 * TODO add "hint tip" to the limited text, to show full text.
 * @param  {String} patientName     Full Name (Any format)
 * @param  {Number} charLimit       default limit 30
 * @return {String}                 result
 */
export function getPatientName(patientName, charLimit) {
    if (!charLimit) {
        charLimit = 30;
    }
    if (!patientName) {
        return "";
    }
    if (patientName.length >= charLimit) {
        return patientName.substring(0, charLimit - 1) + "…";
    }
    return patientName;
}
/**
 * Function to limit characters on Practitioner Name and add … at the end
 * @param  {String} first_name      First Name (Any format)
 * @param  {String} last_name       Last Name (Any format)
 * @param  {Number} charLimit       default limit 30
 * @return {String}                 result
 */
export function getPractitionerName(first_name, last_name, charLimit) {
    if (!charLimit) {
        charLimit = 30;
    }
    if (!first_name && !last_name) {
        return "";
    }
    let out = "";
    if (last_name.length >= charLimit - 2) {
        out = last_name.substring(0, charLimit - 3) + "…";
    }
    else{
        out = last_name;
    }
    if (first_name){
        out += ", " + first_name[0].toUpperCase()
    }
    return out;
}

export function getEmojiForMood(mood) {
    switch(mood){
        case 5:
            return <img src={mood0} style={{height:"20px", width:"20px"}} />
        case 4:
            return <img src={mood1} style={{height:"20px", width:"20px"}} />
        case 3:
            return <img src={mood2} style={{height:"20px", width:"20px"}} />
        case 2:
            return <img src={mood3} style={{height:"20px", width:"20px"}} />
        case 1:
            return <img src={mood4} style={{height:"20px", width:"20px"}} />
        default:
            return "☺";

    }
}
export function getTextForMood(mood, t) {
    switch(mood){
        case 5:
            return t("Very Negative")
        case 4:
            return t("Negative")
        case 3:
            return t("Neutral")
        case 2:
            return t("Positive")
        case 1:
            return t("Very Positive")
        default:
            return "";
    }
}

export function getMoodColor(mood){
    switch(mood){
        case 5:
            return "#CC0000"
        case 4:
            return "#F1421B"
        case 3:
            return "#EECB49"
        case 2:
            return "#71BD24"
        case 1:
            return "#279458"
    }
}
export function getConditionColor(level){
    if (level>=80)
        return "#279458"
    if (level>=60)
        return "#71BD24"
    if (level>=40)
        return "#EECB49"
    if (level>=20)
        return "#F1421B"
    if (level>=0)
        return "#CC0000"
}

/**
 * Function to get Condition wording based on condition level.
 * @param  {Number} condition       condition, from 0 to 100
 * @param  {Function} t             TFunction form i18n library
 * @return {String}                 Condition wording
 */
export function getConditionWording(condition, t) {
    if (condition > 90) {
        return t("great");
    }
    if (condition > 80) {
        return t("very_good");
    }
    if (condition > 70) {
        return t("good");
    }
    if (condition > 60) {
        return t("fair");
    }
    if (condition > 50) {
        return t("mediocre");
    }
    if (condition > 40) {
        return t("not_good");
    }
    if (condition > 30) {
        return t("poor");
    }
    if (condition > 20) {
        return t("bad");
    }
    if (condition > 10) {
        return t("very_bad");
    }
    if (condition >= 0) {
        return t("horrible");
    }
    return "";
}

/**
 * Function to get Symptom wording based on level.
 * Used for dynamic coloring
 * Returns the CSS var string, which is defined in index.css
 * @param  {Number} level       level, from 1 to 4
 * @param  {Boolean} translate  whether to translate the result
 * @param  {Function} t         TFunction form i18n library
 * @return {String}             Symptom wording
 */
export function getSymptomWordingFromLevel(level, translate, t) {
    level -= 1  // as apps record 1 to 5, but the standard is 0 to 4
    if (level >= 4) {
        if (translate) {
            return t("Very Severe");
        }
        return "Unbearable";
    }
    if (level >= 3) {
        if (translate) {
            return t("severe");
        }
        return "Severe";
    }
    if (level >= 2) {
        if (translate) {
            return t("moderate");
        }
        return "Moderate";
    }
    if (level >= 1) {
        if (translate) {
            return t("mild");
        }
        return "Mild";
    }
    if (translate) {
        return t("none");
    }
    return "None";
}

/**
 * Function to get Symptom Color based on level.
 * Used for dynamic coloring
 * Returns the CSS var string, which is defined in index.css
 * @param  {Number} level           level, from 1 to 4
 * @param  {Boolean} translate      translate, true or false
 * @param  {Function} t             translation
 * @return {String}                 CSS var string for color
 */
export function getSymptomColorFromLevel(level, translate=false, t) {
    return `var(--${getSymptomWordingFromLevel(level, translate, t).toLowerCase()}-symptom)`;
}

/**
 * Function to convert birthdate to age
 * @param  {String} dateString      birthdate
 * @return {Number}                 Formatted Phone number string
 */
export function getAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function getFormattedBirthdate(dateString, t){
    let birthDate = new Date(dateString);
    return getFormattedDate(birthDate, "DD MMM yyyy", t)
}

/**
 * Function to format Phone Number into +(country-code)'NO-BREAK SPACE'(3-digits)'NO-BREAK SPACE'(3-digits)'NO-BREAK SPACE'(4-digits)
 * @param  {String} phone    username or email
 * @return {String}          Formatted Phone number string
 */
export function formatPhoneNumber(phone) {
    if (typeof phone !== 'string') {
        return phone;
    }
    let filtered = phone.match(/[0-9]/g);
    return (filtered.slice(0, -10).length > 0 ? ("+" + filtered.slice(0, -10).join("") + `\u00a0`) : "") + filtered.slice(-10, -7).join("") + "\u00a0" + filtered.slice(-7, -4).join("") + "\u00a0" + filtered.slice(-4).join("");

}

/**
 * Function to check valid email.
 * It is a simple regex comparison.
 * NOTE: It is turned off for now as username could be non-email
 * @param  {String} username    username or email
 * @return {Boolean}            result
 */
export function checkValidEmail(username) {
    if (username === "") {
        return false;
    }
    let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
    if (!re.test(username)) {
        // debounce(() => this.setState({error: "Please enter a valid email.", usernameError: true}), 500);
        // return false;
        return true;    // stubbed as the username could be non-email field
    } else {
        return true;
    }
}

/**
 * Simpler parser for JWT
 * IMP: throws parsing error
 * @param  {String} token   JWT Token
 * @return {Object}         json parsed - Object
 */
export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * JWT Token has properties, which includes the component list that the user has access to.
 * The function returns the component object, or an empty object
 * @param  {String, null} token JWT Token
 * @return {Object}      Components json as JS Object
 */
export function getComponentsFromJwt(token) {
    let defaultComponents = {
        // side_nav_bar:["risk_portal", "notifications", "survey", "help_and_resources", "my_profile", "tasks", "staff-accounts", "patient-metric"],
        side_nav_bar: [],
        // help_and_resources: "WagHelpAndResources",
        // patient_profile: "SimplePatientInfoStatus",
        patient_list_columns: ["name"],
        patient_list_search: 1,
        patient_list_filters: 0,
        patient_list_title: "Patient List",
        task_list: "TaskList",
        patient_metrics: "PatientMetrics",
        staff_accounts: "StaffAccounts",
        add_patients: 0,
        screen_patients: 0,
        bs_weekly_report: 0,
        risk_tooltip: 0,
        ohif_v3: 0,
        // my_profile: ['profile', 'password', 'terms', 'privacy']
        // my_profile: "MyProfile"
    }
    try {
        // default component list to be created
        return Boolean(parseJwt(token).pro.components) ? parseJwt(token).pro.components : defaultComponents;
    } catch (error) {
        return defaultComponents;
    }
}

/**
 * Function to report to multiple systems.
 * @param eventName
 * @param eventDetails
 */
export function reportAnalyticsEvent(eventName, eventDetails){
    ReactGA.event(eventName, eventDetails);
}

/**
 * Function to set User Properties for reporting
 * @param userProperties
 */
export function setAnalyticsUserProperties(userProperties){
    ReactGA.set({user_properties: userProperties});
}

/**
 * Function to setup reporting
 * @param userProperties
 */
export function initializeAnalytics(userProperties){
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {gaOptions: userProperties});
}

/**
 * Function to get all supported Languages
 */
export function getAllLanguages(){
    return {
        "en-us": { title: "English (English US)", icon: EnUsIcon, key: "en-us" },
        "de": { title: "Deutsch (German)", icon: DeIcon, key: "de" },
        "es": { title: "Español (Spanish)", icon: EsIcon, key: "es" }
    }
}

export function getTxStatus (values, t, maxLimit, backgroundColor=null, boldText=false){
    let MAX_STATUS_LENGTH_TO_SHOW = maxLimit ? maxLimit : 6
    var bgStyle = {
        minWidth: "73px",
        maxWidth: "73px",
        color: `var(--text-color)`,
        borderRadius: "2px",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        width: "73px",
        textAlign: "center",
        height: "26px"
    }
    var isLargeStatusWord = t(values.title).length > MAX_STATUS_LENGTH_TO_SHOW;
    if (values.status === 'as_candidate'){
        bgStyle.backgroundColor = `rgba(var(--wave-green-rgb), ${0.65 - values.as_year / 20})`;
    } else if (values.status === 'candidate'){
        MAX_STATUS_LENGTH_TO_SHOW = maxLimit ? 4: 100;
        isLargeStatusWord = Boolean(maxLimit);
        if(!Boolean(maxLimit)){
            bgStyle.width = "85px";
            bgStyle.maxWidth = "85px";
        }
        bgStyle.border = `2px solid var(--border-color-faintest)`;
        bgStyle.backgroundColor = `rgba(var(--background-color), ${0.65 - values.as_year / 20})`;
    } else if (values.status === 'non_as'){
        bgStyle.backgroundColor = 'var(--background-color-faint)';
    } else if (values.status === 'deactivated'){
        MAX_STATUS_LENGTH_TO_SHOW = 6;
        isLargeStatusWord = true;
        bgStyle.backgroundColor = 'var(--border-color-fainter)';
    }
    if (backgroundColor){
        bgStyle.backgroundColor = backgroundColor
        bgStyle.border = `2px solid var(--border-color)`;
    }
    if (boldText){
        bgStyle.fontWeight = 600
    }
    return <div className='tx-status-text'><span style={bgStyle}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`
              }}>{t(values.title).slice(0, MAX_STATUS_LENGTH_TO_SHOW) + (isLargeStatusWord ? "." : '')}</span>
            </span>
        {isLargeStatusWord ? <div style={{position: "relative", height: 0}}>
            <div className='tx-status-tool-tip'>{t(values.title)}</div>
        </div> : ''}
    </div>
}


export function getAsDecisionStatus(values, t, maxLimit){
    let MAX_STATUS_LENGTH_TO_SHOW = maxLimit ? maxLimit : 6
    let width = maxLimit * 9.5;
    var bgStyle = {
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
        color: `var(--text-color)`,
        borderRadius: "2px",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        width: `${width}px`,
        textAlign: "center",
        height: "26px"
    }
    var isLargeStatusWord = t(values.title).length > MAX_STATUS_LENGTH_TO_SHOW;
    if (values.status === 'final_decision' || values.status === 'FINALDECISION'){
        bgStyle.backgroundColor = `rgba(var(--wave-orange-rgb), 0.5)`;
    } else if (values.status === 'in_progress') {
        bgStyle.backgroundColor = `rgba(var(--wave-yellow-rgb), 0.5)`;
    }
    return <div className='tx-status-text'><span style={bgStyle}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`
              }}>{t(values.title).slice(0, MAX_STATUS_LENGTH_TO_SHOW) + (isLargeStatusWord ? "..." : '')}</span>
            </span>
        {isLargeStatusWord ? <div style={{position: "relative", height: 0}}>
            <div className='tx-status-tool-tip'>{t(values.title)}</div>
        </div> : ''}
    </div>
}


export function getPdxToDisplay(values, t, maxLimit=6, editable=false, pdxOnClick=null, pdxChoices=[]){
    let MAX_STATUS_LENGTH_TO_SHOW = maxLimit ? maxLimit : 6
    let width = maxLimit * 13;
    var bgStyle = {
        minWidth: `80px`,
        maxWidth: `${width}px`,
        color: `var(--text-color)`,
        borderRadius: "2px",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "center",
        whiteSpace:"nowrap",
        height: "26px"
    }
    var isLargeStatusWord = t(values).length > MAX_STATUS_LENGTH_TO_SHOW;
    switch(values.toLowerCase()){
        case "nmpc":
            bgStyle.backgroundColor = `rgba(var(--wave-purple-rgb), 0.5)`;
            bgStyle.maxWidth = "80px"
            break;
        case "mhspc":
            bgStyle.backgroundColor = `rgba(var(--wave-yellow-rgb), 0.5)`;
            bgStyle.maxWidth = "80px"
            break;
        case "mcrpc":
            bgStyle.backgroundColor = `rgba(var(--wave-green-rgb), 0.5)`;
            bgStyle.maxWidth = "80px"
            break;
        default:
            bgStyle.backgroundColor = `var(--wave-purple-faint)`;
            break;
    }

    if (editable && pdxChoices){
        return <div style={{fontSize:"14px", lineHeight:"22px", width:"max-content", whiteSpace:"nowrap", borderRadius: "2px", backgroundColor:bgStyle.backgroundColor}}>
            <Select styles={{...reactSelectStyles}}
                    isSearchable={false}
                    value={{ value:values, label: values }}
                    components={{Placeholder: ({ children, ...props }) => (
                          <components.Placeholder {...props}>
                              <span style={{color:"var(--text-color-faintest)", fontWeight:600}}>{'Select'}</span>
                          </components.Placeholder>
                        ), DropdownIndicator}}
                    options={pdxChoices}
                    onChange={(temp) => pdxOnClick({ "pdx": temp.value })}
            />
        </div>
    }
    return <div className='tx-status-text'><span style={bgStyle}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`
              }}>{t(values).slice(0, MAX_STATUS_LENGTH_TO_SHOW) + (isLargeStatusWord ? "..." : '')}</span>
            </span>
        {isLargeStatusWord ? <div style={{position: "relative", height: 0}}>
            <div className='tx-status-tool-tip'>{t(values)}</div>
        </div> : ''}
    </div>
}

export function getStudyStatusToDisplay(values, t, maxLimit=20){
    let MAX_STATUS_LENGTH_TO_SHOW = maxLimit ? maxLimit : 6
    let width = maxLimit * 13;
    var bgStyle = {
        minWidth: `80px`,
        maxWidth: `${width}px`,
        color: `var(--text-color)`,
        borderRadius: "2px",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        width: `120px`,
        textAlign: "center",
        height: "30px"
    }
    var isLargeStatusWord = t(values).length > MAX_STATUS_LENGTH_TO_SHOW;
    // if(values.toLowerCase().includes("active"))
    bgStyle.backgroundColor = `rgba(var(--wave-green-rgb), 0.5)`;

    return <div className='tx-status-text'><span style={bgStyle}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`,
                  whiteSpace:"nowrap"
              }}>{t(values).slice(0, MAX_STATUS_LENGTH_TO_SHOW) + (isLargeStatusWord ? "..." : '')}</span>
            </span>
        {isLargeStatusWord ? <div style={{position: "relative", height: 0}}>
            <div className='tx-status-tool-tip'>{t(values)}</div>
        </div> : ''}
    </div>
}

export function getLastActiveText(lastActive, t){
    var la = new Date(0);
    la.setUTCSeconds(lastActive);
    var today = new Date();
    var outStr = "";
    if (today.getMonth() === la.getMonth() && today.getFullYear() === la.getFullYear()) {
        if (today.getDate() === la.getDate()) outStr = t(`Today`);
        else if ((today.getDate() - 1) === la.getDate()) outStr = t(`Yesterday`);
        else outStr = getFormattedDate(la, "DD MMM YYYY");
    } else outStr = getFormattedDate(la, "DD MMM YYYY");
    return outStr;
}

export function getSaturdayPair(d_utc, dow=6, forCareTaskListToDos=false) {
    let d = new Date(0)
    d.setUTCSeconds(d_utc)
    let isSaturday = (dow + (7 - d.getUTCDay())) % 7 === 0
    d.setUTCDate(d.getUTCDate() + ((dow + (7 - d.getUTCDay())) % 7) - (forCareTaskListToDos ? 7 : 0) )
    if (forCareTaskListToDos && isSaturday){
        d.setUTCDate(d.getUTCDate()+7)
        d = new Date(Date.UTC(d.getUTCFullYear(),d.getUTCMonth(),d.getUTCDate()))
    }
    else
        d = new Date(Date.UTC(d.getUTCFullYear(),d.getUTCMonth(),d.getUTCDate()))
    let pd = new Date(d)
    pd.setUTCDate(d.getUTCDate() - dow)
    return [pd, d]

    // let d = new Date(0)
    // d.setUTCSeconds(d_utc)
    // let startTime = new Date(d)
    // if (d.getUTCMinutes() < 30){
    //     startTime.setUTCMinutes(0)
    //     d.setUTCMinutes(30)
    //     d.setUTCSeconds(0)
    //     startTime.setUTCSeconds(0)
    //     return [startTime, d]
    // }
    // else if (d.getUTCMinutes() < 60){
    //     startTime.setUTCMinutes(30)
    //     d.setUTCMinutes(60)
    //     d.setUTCSeconds(0)
    //     startTime.setUTCSeconds(0)
    //     return [startTime, d]
    // }
}

export function isCurrentReport(time){
    let satPair = getSaturdayPair(Math.floor(Date.now()/1000), 6, true)
    if (Math.floor(satPair[0].getTime()/1000) < time){
        return true
    }
    return false
    // let d = new Date()
    // let dTime = new Date(0)
    // dTime.setUTCSeconds(time)
    // if (Math.floor(d.getTime()/1000) > time && Math.floor(d.getTime()/1000) - time > (30 * 60))
    //     return false
    // if (d.getMinutes() < 30){
    //     return dTime.getMinutes() < 30
    // }
    // else if (d.getMinutes() < 60){
    //     return dTime.getMinutes() < 60
    // }
    // return false
}

export function getDateRange(reportEndTimeSeconds, t){

    let reportStartTime = reportEndTimeSeconds - (6 * 24 * 60 * 60)
    let dateRange = getFormattedDate(getUTCFormattedDate(reportStartTime), "DD MMM", t)
    if (getFormattedDate(reportStartTime, "YYYY") !== getFormattedDate(reportEndTimeSeconds, "YYYY"))
      dateRange += getFormattedDate(getUTCFormattedDate(reportStartTime), " YY", t)
    dateRange += " - "
    dateRange += getFormattedDate(getUTCFormattedDate(reportEndTimeSeconds), "DD MMM YY", t)
    return dateRange

    /// start demo code
    // let reportEndTime = new Date(0)
    // reportEndTime.setUTCSeconds(reportEndTimeSeconds)
    // let reportStartTime = new Date(reportEndTime)
    // if (reportEndTime.getUTCMinutes() < 30){
    //     reportEndTime.setUTCMinutes(30)
    //     reportStartTime.setUTCMinutes(0)
    // }
    // else if (reportEndTime.getUTCMinutes() <= 60){
    //     reportStartTime.setUTCMinutes(30)
    //     reportEndTime.setUTCMinutes(60)
    // }
    // let dateRange = getFormattedDate(reportStartTime, "HH:mm", t)
    // if (getFormattedDate(reportStartTime, "DD MMM YYYY") !== getFormattedDate(reportEndTime, "DD MMM YYYY"))
    //     dateRange += getFormattedDate(reportStartTime, " DD MMM YY", t)
    // dateRange += " - "
    // dateRange += getFormattedDate(reportEndTime, "HH:mm DD MMM, YY", t)
    // return dateRange
}