import React from "react";
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

export default function TableHeaderAs(props){
    const { t } = useTranslation();
    // if (props.column_header.key && typeof props.column_header.key === "string" && props.column_header.key.includes("mr_pi_score_l")){
    //     if (!props.inputs["number_of_pi_rads_lesions"] || (props.inputs["number_of_pi_rads_lesions"] && parseInt(props.column_header.key.substring(13, 14)) > parseInt(props.inputs["number_of_pi_rads_lesions"]))){
    //         return "";
    //     }
    // }
    return <th colSpan={props.column_header.col_span} style={{
        visibility: props.column_header.visible ? "visible": "hidden",
        height: props.column_header.visible ? "" : 0,
        border: props.column_header.visible ? "1px solid var(--border-color)": "",
        textAlign: "center",
        width: props.column_header.value.includes("L1") || props.column_header.value.includes("L2") || props.column_header.value.includes("L3") || props.column_header.value.includes("L4")
          ? `max(160px, ${props.column_header.value.length > 15 ? "100": props.column_header.value.length * 9}px)`
          : `${Math.floor(props.column_header.col_width / 12 * 100)}%`
    }}>
        <div style={{display:"flex", flexDirection:"row", alignContent:"center", justifyContent:'center'}}>{props.column_header.value.includes("L1") || props.column_header.value.includes("L2") || props.column_header.value.includes("L3") || props.column_header.value.includes("L4")
          ? <span className='tx-status-text'>{(t(props.column_header.value).substring(0, 12) + '...' + t(props.column_header.value).substring(t(props.column_header.value).length - 3))}
              <div style={{position: "relative", height: 0}}>
            <div className='tx-status-tool-tip' style={{zIndex:4000}}>{t(props.column_header.value)}</div>
              </div>
              </span>
      :t(props.column_header.value)}
        {/*{props.column_header.value.includes("L3") || props.column_header.value.includes("L4") ?*/}
        {/*  <Button className={"white-buttons-round"} style={{height:"16px", width:"16px", color:"var(--wave-blue)"}}>+</Button>*/}
        {/*  : ""}*/}
            </div>
    </th>
}