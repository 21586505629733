import React, { useState } from 'react'
import {Col, Row, Container} from "reactstrap";
import FormCheck from "react-bootstrap/FormCheckInput";
import {Button} from "react-bootstrap";
import {styled} from "@mui/system";
import SliderUnstyled from "@mui/base/SliderUnstyled";
import {useTranslation} from "react-i18next";
import PractitionerCard from '../PractitionerCard';
import debounce from 'lodash.debounce';

const StyledSlider = styled(SliderUnstyled)(
    ({theme}) => `
  color: ${theme.palette.mode === 'light' ? 'var(--bs-primary)' : 'var(--background-color)'};
  height: 20px;
  width: 100%;
  padding: 15px 0px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  &:hover {
    opacity: 1;
  }

  & .MuiSlider-rail {
    display: block;
    position: absolute;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: currentColor;
    color: var(--border-color-faintest);
    opacity: 0.38;
  }

  & .MuiSlider-track {
    display: block;
    position: absolute;
    height: 20px;
    border-radius: 0px;
    background-color: currentColor;
  }

  & .MuiSlider-thumb {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    border: 7px solid currentColor;
    background-color: var(--background-color); 
    }
  }
`,
);


export default function PatientListFilters(props) {
    // const [patientPhotoError, setPhotoError] = useState(true);
    const {t} = useTranslation();
    const [pdxShowDetails, setPdxShowDetails] = useState(false);
    const [therapyShowDetails, setTherapyShowDetails] = useState(false);
    const [studyStatusShowDetails, setStudyStatusShowDetails] = useState(false);
    let dbUpdateState = debounce(props.updateState, 500)
    function checkedFilterItem(filterName, key) {
        if (filterName === "campus") {
            let campus = props.filters.campus;
            campus[key] = !props.filters.filters.campus[key];
            props.updateState({isLoaded: false, fetchData: true, filters: {...props.filters.filters, campus}})
        } else if (filterName === "clinicalStudy") {
            let clinicalStudy = props.filters.filters.clinicalStudy;
            clinicalStudy[key] = !props.filters.filters.clinicalStudy[key];
            props.updateState({isLoaded: false, fetchData: true, filters: {...props.filters.filters, clinicalStudy}})
        }

    }
    if (!props.filters.expert_urologist){
        props.filters.expert_urologist = {}
    }
    if (!props.filters.expert_radiologist){
        props.filters.expert_radiologist = {}
    }
    if (!props.filters.treating_hcp){
        props.filters.treating_hcp = {}
    }
    return <Container style={{
        background: "var(--background-color)",
        borderRadius: "10px",
        boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)",
        color: "var(--text-color)",
        justifyContent: "center",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        padding: 0,
        margin: 0
    }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                padding: "20px 20px 0px 20px"
            }}>
                <Col style={{
                    textAlign: "start",
                    fontSize: "14px",
                    lineHeight: "22px"
                }}><span><span
                    style={{fontWeight: 600}}>{t("Age")}</span> ({t("Slide to Select")})</span></Col>
                <Col style={{
                    textAlign: "end",
                    alignSelf: "flex-end",
                    justifySelf: "end",
                    paddingRight: 0,
                    marginRight: 0
                }}><span
                    style={{fontWeight: 400}}>{props.filters.ageFilter[0]}-{props.filters.ageFilter[1] >= 100 ? "100+" : props.filters.ageFilter[1]}</span></Col>
            </div>
            <div style={{
                borderBottom: "1px solid var(--border-color-faintest)",
                padding: "0 30px 20px 30px"
            }}>
                <StyledSlider value={props.filters.ageFilter}
                              onChange={(event, values, activeThumb) => dbUpdateState({
                                  isLoaded: false,
                                  fetchData: true,
                                  filters: {...props.filters, ageFilter: values}
                              })}/>
            </div>
            <div style={{
                borderBottom: "1px solid var(--border-color-faintest)",
                width: "100%",
                display: "None",
                flexDirection: "row",
                justifyItems: "center",
                padding: "15px 20px 15px 20px"
            }}>
                <Col style={{
                    textAlign: "start",
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontWeight: 600
                }}><span>{t("Gender")}</span></Col>
                <Col style={{
                    textAlign: "end",
                    alignSelf: "flex-end",
                    justifySelf: "end",
                    paddingRight: 0,
                    marginRight: 0
                }}>
                    <span onClick={() => props.updateState({
                        isLoaded: false,
                        fetchData: true,
                        filters: {
                            ...props.filters,
                            gender: {...props.filters.gender, ...(props.filters.gender.hasOwnProperty("male") ? {male: !props.filters.gender.male}: {}), ...(props.filters.gender.hasOwnProperty("Male") ? {Male: !props.filters.gender.Male}: {}), ...(props.filters.gender.hasOwnProperty("MALE") ? {MALE: !props.filters.gender.MALE}: {}), ...(props.filters.gender.hasOwnProperty("null") ? {"null": !props.filters.gender["null"]}: {})}
                        }
                    })} style={{
                        cursor: "pointer",
                        fontWeight: 400,
                        color: props.filters.gender.male || props.filters.gender.Male || props.filters.gender.MALE || props.filters.gender["null"] ? "var(--background-color)" : "var(--text-color-faint)",
                        padding: "4px 13px",
                        borderRadius: "100px",
                        border: "1px solid var(--border-color-faint)",
                        height: "30px",
                        display: props.filters.gender.hasOwnProperty("male") || props.filters.gender.hasOwnProperty("null") || props.filters.gender.hasOwnProperty("Male") || props.filters.gender.hasOwnProperty("MALE") ? "": "none",
                        background:  props.filters.gender.male || props.filters.gender.Male || props.filters.gender.MALE || props.filters.gender["null"] ? "var(--bs-primary-dark)" : "var(--background-color)",
                        marginRight: "5px"
                    }}>
                      {t("Male")}
                    </span>
                    <span onClick={() => props.updateState({
                        isLoaded: false,
                        fetchData: true,
                        filters: {
                            ...props.filters,
                            gender: {
                                ...props.filters.gender,
                                female: !props.filters.gender.female
                            }
                        }
                    })} style={{
                        cursor: "pointer",
                        fontWeight: 400,
                        color: props.filters.gender.female ? "var(--background-color)" : "var(--text-color-faint)",
                        padding: "4px 13px",
                        borderRadius: "100px",
                        border: "1px solid var(--border-color-faint)",
                        height: "30px",
                        display: props.filters.gender.hasOwnProperty("female") || props.filters.gender.hasOwnProperty("Female") || props.filters.gender.hasOwnProperty("FEMALE") ? "": "none",
                        background: props.filters.gender.female ? "var(--bs-primary-dark)" : "var(--background-color)"
                    }}>
                      {t("Female")}
                    </span>
                </Col>
            </div>
            { !props.columnList?.tx_status.disabled ? <div style={{
                borderBottom: '1px solid var(--border-color-faintest)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '15px 20px 15px 20px'
            }}>
                <Row>
                    <Col style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('TX Status')}</span></Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    {props.filters.tx_status ? Object.values(props.filters.tx_status).map((as_status, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                        <span onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            filters: {
                                ...props.filters,
                                tx_status: {
                                    ...props.filters.tx_status,
                                    [as_status[1].status]: [!as_status[0], as_status[1]]
                                }
                            }
                        })} style={{
                            cursor: 'pointer',
                            fontWeight: 400,
                            color: as_status[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                            padding: '4px 13px',
                            borderRadius: '100px',
                            border: '1px solid var(--border-color-faint)',
                            height: '30px',
                            background: as_status[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                            marginRight: '5px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}>
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                color: 'var(--background-color)',
                                border: `2px solid ${as_status[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                alignSelf: 'center',
                                marginRight: '5px'
                            }}>
                                  {as_status[0] ? '✓' : ''}
                              </div>
                            {t(as_status[1].title)}
                        </span>
                      </Col>
                    )) : ''}
                </Row>
            </div> : ""}
            { !props.columnList?.as_decision_status.disabled ? <div style={{
                borderBottom: '1px solid var(--border-color-faintest)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '15px 20px 15px 20px'
            }}>
                <Row>
                    <Col style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('AS Decision Status')}</span></Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>

                    {props.filters.as_decision_status ? Object.values(props.filters.as_decision_status).map((as_status, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                        <span onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            filters: {
                                ...props.filters,
                                as_decision_status: {
                                    ...props.filters.as_decision_status,
                                    [as_status[1].status]: [!as_status[0], as_status[1]]
                                }
                            }
                        })} style={{
                            cursor: 'pointer',
                            fontWeight: 400,
                            color: as_status[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                            padding: '4px 13px',
                            borderRadius: '100px',
                            border: '1px solid var(--border-color-faint)',
                            height: '30px',
                            background: as_status[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                            marginRight: '5px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}>
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                color: 'var(--background-color)',
                                border: `2px solid ${as_status[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                alignSelf: 'center',
                                marginRight: '5px'
                            }}>
                                  {as_status[0] ? '✓' : ''}
                              </div>
                            {t(as_status[1].title)}
                        </span>
                      </Col>
                    )) : ''}
                </Row>
            </div>: ""}

            { !props.columnList?.pdx.disabled ? <div style={{
                borderBottom: '1px solid var(--border-color-faintest)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '15px 20px 15px 20px'
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('PDX')}</span></Col>
                    <Col xs={1} style={{
                        textAlign: "end",
                        alignSelf: "flex-end",
                        justifySelf: "end",
                        marginLeft:"10px",
                        paddingRight: 0,
                        marginRight: 0
                    }}>
                        <svg onClick={() => setPdxShowDetails(!pdxShowDetails)} style={{
                            transform: pdxShowDetails ? "rotate(180deg)" : "",
                            cursor: "pointer"
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{ marginTop: pdxShowDetails ? '20px': "" }}>
                    {props.filters.pdx && pdxShowDetails ? Object.values(props.filters.pdx).map((as_status, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                        <span onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            filters: {
                                ...props.filters,
                                pdx: {
                                    ...props.filters.pdx,
                                    [as_status[1]]: [!as_status[0], as_status[1]]
                                }
                            }
                        })} style={{
                            cursor: 'pointer',
                            fontWeight: 400,
                            color: as_status[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                            padding: '4px 13px',
                            borderRadius: '100px',
                            border: '1px solid var(--border-color-faint)',
                            height: '30px',
                            background: as_status[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                            marginRight: '5px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}>
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                color: 'var(--background-color)',
                                border: `2px solid ${as_status[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                alignSelf: 'center',
                                marginRight: '5px'
                            }}>
                                  {as_status[0] ? '✓' : ''}
                              </div>
                            {t(as_status[1])}
                        </span>
                      </Col>
                    )) : ''}
                </Row>
            </div>: ""}

            { !props.columnList?.therapy.disabled ? <div style={{
                borderBottom: '1px solid var(--border-color-faintest)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '15px 20px 15px 20px'
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('Therapy')}</span></Col>
                    <Col xs={1} style={{
                        textAlign: "end",
                        alignSelf: "flex-end",
                        justifySelf: "end",
                        marginLeft:"10px",
                        paddingRight: 0,
                        marginRight: 0
                    }}>
                        <svg onClick={() => setTherapyShowDetails(!therapyShowDetails)} style={{
                            transform: therapyShowDetails ? "rotate(180deg)" : "",
                            cursor: "pointer"
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{ marginTop: therapyShowDetails ? '20px' : "" }}>

                    {therapyShowDetails && props.filters.therapy ? Object.values(props.filters.therapy).map((as_status, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                        <span onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            filters: {
                                ...props.filters,
                                therapy: {
                                    ...props.filters.therapy,
                                    [as_status[1]]: [!as_status[0], as_status[1]]
                                }
                            }
                        })} style={{
                            cursor: 'pointer',
                            fontWeight: 400,
                            color: as_status[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                            padding: '4px 13px',
                            borderRadius: '100px',
                            border: '1px solid var(--border-color-faint)',
                            height: '30px',
                            background: as_status[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                            marginRight: '5px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}>
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                color: 'var(--background-color)',
                                border: `2px solid ${as_status[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                alignSelf: 'center',
                                marginRight: '5px'
                            }}>
                                  {as_status[0] ? '✓' : ''}
                              </div>
                            {t(as_status[1])}
                        </span>
                      </Col>
                    )) : ''}
                </Row>
            </div>: ""}

            { !props.columnList?.study_status.disabled ? <div style={{
                borderBottom: '1px solid var(--border-color-faintest)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '15px 20px 15px 20px'
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('Study Status')}</span></Col>
                    <Col xs={1} style={{
                        textAlign: "end",
                        alignSelf: "flex-end",
                        justifySelf: "end",
                        marginLeft:"10px",
                        paddingRight: 0,
                        marginRight: 0
                    }}>
                        <svg onClick={() => setStudyStatusShowDetails(!studyStatusShowDetails)} style={{
                            transform: studyStatusShowDetails ? "rotate(180deg)" : "",
                            cursor: "pointer"
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{ marginTop: studyStatusShowDetails ? '20px': "" }}>

                    {studyStatusShowDetails && props.filters.study_status ? Object.values(props.filters.study_status).map((as_status, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                        <span onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            filters: {
                                ...props.filters,
                                study_status: {
                                    ...props.filters.study_status,
                                    [as_status[1]]: [!as_status[0], as_status[1]]
                                }
                            }
                        })} style={{
                            cursor: 'pointer',
                            fontWeight: 400,
                            color: as_status[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                            padding: '4px 13px',
                            borderRadius: '100px',
                            border: '1px solid var(--border-color-faint)',
                            height: '30px',
                            background: as_status[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                            marginRight: '5px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}>
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                color: 'var(--background-color)',
                                border: `2px solid ${as_status[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                alignSelf: 'center',
                                marginRight: '5px'
                            }}>
                                  {as_status[0] ? '✓' : ''}
                              </div>
                            {t(as_status[1])}
                        </span>
                      </Col>
                    )) : ''}
                </Row>
            </div>: ""}

            { !props.columnList?.expert_urologist.disabled ? <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '20px 20px 20px 20px'
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('Expert Urologist')}</span></Col>
                    <Col xs={2} style={{
                        textAlign: 'end',
                        alignSelf: 'flex-end',
                        justifySelf: 'end',
                        paddingRight: '15px'
                    }}>
                        <svg onClick={() => props.updateState({
                            expertUrologistShowDetails: !props.filters.expertUrologistShowDetails
                        })} style={{
                            transform: props.filters.expertUrologistShowDetails ? 'rotate(180deg)' : '',
                            cursor: 'pointer'
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', display: props.filters.expertUrologistShowDetails ? '' : 'none' }}>
                    {props.filters.expert_urologist ? Object.values(props.filters.expert_urologist).map((urologist, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                          <div onClick={() => {
                              props.updateState({
                                  isLoaded: false,
                                  fetchData: true,
                                  filters: {
                                      ...props.filters,
                                      expert_urologist: {
                                          ...props.filters.expert_urologist,
                                          [urologist[1].uuid]: [`${urologist[0]}` === `false`, urologist[1]]
                                      }
                                  }
                              })
                          }}
                               style={{
                                   cursor: 'pointer',
                                   fontWeight: 400,
                                   color: urologist[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                                   padding: '4px 13px',
                                   borderRadius: '100px',
                                   border: '1px solid var(--border-color-faint)',
                                   minHeight: '30px',
                                   background: urologist[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                                   wordBreak: 'keep-all',
                                   whiteSpace: 'nowrap',
                                   display: 'flex',
                                   flexDirection: 'row',
                                   alignContent: 'center'
                               }}>
                              <div style={{
                                  height: '16px',
                                  width: '16px',
                                  borderRadius: '8px',
                                  fontWeight: '600',
                                  fontSize: '10px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  color: 'var(--background-color)',
                                  border: `2px solid ${urologist[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                  alignSelf: 'center',
                                  marginRight: '5px'
                              }}>
                                  {urologist[0] ? '✓' : ''}
                              </div>
                              <div className="person-card-container" style={{ padding: '5px' }}><span
                                style={{ borderBottom: '1px solid var(--dark-gray' }}>
                              {urologist[1].last_name}{urologist[1].first_name ? `, ${urologist[1].first_name[0]}` : ''}
                            </span>
                                  <div style={{
                                      position: 'relative',
                                      height: 0,
                                      background: 'transparent',
                                      left: '-100px'
                                  }}>
                                      <div className="person-card-container-tool-tip"
                                           style={{ background: 'transparent', border: 0, boxShadow: 0 }}>
                                          <PractitionerCard person={urologist[1]}/></div>
                                  </div>
                              </div>
                          </div>
                      </Col>
                    )) : ''}
                </Row>
            </div> : ""}

            { !props.columnList?.expert_radiologist.disabled ? <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                padding: '20px 20px 20px 20px'
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 600
                    }}><span>{t('Expert Radiologist')}</span></Col>
                    <Col xs={2} style={{
                        textAlign: 'end',
                        alignSelf: 'flex-end',
                        justifySelf: 'end',
                        paddingRight: '15px'
                    }}>
                        <svg onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            expertRadiologistShowDetails: !props.filters.expertRadiologistShowDetails
                        })} style={{
                            transform: props.filters.expertRadiologistShowDetails ? 'rotate(180deg)' : '',
                            cursor: 'pointer'
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', display: props.filters.expertRadiologistShowDetails ? '' : 'none' }}>
                    {props.filters.expert_radiologist ? Object.values(props.filters.expert_radiologist).map((radiologist, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: 'start',
                          alignSelf: 'flex-start',
                          justifySelf: 'start',
                          padding: '5px'
                      }}>
                          <div onClick={() => {
                              props.updateState({
                                  isLoaded: false,
                                  fetchData: true,
                                  filters: {
                                      ...props.filters,
                                      expert_radiologist: {
                                          ...props.filters.expert_radiologist,
                                          [radiologist[1].uuid]: [`${radiologist[0]}` === `false`, radiologist[1]]
                                      }
                                  }
                              })
                          }} style={{
                              cursor: 'pointer',
                              fontWeight: 400,
                              color: radiologist[0] ? 'var(--background-color)' : 'var(--text-color-faint)',
                              padding: '4px 13px',
                              borderRadius: '100px',
                              border: '1px solid var(--border-color-faint)',
                              minHeight: '30px',
                              background: radiologist[0] ? 'var(--bs-primary-dark)' : 'var(--background-color)',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                              display: 'flex',
                              flexDirection: 'row',
                              alignContent: 'center'
                          }}>
                              <div style={{
                                  height: '16px',
                                  width: '16px',
                                  borderRadius: '8px',
                                  fontWeight: '600',
                                  fontSize: '10px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  color: 'var(--background-color)',
                                  border: `2px solid ${radiologist[0] ? 'var(--background-color)' : 'var(--bs-primary-dark)'}`,
                                  alignSelf: 'center',
                                  marginRight: '5px'
                              }}>
                                  {radiologist[0] ? '✓' : ''}
                              </div>
                              <div className="person-card-container" style={{ padding: '5px' }}><span
                                style={{ borderBottom: '1px solid var(--dark-gray' }}>
                              {radiologist[1].last_name}{radiologist[1].first_name ? `, ${radiologist[1].first_name[0]}` : ''}
                            </span>
                                  <div style={{
                                      position: 'relative',
                                      height: 0,
                                      background: 'transparent',
                                      left: '-100px'
                                  }}>
                                      <div className="person-card-container-tool-tip"
                                           style={{ background: 'transparent', border: 0, boxShadow: 0 }}>
                                          <PractitionerCard person={radiologist[1]}/></div>
                                  </div>
                              </div>
                          </div>
                      </Col>
                    )) : ''}
                </Row>
            </div>: ""}

            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                padding: "20px 20px 20px 20px"
            }}>
                <Row>
                    <Col xs={10} style={{
                        textAlign: "start",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 600
                    }}><span>{t("Treating HCP")}</span></Col>
                    <Col xs={2} style={{
                        textAlign: "end",
                        alignSelf: "flex-end",
                        justifySelf: "end",
                        paddingRight: "15px"
                    }}>
                        <svg onClick={() => props.updateState({
                            isLoaded: false,
                            fetchData: true,
                            treatingHcpShowDetails: !props.filters.treatingHcpShowDetails
                        })} style={{
                            transform: props.filters.treatingHcpShowDetails ? "rotate(180deg)" : "",
                            cursor: "pointer"
                        }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                                  fill="var(--border-color-fainter)"/>
                        </svg>
                    </Col>
                </Row>
                <Row style={{marginTop:"20px", display: props.filters.treatingHcpShowDetails ? "": "none"}}>
                    {props.filters.treating_hcp ? Object.values(props.filters.treating_hcp).map((thcp, as_i) => (
                      <Col key={as_i} style={{
                          textAlign: "start",
                          alignSelf: "flex-start",
                          justifySelf: "start",
                          padding: "5px"
                      }}>
                        <div onClick={() => {
                            props.updateState({
                                isLoaded: false,
                                fetchData: true,
                                filters: {
                                    ...props.filters,
                                    treating_hcp: {
                                        ...props.filters.treating_hcp,
                                        [thcp[1].uuid]: [`${thcp[0]}` === `false`, thcp[1]]
                                    }
                                }
                            })
                        }} style={{
                            cursor: "pointer",
                            fontWeight: 400,
                            color: thcp[0] ? "var(--background-color)" : "var(--text-color-faint)",
                            padding: "4px 13px",
                            borderRadius: "100px",
                            border: "1px solid var(--border-color-faint)",
                            minHeight: "30px",
                            background: thcp[0] ? "var(--bs-primary-dark)" : "var(--background-color)",
                            wordBreak: "keep-all",
                            whiteSpace: "nowrap",
                            display:"flex",
                            flexDirection: "row",
                            alignContent:"center"
                        }}>
                            <div style={{height:"16px", width:"16px", borderRadius:"8px", fontWeight:"600", fontSize:"10px", display:"flex", justifyContent:"center", alignContent:"center", textAlign:"center", color: "var(--background-color)", border: `2px solid ${thcp[0] ? "var(--background-color)": "var(--bs-primary-dark)"}`, alignSelf:"center", marginRight:"5px"}} >
                                {thcp[0] ? "✓": ""}
                            </div>
                            <div className='person-card-container' style={{padding:"5px"}}><span style={{borderBottom:"1px solid var(--dark-gray"}}>
                              {thcp[1].last_name}{thcp[1].first_name ? `, ${thcp[1].first_name[0]}`: ""}
                            </span>
                                <div style={{position: "relative", height: 0, background:"transparent", left:"-100px"}}>
                                    <div className='person-card-container-tool-tip' style={{background:"transparent", border:0, boxShadow:0}}><PractitionerCard person={thcp[1]}/></div>
                                </div>
                            </div>
                        </div>
                      </Col>
                    )): "" }
                </Row>
            </div>

            <div style={{
                borderTop: "1px solid var(--border-color-faintest)",
                width: "100%",
                display: "None",
                flexDirection: "row",
                justifyItems: "center",
                padding: "15px 20px 20px 20px"
            }}>
                <Col xs={10} style={{
                    textAlign: "start",
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontWeight: 600
                }}><span>{t("Last Active")}</span></Col>
                <Col xs={2} style={{
                    textAlign: "end",
                    alignSelf: "flex-end",
                    justifySelf: "end",
                    paddingRight: 0,
                    marginRight: 0
                }}>
                    <svg onClick={() => props.updateState({
                        lastActiveShowDetails: !props.filters.lastActiveShowDetails
                    })} style={{
                        transform: props.filters.lastActiveShowDetails ? "rotate(180deg)" : "",
                        cursor: "pointer"
                    }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.46466 4.23011C1.46466 3.97411 1.56266 3.71811 1.75766 3.52311C2.14766 3.13211 2.78066 3.13211 3.17166 3.52311L5.99966 6.35111L8.82866 3.52311C9.21866 3.13211 9.85266 3.13211 10.2427 3.52311C10.6327 3.91411 10.6327 4.54611 10.2427 4.93711L6.70666 8.47211C6.31666 8.86311 5.68366 8.86311 5.29266 8.47211L1.75766 4.93711C1.56266 4.74211 1.46466 4.48611 1.46466 4.23011Z"
                              fill="var(--border-color-fainter)"/>
                    </svg>
                </Col>
            </div>
            <div style={{
                width: "100%",
                textAlign: "start",
                paddingBottom: "15px",
                display: props.filters.lastActiveShowDetails ? "block" : "none"
            }}>
                {Object.keys(props.filters.clinicalStudy).map((key, i) =>
                    <div className="filter-list-container" key={i} style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                        alignContent: "center",
                        margin: "5px 15px 10px 20px"
                    }}>
                        <FormCheck readOnly className="list-checkbox-filter"
                                   checked={props.filters.clinicalStudy[key]}
                                   onClick={() => checkedFilterItem("clinicalStudy", key)}/>
                        <span onClick={() => checkedFilterItem("clinicalStudy", key)}
                              style={{
                                  textAlign: "start",
                                  alignSelf: "center",
                                  justifyItems: "center",
                                  cursor: "default"
                              }}>{key}</span>
                    </div>
                )}
            </div>
            <div style={{
                borderTop: "1px solid var(--border-color-faintest)",
                width: "100%",
                paddingBottom: "0px",
                display: "flex",
                textAlign: "center",
                flexDirection: "col",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Button onClick={() => props.resetFilter()} className="reset-button"
                        variant="light" style={{
                    alignSelf: "center",
                    justifySelf: 'center',
                    margin: "20px",
                    width: "100%",
                    borderRadius: "100px",
                    boxSizing: "border-box",
                    border: "1px solid var(--border-color-faint)"
                }}>{t("Reset")}</Button>
            </div>
        </div>

    </Container>
}