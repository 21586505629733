import React, { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as helpers from "../helpers/helpers";
import PageNotFound from "./PageNotFound";

const pageNotFoundRef = "55655c70e2ee";

class PatientProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HarComponent: "Loading..."
        }
    }

    componentDidMount() {
        let HarComponent = PageNotFound; // TODO TTI default
        if (Boolean(this.props.auth)) {
            let components = helpers.getComponentsFromJwt(this.props.auth.token);
            if (Boolean(components.patient_profile)) {
                HarComponent = React.lazy(() =>
                    import(`../components/PatientProfile/${components.patient_profile}.js`)
                );
            }
        }
        this.setState({HarComponent: HarComponent});
    }

    render = () => {
        const HarComponent = this.state.HarComponent;
        return <Suspense fallback={"Loading..."}><HarComponent referenceCode={pageNotFoundRef} /></Suspense>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(PatientProfile)));