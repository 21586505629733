import Button from "@restart/ui/esm/Button";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import './AddPatientForm.css';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function InterveneForm(){
    const {t} = useTranslation();
    const [availableInterventions, setAvailableInterventions] = useState(
        [
            {
                title: "One Sheeter", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false, defaultMessage: "Test 2"}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 
                ],
            },
            {
                title: "Tutorial", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Message", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Survey", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Appointment", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Reminder", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Onboarding", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Treatment", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Care Team", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
            {
                title: "Bayer Information", 
                subList: [
                    {name: "Pain in extremities", selection: false, defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}, 
                    {name: "Sexual Dysfunction", selection: false}, 
                    {name: "Neutropenia", selection: false}, 
                    {name: "Diagnostic Information", selection: false}, 
                    {name: "Change in Liver Function", selection: false}, 
                    {name: "Treatment Information", selection: false}, 
                    {name: "Urinary Problems", selection: false}, 
                    {name: "Treatment Glossary", selection: false}, 

                ]
            },
        ]
    );
    const [intervenePartOne, updateIntervenePartOne] = useState(null);
    const [intervenePartTwo, updateIntervenePartTwo] = useState(null);
    const [intervenePartThree, updateIntervenePartThree] = useState(null);
    const [searching, updateSearching] = useState(false);
    const [searchText, updateSearchText] = useState("");
    const history = useHistory();

    return <Container style={{background:"transparent", width:"792px", paddingTop:"40px", color:"var(--text-color)"}}>
    <Row style={{marginBottom:"30px", alignItems:"center"}}>
        <Col xs={1}>
        <Button onClick={() => history.goBack()} className="white-buttons" variant="light" style={{width:"44px", height:"44px", padding:"8px", textAlign:"center", border: "0", marginLeft:"0"}}>
                <svg style={{transform:"rotate(90deg)", cursor:"pointer"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--border-color)"/>
                </svg>
            </Button> 
        </Col>
        <Col xs={2} style={{fontWeight:600, lineHeight:"26px", fontSize:"18px"}}>{t("intervene")}</Col>
    </Row>
    <Container style={{fontWeight:400, lineHeight:"22px", fontSize:"14px", padding:"50px", background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px"}}>
        <div style={{position:"relative", right:"-390px", height:0}}>
            <div style={{width:"270px"}}>
                <div style={{display:"flex", justifyItems:"end", alignItems:"end", alignContent:"end", borderRadius: "10px", background:"var(--lightest-gray)", border:0}}>
                    <div style={{marginLeft:"15px", alignSelf:"center", top:"-1px"}} alt="Filter">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.25" cy="6.25" r="5.25" stroke="var(--border-color-faint)" strokeWidth="2"/>
                            <path d="M10 10L13.5 13.5" stroke="var(--border-color-faint)" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <input style={{marginLeft:"10px", background:"var(--lightest-gray)", border:0, outline:0, textDecoration: "none", alignSelf:"center"}}
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value)} />
                    <span onClick={() => {updateSearchText(""); updateSearching(false);}} 
                        style={{position:"relative", cursor:"pointer", right:0, padding:"11px 24px 11px 0", fontSize:"14px", lineHeight:"22px", fontWeight:400, color:"--dark-gray", marginLeft:"10px", marginRight:"15px"}}>{t("Cancel")}</span>
                </div>
                {/* <input value={"Search Interventions"} style={{border:0, outline:0, textDecoration:"none", background:"var(--lightest-gray)", borderRadius:"10px"}} /> */}
            </div>
        </div>
        <Row style={{marginBottom:"10px", fontSize:"22px", lineHeight:"30px", fontWeight:600}}>{t("select_intervention")}</Row>
        <Row style={{marginBottom:"20px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Row>
        <div style={{marginLeft:"-15px", marginBottom: "20px", display:"flex", flexDirection:"row", justifyContent:"start", alignContent:"start", alignItems:"start", flexWrap:"wrap"}}>
        {availableInterventions.map((grp, i) => (
            <span onClick={() => {updateIntervenePartOne(availableInterventions[i]); updateIntervenePartTwo(null); updateIntervenePartThree(null)}} key={i} style={{minWidth:"max-content", borderRadius:"100px", paddingTop:"7px", paddingBottom:"7px", margin:"5px", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", cursor:"pointer",
                                  background: (intervenePartOne && grp.title === intervenePartOne.title) ? "var(--bs-primary-dark)": "var(--background-color)", color: (intervenePartOne && grp.title === intervenePartOne.title) ? "var(--background-color)": "var(--text-color-faint)", fontWeight: (intervenePartOne && grp.title === intervenePartOne.title) ? 600: 400}}>
                <span style={{padding:"20px"}}>{grp.title}</span>
            </span>
        ))}
        </div>
        <Row style={{marginLeft:"-15px"}}>
        {intervenePartOne ? intervenePartOne.subList.map((item, i) => (
            <Col onClick={() => {updateIntervenePartTwo(item); updateIntervenePartThree(item.defaultMessage);}} xs={6} key={i} style={{paddingTop:"7px", paddingBottom:"7px", boxSizing:"border-box", cursor:"pointer", color: "var(--text-color-faint)", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start"}}>
                <input readOnly checked={(intervenePartTwo) ? (item.name === intervenePartTwo.name) : false} type="radio" name="partTwoSelection" style={{height:"22px", width:"22px", marginRight:"15px", color:"var(--bs-primary)", border:"2px solid var(--border-color-fainter)"}} />
                <span>{item.name}</span>
            </Col>
        )) : ""}
        </Row>
    </Container>
    <Container style={{fontWeight:400, lineHeight:"22px", fontSize:"14px", padding:"50px 50px 50px 50px", background:"var(--background-color)", boxShadow:"0px 3px 15px 2px rgba(0, 0, 0, 0.05)", borderRadius:"10px", marginTop:"20px", color:"var(--border-color)"}}>
        <Row style={{marginBottom:"10px", fontSize:"22px", lineHeight:"30px", fontWeight:600, color: intervenePartThree ? "var(--text-color)": "var(--text-color-faintest)"}}>Confirm Intervention</Row>
        <Row style={{marginBottom:"20px", display: intervenePartThree ? "block": "none"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Row>
        
        <Row style={{display: intervenePartThree ? "flex": "none", alignItems:"center"}}>
            <Col xs={6} style={{marginLeft:0, paddingLeft:0, paddingTop:"7px", paddingBottom:"7px", boxSizing:"border-box", cursor:"pointer", color: "var(--text-color-faint)", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", fontWeight:600}}>
                {t("message")}
            </Col>
            <Col onClick={() => updateIntervenePartThree(intervenePartTwo.defaultMessage)} xs={6} style={{textAlign:"end", fontSize:"12px", cursor:"pointer"}}>{t("reset")}</Col>
        </Row>
        <Row style={{display: intervenePartThree ? "block": "none"}}>
            <Col style={{padding:"15px 20px", cursor:"pointer", color: "var(--text-color-faint)", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"start", border:"1px solid var(--border-color-fainter)", boxSizing:"border-box", borderRadius:"10px"}}>
                <textarea rows={intervenePartThree ? (parseInt(intervenePartThree.length/92)+1) : 4} type="text" value={intervenePartThree} onChange={(e) => updateIntervenePartThree(e.target.value)}
                        style={{resize:"none", width:"100%", border: 0, outline:0, textDecoration: "none", marginBottom:"30px", wordBreak:"break-word"}} />
                <Row style={{width:"100%", display:"flex", flexDirection:"row", background:"var(--lightest-gray)", minHeight:"26px", borderRadius:"2px"}}>
                    <Col xs={6} style={{display:"flex", flexDirection:"row", alignItems:"center"}}><span style={{textDecoration:"underline", marginRight:"10px"}}>{intervenePartTwo ? intervenePartTwo.name: ""}</span>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.85547 1V0.5C10.1316 0.5 10.3555 0.723858 10.3555 1H9.85547ZM5.00069 6.56189C4.80543 6.75715 4.48884 6.75715 4.29358 6.56189C4.09832 6.36663 4.09832 6.05004 4.29358 5.85478L5.00069 6.56189ZM9.35547 5.16667V1H10.3555V5.16667H9.35547ZM9.85547 1.5H5.6888V0.5H9.85547V1.5ZM10.209 1.35355L5.00069 6.56189L4.29358 5.85478L9.50192 0.646447L10.209 1.35355Z" fill="var(--border-color)"/>
                            <path d="M9.33203 7.25004V7.85421C9.33203 8.79702 9.33203 9.26842 9.03914 9.56131C8.74624 9.85421 8.27484 9.85421 7.33203 9.85421H2.9987C2.05589 9.85421 1.58449 9.85421 1.29159 9.56131C0.998699 9.26842 0.998699 8.79702 0.998699 7.85421V3.52087C0.998699 2.57806 0.998699 2.10666 1.29159 1.81377C1.58449 1.52087 2.05589 1.52087 2.9987 1.52087H3.60287" stroke="var(--border-color)" strokeLinecap="round"/>
                        </svg>
                    </Col>
                    <Col xs={6} style={{textAlign:"end", justifyContent:"end", alignItems:"center", display:"flex", flexDirection:"row", color:"var(--text-color-faint)"}}>PDF Document • 45 KB</Col>
                </Row>        
            </Col>
        </Row>
        <hr style={{background:"var(--border-color-fainter)", width:"115%", position: "relative", left:"-50px", display: intervenePartThree ? "block": "none", height: intervenePartThree ? "2px": "0px", marginTop:"50px", marginBottom:"30px", paddingTop:0, paddingBottom:0}} />
    
        <Row style={{fontWeight:600, alignItems:"center", justifyContent:"center", display: intervenePartThree ? "flex": "none"}}>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", border:"1px solid var(--border-color-faint)", background:"var(--background-color)", textAlign:"center", marginLeft:"0", color:"var(--text-color-faint)"}}>{t("Cancel")}</Button>
            </Col>
            <Col xs={3}>
                <Button variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: "var(--bs-primary)", color: "var(--background-color)"}}>{t("save")}</Button>
            </Col>
        </Row>
    </Container>
    <div style={{height: intervenePartThree ? "50px": "0px"}}></div>
    </Container>
}