import React, { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as helpers from "../helpers/helpers";
import PageNotFound from "./PageNotFound";
import { reportAction } from '../utils/axios'

const pageNotFoundRef = "0c197ce99aee";

class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HarComponent: "Loading..."
        }
        // TODO Translations
    }

    componentDidMount() {
        reportAction("VIEWED_MY_PROFILE");
        let HarComponent = PageNotFound; // TODO TTI default
        if (Boolean(this.props.auth)) {
            let components = helpers.getComponentsFromJwt(this.props.auth.token);
            if (Boolean(components.my_profile)) {
                HarComponent = React.lazy(() =>
                    import(`../components/MyProfile/${components.my_profile}.js`)
                );
            }
        }
        this.setState({HarComponent: HarComponent});
    }

    render = () => {
        const HarComponent = this.state.HarComponent;
        return <Suspense fallback={<div>Loading...</div>}><HarComponent referenceCode={pageNotFoundRef} /></Suspense>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(MyProfile)));