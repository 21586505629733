import { Col, Row, Container } from 'reactstrap'
import EsReportWaveIcon from '../../assets/icons/EsReportWaveIcon.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as helpers from '../../helpers/helpers'

const DEFAULT_BODY_COLOR = '#ACCBCE';

// Object.keys(pt).forEach(t => {
//     let p1 = "Front";
//     let p2 = "Left";
//     if (t.includes("back")){
//         p1 = "Back"
//     }
//     if (t.includes("right")){
//         p2 = "Right"
//     }
//     let temp = t.replaceAll("left", "")
//     temp = temp.replaceAll("right", "")
//     temp = temp.replaceAll("_", "")
//     temp = temp.replaceAll("back","")
//     temp = temp.replaceAll("front","")
//     let p = ""
//     if (temp)
//         p = temp[0].toUpperCase() + temp.substring(1)
//     nt[t] = p + " - " + p1 + " " + p2
// })

const matching = {
  "front_right_head": "back_right_head",
  "front_left_head": "back_left_head",
  "front_right_neck": "back_right_neck",
  "front_left_neck": "back_left_neck",
  "front_right_shoulder": "back_right_shoulder",
  "front_left_shoulder": "back_left_shoulder",
  "front_right_arm": "back_right_arm",
  "front_left_arm": "back_left_arm",
  "front_right_elbow": "back_right_elbow",
  "front_left_elbow": "back_left_elbow",
  "front_right_forearm": "back_right_forearm",
  "front_left_forearm": "back_left_forearm",
  "front_right_wrist": "back_right_wrist",
  "front_left_wrist": "back_left_wrist",
  "front_right_hand": "back_right_hand",
  "front_left_hand": "back_left_hand",
  "front_right_hip": "back_right_hip",
  "front_left_hip": "back_left_hip",
  "front_right_thigh": "back_right_thigh",
  "front_left_thigh": "back_left_thigh",
  "front_right_ankle": "back_right_ankle",
  "front_left_ankle": "back_left_ankle",
  "back_right_head": "front_right_head",
  "back_left_head": "front_left_head",
  "back_right_neck": "front_right_neck",
  "back_left_neck": "front_left_neck",
  "back_right_shoulder": "front_right_shoulder",
  "back_left_shoulder": "front_left_shoulder",
  "back_right_arm": "front_right_arm",
  "back_left_arm": "front_left_arm",
  "back_right_elbow": "front_right_elbow",
  "back_left_elbow": "front_left_elbow",
  "back_right_forearm": "front_right_forearm",
  "back_left_forearm": "front_left_forearm",
  "back_right_wrist": "front_right_wrist",
  "back_left_wrist": "front_left_wrist",
  "back_right_hand": "front_right_hand",
  "back_left_hand": "front_left_hand",
  "back_right_hip": "front_right_hip",
  "back_left_hip": "front_left_hip",
  "back_right_thigh": "front_right_thigh",
  "back_left_thigh": "front_left_thigh",
  "back_right_ankle": "front_right_ankle",
  "back_left_ankle": "front_left_ankle"
}

const PAIN_TITLES = {
  "front_right_head": "Cabeza - Lado derecho",
  "front_left_head": "Cabeza - Lado izquierdo",
  "front_right_face": "Cara - Lado derecho",
  "front_left_face": "Cara - Lado izquierdo",
  "front_right_neck": "Cuello - Lado derecho",
  "front_left_neck": "Cuello - Lado izquierdo",
  "front_right_shoulder": "Hombro derecho",
  "front_left_shoulder": "Hombro izquierdo",
  "front_right_chest": "Pecho - Lado derecho",
  "front_left_chest": "Pecho - lado izquierdo",
  "front_right_arm": "Brazo derecho",
  "front_left_arm": "Brazo izquierdo",
  "front_right_elbow": "Codo derecho",
  "front_left_elbow": "Codo izquierdo",
  "front_right_forearm": "Antebrazo derecho",
  "front_left_forearm": "Antebrazo izquierdo",
  "front_right_wrist": "Muñeca derecha",
  "front_left_wrist": "Muñeca izquierda",
  "front_right_hand": "Mano Derecha",
  "front_left_hand": "Mano izquierda",
  "front_right_abdomen": "Abdomen - Lado derecho",
  "front_left_abdomen": "Abdomen - Lado izquierdo",
  "front_right_hip": "Cadera derecha",
  "front_left_hip": "Cadera izquierda",
  "front_right_groin": "Ingle - lado derecho",
  "front_left_groin": "Ingle - lado izquierdo",
  "front_right_thigh": "Muslo derecho",
  "front_left_thigh": "Muslo izquierdo",
  "front_right_knee": "Rodilla derecha",
  "front_left_knee": "Rodilla izquierda",
  "front_right_shin": "Espinilla derecha",
  "front_left_shin": "Espinilla izquierda ",
  "front_right_ankle": "Tobillo derecho",
  "front_left_ankle": "Tobillo izquierdo",
  "front_right_foot": "Pie derecho",
  "front_left_foot": "Pie izquierdo",
  "front_left_leg": "Pierna izquierdo",
  "front_right_leg": "Pierna derecho",
  "back_right_head": "Cabeza - Lado derecho",
  "back_left_head": "Cabeza - Lado izquierdo",
  "back_of_head_right": "Cabeza - Parte posterior Derecha",
  "back_of_head_left": "Cabeza - Parte posterior Izquierda",
  "back_right_neck": "Cuello - Lado derecho",
  "back_left_neck": "Cuello - Lado izquierdo",
  "back_right_shoulder": "Hombro derecho",
  "back_left_shoulder": "Hombro izquierdo",
  "upper_back_right": "Espalda - Parte superior Derecha",
  "upper_back_left": "Espalda - Parte superior Izquierda",
  "back_right_arm": "Brazo derecho",
  "back_left_arm": "Brazo izquierdo",
  "back_right_elbow": "Codo derecho",
  "back_left_elbow": "Codo izquierdo",
  "back_right_forearm": "Antebrazo derecho",
  "back_left_forearm": "Antebrazo izquierdo",
  "back_right_wrist": "Muñeca derecha",
  "back_left_wrist": "Muñeca izquierda",
  "back_right_hand": "Mano Derecha",
  "back_left_hand": "Mano izquierda",
  "back_right": "Espalda - Lado derecho",
  "back_left": "Espalda - Lado izquierdo",
  "lower_back_right": "Espalda baja - Lado derecho",
  "lower_back_left": "Espalda baja - Lado izquierdo",
  "back_right_hip": "Cadera derecha",
  "back_left_hip": "Cadera izquierda",
  "buttock_right": "Glúteos - Lado Derecho",
  "buttock_left": "Glúteos - Lado Izquierdo",
  "back_right_thigh": "Muslo derecho",
  "back_left_thigh": "Muslo izquierdo",
  "back_of_knee_right": "Parte posterior derecha de la rodilla",
  "back_of_knee_left": "Parte posterior izquierda de la rodilla",
  "calf_right": "Pantorrilla derecha",
  "calf_left": "Pantorrilla izquierda",
  "back_right_ankle": "Tobillo derecho",
  "back_left_ankle": "Tobillo izquierdo",
  "heel_right": "Talón derecho",
  "heel_left": "Talón izquierdo"
}

const symptomToQuestionRelation = {
  "Q2M": "pain",
  "Q2C": "sad_feelings",
  "Q2D": "nausea",
  "Q2K": "skin_changes",
  "Q1C": "vomiting",
  "Q2G": "constipation",
  "Q2H": "dizziness",
  "Q2I": "headache",
  "Q2J": "chills",
  "Q2B": "fatigue",
  "Q2L": "urination_problems",
  "Q2F": "numbness_tingling_hands_feet",
  "Q1A": "diarrhea",
  "Q2E": "weakness",
  "Q1B": "sleeping_problems",
  "Q2A": "anxiety",
}

const symptomsInitial = {
  pain: { key: "pain", title: "Pain", prefix: "S", suffix: "0", level: -1 },
  sad_feelings: { key: "sad_feelings", title: "Sad Feelings or unhappy feelings", prefix: "S", suffix: "", level: -1 },
  nausea: { key: "nausea", title: "Nausea", prefix: "S", suffix: "", level: -1 },
  skin_changes: { key: "skin_changes", title: "Skin Changes", prefix: "S", suffix: "0", level: -1 },
  vomiting: { key: "vomiting", title: "Vomiting", prefix: "F", suffix: "", level: -1 },
  constipation: { key: "constipation", title: "Constipation", prefix: "S", suffix: "", level: -1 },
  dizziness: { key: "dizziness", title: "Dizziness", prefix: "S", suffix: "", level: -1 },
  headache: { key: "headache", title: "Headache", prefix: "S", suffix: "", level: -1 },
  chills: { key: "chills", title: "Chills (shivering or shaking chills)", prefix: "S", suffix: "", level: -1 },
  fatigue: { key: "fatigue", title: "Fatigue (tiredness or lack of energy)", prefix: "S", suffix: "", level: -1 },
  urination_problems: { key: "urination_problems", title: "Urination Problems", prefix: "S", suffix: "0", level: -1 },
  numbness_tingling_hands_feet: { key: "numbness_tingling_hands_feet", title: "Numbness/Tingling in the hands and feet", prefix: "S", suffix: "", level: -1 },
  diarrhea: { key: "diarrhea", title: "Diarrhea (loose or watery stools)", prefix: "F", suffix: "", level: -1 },
  weakness: { key: "weakness", title: "Weakness in the arms and legs", prefix: "S", suffix: "", level: -1 },
  sleeping_problems: { key: "sleeping_problems", title: "Sleeping Problems", prefix: "F", suffix: "", level: -1 },
  anxiety: { key: "anxiety", title: "Anxiety", prefix: "F", suffix: "", level: -1 },
};

const symptomsSample = [
  { key: "pain", title: "Pain", prefix: "S", suffix: "0", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "sad_feelings", title: "Sad Feelings or unhappy feelings", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "nausea", title: "Nausea", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "skin_changes", title: "Skin Changes", prefix: "S", suffix: "0", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "vomiting", title: "Vomiting", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "constipation", title: "Constipation", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "dizziness", title: "Dizziness", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "headache", title: "Headache", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "chills", title: "Chills (shivering or shaking chills)", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "fatigue", title: "Fatigue (tiredness or lack of energy)", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "urination_problems", title: "Urination Problems", prefix: "S", suffix: "0", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "numbness_tingling_hands_feet", title: "Numbness/Tingling in the hands and feet", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "diarrhea", title: "Diarrhea (loose or watery stools)", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "weakness", title: "Weakness in the arms and legs", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "sleeping_problems", title: "Sleeping Problems", prefix: "F", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
  { key: "anxiety", title: "Anxiety", prefix: "S", suffix: "", level: Math.random() < 0.3 ? Math.floor(Math.random() * 5) : 0 },
];

function getColorForSymptom(level){
  switch (level){
    case 0:
      return "--none-symptom";
    case 1:
      return "--mild-symptom";
    case 2:
      return "--moderate-symptom";
    case 3:
      return "--severe-symptom";
    case 4:
      return "--unbearable-symptom";
    default: return "--background-color"
  }
}

function getPainTitle(rp){
  // was asked to provide only spanish titles
  if (PAIN_TITLES[rp])
    return PAIN_TITLES[rp]
  console.debug("not found", rp)
  return rp
}

function getColorNameForPain(level){
  if (!level) return { color: DEFAULT_BODY_COLOR, "name": ""};
  switch (level){
    case 0:
      return { "color": '#CDCDCD', "name": "No Pain - 0"};
    case 1:
      return {
        "color": "#7ABE27",
        "name": "Mild - 1"
      };
    case 2:
      return {
        "color": "#95C12F",
        "name": "Mild - 2"
      }
    case 3:
      return {
        "color": "#AEC437",
        "name": "Mild - 3"
      };
    case 4:
      return {
        "color": "#CCC73F",
        "name": "Moderate - 4"
      };
    case 5:
      return {
        "color": "#E9CB48",
        "name": "Moderate - 5"
      };
    case 6:
      return {
        "color": "#F3B937",
        "name": "Moderate - 6"
      };
    case 7:
      return {
        "color": "#F9A11F",
        "name": "Severe - 7"
      };
    case 8:
      return {
        "color": "#F7800D",
        "name": "Severe - 8"
      };
    case 9:
      return {
        "color": "#E44607",
        "name": "Severe - 9"
      };
    case 10:
      return {
        "color": "#CF0901",
        "name": "Worst Pain - 10"
      };
    default:
      console.debug("NOT FOUND for", level)
      return { "color": '#CDCDCD', "name": "No Pain - 0"};
  }
}

export default function EsWaveReport (props) {
  const { t } = useTranslation()
  let reported_pain = {}
  let urination_problems = []
  let skin_changes = []
  let treatment_activity_changes = []
  let care_team_interactions = []
  let sexual_issues = ""
  if (props.testReport){
    props.patient.last_name = "ES001001"
    reported_pain = {
      "front_left_shoulder": 10,
      "back_left_shoulder": 10,
      "back_right_hip": 2
    };
    skin_changes = ["Rash", "Acne"]
    urination_problems = []
    sexual_issues = ["Libido Level (None to Low)", "Erectile Function (Very bad to Moderate)", "Satisfaction Level (Low to Moderate)"]
    treatment_activity_changes = []
    care_team_interactions = ["Recieved a message from my care team", "Unplanned visit to an emergency room or clinic"]
  }


  let symptomTitles = {
    pain: <span><b>{t("Pain")}</b></span>,
    sad_feelings: <span><b>{t("Sad Feelings")}</b> {t("or unhappy feelings")}</span>,
    nausea: <span><b>{t("Nausea")}</b></span>,
    skin_changes: <span><b>{t("Skin Changes")}</b></span>,
    vomiting: <span><b>{t("Vomiting")}</b></span>,
    constipation: <span><b>{t("Constipation")}</b></span>,
    dizziness: <span><b>{t("Dizziness")}</b></span>,
    headache: <span><b>{t("Headache")}</b></span>,
    chills: <span><b>{t("Chills")}</b> ({t("shivering or shaking chills")})</span>,
    fatigue: <span><b>{t("Fatigue")}</b> ({t("tiredness or lack of energy")})</span>,
    urination_problems: <span><b>{t("Urination Problems")}</b></span>,
    numbness_tingling_hands_feet: <span><b>{t("Numbness/Tingling")}</b> {t("in the hands and feet")}</span>,
    diarrhea: <span><b>{t("Diarrhea")}</b> ({t("loose or watery stools")})</span>,
    weakness: <span><b>{t("Weakness")}</b> {t("in the arms and legs")}</span>,
    sleeping_problems: <span><b>{t("Sleeping Problems")}</b></span>,
    anxiety: <span><b>{t("Anxiety")}</b></span>,
  }
  let symptoms = ['pain', 'sad_feelings', 'nausea', 'skin_changes', 'vomiting', 'constipation', 'dizziness', 'headache', 'chills', 'fatigue', 'urination_problems', 'numbness_tingling_hands_feet', 'diarrhea', 'weakness', 'sleeping_problems', 'anxiety']
  let selectedReportNumber = props.report && props.report.data ? (![null, undefined].includes(JSON.parse(props.report.data).week) ? JSON.parse(props.report.data).week : 0): 0;
  let symptomOptions = [
    {title:t("None/Never"), color:"--none-symptom"},
    {title:t("Mild/Rarely"), color:"--mild-symptom"},
    {title:t("Moderate/Occasionally"), color:"--moderate-symptom"},
    {title:t("Severe/Frequently"), color:"--severe-symptom"},
    {title:t("Very Severe/Almost constantly"), color:"--unbearable-symptom"},
  ]
  let dateRange = ""
  let completed_at = 0
  if (props.report && props.report.data){
    let data = props.report.data ? JSON.parse(props.report.data) : {}
    completed_at = data?.completed ? data?.completed : 0
    if (data.answers){
      let reportedPainQuestion = data.answers.find(q => q.question_id === "Q5")
      if (reportedPainQuestion){
        let reported_pain_list = reportedPainQuestion.value ? JSON.parse(reportedPainQuestion.value): {}
        if (reported_pain_list && Array.isArray(reported_pain_list))
          reported_pain_list.forEach(rp => {
            reported_pain[rp.key] = rp.value
          })
      }
    }
    let reportEndTime = props.report.due - (data.due_window && ![null, ''].includes(data.due_window) ? data.due_window : 259200)
    let dates = helpers.getSaturdayPair(reportEndTime, 6)
    dates = [helpers.getUTCFormattedDate(dates[0].getTime()/1000), helpers.getUTCFormattedDate(dates[1].getTime()/1000)]
    dateRange = helpers.getFormattedDate(dates[0], "DD MMM", t)
    if (helpers.getFormattedDate(dates[0], "YYYY") !== helpers.getFormattedDate(dates[1], "YYYY"))
      dateRange += helpers.getFormattedDate(dates[0], "YY", t)
    dateRange += " - "
    dateRange += helpers.getFormattedDate(dates[1], "DD MMM YY", t)
  }
  if (props.report && props.report.user_data){
    let user_data = props.report.user_data ? JSON.parse(props.report.user_data) : {}
    let addtional_info = user_data.additional_information ? user_data.additional_information : {}
    skin_changes = addtional_info.skin_changes ? addtional_info.skin_changes : []
    urination_problems = addtional_info.urination ? addtional_info.urination : []
    sexual_issues = addtional_info.sexual_issues ? addtional_info.sexual_issues : []
    treatment_activity_changes = addtional_info.treatment_activity_changes ? addtional_info.treatment_activity_changes : []
    care_team_interactions = addtional_info.care_team_interactions ? addtional_info.care_team_interactions : []
  }
  if (props.reports){
    props.reports.forEach(r => {
      let data = r.data ? JSON.parse(r.data): {}
      r.week = ![null, undefined].includes(data.week) ? (data.week): 0
      if (!r.symptoms){
        r.symptoms = { ...symptomsInitial }
      }
      if (data && data.answers)
        data.answers.forEach(q => {
          if (q.question_id === "Q5"){
            if (!r.reported_pain){
              r.reported_pain = {}
            }
            let reported_pain_list = {}
            try{
              reported_pain_list = q.value ? JSON.parse(q.value): {}
            }
            catch (err){
              console.error("Report With error -" + r.uid + "Json parse error", err)
            }
            if (reported_pain_list && Array.isArray(reported_pain_list))
              reported_pain_list.forEach(rp => {
                r.reported_pain[rp.key] = rp.value
              })
          }
          if (symptomToQuestionRelation[q.question_id]){
            r.symptoms[symptomToQuestionRelation[q.question_id]] = {...symptomsInitial[symptomToQuestionRelation[q.question_id]], level:parseInt(q.value)}
            if (symptomToQuestionRelation[q.question_id] === "pain"){
              r.symptoms[symptomToQuestionRelation[q.question_id]].suffix = r.reported_pain ? Object.keys(r.reported_pain).length: 0
            }
          }
        })
    })
  }
  function getReportForWeekNumber(week, symptom){
    let rep = props.reports.find(r => r.week === week)
    if (rep && rep.symptoms && rep.symptoms[symptom]){
      return `var(${getColorForSymptom(rep.symptoms[symptom].level)})`
    }
    return "var(--background-color)"
  }

  function getSymptomSuffix(symptom){
    switch(symptom){
      case "pain":
        return Object.keys(reported_pain).length;
      case "skin_changes":
        return Object.keys(skin_changes).length;
      case "urination_problems":
        return Object.keys(urination_problems).length;
      default:
        return ""
    }

  }

  // console.debug("props.reports", props.reports)
  // console.debug("props.reports[selectedReportNumber]", props.reports[selectedReportNumber-1])
  // let sortedSymptoms = []
  if (selectedReportNumber >= 0 && props.reports){
    let currentReport = props.reports.find(r => r.week === selectedReportNumber)
    // console.debug(currentReport)
    if(currentReport){
      symptoms = Object.values(currentReport.symptoms).sort((a, b) => {
        return b.level - a.level
      }).map(r => r.key)
      // let indexGlobal = 0
      // let reportIndex = selectedReportNumber
      // let symptomsToSort = [...symptoms]
      // while(indexGlobal < symptoms.length){
      //   reportIndex-=1
      //   sortedSymptoms = [...sortedSymptoms, ...symptomsToSort.slice(sortedSymptoms.length-1, indexGlobal)]
      //   symptomsToSort = symptomsToSort.slice(indexGlobal)
      //   currentReport = props.reports.find(r => r.week === reportIndex)
      //   if (!currentReport) break
      //   symptomsToSort.sort((a, b) => {
      //     return currentReport.symptoms[b].level - currentReport.symptoms[a].level
      //   })
      //   let index = indexGlobal
      //   let curLevel = -2
      //   let preLevel = -1
      //   while (curLevel !== preLevel && index < symptomsToSort.length-1){
      //     curLevel = currentReport.symptoms[symptomsToSort[index]].level
      //     preLevel = currentReport.symptoms[symptomsToSort[index+1]].level
      //     index+=1
      //   }
      //   console.debug("sortedSymptoms", sortedSymptoms)
      //   console.debug("indexGlobal", indexGlobal, currentReport.symptoms[symptomsToSort[index-1]]?.level)
      //   console.debug("index", index)
      //   indexGlobal = index
      // }
    }
    // console.debug(sortedSymptoms.length)
    // if (sortedSymptoms.length === symptoms.length){
    //   symptoms = sortedSymptoms
    // }
  }



  return <Container style={{
    borderRadius: '10px',
    padding: 0,
    margin: 0,
    width: '792px',
    maxWidth: '792px',
    background: 'var(--background-color)',
    color: 'var(--text-color)'
  }}>
    <Row style={{ padding: '15px 20px', margin: '0' }}>
      <Col style={{
        paddingLeft: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start'
      }}>
        <Row>
          <Col xs={3}>
            <img src={EsReportWaveIcon} style={{ height: '40px', width: '40px' }}/>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{
              fontWeight: 600,
              color: 'var(--text-color)',
              fontSize: '12px', lineHeight: '18px',
              paddingLeft: !Boolean(dateRange) ? "20px": ""
            }}>
              {t("Patient")} {props.patient ? ((props.patient.last_name ? (props.patient.last_name + (props.patient.first_name ? ", ": "")) : "") + (props.patient.first_name ? props.patient.first_name: "")): ""}
          </span>
            <span style={{ fontSize: '12px', lineHeight: '18px' }}>{dateRange}</span>
          </Col>
        </Row>

      </Col>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <span style={{
            fontWeight: 600,
            color: 'var(--text-color)',
            fontSize: '12px', lineHeight: '18px'
          }}>
          {t("Weekly Symptom Review")}
          </span>
        <span style={{ fontSize: '12px', lineHeight: '18px' }}>{completed_at ? (t("Submitted") + ": " + helpers.getFormattedDate(completed_at, "DD MMM YYYY, HH:mm", t)): ""}</span>
      </Col>
    </Row>
    <hr style={{ background: 'var(--border-color-faint)', height: '1px', padding:0, marginBottom:"10px", marginTop:0 }}/>
    <Row style={{ padding: '0px 20px 14px 20px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 600,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px'
        }}>
          {t("Reported Symptom Severity (S) or Frequency (F)")}
        </span>
      </Col>
    </Row>
    <Row style={{ padding: '0', margin: '0 20px', background: 'var(--background-color-faint)', alignItems: 'center' }}>
      <Col xs={6} style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <span style={{
          fontWeight: 400,
          color: 'var(--text-color)',
          fontSize: '12px', lineHeight: '22px',
          textAlign: 'end',
          paddingRight:"10px"
        }}>
          {t("Study Week No.")}
        </span>
      </Col>
      <Col xs={6}
           style={{ display: 'flex', flexDirection: 'row'}}>
        <Row>
          {Array.from(Array(13).keys()).map((key, i) => (
            <Col key={i} style={{
              fontWeight: 400,
              color: 'var(--text-color)',
              fontSize: '12px', lineHeight: '22px',
              alignContent: 'center',
              width: '28px',
              height: '28px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: "center",
              border: key === selectedReportNumber ? "1px solid black": 0
            }}>
              {String(key).padStart(2, '0')}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
    {symptoms.map((symptom, k) => {

        return <Row key={k} style={{ margin: '0px 20px', padding: '0', justifyContent:"top", display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          <Col xs={6} style={{ padding: 0, margin: 0, borderBottom: "1px solid var(--light-gray)", display:"flex", flexDirection: "row", justifyContent:"start", alignItems:"center" }}>
      <span style={{
        fontWeight: 400,
        color: 'var(--text-color)',
        fontSize: '12px', lineHeight: '20px',
        marginRight: '10px',
        paddingLeft: '3px'
      }}>
      {t(props.reports.find(r => r.week === selectedReportNumber)?.symptoms[symptom]?.prefix)}
      </span>
            <span style={{
              color: 'var(--text-color)',
              fontSize: '12px', lineHeight: '20px',
              marginRight: '5px'
            }}>
    {symptomTitles[symptom]}
      </span>
            {["urination_problems", "pain", "skin_changes"].includes(symptom) ? <span style={{fontWeight:400}}>
            (<span style={{
              fontWeight: 600,
              color: 'var(--wave-purple)',
              fontSize: '12px', lineHeight: '20px'
            }}>
        {getSymptomSuffix(symptom)}
      </span>)</span>: ""}
          </Col>
          <Col xs={6}
               style={{ display: 'flex', flexDirection: 'row', height:"100%"}}>
            <Row style={{height:"100%"}}>
              {Array.from(Array(13).keys()).map((key, i) => (
                <Col key={i} style={{
                  fontWeight: 400,
                  alignContent: 'center',
                  width: '28px',
                  minHeight: '28px',
                  height:"100%",
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: "center",
                  borderLeft: key === selectedReportNumber ? "1px solid black": 0,
                  borderRight: key === selectedReportNumber ? "1px solid black": 0,
                  borderBottom: key === selectedReportNumber ? "1px solid black": "1px solid var(--light-gray)"
                }}>
                  <div style={{ width: '20px', borderRadius: '10px', backgroundColor: getReportForWeekNumber(key, symptom), height: '20px', visibility: parseInt(key) > parseInt(selectedReportNumber) ? "hidden" : "visible" }}/>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      }
    )}
    <div style={{display:"flex", flexDirection:"row", paddingTop:"5px", paddingBottom:"5px", borderBottom: "1px solid var(--light-gray)", margin:"0 30px 5px 20px", justifyItems:"start", alignItems:"center",}}>
      {symptomOptions.map((k, i) => (
        <div key={i} style={{display:"flex", width:"max-content", flexDirection:"row", paddingRight:"10px", justifyItems:"start", alignItems:"center", fontWeight:400, fontSize:"12px", lineHeight:"20px"}}>
          <div style={{flex:2, width: '12px',maxWidth: '12px', borderRadius: '10px', backgroundColor: `var(${k.color})`, height: '12px' }}/>
          <span style={{flex:10, marginLeft:"5px", width:"100%", whiteSpace:"nowrap"}}>{k.title}</span>
        </div>
      ))}
    </div>
    <div style={{minHeight:"310px", display:"flex", flexDirection:"row", margin:"0 20px", padding:"10px 0", lineHeight:"22px"}}>
      <div style={{flex:3, display:"flex", flexDirection:"column", fontWeight:400, fontSize:"12px"}}>
        <span style={{fontSize:"18px", fontWeight:600, marginBottom:"10px"}}>{t("Additional Information")}</span>
        <span style={{fontSize:"12px", marginBottom:"10px"}}><b>{t("Pain")}</b> (<b style={{color:"#6064DE"}}>{Object.keys(reported_pain).length}</b>):
          {
            Object.keys(reported_pain).length > 0 ?
            Object.keys(reported_pain).map((rp, y) => {
              return <span style={{marginLeft:"4px"}} key={y}>
                { t(getPainTitle(rp)) }&nbsp;
                <b style={{color:getColorNameForPain(reported_pain[rp]).color}}>({t("Level")} {reported_pain[rp]})</b>
                { y !== Object.keys(reported_pain).length ? ";": "" }
              </span>
              }
            )
            : ""
          }
        </span>
        <span><b>{t("Skin Changes")}</b> (<b style={{color:"#6064DE"}}>{Object.keys(skin_changes).length}</b>): {skin_changes && skin_changes.length > 0 ? skin_changes.map(p => t(p)).join("; ") : ""}</span>
        <span><b>{t("Urination Problems")}</b> (<b style={{color:"#6064DE"}}>{Object.keys(urination_problems).length}</b>): {urination_problems && urination_problems.length > 0 ? urination_problems.map(p => t(p)).join("; ") : ""}</span>
        <span><b>{t("Sexual Issues")}</b> (<b style={{color:"#6064DE"}}>{Object.keys(sexual_issues).length}</b>): {sexual_issues && sexual_issues.length > 0 ? sexual_issues.map(p => t(p)).join("; ") : ""}</span>
        <span><b>{t("Treatment Activity Changes")}</b>: {treatment_activity_changes && treatment_activity_changes.length > 0 ? treatment_activity_changes.map(p => t(p)).join("; ") : ""}</span>
        <span><b>{t("Care Team Interactions")}</b>: {care_team_interactions && care_team_interactions.length > 0 ? care_team_interactions.map(p => t(p)).join("; ") : ""}</span>
      </div>
      <div style={{flex:2, height:"310px", display:"flex", flexDirection:"row", paddingLeft:"20px"}}>
        <svg height="300px" viewBox="0 0 255 544" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#000000" fillRule="nonzero" id="Group">
              <g transform="translate(127.700000, 272.000000) scale(-1, 1) rotate(-180.000000) translate(-127.700000, -272.000000) translate(0.700000, 0.000000)">
                <path d="M118.3,542.6 C111.8,541.2 107.6,539.1 104.7,535.8 C102.3,533.2 99.9,526.6 100.6,524.6 C101.2,523.2 109,521.4 119.6,520.4 L126.4,519.7 L126.1,531.5 C125.9,538.3 125.4,543.4 124.8,543.6 C124.3,543.7 121.3,543.3 118.3,542.6 Z" id="front_right_head" fill={getColorNameForPain(reported_pain.front_right_head).color}/>
                <path d="M127.3,531.5 L127.3,520 L131.1,520 C135.1,520 147.7,521.7 150.6,522.6 C153.9,523.6 151.8,532.3 147.1,536.9 C143.7,540.2 136.2,543 130.6,543 L127.3,543 L127.3,531.5 Z" id="front_left_head" fill={getColorNameForPain(reported_pain.front_left_head).color}/>
                <path d="M101.6,516.6 L102.3,512 L99.9,513.5 C97.8,514.9 97.5,514.9 96.3,513.5 C94.6,511.4 95.7,506.8 99.2,501 C100.8,498.3 102.7,494.2 103.4,491.8 C104.1,489.5 105.3,486.7 106.1,485.8 C108.2,483.4 117.6,480 122.3,480 L126.3,480 L126.3,485.9 C126.3,490.7 126,491.9 124.6,492.3 C122.9,492.7 122.9,492.8 124.6,492.9 C126.1,493 126.3,494.3 126.3,505.8 L126.3,518.7 L116.2,519.4 C110.6,519.7 104.9,520.3 103.5,520.7 C101,521.3 101,521.2 101.6,516.6 Z" id="front_right_face" fill={getColorNameForPain(reported_pain.front_right_face).color}/>
                <path d="M141.8,519.8 C137.4,519.3 132.3,518.7 130.6,518.4 L127.3,517.9 L127.3,505.5 C127.3,493.5 127.4,493.1 129.6,492.7 L131.8,492.3 L129.6,492.1 C127.5,492 127.3,491.5 127.3,486 L127.3,480 L130.6,480 C135.4,480 144.1,482.8 146.3,485 C147.3,486 148.5,488.6 148.9,490.7 C149.3,492.8 151.1,497.1 153.1,500.2 C155.3,503.9 156.6,507.3 156.7,509.7 C156.8,513.3 156.6,513.5 153.8,513.4 C150.9,513.3 150.8,513.4 151.1,517.1 C151.3,519.3 151.1,520.9 150.7,520.8 C150.2,520.8 146.2,520.3 141.8,519.8 Z" id="front_left_face" fill={getColorNameForPain(reported_pain.front_left_face).color}/>
                <path d="M108.1,473.6 C108,469.4 107.9,465 107.9,463.8 C107.8,462 109,460.9 113.6,458.7 C116.8,457.1 121,455.6 122.9,455.3 L126.4,454.7 L126.1,465.5 L125.8,476.2 L118.8,478 C115,479 111,480.1 110.1,480.5 C108.5,481.1 108.3,480.4 108.1,473.6 Z" id="front_right_neck" fill={getColorNameForPain(reported_pain.front_right_neck).color}/>
                <path d="M136.8,478.4 C134.1,477.6 130.8,477.2 129.6,477.5 C127.3,478 127.3,478 127.3,466.5 C127.3,453.2 127.5,453 136.1,457.5 C144.4,461.8 144.3,461.7 144.3,471.4 C144.3,481 144.7,480.6 136.8,478.4 Z" id="front_left_neck" fill={getColorNameForPain(reported_pain.front_left_neck).color}/>
                <path d="M144.8,460.6 C142.9,459.3 142.9,459.1 144.8,454.9 C151.2,440.6 168.3,420.8 182.3,411.5 C190.2,406.3 204.1,399.8 205.5,400.6 C206.8,401.4 206.4,412.6 204.7,420.8 C201.2,437.6 195.9,444 181.7,448.4 C176.8,449.9 167.1,453.6 160.1,456.6 C153.1,459.6 147.3,462 147.1,462 C146.8,462 145.8,461.4 144.8,460.6 Z" id="front_left_shoulder" fill={getColorNameForPain(reported_pain.front_left_shoulder).color}/>
                <path d="M93.1,456.5 C87.7,454 78.4,450.4 72.4,448.4 C62.4,445.2 61,444.4 57.3,440.3 C50.8,432.9 45.8,413.1 47.7,402.7 L48.4,399.1 L52.5,400.6 C67,405.7 85,420 97.1,436.1 C101.1,441.4 110.3,456.7 110.3,458.1 C110.3,458.8 105.6,461 104,461 C103.3,461 98.4,458.9 93.1,456.5 Z" id="front_right_shoulder" fill={getColorNameForPain(reported_pain.front_right_shoulder).color}/>
                <path d="M108.8,452.3 C100,436.7 86,419.7 75.4,411.7 L72,409.2 L74.1,404.1 C77.6,395.6 78.7,388 77.4,380.9 C76.6,376.1 76.7,372.5 77.8,364.6 C78.6,359 79.4,352.7 79.5,350.5 L79.8,346.5 L90.3,346.6 C97.3,346.7 101.7,347.2 103.5,348.1 C105,348.9 108.3,352.4 111,356 C114.6,360.9 117.1,363.1 121,365 L126.2,367.5 L126.3,410.1 L126.3,452.7 L120.1,454.5 C116.6,455.6 113.3,456.6 112.6,456.8 C112,457 110.2,455 108.8,452.3 Z" id="front_right_chest" fill={getColorNameForPain(reported_pain.front_right_chest).color}/>
                <path d="M134.8,455 C132.6,453.9 130,453.3 129.1,453.6 C127.4,454.1 127.3,451.6 127.3,410.7 L127.3,367.2 L130.8,366.6 C134.8,365.8 140.1,361 145,353.7 C146.8,351.1 149.5,348.5 150.9,348 C152.3,347.4 158.2,346.7 163.9,346.3 L174.3,345.7 L174.3,350.1 C174.3,352.5 175,358.9 175.8,364.3 C176.7,370.1 177.1,377.9 176.8,383.3 C176.5,389.8 176.6,391.6 177.3,389.5 C178,387.1 178.1,387.6 177.8,392 C177.4,396.3 177.8,398.8 179.8,403.5 C181.1,406.8 181.9,409.7 181.5,410 C169.6,419.6 166,422.7 162.3,427.2 C154.7,436.1 151.8,440.2 146.8,448.8 C143.8,453.9 141.4,457 140.4,456.9 C139.5,456.9 137,456 134.8,455 Z" id="front_left_chest" fill={getColorNameForPain(reported_pain.front_left_chest).color}/>
                <path d="M63.8,404.5 C60.5,402.6 55.7,400.3 53.1,399.4 L48.3,397.8 L48.3,388.6 C48.3,383.5 48,374.4 47.6,368.5 C47,358.1 47.1,357.7 48.9,358.4 C49.9,358.9 53.6,359.4 57,359.7 C62.2,360.2 63.8,359.9 66.9,358.1 C68.9,356.9 70.7,356.1 70.9,356.3 C71.1,356.5 72.7,363.4 74.4,371.6 C77.1,384.6 77.4,387.2 76.5,392.1 C75.4,397.9 71.6,408 70.4,408 C70.1,408 67.1,406.4 63.8,404.5 Z" id="front_right_arm" fill={getColorNameForPain(reported_pain.front_right_arm).color}/>
                <path d="M183.2,405.2 C179.3,394.9 178,379.2 180.3,368.5 C182.1,359.8 182.1,359.7 182.2,357.8 C182.3,356.2 182.7,356.2 187.6,358.1 C191.8,359.8 194.1,360.1 199.6,359.7 L206.4,359.1 L205.8,376.8 C205.4,386.5 205,395.4 204.8,396.5 C204.5,397.9 201.4,399.9 194.3,403.2 L184.2,407.9 L183.2,405.2 Z" id="front_left_arm" fill={getColorNameForPain(reported_pain.front_left_arm).color}/>
                <path d="M120.4,363.7 C117.2,362.1 114.8,359.7 111.9,355.3 C106.6,347.2 103.8,345.9 90.8,345.2 L80.5,344.7 L79.9,338.1 C79.6,334.5 79,325.9 78.7,319 L78.1,306.5 L82.9,304.7 C93.1,301.1 97.4,294.2 98.1,280.7 L98.6,271.8 L101.5,272.4 C103.3,272.8 107.5,275.8 112,279.9 C116.1,283.8 121,287.5 122.9,288.2 L126.4,289.4 L125.9,302.5 C125.3,317.6 125.3,314.6 125.9,342.4 C126.2,354.5 126,364.7 125.6,365.1 C125.1,365.6 122.8,364.9 120.4,363.7 Z" id="front_right_abdomen" fill={getColorNameForPain(reported_pain.front_right_abdomen).color}/>
                <path d="M128,365.3 C126.8,364.2 127.2,326.2 128.5,321.3 C129.3,317.8 129.3,316.3 128.5,314.9 C127.8,313.8 127.3,308.4 127.3,301.2 L127.3,289.4 L131,288.1 C133,287.4 137.4,284.2 140.8,281 C147.2,275.1 151.9,272 154.8,272 C156,272 156.3,273.4 156.3,279.3 C156.3,283.4 157,288.7 157.9,291.3 C159.9,297.3 166,303.5 171.4,305.1 L175.5,306.4 L174.9,318.9 C174.6,325.8 174.1,334.5 173.7,338.1 L173.1,344.7 L163.4,345.3 C150.2,346.1 148.5,346.9 142.9,354.5 C138.1,361 134.6,364.2 130.7,365.4 C129.6,365.7 128.4,365.7 128,365.3 Z" id="front_left_abdomen" fill={getColorNameForPain(reported_pain.front_left_abdomen).color}/>
                <path d="M53,358 C49.3,356.9 47.3,355.2 47.3,353.1 C47.3,352.2 46.1,349.8 44.7,347.7 C43.3,345.6 42.3,343.1 42.5,342.2 C43,339.8 51.4,337.8 58.6,338.4 C64.8,338.8 67.3,340.2 67.3,343 C67.3,344 67.9,346.6 68.7,348.8 C70.4,353.6 69.6,355.2 64.1,357.5 C60,359.1 57.3,359.3 53,358 Z" id="front_right_elbow" fill={getColorNameForPain(reported_pain.front_right_elbow).color}/>
                <path d="M191.8,358.1 C187.3,357 184.3,355.1 184.3,353.4 C184.3,350 186.7,342.4 188.2,341 C189.1,340.2 190.7,339.3 191.8,339.1 C196.4,338.3 208,338.8 210.1,339.9 L212.4,341.1 L209.9,345.5 C208.6,348 207,351.5 206.3,353.4 C205.7,355.3 204.4,357.1 203.5,357.4 C199.7,358.5 194.6,358.8 191.8,358.1 Z" id="front_left_elbow" fill={getColorNameForPain(reported_pain.front_left_elbow).color}/>
                <path d="M39.4,337.6 C38,333.3 36.5,325 35.3,315 C34.7,310.3 33.8,304.3 33.2,301.6 C31.8,295.8 32.6,295 39.3,295 C42,295 45.1,294.2 47.1,293 C48.9,291.9 50.9,291.3 51.5,291.6 C53.2,292.7 63.1,314 64.6,320 C65.4,323 66.4,328.2 66.7,331.5 L67.4,337.5 L57.8,337.2 C49.9,336.9 47.5,337.2 44.3,338.8 L40.4,340.7 L39.4,337.6 Z" id="front_right_forearm" fill={getColorNameForPain(reported_pain.front_right_forearm).color}/>
                <path d="M186.8,338 C185.7,336.2 189.4,317.5 192.2,310.9 C195.5,303 201.9,291.4 203.4,290.4 C204,290.1 205.2,290.5 206.1,291.3 C208.4,293.3 213.9,295 217.9,295 C219.8,295 221.3,295.2 221.3,295.4 C221.3,295.6 220.1,303.8 218.7,313.5 C215.9,333.4 214.4,338.7 212.2,338.1 C208.5,337.1 193.1,336.9 190.4,337.8 C188.6,338.4 187.1,338.5 186.8,338 Z" id="front_left_forearm" fill={getColorNameForPain(reported_pain.front_left_forearm).color}/>
                <path d="M125.9,318.3 C124.8,317.2 125.3,316 126.8,316 C127.6,316 128.3,316.4 128.3,316.9 C128.3,318.2 126.7,319.1 125.9,318.3 Z" id="center_unused" fill={getColorNameForPain(reported_pain.center_unused).color}/>
                <path d="M76,293.3 C73.5,275.8 72.3,264.3 72.3,256.7 C72.3,250.1 72.4,249.9 74.6,250.5 C78.4,251.7 84.8,255.7 88.2,259.2 C101.3,272.2 98.8,296.2 83.8,303.1 C81.6,304.1 79.3,305 78.7,305 C78.1,305 76.9,299.9 76,293.3 Z" id="front_right_hip" fill={getColorNameForPain(reported_pain.front_right_hip).color}/>
                <path d="M169.5,302.9 C156.2,296.3 153.2,275.3 163.6,261.9 C166.5,258.2 178.2,250 180.6,250 C182.4,250 178.5,296.8 176.2,302.7 C175.2,305.5 174.9,305.5 169.5,302.9 Z" id="front_left_hip" fill={getColorNameForPain(reported_pain.front_left_hip).color}/>
                <path d="M31.7,292.9 C29.2,289.3 24.1,277 24.6,275.6 C25.5,273.2 31.4,270 34.8,270 C38.8,270.1 40.4,271.5 44.7,278.5 C49,285.6 49.3,289.3 45.9,291.5 C42.5,293.8 33,294.7 31.7,292.9 Z" id="front_right_wrist" fill={getColorNameForPain(reported_pain.front_right_wrist).color}/>
                <path d="M210.8,292.4 C203.9,289.8 203.7,289.2 207.7,281.2 C212.2,272.3 215.4,269.6 220.8,270.3 C225.6,271 229.3,274.7 228.6,278.2 C228.3,279.4 226.7,283.4 225,287 C222,293 221.6,293.5 218.3,293.7 C216.4,293.8 213,293.2 210.8,292.4 Z" id="front_left_wrist" fill={getColorNameForPain(reported_pain.front_left_wrist).color}/>
                <path d="M121.2,285.8 C119.4,284.7 114.9,281.1 111.4,277.9 C107.9,274.6 103.6,271.6 101.9,271.2 C100.2,270.7 99,270 99.2,269.4 C99.7,268.3 112.4,257.5 117.1,254.4 C119,253.1 121.9,252 123.4,252 L126.3,252 L126.3,269.4 C126.3,279 125.9,287.1 125.5,287.4 C125,287.7 123.1,287 121.2,285.8 Z" id="front_right_groin" fill={getColorNameForPain(reported_pain.front_right_groin).color}/>
                <path d="M127.3,270.1 L127.3,252 L129.8,252 C133.4,252 139.1,255.1 144.9,260.3 C147.6,262.7 151.5,265.9 153.6,267.3 L157.3,269.8 L153.6,271.1 C148.5,272.7 143,276.3 140.4,279.6 C137.9,282.7 132.9,286.4 129.6,287.4 L127.3,288.1 L127.3,270.1 Z" id="front_left_groin" fill={getColorNameForPain(reported_pain.front_left_groin).color}/>
                <path d="M16.7,276.1 C14.6,274.5 9.7,270 5.8,266 C-1,259 -1.1,258.8 2,259.3 C3.8,259.7 7.1,261.1 9.3,262.6 C11.5,264.1 13.6,265 13.9,264.8 C14.2,264.5 13.7,257.5 12.8,249.4 C10.7,229.1 10.6,228 11.4,228 C12.1,228 13.7,232.7 16.3,241.8 C18.4,249.6 18.4,245.5 16.3,235.2 C15.2,229.7 14.3,225 14.3,224.8 C14.3,224.5 15.8,224.3 17.7,224.2 C21.4,224.1 21.1,223.7 26.7,236.4 C29.8,243.4 30.9,243.2 29.4,236 C28.8,233.2 28.3,229.9 28.3,228.7 C28.4,226.5 28.4,226.5 29.5,228.5 C32.9,234.4 41.1,260.5 41.2,265.8 L41.3,269 L35.5,269 C28.8,269 25.7,270.7 23.7,275.6 C22.9,277.5 21.9,279 21.4,279 C21,279 18.8,277.7 16.7,276.1 Z" id="front_right_hand" fill={getColorNameForPain(reported_pain.front_right_hand).color}/>
                <path d="M229.8,275.6 C227.3,270.4 224.1,268.7 217.7,269.2 C213.4,269.6 212.3,269.4 212.3,268.2 C212.3,264.5 214.7,253.2 216.1,250.6 C216.9,248.9 218.8,243.5 220.4,238.6 C224.1,226.9 225.7,224.5 224.8,232 C224.5,235 223.9,238.6 223.6,240 C222.7,244 224.5,241.5 228.8,232.7 C232.5,225.1 233,224.5 236.1,224.3 C239.9,224.1 239.9,224 237.3,235.6 C235,246 235,246 236.3,246 C236.9,246 237.3,244.9 237.3,243.6 C237.3,240.2 241.1,228.2 242.5,227.4 C243.2,226.9 243.4,228.3 242.9,232.1 C241.4,244.5 239.5,264.5 239.8,264.8 C240,265 242.5,263.8 245.5,262.1 C254.8,256.8 255.6,258.1 247.8,266.1 C240.9,273.1 233.8,279 232.2,279 C231.8,279 230.7,277.5 229.8,275.6 Z" id="front_left_hand" fill={getColorNameForPain(reported_pain.front_left_hand).color}/>
                <path d="M95.8,266.1 C94.7,262.5 85,253.9 78.5,250.9 L72,247.8 L72.7,239.5 C73,235 74.2,227 75.2,221.9 C77.4,211.4 88.4,164.6 88.7,164.2 C88.9,164.1 90.4,164.5 92.2,165.1 C96.5,166.6 109,165.7 115.4,163.4 C118.1,162.4 120.5,161.9 120.8,162.1 C121.1,162.4 121.8,176.8 122.3,194.1 C122.8,211.4 123.8,230.6 124.4,236.8 C125.5,248.7 125,251 121.4,251 C119,251 113.9,254.4 105.7,261.5 C101.6,265.1 97.9,268 97.4,268 C96.9,268 96.2,267.1 95.8,266.1 Z" id="front_right_thigh" fill={getColorNameForPain(reported_pain.front_right_thigh).color}/>
                <path d="M151.5,263.9 C141.9,256 134.2,251 131.8,251 C128.6,251 128.2,249.3 129.3,239.8 C129.8,235.2 130.7,215.8 131.3,196.5 C131.9,177.3 132.5,161.4 132.7,161.3 C132.9,161.1 135.2,162 137.9,163.2 C143.6,165.7 156.5,166.8 161.3,165.2 C165.3,163.8 165,163.1 171.4,190 C178.6,220.9 180.3,229.4 181,239.5 L181.6,248.5 L176,250.8 C170.5,253.1 164.1,258.5 159.3,264.9 L156.8,268.2 L151.5,263.9 Z" id="front_left_thigh" fill={getColorNameForPain(reported_pain.front_left_thigh).color}/>
                <path d="M92.1,163.5 C89.2,161.9 89,161.4 86.8,145.5 C85.9,139.5 85,134 84.8,133.3 C84.1,131.3 90,127.2 95.4,126.1 C101.1,124.8 112.4,126.2 114.2,128.4 C115.6,130 120.1,148.4 120.5,153.9 C120.8,158 120.5,158.6 117.5,160.6 C111.4,164.8 97.4,166.3 92.1,163.5 Z" id="front_right_knee" fill={getColorNameForPain(reported_pain.front_right_knee).color}/>
                <path d="M142.7,163.6 C140.5,162.9 137.3,161.5 135.7,160.5 L132.7,158.8 L133.5,152.1 C134.7,143.3 138.2,129.8 139.9,128.1 C141.6,126.2 155.5,125.4 161,126.9 C163.2,127.5 166,128.8 167.1,129.8 C169,131.5 169,131.6 166.9,146.3 C164.4,164.3 163.8,165 153.4,164.9 C149.8,164.9 145,164.3 142.7,163.6 Z" id="front_left_knee" fill={getColorNameForPain(reported_pain.front_left_knee).color}/>
                <path d="M84,128.3 C83.7,127.8 83,123.2 82.4,118 C81.4,110.1 81.5,107 82.8,99.5 C84.1,92.2 92,60.1 92.7,59.3 C92.8,59.2 95.8,60 99.3,61.1 C103.9,62.5 107.2,63 110.4,62.6 C114.9,62 115.1,62.1 115.8,64.7 C116.7,68.3 116.7,119.7 115.8,123.3 C115,126.4 114.4,126.5 107.5,124.8 C101.9,123.6 93.6,124.7 88,127.5 C84.5,129.2 84.6,129.2 84,128.3 Z" id="front_right_shin" fill={getColorNameForPain(reported_pain.front_right_shin).color}/>
                <path d="M164.8,127.1 C158.8,124.4 152.6,123.9 145.1,125.4 L138.5,126.7 L137.8,122.2 C136.9,116.3 136.9,74.7 137.8,67.6 L138.5,62 L143.7,62.6 C147.3,63 150.6,62.6 155,61.2 L161.1,59.3 L164.2,70.9 C171.8,99.9 172.6,104.6 171.5,115.7 C170.2,129 169.9,129.4 164.8,127.1 Z" id="front_left_shin" fill={getColorNameForPain(reported_pain.front_left_shin).color}/>
                <path d="M99,59.8 C94.6,58.3 93.3,57.4 93.7,56.2 C94,55.2 95.4,49.6 96.9,43.5 C98.4,37.5 100.1,32.1 100.7,31.7 C102.2,30.6 115.3,31.6 116.6,32.9 C117.3,33.6 117.2,36.2 116.5,40.9 C115.8,44.7 115.3,50.5 115.3,53.8 C115.3,59.1 115.1,59.8 112.8,60.8 C109.2,62.4 105.8,62.2 99,59.8 Z" id="front_right_ankle" fill={getColorNameForPain(reported_pain.front_right_ankle).color}/>
                <path d="M140.6,60.5 C138.7,59.2 138.3,57.9 138.3,53.7 C138.3,50.8 137.8,45.1 137.3,41 C136.7,36.5 136.7,33.2 137.2,32.7 C138.4,31.5 151.5,30.7 152.9,31.7 C153.5,32.1 155.4,38.1 157.1,45 L160.1,57.5 L156.2,59.3 C150.4,62 143.3,62.5 140.6,60.5 Z" id="front_left_ankle" fill={getColorNameForPain(reported_pain.front_left_ankle).color}/>
                <path d="M108.3,30.1 C104.2,29.8 100.2,29.1 99.6,28.7 C98.8,28.2 98.3,25.8 98.3,23.1 C98.3,18.1 94.2,8.3 91.7,7.3 C89.4,6.4 90,3.8 92.3,4.5 C93.4,4.9 94.3,4.7 94.3,4.1 C94.3,3.5 94.8,3 95.4,3 C95.9,3 96.3,4 96.1,5.3 C95.9,6.9 96.3,7.5 97.6,7.4 C98.5,7.3 99.3,7.7 99.3,8.3 C99.3,8.9 99.6,9 100,8.7 C100.3,8.3 99.8,7.1 98.8,6 C97.4,4.4 97.3,3.8 98.3,2.5 C100,0.5 101.3,0.6 101.3,2.8 C101.3,5 103.5,10 104.6,10 C105.7,10 105.4,8.6 103.7,6.2 C102,3.7 102.6,2 105.2,2 C106.2,2 107.2,3.2 107.8,5.2 C108.4,6.9 109.4,8.6 110.1,8.8 C111.1,9.1 111,8.5 109.8,6.6 C108.1,4.1 108.1,3.9 109.8,2 C111.7,-0.1 114.9,-0.6 117.2,0.9 C118.3,1.6 118.4,2.9 117.8,6.7 C116.7,13.1 116.6,18.3 117.6,25.3 C118.3,29.8 118.2,31 117.1,30.8 C116.4,30.7 112.4,30.4 108.3,30.1 Z" id="front_right_foot" fill={getColorNameForPain(reported_pain.front_right_foot).color}/>
                <path d="M136.1,16.5 C136.3,8.8 137,1.9 137.5,1.3 C139,-0.5 141.7,-0.3 143.6,1.8 C145.7,4.1 145.7,4.9 143.6,7.2 C142.7,8.2 142.4,9 142.9,9 C144.6,9 146.3,6.7 146.3,4.3 C146.3,2.7 146.9,2 148.3,2 C151.2,2 151.8,4.3 149.8,7.7 L148.1,10.5 L150.2,8.1 C151.4,6.8 152.3,5 152.3,3.9 C152.3,1.5 153.8,0.7 155.3,2.4 C156.2,3.6 156.1,4.5 154.7,6.7 C153.7,8.2 153.5,9 154.2,8.3 C155,7.7 156.1,7.4 156.9,7.7 C157.6,7.9 158.8,7.2 159.5,6.1 C160.7,4.2 164.3,3.1 164.3,4.6 C164.3,5 162.8,7 160.9,9.1 C157.1,13.2 155.3,18.2 155.3,24.8 C155.3,27.1 155.2,29 155.1,29.1 C154.9,29.2 150.5,29.5 145.3,29.9 L135.8,30.5 L136.1,16.5 Z" id="front_left_foot" fill={getColorNameForPain(reported_pain.front_left_foot).color}/>
              </g>
            </g>
          </g>
        </svg>
        <svg height="300px" viewBox="0 0 248 540" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M113.943 1.07607C106.718 2.73911 102.52 5.67389 100.372 10.4674C96.6614 18.8804 99.2976 21.1304 114.431 22.2065L123.024 22.7935V11.3478V-2.09808e-05L120.387 0.097805C118.825 0.195631 115.994 0.586935 113.943 1.07607Z" id="back_left_head" fill={getColorNameForPain(reported_pain.back_left_head).color}/>
          <path d="M124.976 11.25V22.7935L133.569 22.2065C148.702 21.1304 151.143 19.0761 147.531 10.4674C145.773 6.4565 144.797 5.47824 140.403 3.42389C137.572 2.15215 132.885 0.782587 130.151 0.391283L124.976 -0.293499V11.25Z" id="back_right_head" fill={getColorNameForPain(reported_pain.back_right_head).color}/>
          <path d="M99.2 27.0978C99.7858 31.0109 99.5906 31.5978 98.5165 30.5218C97.8331 29.9348 96.4661 29.3478 95.4898 29.3478C93.0488 29.3478 93.2441 32.5761 96.0756 39.1305C97.3449 42.0652 99.9811 48.2283 101.934 52.8261C106.718 63.9783 109.55 66.5218 117.165 66.5218H123.024V45.587V24.75L113.26 23.5761C97.8331 21.8152 98.5165 21.7174 99.2 27.0978Z" id="back_of_head_left" fill={getColorNameForPain(reported_pain.back_of_head_left).color}/>
          <path d="M133.08 23.6739L124.976 24.6522V45.587V66.5218H130.835C138.45 66.5218 141.282 63.9783 146.066 52.8261C148.019 48.2283 150.655 42.0652 151.924 39.1305C154.756 32.5761 154.951 29.3478 152.51 29.3478C151.534 29.3478 150.167 29.9348 149.483 30.5218C148.409 31.5978 148.214 31.0109 148.8 27.0978L149.386 22.5L145.187 22.5978C142.942 22.6957 137.474 23.1848 133.08 23.6739Z" id="back_of_head_right" fill={getColorNameForPain(reported_pain.back_of_head_right).color}/>
          <path d="M106.035 73.4674C105.546 77.2826 105.742 79.8261 106.425 80.7065C107.499 81.9783 115.603 85.7935 120.387 87.163L123.024 87.9456V77.6739V67.5H114.822H106.718L106.035 73.4674Z" id="back_left_neck" fill={getColorNameForPain(reported_pain.back_left_neck).color}/>
          <path d="M124.976 77.6739V87.9456L127.71 87.163C132.397 85.7935 140.501 81.9783 141.575 80.7065C142.258 79.8261 142.454 77.2826 141.965 73.4674L141.282 67.5H133.178H124.976V77.6739Z" id="back_right_neck" fill={getColorNameForPain(reported_pain.back_right_neck).color}/>
          <path d="M88.2646 86.8696C81.137 89.9022 72.0567 93.7174 67.9559 95.1848C56.7276 99.2935 51.9433 105.163 48.3307 119.152C46.1827 127.272 45.3039 138.522 46.7685 140.283C48.3307 142.141 59.559 144.196 63.6598 143.315C69.1276 142.141 73.8142 139.207 80.6488 132.848C92.0724 122.087 105.546 98.8044 107.109 87.0652C107.499 84.0326 107.304 83.4457 105.058 82.3696C103.594 81.6848 102.129 81.1957 101.739 81.2935C101.348 81.2935 95.2945 83.837 88.2646 86.8696Z" id="back_left_shoulder" fill={getColorNameForPain(reported_pain.back_left_shoulder).color}/>
          <path d="M142.649 82.4674C140.794 83.3478 140.501 84.1305 140.891 86.9674C142.454 98.8044 155.928 122.087 167.351 132.848C174.186 139.207 178.872 142.141 184.34 143.315C188.441 144.196 199.669 142.141 201.232 140.283C202.696 138.522 201.817 127.272 199.669 119.152C196.154 105.359 191.272 99.4891 180.435 95.3805C176.529 93.9131 167.156 90.0978 159.735 86.9674C152.315 83.7392 145.871 81.1957 145.578 81.2935C145.285 81.2935 143.918 81.7826 142.649 82.4674Z" id="back_right_shoulder" fill={getColorNameForPain(reported_pain.back_right_shoulder).color}/>
          <path d="M110.331 88.5326C106.718 97.9239 102.129 108.098 99.2 113.283C94.2205 121.696 85.1402 132.946 79.1843 137.935C76.4504 140.283 74.4 142.337 74.5953 142.63C75.7669 143.804 97.8331 146.837 109.647 147.326L123.024 148.011V118.663V89.413L117.849 87.75C111.209 85.6956 111.405 85.5978 110.331 88.5326Z" id="upper_back_left" fill={getColorNameForPain(reported_pain.upper_back_left).color}/>
          <path d="M129.663 87.8478L124.976 89.413V118.663V148.011L138.45 147.326C150.167 146.837 172.233 143.804 173.405 142.63C173.6 142.337 171.55 140.283 168.816 137.935C158.173 128.935 148.117 113.967 141.087 96.8478C138.743 91.1739 136.302 86.4783 135.521 86.3804C134.838 86.2826 132.202 86.9674 129.663 87.8478Z" id="upper_back_right" fill={getColorNameForPain(reported_pain.upper_back_right).color}/>
          <path d="M46.8661 149.967C46.8661 153.391 46.5732 162.294 46.1827 169.728L45.5968 183.228L52.0409 182.641C56.5323 182.25 60.0472 182.446 63.5622 183.522C66.2961 184.304 68.737 184.891 68.9323 184.598C70.0063 183.62 71.2756 172.957 71.2756 165.326C71.2756 156.717 69.9087 146.935 68.5417 145.663C68.1512 145.272 63.1716 144.685 57.411 144.391L46.8661 143.707V149.967Z" id="back_left_arm" fill={getColorNameForPain(reported_pain.back_left_arm).color}/>
          <path d="M70.5921 145.859C72.7402 154.565 78.0126 192.326 78.1102 198.88C78.1102 206.12 79.7701 207.196 94.7087 209.739C99.2976 210.522 107.597 211.5 113.065 211.891L123.024 212.576V180.783V148.989L107.694 148.402C93.0488 147.717 75.8646 145.761 71.8614 144.196C70.2992 143.609 70.1039 143.804 70.5921 145.859Z" id="back_left" fill={getColorNameForPain(reported_pain.back_left).color}/>
          <path d="M170.378 145.37C163.153 146.935 143.235 148.696 132.69 148.696H124.976V180.587V212.576L134.935 211.891C140.403 211.5 148.702 210.522 153.291 209.739C168.23 207.196 169.89 206.12 169.89 198.88C169.987 192.326 175.26 154.663 177.408 145.957C177.701 144.783 177.506 143.902 177.115 143.902C176.627 144 173.6 144.685 170.378 145.37Z" id="back_right" fill={getColorNameForPain(reported_pain.back_right).color}/>
          <path d="M186.195 144.489C180.044 144.88 179.458 145.076 178.482 147.522C176.236 153.391 176.627 182.152 179.068 184.598C179.263 184.891 181.704 184.304 184.438 183.522C187.953 182.446 191.468 182.25 195.959 182.641L202.403 183.228L201.817 169.728C201.427 162.294 201.134 153.391 201.134 149.967V143.804L197.033 144C194.69 144.098 189.808 144.293 186.195 144.489Z" id="back_right_arm" fill={getColorNameForPain(reported_pain.back_right_arm).color}/>
          <path d="M50.0882 184.891C47.452 185.967 41.9843 195.261 41.9843 198.783C41.9843 201.13 42.4724 201.424 46.8661 202.402C54.0913 203.967 59.0709 203.772 61.4142 201.717C62.5858 200.837 64.5386 197.413 65.9055 194.185C67.8583 189.293 68.0535 188.12 66.9795 187.043C64.4409 184.402 54.3843 183.13 50.0882 184.891Z" id="back_left_elbow" fill={getColorNameForPain(reported_pain.back_left_elbow).color}/>
          <path d="M185.609 184.891C179.849 186.261 179.458 187.533 182.094 194.185C183.461 197.413 185.414 200.837 186.586 201.717C188.929 203.772 193.909 203.967 201.134 202.402C205.528 201.424 206.016 201.13 206.016 198.783C206.016 194.967 200.548 185.967 197.619 184.891C194.787 183.815 190.296 183.815 185.609 184.891Z" id="back_right_elbow" fill={getColorNameForPain(reported_pain.back_right_elbow).color}/>
          <path d="M38.0787 204.652C37.4929 205.826 35.6378 215.511 33.8803 226.076L30.7559 245.348L35.3449 245.837C37.7858 246.13 41.6913 247.304 43.8394 248.38C45.9874 249.554 48.2331 250.435 48.8189 250.435C50.0882 250.435 58.485 233.022 61.4142 224.217C62.5858 220.891 63.7575 214.826 64.148 210.913L64.7339 203.576L60.6331 204.652C56.9228 205.63 44.8157 204.457 40.3244 202.793C39.6409 202.5 38.6646 203.38 38.0787 204.652Z" id="back_left_forearm" fill={getColorNameForPain(reported_pain.back_left_forearm).color}/>
          <path d="M200.157 204.163C194.69 205.239 191.565 205.435 188.05 204.75L183.266 203.772L183.95 210.913C184.243 214.924 185.414 220.891 186.586 224.217C189.515 233.022 197.912 250.435 199.181 250.435C199.767 250.435 202.013 249.554 204.161 248.38C206.309 247.304 210.117 246.13 212.655 245.837L217.244 245.348L214.12 226.076C210.995 207.293 209.726 202.402 208.261 202.598C207.773 202.696 204.161 203.38 200.157 204.163Z" id="back_right_forearm" fill={getColorNameForPain(reported_pain.back_right_forearm).color}/>
          <path d="M78.0126 211.5C77.3291 212.772 76.7433 216.587 76.7433 220.109L76.6457 226.37L83.4803 231.652C87.2882 234.489 92.9512 238.109 96.1732 239.674C101.836 242.511 114.334 245.543 120.192 245.543H123.024V229.891V214.239H119.411C117.361 214.239 107.792 213.163 98.126 211.793C88.4598 210.424 80.2583 209.348 79.8677 209.348C79.4772 209.348 78.5984 210.326 78.0126 211.5Z" id="lower_back_left" fill={getColorNameForPain(reported_pain.lower_back_left).color}/>
          <path d="M148.898 211.793C140.306 213.065 131.42 214.141 129.175 214.141L124.976 214.239V229.891V245.543H127.808C133.666 245.543 146.164 242.511 151.827 239.674C155.049 238.109 160.809 234.489 164.52 231.652C171.061 226.663 171.354 226.272 171.647 222.065C171.843 219.62 171.354 215.902 170.671 213.75C169.109 208.859 169.206 208.859 148.898 211.793Z" id="lower_back_right" fill={getColorNameForPain(reported_pain.lower_back_right).color}/>
          <path d="M77.622 232.043C76.0598 236.739 70.2992 281.837 70.2992 289.859C70.2992 292.206 70.7874 293.772 71.6661 294.065C73.6189 294.848 80.9417 289.663 85.726 284.087C91.4866 277.63 93.2441 271.761 93.2441 258.75C93.2441 245.348 90.9984 239.478 84.1638 234.196C79.3795 230.576 78.3055 230.185 77.622 232.043Z" id="back_left_hip" fill={getColorNameForPain(reported_pain.back_left_hip).color}/>
          <path d="M163.836 234.196C157.002 239.478 154.756 245.348 154.756 258.75C154.756 271.761 156.513 277.63 162.274 284.087C167.058 289.663 174.381 294.848 176.334 294.065C178.189 293.38 178.091 289.761 175.65 268.141C173.112 245.739 170.671 231.652 169.304 231.163C168.718 230.967 166.277 232.337 163.836 234.196Z" id="back_right_hip" fill={getColorNameForPain(reported_pain.back_right_hip).color}/>
          <path d="M94.3181 245.152C94.7087 246.717 95.0992 252.391 95.0992 257.772C95.0992 271.663 92.6583 278.707 84.652 287.217C81.4299 290.739 77.1339 294.457 75.2787 295.435L71.7638 297.196L75.5717 298.761C83.7732 302.185 91.2913 303.554 103.496 303.652C114.724 303.75 115.994 303.554 118.728 301.598L121.852 299.348L122.535 286.043C123.219 273.913 122.633 264.424 120.485 253.859C119.899 251.022 119.899 251.022 121.461 252.88C122.926 254.739 122.926 254.739 123.024 251.413V247.989L111.014 245.739C104.472 244.565 97.8331 243.293 96.3685 243C93.7323 242.413 93.6346 242.511 94.3181 245.152Z" id="buttock_left" fill={getColorNameForPain(reported_pain.buttock_left).color}/>
          <path d="M140.598 245.152C134.935 246.326 129.175 247.402 127.71 247.402C125.172 247.5 124.976 247.793 124.976 251.12C125.074 254.641 125.074 254.739 126.539 252.88C128.101 251.022 128.101 251.022 127.515 253.859C125.367 264.424 124.781 273.913 125.465 286.043L126.148 299.348L129.272 301.598C132.006 303.554 133.276 303.75 144.504 303.652C156.709 303.554 164.227 302.185 172.428 298.761L176.236 297.196L172.721 295.435C170.866 294.457 166.57 290.739 163.348 287.217C155.342 278.707 152.901 271.663 152.901 257.772C152.901 252.391 153.194 246.815 153.584 245.25C154.463 242.022 154.658 242.022 140.598 245.152Z" id="buttock_right" fill={getColorNameForPain(reported_pain.buttock_right).color}/>
          <path d="M29.389 250.533C28.4126 252.196 27.0457 255.424 26.3622 257.772C25.3858 261.293 25.3858 262.37 26.6551 264.13C28.2173 266.38 34.0756 270.978 35.3449 270.978C37.1024 270.978 41.3008 265.402 44.0346 259.826C47.452 252.685 46.7685 251.022 39.8362 248.967C32.8063 246.913 31.4394 247.109 29.389 250.533Z" id="back_left_wrist" fill={getColorNameForPain(reported_pain.back_left_wrist).color}/>
          <path d="M207.773 249.065C201.427 251.022 200.646 252.783 203.575 259.044C206.309 265.011 209.628 269.707 211.679 270.489C213.534 271.174 219.978 266.772 221.638 263.641C222.712 261.587 222.614 260.511 221.15 256.598C217.635 246.913 216.561 246.326 207.773 249.065Z" id="back_right_wrist" fill={getColorNameForPain(reported_pain.back_right_wrist).color}/>
          <path d="M12.2047 268.728C4.58897 275.283 0.19527 280.272 1.07401 281.152C1.36692 281.446 3.90551 280.565 6.737 279.196C9.66614 277.924 12.2047 276.946 12.4 277.239C12.7905 277.63 11.1307 300.326 10.1543 308.152C9.37322 314.12 10.9354 312.359 13.5716 304.239L15.7197 297.88L15.1339 303.75C14.8409 306.978 14.3528 311.283 13.9622 313.239C13.474 316.565 13.6693 316.957 15.4268 316.957C17.7701 316.957 22.0661 312.065 24.4094 306.685C25.3858 304.533 26.8504 301.891 27.8268 300.815L29.389 298.859L28.9008 300.815C27.6315 306.391 27.2409 311.478 28.1197 310.598C29.7795 309.033 36.9071 288.196 38.1764 281.25C39.5433 274.207 38.8598 272.543 34.3685 271.663C32.9039 271.37 29.2913 269.12 26.3622 266.576C23.4331 264.13 20.6992 262.174 20.4063 262.174C20.0157 262.174 16.3055 265.109 12.2047 268.728Z" id="back_left_hand" fill={getColorNameForPain(reported_pain.back_left_hand).color}/>
          <path d="M221.638 266.576C218.709 269.12 215.096 271.37 213.631 271.663C209.14 272.543 208.457 274.207 209.824 281.25C211.093 288.196 218.22 309.033 219.88 310.598C220.759 311.478 220.368 306.391 219.099 300.815L218.611 298.859L220.173 300.815C221.15 301.891 222.614 304.533 223.591 306.685C225.934 312.065 230.23 316.957 232.573 316.957C234.331 316.957 234.526 316.565 234.038 313.239C233.647 311.283 233.159 306.978 232.866 303.75L232.28 297.88L234.428 304.239C237.065 312.359 238.627 314.12 237.846 308.152C236.869 300.326 235.209 277.63 235.6 277.239C235.795 276.946 238.334 277.924 241.263 279.196C244.094 280.565 246.633 281.446 246.926 281.152C247.805 280.272 243.411 275.283 235.795 268.728C231.694 265.109 227.984 262.174 227.594 262.174C227.301 262.174 224.567 264.13 221.638 266.576Z" id="back_right_hand" fill={getColorNameForPain(reported_pain.back_right_hand).color}/>
          <path d="M71.2756 302.87C71.2756 307.076 76.1575 331.533 82.3087 358.043C85.5307 371.739 86.8976 376.043 87.874 375.848C96.4661 373.891 99.4929 373.793 107.89 375.554C112.772 376.533 116.97 377.12 117.361 376.728C117.751 376.337 118.532 363.326 119.118 347.772C119.606 332.12 120.29 315.489 120.68 310.793L121.266 302.185L117.946 303.946C115.31 305.413 112.576 305.707 102.52 305.609C90.7055 305.511 84.9449 304.435 72.5449 299.641C71.6661 299.25 71.2756 300.13 71.2756 302.87Z" id="back_left_thigh" fill={getColorNameForPain(reported_pain.back_left_thigh).color}/>
          <path d="M166.472 302.576C158.564 305.217 156.416 305.609 145.48 305.609C135.424 305.707 132.69 305.413 130.054 303.946L126.734 302.185L127.32 310.793C127.71 315.489 128.394 332.12 128.882 347.772C129.468 363.326 130.249 376.337 130.639 376.728C131.03 377.12 135.326 376.533 140.11 375.554C148.507 373.793 151.534 373.891 160.126 375.848C161.102 376.043 162.469 371.739 165.691 358.043C174.674 319.207 178.189 299.152 176.041 299.446C175.553 299.543 171.354 300.913 166.472 302.576Z" id="back_right_thigh" fill={getColorNameForPain(reported_pain.back_right_thigh).color}/>
          <path d="M90.9984 378.685C86.9953 379.859 86.5071 381.033 84.3591 396.88C82.8945 408.033 82.7969 409.989 84.0661 411.457C86.4095 414.293 91.389 415.761 98.6142 415.761C106.523 415.761 110.331 414.196 112.186 410.283C113.748 406.859 118.142 385.239 117.458 384.261C116.482 382.598 109.452 379.663 103.984 378.685C97.3449 377.413 95.3921 377.413 90.9984 378.685Z" id="back_of_knee_left" fill={getColorNameForPain(reported_pain.back_of_knee_left).color}/>
          <path d="M142.551 378.978C137.279 380.152 131.42 382.696 130.444 384.261C129.858 385.239 134.252 406.859 135.814 410.283C137.669 414.196 141.477 415.761 149.386 415.761C156.611 415.761 161.591 414.293 163.934 411.457C165.203 409.989 165.106 408.033 163.641 396.88C161.395 380.641 161.102 379.957 156.513 378.685C151.924 377.413 149.386 377.413 142.551 378.978Z" id="back_of_knee_right" fill={getColorNameForPain(reported_pain.back_of_knee_right).color}/>
          <path d="M81.7228 415.467C80.6488 416.543 80.9417 444.62 82.1134 450.196C83.285 455.87 89.8268 480.522 90.4126 481.402C90.5102 481.696 93.7323 481.207 97.3449 480.228C101.25 479.25 105.644 478.761 107.987 479.054L111.991 479.543L112.576 473.478C113.455 465.652 113.26 424.076 112.381 420.163L111.698 417.228L104.277 417.522C99.9811 417.62 93.9276 417.13 90.315 416.25C82.9921 414.587 82.6016 414.587 81.7228 415.467Z" id="calf_left" fill={getColorNameForPain(reported_pain.calf_left).color}/>
          <path d="M159.638 415.957C158.076 416.543 152.12 417.033 146.457 417.13L136.205 417.228L135.619 420.163C134.74 424.174 134.545 465.75 135.424 473.478L136.009 479.543L140.013 479.054C142.356 478.761 146.75 479.25 150.655 480.228C154.268 481.207 157.49 481.696 157.587 481.402C158.368 480.228 164.91 455.185 165.984 449.511C166.57 446.185 166.961 437.38 166.765 429.457C166.472 416.054 166.375 415.272 164.52 415.076C163.446 414.978 161.298 415.37 159.638 415.957Z" id="calf_right" fill={getColorNameForPain(reported_pain.calf_right).color}/>
          <path d="M97.9307 481.989C93.2441 483.359 92.7559 483.75 92.7559 486.391C92.7559 487.957 93.4394 492.261 94.3181 495.978L95.7827 502.728L101.25 503.413C104.277 503.804 107.89 503.707 109.257 503.217C111.698 502.337 111.795 501.946 112.088 494.022C112.381 483.652 111.209 480.913 106.425 480.62C104.57 480.522 100.665 481.109 97.9307 481.989Z" id="back_left_ankle" fill={getColorNameForPain(reported_pain.back_left_ankle).color}/>
          <path d="M137.572 481.989C136.595 483.065 136.205 486.391 136.205 492.946C136.205 502.141 136.205 502.337 138.743 503.217C140.11 503.707 143.723 503.804 146.75 503.413L152.217 502.728L153.682 495.978C154.561 492.261 155.244 487.957 155.244 486.391C155.244 483.75 154.854 483.359 149.581 481.891C142.356 479.935 139.231 479.935 137.572 481.989Z" id="back_right_ankle" fill={getColorNameForPain(reported_pain.back_right_ankle).color}/>
          <path d="M96.3685 513.391C95.5874 523.37 94.5134 527.283 90.7055 532.565C89.1433 534.717 88.1669 536.772 88.5575 537.163C89.8268 538.337 101.446 540 108.769 540H115.408L114.724 523.076C114.334 513.88 113.846 505.957 113.553 505.467C113.357 505.076 109.55 504.783 105.058 504.783H96.9543L96.3685 513.391Z" id="heel_left" fill={getColorNameForPain(reported_pain.heel_left).color}/>
          <path d="M134.447 505.467C134.154 505.957 133.666 513.88 133.276 523.076L132.592 540H139.232C146.554 540 158.173 538.337 159.443 537.163C159.833 536.772 158.857 534.717 157.294 532.565C153.487 527.283 152.413 523.37 151.632 513.391L151.046 504.783H142.942C138.45 504.783 134.643 505.076 134.447 505.467Z" id="heel_right" fill={getColorNameForPain(reported_pain.heel_right).color}/>
        </svg>
      </div>
    </div>
  </Container>
}