import './Login.css';
import React from 'react';
import * as Constants from '../constants/Constants';
import authSlice from "../store/slices/auth";
import axios from "axios";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {instanceOf} from 'prop-types';
import {Container, Row, Col} from "reactstrap";
import {withRouter} from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import {Button} from 'react-bootstrap';
import { fetcher, fetchUserSettings, reportAction } from '../utils/axios'
import debounce from 'lodash.debounce';
import {Cookies, withCookies} from 'react-cookie';
import {withTranslation} from "react-i18next";
import LogoAndBackground from "../components/WaveLogoBlueBack";
import LanguageSelector from "../components/LanguageSelector";
import { toast } from 'react-toastify'

class Login extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            isLoaded: false,    // TODO coorect the definition and keep it same everywhere
            username: "",
            password: "",
            usernameError: false,
            passwordError: false,
            showPassword: false,
            secondPartOfLogin: false,
            samlUser: false,
            isFetchingData: false,
            tfaRequested: false,
            tfaCode: "",
            tfaToken: ""
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            return;
        }
        if (this.state.username === "" || !this.checkValidEmail() || this.state.samlUser) {
            this.setState({isLoaded: true, secondPartOfLogin: false});
            return;
        }
    }

    handleLogin = () => {
        if (this.state.isFetchingData) {
            return;
        }
        if (!this.state.username || this.state.username.length < 1) {
            this.setState({error: this.props.t("username_cannot_be_empty")});
            return;
        }
        if (!this.checkValidEmail()) {
            return;
        }
        this.props.dispatch(authSlice.actions.setUsername(this.state.username));
        this.setState({isFetchingData: true});
        if (!this.state.secondPartOfLogin) {
            axios
                .post(`${process.env.REACT_APP_AUTH_API_URL}${Constants.LOGIN_API}`,
                    {username: this.state.username, callback: (process.env.REACT_APP_BASE_URL + Constants.LOGIN)})
                .then((res) => {
                    this.setState({isLoaded: true, isFetchingData: false});
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.data.code === 307) {
                            this.setState({samlUser: true, isFetchingData: false});
                            this.props.dispatch(authSlice.actions.setUserStrategySaml(true));
                            window.open(err.response.data.forward, "_self")
                            return;
                        } else {
                            this.setState({
                                isLoaded: true,
                                secondPartOfLogin: true,
                                samlUser: false,
                                isFetchingData: false
                            });
                            this.props.dispatch(authSlice.actions.setUserStrategySaml(false));
                            return;
                        }
                    }

                    this.setState({error: err.toString(), isLoaded: true, isFetchingData: false});     // todo error message
                });
        } else {
            if (!this.state.password || this.state.password.length < 1) {
                this.setState({error: this.props.t("password_empty_message"), isLoaded: true, isFetchingData: false});
                return;
            }
            axios
                .post(`${process.env.REACT_APP_AUTH_API_URL}${Constants.LOGIN_API}`,
                    {username: this.state.username, password: this.state.password, ...({code: this.state.tfaCode, token: this.state.tfaToken})})
                .then((res) => {
                    if (res.error) {
                        this.setState({error: res.error, isFetchingData: false});
                        return;
                    }
                    if (res.data.message.mfa && res.data.message.mfa.token){
                        this.setState({tfaRequested: true, isLoaded: true, isFetchingData: false, tfaToken: res.data.message.mfa.token});
                        return;
                    }
                    this.props.dispatch(
                        authSlice.actions.setAuthTokens({
                            ...res.data.message
                        })
                    );
                    setTimeout(() => reportAction("ACTION_LOG_IN"), 3000);
                    fetchUserSettings(true).then((res) => {
                        let lt = localStorage.getItem("language_selected_timestamp")
                        let lang_selected_before_login = localStorage.getItem("lng")
                        let language_updated = false
                        if (lt && lang_selected_before_login && lang_selected_before_login !== res.user.language){
                            // make api call to change language
                            language_updated = true
                            res.user.language = lang_selected_before_login
                            try{
                                if (parseInt(lt) - Date.now() < (20 * 60 * 1000)){
                                    let data = JSON.stringify({
                                        query: `mutation{
                                            user_modify(language:"${lang_selected_before_login}"){
                                                language
                                            }
                                        }`,
                                        variables: {}
                                    });
                                    fetcher(Constants.GRAPHQL_API, data)
                                      .then((response) => {
                                          if(response.errors || !response.data.user_modify){
                                              console.debug("Got Error Changing language", response.errors);
                                              return;
                                          }
                                          this.props.dispatch(authSlice.actions.setLanguage(lang_selected_before_login));
                                          this.props.i18n.changeLanguage(res.user.language);
                                          localStorage.setItem("lng", lang_selected_before_login)
                                      })
                                      .catch((err) => {
                                          console.debug("Got Error Changing language.", err);
                                      });
                                }
                            }
                            catch (err){
                                console.error("Error in getting local language selected timestamp")
                            }
                        }
                        localStorage.setItem("language_selected_timestamp", "")
                        if (res && res.user && res.user.language && !language_updated)
                            this.props.i18n.changeLanguage(res.user.language);
                        this.props.dispatch(authSlice.actions.setAccount(res));
                        this.props.history.push(this.props.auth.redirectAfterLogin);
                        this.props.dispatch(authSlice.actions.clearRedirectAfterLogin());
                        this.setState({isLoaded: true, isFetchingData: false});
                    }).catch((err) => {
                        console.debug("error while fetching user in", err);
                        this.setState({error: "Error, Please contact Admin", isLoaded: true, isFetchingData: false});
                        this.props.dispatch(authSlice.actions.setLogout());
                    })
                })
                .catch((err) => {
                    console.debug("error while logging in", err);
                    this.setState({error: err.toString(), isLoaded: true, isFetchingData: false});
                    this.props.dispatch(authSlice.actions.setLogout());
                });
        }
    };

    checkValidEmail() {
        if (this.state.username === "") {
            return false;
        }
        let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.state.username)) {
            // debounce(() => this.setState({error: this.props.t("please_enter_a_valid_email_"), usernameError: true}), 500);
            // return false;
            return true;
        } else {
            debounce(() => this.setState({error: "", usernameError: false}), 500);
            return true;
        }
    }

    updateUsername(event) {
        if (this.state.secondPartOfLogin) {
            return;
        }
        if (event.target.value === "") {
            this.setState({error: "", usernameError: false, username: ""});
        } else {
            this.setState({username: event.target.value});
        }
    }

    updatePassword(event) {
        this.setState({password: event.target.value});
    }

    render = () => {
        const t = this.props.t;
        return (
            <Container className="login-container">
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>
                <Row style={{padding: "0", margin: "0"}}>
                    <Col xs={6} style={{paddingRight: "0"}}>
                        <LogoAndBackground/>
                    </Col>
                    <Col xs={6} style={{
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>
                        <div style={{position: "absolute", right:"40px", top:"40px"}}>
                            <LanguageSelector mainSelectionBackground={"var(--lightest-gray)"} />
                        </div>
                        <div style={{
                            position: "absolute",
                            alignSelf: "center",
                            justifySelf: "center",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            color: "var(--text-color)"
                        }}>
                            <span style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                lineHeight: "30px",
                                color: "var(--text-color)",
                                alignSelf: "center"
                            }}>{t("log_in")}</span>
                            <span style={{
                                color: `var(--wave-clay)`,
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "22px",
                                marginTop: "30px",
                                minHeight: "22px",
                                maxWidth: "max(25vw, 375px)"
                            }}>{this.props.t(this.state.error)}</span>
                            <label style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22px",
                                color: "var(--text-color)",
                                alignSelf: "start",
                                marginTop: "10px"
                            }}>{t("username")}</label>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <input type="email" autoFocus
                                       className={this.state.usernameError ? "incorrect-input-field" : "input-field"}
                                       placeholder={t("enter_your_username")}
                                       value={this.state.username}
                                       onChange={this.updateUsername}
                                       onKeyPress={(e) => {
                                           if (e.key === "Enter") {
                                               this.setState({error: "", isLoaded: false});
                                               this.handleLogin();
                                           }
                                       }}
                                       onBlur={() => setTimeout(function () {
                                           this.checkValidEmail()
                                       }.bind(this), 200)}/>
                                <span onClick={() => {
                                    if (this.state.isLoaded)
                                        this.setState({
                                            username: "",
                                            usernameError: false,
                                            secondPartOfLogin: false,
                                            error: "",
                                            samlUser: false
                                        })
                                }} style={{
                                    position: "absolute",
                                    right: "20px",
                                    visibility: this.state.username.length > 0 ? "visible" : "hidden",
                                    cursor: "pointer"
                                }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                    fill={this.state.usernameError ? "var(--wave-clay)" : "var(--border-color)"}/>
                            </svg>
                        </span>
                            </div>
                            <label style={{
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22px",
                                color: "var(--text-color)",
                                alignSelf: "start",
                                marginTop: "10px",
                                display: this.state.secondPartOfLogin ? "flex" : "none",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <span style={{textAlign: "start", justifySelf: "start", flex: 1}}>{t("password")}</span>
                                {/*<span style={{*/}
                                {/*    textAlign: "end",*/}
                                {/*    justifySelf: "end",*/}
                                {/*    flex: 1,*/}
                                {/*    fontSize: "12px",*/}
                                {/*    fontWeight: "400",*/}
                                {/*    lineHeight: "16px",*/}
                                {/*}}>{t("forgot_password_")}</span>*/}
                            </label>
                            <div style={{
                                display: this.state.secondPartOfLogin ? "flex" : "none",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <input className={this.state.passwordError ? "incorrect-input-field" : "input-field"}
                                       style={{paddingRight: "80px"}}
                                       type={this.state.showPassword ? "text" : "password"}
                                       placeholder={t("enter_your_password")}
                                       onChange={this.updatePassword}
                                       onKeyPress={(e) => {
                                           if (e.key === "Enter") {
                                               this.setState({error: "", isLoaded: false});
                                               this.handleLogin();
                                           }
                                       }}
                                />
                                <span style={{
                                    position: "absolute", cursor: "pointer",
                                    right: "40px",
                                    visibility: this.state.password.length > 0 ? "visible" : "hidden",
                                    fontSize: "12px",
                                    lineHeight: "16px"
                                }}
                                      onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                            {t(this.state.showPassword ? "Hide" : "Show")}
                        </span>
                                <span onClick={() => {
                                    if (this.state.isLoaded)
                                        this.setState({password: "", passwordError: false})
                                }} style={{
                                    position: "absolute",
                                    right: "20px",
                                    visibility: this.state.password.length > 0 ? "visible" : "hidden",
                                    cursor: "pointer"
                                }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                  fill={this.state.passwordError ? "var(--wave-clay)" : "var(--border-color)"}/>
                            </svg>
                        </span>
                            </div>
                            <label style={{
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22px",
                                color: "var(--text-color)",
                                alignSelf: "start",
                                marginTop: "10px",
                                display: this.state.secondPartOfLogin && this.state.tfaRequested ? "flex" : "none",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <span style={{textAlign: "start", justifySelf: "start", flex: 1}}>{t("Code")}</span>
                            </label>
                            <div style={{
                                display: this.state.secondPartOfLogin && this.state.tfaRequested ? "flex" : "none",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <input className={"input-field"}
                                       style={{paddingRight: "80px"}}
                                       type={"text"}
                                       placeholder={t("Enter Code")}
                                       onChange={(event) => this.setState({tfaCode: event.target.value})}
                                       onKeyPress={(e) => {
                                           if (e.key === "Enter") {
                                               this.setState({error: "", isLoaded: false});
                                               this.handleLogin();
                                           }
                                       }}
                                />
                                <span onClick={() => {
                                    if (this.state.isLoaded)
                                        this.setState({tfaCode: ""})
                                }} style={{
                                    position: "absolute",
                                    right: "20px",
                                    visibility: this.state.tfaCode.length > 0 ? "visible" : "hidden",
                                    cursor: "pointer"
                                }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                  fill={"var(--border-color)"}/>
                            </svg>
                        </span>
                            </div>
                            <Button onClick={() => {
                                this.setState({error: "", isLoaded: false});
                                this.handleLogin();
                            }}
                                    style={{
                                        width: "100%",
                                        flexGrow: "1",
                                        height: "44px",
                                        marginTop: "30px",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "white",
                                        background: (this.checkValidEmail() && !this.state.secondPartOfLogin) || (this.checkValidEmail() && this.state.password.length > 0) ? "var(--bs-primary)" : "var(--border-color-faint)",
                                        borderRadius: "100px",
                                        boxShadow: "none"
                                    }} variant="light">
                                {this.state.secondPartOfLogin ? t("log_in") : t("continue")}
                            </Button>
                            <div style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "22px",
                                marginTop: "30px",
                                display: "none"
                            }}>
                                {t("new_to_wave_health_")} <Link className="plain-a"
                                                                 to={this.state.isLoaded ? Constants.CREATE_ACCOUNT : ""}
                                                                 style={{
                                                                     fontWeight: "600",
                                                                     cursor: "pointer",
                                                                     color: `var(--bs-primary)`
                                                                 }}>{t("create_account")}</Link>
                            </div>
                            <div style={{fontWeight: "400", fontSize: "14px", lineHeight: "22px", marginTop: "30px"}}>
                                <Link className="plain-a" to={this.state.isLoaded ? Constants.FORGOT_PASSWORD : ""}
                                      style={{
                                          fontWeight: "600",
                                          cursor: "pointer",
                                          color: `var(--bs-primary)`,
                                          margin: "10px"
                                      }}>{t("forgot_password")}</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withCookies(withRouter(connect(mapDispatchToProps)(Login))));