import './PatientList.scss';
import React from 'react';
import SettingsIcon from '../assets/icons/Settings.svg';
import {ReactComponent as FilterIcon} from '../assets/icons/Filter.svg';
import {ReactComponent as UserIcon} from '../assets/icons/UserIcon.svg';
import authSlice from "../store/slices/auth";
import {fetchUserSettings} from '../utils/axios';
import {ReactComponent as SearchIcon} from '../assets/icons/Search.svg';
import * as Constants from '../constants/Constants';
import {connect} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Card as ColumnSettingCard} from '../components/ColumnSettingCard';
import {Row, Col} from "reactstrap";
import {withRouter} from 'react-router-dom';
import update from 'immutability-helper';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import RiskStatusTooltip from '../components/RiskStatusTooltipBlock';
import StudyStatusTooltip from '../components/PatientList/StudyStatusTooltipBlock';
import * as helpers from '../helpers/helpers';

import {Button} from 'react-bootstrap';
import FormCheck from 'react-bootstrap/esm/FormCheckInput';
import HashLoader from "react-spinners/HashLoader";
import store from "../store";
import ReactGA from "react-ga4";
import {withTranslation} from "react-i18next";
import PatientListFilters from "../components/PatientList/PatientListFilters";
import {fetchPatients} from "../store/slices/patients";
import PractitionerCard from "../components/PractitionerCard";
import SearchPatients from "../components/PatientList/SearchPatients";
import InvitePatientCard from '../components/PatientList/InvitePatient'
import { getAsDecisionStatus, getPdxToDisplay } from '../helpers/helpers'

const column_list = {
    name: {name: "Patient List", key: "name", show: true, sortable: true, disabled: false},
    created_at: {name: "Acct. Date", key: "created_at", show: true, sortable: true, disabled: true},
    seg: {name: "SEG", key: "seg", show: false, sortable: false, disabled: true},
    birthday: {name: "DOB", key: "birthday", show: true, sortable: true, disabled: true},
    birthdate: {name: "DOB (Age)", key: "birthdate", show: true, sortable: true, disabled: true},
    age: {name: "Age", key: "age", show: true, sortable: true, disabled: true},
    pdx: {name: "PDX", key: "pdx", show: true, sortable: true, disabled: true},
    therapy: {name: "Therapy", key: "therapy", show: true, sortable: true, disabled: true},
    campus: {name: "Campus", key: "campus", show: false, sortable: true, disabled: true},
    clinical_study: {name: "Clinical Study", key: "clinical_study", show: false, sortable: true, disabled: true},
    condition: {name: "Condition", key: "condition", show: false, sortable: true, disabled: true},
    groups: {name: "Groups", key: "groups", show: false, sortable: true, disabled: true},
    wal_location: {name: "Rx Number", key: "rx_number", show: true, sortable: true, disabled: true},
    rx_group: {name: "Rx Group", key: "rx_group", show: true, sortable: true, disabled: true},
    mood: {name: "Mood", key: "mood", show: false, sortable: true, disabled: true},
    sdx: {name: "SDX", key: "sdx", show: false, sortable: true, disabled: true},
    reports: {name: "Wave Reports", key: "reports", show: true, sortable: false, disabled: true},
    account_creation_date: {name: "Acct. Date", key: "created_at", show: false, sortable: true, disabled: false},
    tx_status: {name: "TX Status", key: "tx_status", show: true, sortable: true, disabled: true},
    as_decision_status: {name: "AS Decision Status", key: "as_decision_status", show: true, sortable: true, disabled: true},
    expert_urologist: {name: "Expert Urologist", key: "expert_urologist", show: true, sortable: true, disabled: true},
    expert_radiologist: {name: "Expert Radiologist", key: "expert_radiologist", show: true, sortable: true, disabled: true},
    treating_hcp: {name: "Treating HCP", key: "treating_hcp", show: true, sortable: true, disabled: true},
    study_status: {name: "Study Status", key: "study_status", show: true, sortable: true, disabled: true},
    last_active: {name: "Last Active", key: "last_active", show: true, sortable: true, disabled: false},
    psa_status: {name: "PSA Status", key: "psa_status", show: false, sortable: true, disabled: true},
    mri_status: {name: "MRI Status", key: "mri_status", show: false, sortable: true, disabled: true},
    biopsy_status: {name: "Biopsy Status", key: "biopsy_status", show: false, sortable: true, disabled: true},
}

class PatientList extends React.Component {
    constructor(props) {
        super(props);

        // TODO change filters implementation according to server
        let defaultFilters = {
            age: [0, 100],
            ageFilter: [0, 100],
            gender: {},
            status: {riskPriority: false, watchWait: false},
            physician: {},
            pdx: {},
            therapy: {},
            study_status: {},
            pdx_selected: 1,
            campus: {},
            clinicalStudy: {},
            tx_status: {},
            as_decision_status: {},
            treating_hcp: {},
            expert_urologist: {},
            expert_radiologist: {}
        }
        this.state = {
            error: null,
            networkError: false,
            emptyList: false,
            isLoaded: false,
            fetchData: false,
            patientsCount: 0,
            patientList: null,
            columnList: column_list,
            selectionList: null,
            sortBy: 'name',
            sortDescending: true,
            patientPhotoErrors: [],
            patientSearchPhotoErrors: [],
            searching: false,   // do not update this from any other function
            searchText: "",     // do not update this from any other function
            searchResults: [],
            searchResultCount: 0,
            isSearchLoading: false,
            clientId: null,
            clientLogo: null,
            hideClientLogo: true,
            adminUser: false,
            selectedGroup: 0,
            showGroups: false,
            showOverlay: false,
            showColumnSettings: false,
            pageSize: 50,
            pageNumber: 1,
            add_patients: 0,
            patient_list_search: 0,
            patient_list_filters: 0,
            patient_list_title: "",
            risk_tooltip: 0,
            study_status_tooltip: 1,
            showFilters: false,
            filters: {...defaultFilters},
            campusShowDetails: false,
            clinicalStudyShowDetails: false,
            lastActiveShowDetails: false,
            treatingHcpShowDetails: false,
            expertUrologistShowDetails: false,
            expertRadiologistShowDetails: false,
            defaultFilters,
            newRequests: {},
            showInvitePatient: false,
            urlQueryParams: new URLSearchParams(this.props.location.search)
        };
        this.searchInput = null;
        this.selectAllClicked = this.selectAllClicked.bind(this);
        this.groupSelected = this.groupSelected.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.moveCard = this.moveCard.bind(this);
        this.updateColumnShow = this.updateColumnShow.bind(this);
    }

    componentDidMount() {
        let query = this.prepareQuery();
        this.setState({newRequests: {[query]: "started"}})
        this.props.dispatch(fetchPatients(query));
        this.componentDidUpdate();
    }

    prepareQuery(uniqueQuery=false) {
        let sortBy = this.state.sortBy;
        if(this.state.columnList[this.state.urlQueryParams.get("sortBy")] && this.state.columnList[this.state.urlQueryParams.get("sortBy")].sortable){
            sortBy = this.state.urlQueryParams.get("sortBy");
        }
        let er = {};
        if (!this.state.columnList.expert_radiologist.disabled)
            if (Object.values(this.state.filters.expert_radiologist).length > 0){
                Object.values(this.state.filters.expert_radiologist).map(p => {
                    if(Boolean(p[0])){
                        er[p[1].uuid] = p
                    }
                })
            }
        let eu = {};
        if (!this.state.columnList.expert_urologist.disabled)
            if (Object.values(this.state.filters.expert_urologist).length > 0){
                Object.values(this.state.filters.expert_urologist).map(p => {
                    if(Boolean(p[0])){
                        eu[p[1].uuid] = p
                    }
                })
            }
        let th = {};
        if (Object.values(this.state.filters.treating_hcp).length > 0){
            Object.values(this.state.filters.treating_hcp).map(p => {
                if(Boolean(p[0])){
                    th[p[1].uuid] = p
                }
            })
        }
        let ts = {};
        if (!this.state.columnList.tx_status.disabled)
            if (Object.values(this.state.filters.tx_status).length > 0){
                Object.values(this.state.filters.tx_status).map(p => {
                    if(Boolean(p[0])){
                        ts[p[1].status] = p
                    }
                })
            }
        let ats = {};
        if (!this.state.columnList.as_decision_status.disabled)
            if (Object.values(this.state.filters.as_decision_status).length > 0){
                Object.values(this.state.filters.as_decision_status).map(p => {
                    if(Boolean(p[0])){
                        ats[p[1].status] = p
                    }
                })
            }
        let pdxf = {};
        if (!this.state.columnList.pdx.disabled)
            if (Object.values(this.state.filters.pdx).length > 0){
                Object.values(this.state.filters.pdx).map(p => {
                    if(Boolean(p[0])){
                        pdxf[p[1]] = p
                    }
                })
            }
        let thf = {};
        if (!this.state.columnList.therapy.disabled)
            if (Object.values(this.state.filters.therapy).length > 0){
                Object.values(this.state.filters.therapy).map(p => {
                    if(Boolean(p[0])){
                        thf[p[1]] = p
                    }
                })
            }
        let ssf = {};
        if (!this.state.columnList.study_status.disabled)
            if (Object.values(this.state.filters.study_status).length > 0){
                Object.values(this.state.filters.study_status).map(p => {
                    if(Boolean(p[0])){
                        ssf[p[1]] = p
                    }
                })
            }
        return JSON.stringify({
            query: `query ($page_limit: Int, $page_offset: Int) { 
            ${uniqueQuery ? Array.from(Array(Math.floor(Math.random()*100)).keys()).map(o => " ") : ""}
        patients(limit: $page_limit, offset: $page_offset, filters: "${JSON.stringify({
                sortBy,
                sortOrder: this.state.sortDescending ? "desc" : "asc",
                expert_radiologist: er,
                expert_urologist: eu,
                treating_hcp: th,
                as_decision_status: ats,
                tx_status: ts,
                pdx: pdxf,
                therapy: thf,
                study_status: ssf,
                ...(this.state.filters.ageFilter && this.state.filters.ageFilter[0] !== 0 && this.state.filters.ageFilter[1] !== 100 ? { ageFilter: { min: this.state.filters.ageFilter[0], max: this.state.filters.ageFilter[1] } }: {})
            }).replaceAll(`"`, `\\"`)}"){
          count
          filters {
              name
              group
              gender
              clinical_study
              campus
              mood
              age
              ageFilter
              condition
              ${!this.state.columnList.study_status.disabled ? "study_status": ""}
              ${!this.state.columnList.pdx.disabled ? "pdx": ""}
              ${!this.state.columnList.therapy.disabled ? "therapy": ""}
              ${!this.state.columnList.tx_status.disabled ? "tx_status": ""}
              ${!this.state.columnList.as_decision_status.disabled ? "as_decision_status": ""}
              ${!this.state.columnList.expert_urologist.disabled ? "expert_urologist": ""}
              ${!this.state.columnList.treating_hcp.disabled ? "treating_hcp": ""}
              ${!this.state.columnList.expert_radiologist.disabled ? "expert_radiologist": ""}
              sortBy
              sortOrder
          }
          data{
            uuid
            first_name
            last_name
            photo
            gender
            active_from
            ${this.state.columnList.age.show && !this.state.columnList.age.disabled ? `age` : ``}
            ${this.state.columnList.psa_status.show && !this.state.columnList.psa_status.disabled ? `psa_status` : ``}
            ${this.state.columnList.mri_status.show && !this.state.columnList.mri_status.disabled ? `mri_status` : ``}
            ${this.state.columnList.biopsy_status.show && !this.state.columnList.biopsy_status.disabled ? `biopsy_status` : ``}
            birthdate
            ${this.state.columnList.birthday.show && !this.state.columnList.birthday.disabled ? `birthday` : ``}
            ${this.state.columnList.study_status.show && !this.state.columnList.study_status.disabled ? `study_status` : ``}
            ${this.state.columnList.therapy.show && !this.state.columnList.therapy.disabled ? `therapy` : ``}
            ${this.state.columnList.last_active.show && !this.state.columnList.last_active.disabled ? `last_active` : ``}
            ${this.state.columnList.account_creation_date.show && !this.state.columnList.account_creation_date.disabled ? `created_at` : ``}
            ${this.state.columnList.as_decision_status.show && !this.state.columnList.as_decision_status.disabled ? `as_decision_status{
            title
            status
            }` : ``}
            ${this.state.columnList.pdx.show && !this.state.columnList.pdx.disabled ? `pdx` : ``}
            ${this.state.columnList.tx_status.show && !this.state.columnList.tx_status.disabled ? `tx_status{
                title
                status
                as_year
            }` : ``}
            care{
                ${this.state.columnList.pdx.show && !this.state.columnList.pdx.disabled ? `pdx` : ``}
                treating_hcp{
                    uuid
                    first_name
                    last_name
                    photo
                    account_type
                    speciality
                    campus{
                        name
                    }
                    contact{
                        contact_type
                        phone
                        email
                        address{
                            first_line
                            unit
                            zipcode
                            city
                        }
                    }
                }
                ${!this.state.columnList.expert_urologist.disabled ? `expert_urologist{
                    uuid
                    first_name
                    last_name
                    photo
                    account_type
                    speciality
                    campus{
                        name
                    }
                    contact{
                        contact_type
                        phone
                        email
                        address{
                            first_line
                            unit
                            zipcode
                            city
                        }
                    }
                }`: ""}
                ${!this.state.columnList.expert_radiologist.disabled ? `expert_radiologist{
                    uuid
                    first_name
                    last_name
                    photo
                    account_type
                    speciality
                    campus{
                        name
                    }
                    contact{
                        contact_type
                        phone
                        email
                        address{
                            first_line
                            unit
                            zipcode
                            city
                        }
                    }
                }`: ""}
                ${this.state.columnList.rx_group.show && !this.state.columnList.rx_group.disabled ? `rx_group` : ``}
                ${this.state.columnList.wal_location.show && !this.state.columnList.wal_location.disabled ? `rx_number` : ``}
                ${this.state.columnList.clinical_study.show && !this.state.columnList.clinical_study.disabled ? `clinical_study {
                  id
                  active
                  location
              }` : ``}
                ${this.state.columnList.campus.show && !this.state.columnList.campus.disabled ? `campus {
                  name
                  location
              }` : ``}
            }
            ${this.state.columnList.reports.show && !this.state.columnList.reports.disabled ? `reports {
              start_date
              end_date
              url
            }` : ``}
          }
        }
      }`,
            variables: {
                page_limit: this.state.pageSize,
                page_offset: (this.state.pageNumber - 1) * this.state.pageSize
            }
        });
    }

    setClientLogo(stopLoop) {
        if (!this.state.clientId) {
            const storedState = store.getState();
            if (storedState !== null) {
                const {account} = storedState.auth;
                if (account && account.user && account.user.organization) {
                    let updatedState = {
                        clientLogo: account.user.organization.logo,
                        clientId: account.user.organization.uid,
                        columnList: {...this.state.columnList},
                        patient_list_search: this.state.patient_list_search,
                        patient_list_filters: this.state.patient_list_filters,
                    };
                    let components = helpers.getComponentsFromJwt(this.props.auth.token);
                    Object.keys(updatedState.columnList).forEach(element => {
                        if (components.patient_list_columns.includes(updatedState.columnList[element].key)) {
                            updatedState.columnList[element].disabled = false;
                        }
                    })
                    if (Boolean(components.patient_list_search)) updatedState.patient_list_search = 1;
                    if (Boolean(components.patient_list_filters)) updatedState.patient_list_filters = 1;
                    if (Boolean(components.patient_list_title)) updatedState.patient_list_title = components.patient_list_title;
                    if (Boolean(components.add_patients)) updatedState.add_patients = 1;
                    if (Boolean(components.risk_tooltip)) updatedState.risk_tooltip = 1;
                    if (Boolean(components.screen_patients)) updatedState.screen_patients = 1;
                    this.setState(updatedState);
                } else {
                    if (stopLoop) {
                        fetchUserSettings(true).then((res) => {
                            this.props.dispatch(authSlice.actions.setAccount(res));
                            this.props.history.push(Constants.PATIENT_LIST);
                            this.setClientLogo(false);
                        })
                            .catch((err) => {
                                // console.debug("error loading client logo", err);
                            });
                    }
                }
            }
        }
    }

    setPatientListFromReducer(data) {
        if(!Boolean(data)) data = this.prepareQuery();
        let state_update = {
            emptyList: true,
            isLoaded: true,
            fetchData: false,
            patientList: [],
            error: null,
            networkError: null
        };
        let dataObj = this.props.patients.data[data];
        if (dataObj && dataObj.patients && dataObj.patients.count > 0) {
            let i;
            let preparedPatientList = [];
            for (i = 0; i < dataObj.patients.data.length; i++) {
                let care = dataObj.patients.data[i]?.care ? {...dataObj.patients.data[i]?.care} : {}
                if (care.hasOwnProperty("pdx") && !care?.pdx){
                    delete care.pdx
                }
                preparedPatientList.push({...dataObj.patients.data[i], ...care});
            }
            this.setState({
                ...state_update,
                emptyList: false,
                patientList: preparedPatientList,
                patientsCount: dataObj.patients.count,
                filters: {
                    ...this.state.filters,
                    ...(dataObj.patients.filters.gender ? { gender: JSON.parse(dataObj.patients.filters.gender) } :{}),
                    ...(dataObj.patients.filters.age ? { age: [JSON.parse(dataObj.patients.filters.age).min || 0, JSON.parse(dataObj.patients.filters.age).max || 0] } :{}),
                    ...(dataObj.patients.filters.ageFilter ? { ageFilter: [JSON.parse(dataObj.patients.filters.ageFilter).min || 0, JSON.parse(dataObj.patients.filters.ageFilter).max || 0] } :{}),
                    ...(dataObj.patients.filters.tx_status ? { tx_status: JSON.parse(dataObj.patients.filters.tx_status) } :{}),
                    ...(dataObj.patients.filters.as_decision_status ? { as_decision_status: JSON.parse(dataObj.patients.filters.as_decision_status) } :{}),
                    ...(dataObj.patients.filters.pdx ? { pdx: JSON.parse(dataObj.patients.filters.pdx) } :{}),
                    ...(dataObj.patients.filters.therapy ? { therapy: JSON.parse(dataObj.patients.filters.therapy) } :{}),
                    ...(dataObj.patients.filters.study_status ? { study_status: JSON.parse(dataObj.patients.filters.study_status) } :{}),
                    ...(dataObj.patients.filters.treating_hcp ? { treating_hcp: JSON.parse(dataObj.patients.filters.treating_hcp) } :{}),
                    ...(dataObj.patients.filters.expert_urologist ? { expert_urologist: JSON.parse(dataObj.patients.filters.expert_urologist) } :{}),
                    ...(dataObj.patients.filters.expert_radiologist ? { expert_radiologist: JSON.parse(dataObj.patients.filters.expert_radiologist) } :{}),
                    sortBy: dataObj.patients.filters.sortBy,
                    sortOrder: dataObj.patients.filters.sortOrder
                },
            });
        }
        else{
            this.setState(state_update);
        }
    }

    componentDidUpdate() {
        var updatedQueryParam = false;
        var updatedState = {};
        if (!this.state.urlQueryParams.get("sortDescending")) {
            this.state.urlQueryParams.set("sortDescending", this.state.sortDescending);
            updatedQueryParam = true;
        } else if (this.state.urlQueryParams.get("sortDescending") !== String(this.state.sortDescending)) {
            updatedState["sortDescending"] = this.state.urlQueryParams.get("sortDescending") === "true";
            this.state.urlQueryParams.set("sortDescending", updatedState["sortDescending"]);
            updatedQueryParam = true;
        }
        if (this.state.urlQueryParams.get("sortBy") !== this.state.sortBy) {
            if (this.state.columnList[this.state.urlQueryParams.get("sortBy")] && this.state.columnList[this.state.urlQueryParams.get("sortBy")].sortable) {
                updatedState["sortBy"] = this.state.urlQueryParams.get("sortBy");
            } else if (this.state.urlQueryParams.get("sortBy")) {
                this.state.urlQueryParams.set("sortBy", this.state.sortBy);
                updatedQueryParam = true;
            }
        }
        if (updatedQueryParam) this.props.history.push({search: this.state.urlQueryParams.toString()});
        if (Object.keys(updatedState).length > 0) {
            this.setState(updatedState);
        }

        if (this.state.isLoaded || Object.keys(updatedState).length > 0 || updatedQueryParam) {
            return;
        }
        this.setClientLogo(true);
        let data = this.prepareQuery();
        let fetchDataNow = false;
        if (!(data in this.state.newRequests) || (data in this.state.newRequests && !['succeeded', 'started'].includes(this.state.newRequests[data]))){
            fetchDataNow = true;
        }
        if (this.state.fetchData || fetchDataNow) {
            this.setState({fetchData: false, newRequests: {...this.state.newRequests, [data]: 'started'}});
            this.props.dispatch(fetchPatients(data));
        }
        if (this.props.patients.status === 'loading') {
            if (data in this.state.newRequests && this.state.newRequests[data] === 'succeeded'){
                this.setPatientListFromReducer(data);
            }
            return;
        }
        if (this.props.patients.status === 'failed') {
            toast.error(this.props.patients.error);
            this.setState({
                isLoaded: true,
                fetchData: false,
                error: this.props.patients.error,
                patientList: null,
                emptyList: false
            })
            return;
        }

        if (this.props.patients.status === 'succeeded') {
            let newReqs = this.state.newRequests;
            for (let key in newReqs){
                if (['failed', 'started'].includes(newReqs[key]) && key in this.props.patients.data){
                    newReqs[key] = 'succeeded';
                }
            }
            this.setState({newRequests: {...newReqs}});
            if (this.props.patients.data && data in this.props.patients.data) {
                this.setPatientListFromReducer(data);
                setTimeout(() => this.setClientLogo(true), 1000);
                return;
            }
            else{
                this.setState({fetchData: true});
                return;
            }
        }
    }

    closeSelection() {
        this.setState({selectionList: null})
    }

    resetFilter() {
        this.setState({filters: {...this.state.defaultFilters}, isLoaded: false});
    }

    isFilterUsed() {
        let filters = this.state.filters;
        for (let key in filters){
            try{
                if (["tx_status", "as_decision_status", "pdx", "therapy", "expert_urologist", "expert_radiologist", "treating_hcp", "study_status"].includes(key)){
                    for (let subKey in filters[key]){
                        if (Boolean(filters[key][subKey][0])) return true
                    }

                }
                if (key === "gender"){
                    if (filters[key].male || filters[key].female) return true
                }
                if (key === "ageFilter"){
                    if (filters[key][0] > 0 || filters[key][1] < 100) return true
                }
            }
            catch (err){
                return false;
            }
        }
    }

    groupSelected(i) {
        this.setState({
            selectedGroup: i,
            showGroups: false,
            showOverlay: false,
            isLoaded: false,
            pageNumber: 1
        });
    }

    getMaxPageCount(real_count=true) {
        let count = parseInt(this.state.patientsCount / this.state.pageSize) + 1 * (this.state.patientsCount % this.state.pageSize > 0);
        if (real_count) return count;
        if (count < 30){
            return count;
        }
        else return 30;
    }

    hideOverlay() {
        this.setState({
            showGroups: false,
            showOverlay: false,
            showColumnSettings: false,
            showFilters: false,
            showInvitePatient: false
        })
    }

    nextPage() {
        if (this.state.pageNumber < this.getMaxPageCount()) {
            this.setState({pageNumber: this.state.pageNumber + 1, isLoaded: false});
        }
    }

    previousPage() {
        if (this.state.pageNumber > 1) {
            this.setState({pageNumber: this.state.pageNumber - 1, isLoaded: false});
        }
    }

    patientSelected(event, mrn) {
        return;
        // let selectionList = this.state.selectionList;
        // if (selectionList === null) {
        //     selectionList = {};
        // }
        // if (selectionList[mrn] === 1) {
        //     selectionList[mrn] = 0;
        // } else {
        //     selectionList[mrn] = 1;
        // }
        // this.setState({selectionList: selectionList});
        // event.stopPropagation()
    }

    selectAllClicked() {
        let i = 0;
        let selectionList = [];
        if (this.state.patientList) {
            for (i = 0; i < this.state.patientList.length; i++) {
                selectionList[this.state.patientList[i].uuid] = 1;
            }
            this.setState({selectionList: selectionList});
            toast.info(this.state.patientList.length + " items selected.");
        }
    }

    photoError(index) {
        let pe = this.state.patientPhotoErrors;
        pe.push(index)
        this.setState({patientPhotoErrors: pe})
    }

    patientSearchPhotoError(index) {
        let pe = this.state.patientSearchPhotoErrors;
        pe.push(index)
        this.setState({patientSearchPhotoErrors: pe})
    }

    getDisplay(values, key, patientUuid) {
        if ((typeof (values) === 'string') && !["birthday", "birthdate", "rx_group", "created_at", "pdx", "therapy"].includes(key)) {
            if (["psa_status", "mri_status", "biopsy_status", "study_status"].includes(key)) return this.props.t(values)
            return values;
        } else if (values === undefined || values === null) {
            return "";
        }
        if (key === 'rx_group' && values) {
            return <div className='rx-group-text'><span style={{
                background: "#4676F420",
                minWidth: "260px",
                maxWidth: "260px",
                color: `var(--text-color)`,
                borderRadius: "2px",
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                padding: "2px 8px",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
                width: "100%",
                textAlign: "center",
                height: "26px"
            }}>
              <span style={{
                  textAlign: "center",
                  opacity: 1,
                  alignSelf: "center",
                  color: `var(--text-color)`
              }}>{values[0].toUpperCase() + values.slice(1, 100)}</span>
            </span>
                <div style={{position: "relative", height: 0, display: "none"}}>
                    <div className='rx-group-tool-tip2'>{values[0].toUpperCase() + values.slice(1)}</div>
                </div>
            </div>
        } else if (["expert_radiologist", "expert_urologist", "treating_hcp", "support_team"].includes(key)) {
            return <div className='person-card-container' style={{padding:"5px"}}><span style={{borderBottom:"1px solid var(--dark-gray"}}>
              {values.last_name}, {values.first_name ? values.first_name.slice(0, 1): ""}
            </span>
                <div style={{position: "relative", height: 0, background:"transparent", left:"-100px"}}>
                    <div className='person-card-container-tool-tip' style={{background:"transparent", border:0, boxShadow:0}}><PractitionerCard person={values}/></div>
                </div>
            </div>
        } else if (key === 'pdx' && values) {
            return helpers.getPdxToDisplay(values, this.props.t, 40);
        } else if (key === 'age' && values) {
            return values;
        } else if (key === 'therapy' && values) {
            return this.props.t(values);
        } else if (key === 'tx_status' && values) {
            return helpers.getTxStatus(values, this.props.t, 20);
        } else if (key === 'as_decision_status' && values) {
            return helpers.getAsDecisionStatus(values, this.props.t, 20)
        } else if (key === 'last_active') {
            var la = new Date(0);
            la.setUTCSeconds(values);
            var today = new Date();
            var outStr = "";
            if (today.getMonth() === la.getMonth() && today.getFullYear() === la.getFullYear()) {
                if (today.getDate() === la.getDate()) outStr = this.props.t(`Today`);
                else if ((today.getDate() - 1) === la.getDate()) outStr = this.props.t(`Yesterday`);
                else outStr = helpers.getFormattedDate(la, "DD MMM YYYY", this.props.t);
            } else outStr = helpers.getFormattedDate(la, "DD MMM YYYY", this.props.t);
            outStr += outStr = helpers.getFormattedDate(la, ", HH:mm", this.props.t);
            return <span style={{whiteSpace:"nowrap"}}>{outStr}</span>
        } else if (["birthdate", "birthday"].includes(key)) {
            let bd = new Date(values)
            var utc = new Date(bd.getTime() + bd.getTimezoneOffset() * 60000)
            let out = helpers.getFormattedDate(utc, "DD MMM YYYY", this.props.t);
            if (key === "birthdate"){
                out += " (" + helpers.getAge(values) + ")"
            }
            return <span style={{whiteSpace:"nowrap"}}>{out}</span>
        } else if (key === 'created_at') {
            var la = new Date(0);
            la.setUTCSeconds(values);
            return helpers.getFormattedDate(la, "DD MMM YYYY");
        } else if (typeof (values) === 'object') {
            if (values instanceof Array && values.length > 0) {
                if (key === "reports") {
                    var final_out = helpers.getReportDate(values, this.props.t);
                    return <div style={{width: "100%", display: "flex", justifyContent: "start"}}>
                        <div onClick={(e) => {
                            this.props.history.push(Constants.PATIENT_STATUS + "/" + patientUuid + Constants.REPORT + "/" + values[0].url.substring(values[0].url.lastIndexOf("/") + 1));
                            e.stopPropagation();
                        }} className="wave-reports-body" style={{
                            borderRadius: "100px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: 'center',
                            justifySelf: "end",
                            alignSelf: "end",
                            padding: "10px 20px",
                            width: "max-content"
                        }}>
                            <span style={{
                                color: "var(--text-color)",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                alignSelf: 'center',
                                justifySelf: "center"
                            }}>{final_out}</span>
                            <svg style={{alignSelf: 'center', justifySelf: "center", marginLeft: "10px"}} width="12"
                                 height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.23072 10.5355C3.97472 10.5355 3.71872 10.4375 3.52372 10.2425C3.13272 9.8525 3.13272 9.2195 3.52372 8.8285L6.35172 6.0005L3.52372 3.1715C3.13272 2.7815 3.13272 2.1475 3.52372 1.7575C3.91472 1.3675 4.54672 1.3675 4.93772 1.7575L8.47272 5.2935C8.86372 5.6835 8.86372 6.3165 8.47272 6.7075L4.93772 10.2425C4.74272 10.4375 4.48672 10.5355 4.23072 10.5355Z"
                                      fill="var(--border-color-fainter)"/>
                            </svg>
                        </div>
                    </div>
                }
                let outStr = "";
                let display_string_part_one = values[0];
                if (values.length < 1) {
                    return "";
                } else if (values.length === 1) {
                    outStr = <span>{display_string_part_one}</span>
                } else {
                    outStr = <span>{display_string_part_one} <br/>{" (+" + (values.length - 1) + " more)"}</span>
                }
                return <div style={{display: "flex", flexDirection: "row"}}>
                    {outStr}
                </div>
            } else if (['interventions', 'campus'].includes(key)) {
                return <span style={{display: "flex", flexDirection: "column"}}>{values.name}
                    <span className="table-content-subtext">
          {values.city}
        </span></span>
            } else if (key === 'study') {
                return <span style={{display: "flex", flexDirection: "column"}}>
          {values.name ? values.name : values.location}
                    <br/>
          <span className="table-content-subtext"><span style={{
              color: values.status ? "var(--text-color)" : "var(--text-color-faintest)",
              fontWeight: 600
          }}>{values.status ? "Active" : "Inactive"} </span>#{values.study_id ? values.study_id : values.id}</span>
        </span>
            }
        }
    }

    moveCard(dragIndex, hoverIndex) {
        var cList = Object.keys(this.state.columnList);
        const dragCard = cList[dragIndex];
        let newList = update(cList, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        var columnDict = {};
        for (let i = 0; i < newList.length; i++) {
            columnDict[newList[i]] = this.state.columnList[newList[i]];
        }

        this.setState({columnList: columnDict});
    }

    updateColumnShow(key) {
        if (key === "created_at" && !this.state.columnList[key]){
            key = "account_creation_date"
        }
        this.setState({
            columnList: {
                ...this.state.columnList,
                [key]: {...this.state.columnList[key], show: !this.state.columnList[key].show}
            }
        })
    }

    openColumnSettings() {
        this.setState({
            showColumnSettings: !this.state.showColumnSettings,
            showGroups: false,
            showOverlay: !this.state.showColumnSettings,
            showFilters: false
        });
    }

    showInvitePatientBox() {
        this.setState({
            showInvitePatient: !this.state.showInvitePatient,
            showGroups: false,
            showOverlay: !this.state.showInvitePatient,
            showFilters: false
        });
    }

    toggleFilters() {
        this.setState({
            showColumnSettings: false,
            showGroups: false,
            showOverlay: !this.state.showFilters,
            showFilters: !this.state.showFilters
        });
    }

    startSearching() {
        ReactGA.event({
            category: "Engagement",
            action: "button-click",
            label: "pl-search-icon", // optional
            nonInteraction: false, // optional, true/false
        });
        if (this.searchInput)
            this.searchInput.focus();
        this.setState({
            searching: true,
            showOverlay: false,
            showColumnSettings: false,
            showGroups: false,
            showFilters: false
        });
    }

    openPatient(patient) {
        if (!this.state.clientId) {
            toast("Unable to Load, please refresh");
            return;
        }
        this.props.history.push({pathname: `/patient/${patient.uuid}${Constants.PROFILE}`, state: {patient}});
    }

    render = () => {
        const t = this.props.t;
        return (
            <div className="patient-list-container">
                <div onClick={this.hideOverlay} style={{
                    position: "fixed",
                    // width: "100%",
                    height: "100vh",
                    background: "rgba(0, 0, 0, 0.05)",
                    left: 0,
                    top: 0,
                    display: this.state.showOverlay ? "block" : "none",
                    zIndex: 80
                }}/>
                <Row style={{marginRight: "0", marginLeft: "0",
                    // maxWidth: "calc(max(min(100vw, max-content), 1440px))"
                }}>
                    <Col xs={4} className="page-tittle" style={{
                        paddingLeft: "0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "start",
                        whiteSpace:"nowrap"
                    }}>
                        {this.state.clientLogo ? <img src={this.state.clientLogo} alt="logo" style={{
                            minWidth: "44px",
                            maxHeight: "44px",
                            display: this.state.hideClientLogo ? "none" : "block"
                        }} onLoad={() => this.setState({hideClientLogo: false})}/> : ""}
                        <span
                            style={{
                                alignSelf: "center",
                                justifySelf: "center",
                                marginLeft: "15px"
                            }}>{t(this.state.patient_list_title)}
                             </span>
                    </Col>
                    <Col xs={8} style={{
                        display: this.state.selectionList ? "None" : "flex",
                        flexDirection: "row",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        paddingRight: "5px"
                    }}>
                        <div
                            style={{display: !this.state.add_patients ? "none" : ""}}>
                            <Button onClick={() => this.showInvitePatientBox()} className="white-buttons" variant="light" style={{
                                minHeight: "44px",
                                padding: "6px 27px",
                                display: this.state.searchText || this.state.searching ? "none" : "flex"
                            }}>
                                {t("Invite Patient")}
                            </Button>
                        </div>
                        <div
                            style={{display: !this.state.screen_patients ? "none" : ""}}>
                            <Button onClick={() => this.props.history.push(Constants.SCREEN_PATIENT)} className="white-buttons" variant="light" style={{
                                minHeight: "44px",
                                padding: "6px 27px",
                                display: this.state.searchText || this.state.searching ? "none" : "flex"
                            }}>
                                {t("Screen Patient")}
                            </Button>
                        </div>
                        <div className="groups-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            left: `-247px`,
                            top: "64px",
                            display: this.state.showInvitePatient ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "142px", top: "-42px"}}/>
                            <div style={{
                                background: "var(--background-color)",
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <InvitePatientCard closeBox={() => this.hideOverlay()} registrationCodes={(this.props.auth && this.props.auth.account && this.props.auth.account.organization && this.props.auth.account.organization.registration_codes) ? this.props.auth.account.organization.registration_codes: []} />
                            </div>
                        </div>
                        <div className="groups-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            right: `147px`,
                            top: "64px",
                            display: this.state.showFilters ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "153px", top: "-42px"}}/>
                            <div style={{
                                background: "var(--background-color)",
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <PatientListFilters filters={{ ...this.state.filters,
                                    campusShowDetails: this.state.campusShowDetails,
                                    clinicalStudyShowDetails: this.state.clinicalStudyShowDetails,
                                    lastActiveShowDetails: this.state.lastActiveShowDetails,
                                    treatingHcpShowDetails: this.state.treatingHcpShowDetails,
                                    expertUrologistShowDetails: this.state.expertUrologistShowDetails,
                                    expertRadiologistShowDetails: this.state.expertRadiologistShowDetails
                                }}
                                                    columnList={this.state.columnList}
                                                    updateState={(states) => this.setState(states)}
                                                    resetFilter={() => this.resetFilter()}/>
                            </div>
                        </div>
                        <div
                            style={{display: (this.state.searching || !this.state.patient_list_filters) ? "none" : ""}}>
                            <Button onClick={() => this.toggleFilters()} className="buttons-round" variant="light"
                                    style={{
                                        width: "44px",
                                        height: "44px",
                                        padding: "8px",
                                        textAlign: "center",
                                        display: (this.state.searchText || this.state.searching) ? "none" : "",
                                        backgroundColor: this.isFilterUsed() ? "var(--wave-blue)" : "var(--background-color)"
                                    }}>
                                <FilterIcon style={{width: "28px", height: "28px"}}
                                            fill={this.isFilterUsed() ? "var(--background-color)": "var(--text-color)"}/>
                            </Button>
                        </div>
                        <SearchPatients searching={this.state.searching}
                                        searchText={this.state.searchText}
                                        onFocus={() => this.setState({searching: true})}
                                        ref={(inpt) => {
                                            this.searchInput = inpt;
                                        }}
                                        loading={this.state.isSearchLoading}
                                        searchError={this.state.searchError}
                                        searchResults={this.state.searchResults}
                                        searchResultCount={this.state.searchResultCount}
                                        openPatient={(p) => this.openPatient(p)}
                                        patientSearchPhotoErrors={this.state.patientSearchPhotoErrors}
                                        patientSearchPhotoError={(index) => this.patientSearchPhotoError(index)}
                                        setState={(args) => this.setState(args)}
                        />
                        <div
                            style={{display: (this.state.searchText || this.state.searching) || (!this.state.patient_list_search) ? "none" : ""}}>
                            <Button className="white-buttons-round pl-search-icon" variant="light" style={{
                                width: "44px",
                                height: "44px",
                                padding: "8px",
                                textAlign: "center",
                                display: this.state.searchText || this.state.searching ? "none" : ""
                            }}
                                    onClick={(e) => this.startSearching()}>
                                <SearchIcon style={{width: "18px", height: "18px", position: "relative", top: "-1px"}}
                                            fill={"var(--background-color)"} stroke={"var(--text-color)"}/>
                            </Button>
                        </div>
                        <div
                            style={{display: (this.state.searchText || this.state.searching) ? "none" : ""}}>
                            <Button onClick={() => this.openColumnSettings()}
                                    className="white-buttons-round" variant="light" style={{
                                width: "44px",
                                height: "44px",
                                padding: "8px",
                                textAlign: "center",
                                display: (this.state.searchText || this.state.searching) ? "none" : ""
                            }}>
                                <img onClick={() => this.openColumnSettings()} src={SettingsIcon} style={{
                                    width: "18px",
                                    height: "18px",
                                    position: "relative",
                                    top: "-1px",
                                    cursor: "pointer"
                                }} alt="setting"/>
                            </Button>
                        </div>
                        <div onMouseEnter={() => this.setState({showColumnSettings: true, showGroups: false})}
                             className="groups-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            right: `350px`,
                            top: "64px",
                            display: this.state.showColumnSettings ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "293px", top: "-42px"}}/>
                            <div style={{
                                background: "var(--background-color)",
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    justifyItems: "center",
                                    width: "100%"
                                }}>
                                    <DndProvider backend={HTML5Backend}>
                                        {Object.values(this.state.columnList).map((column, i) =>
                                            !column.disabled && column.key !== 'name' && column.key !== 'status' ?
                                                <ColumnSettingCard key={i} index={i} id={column.key} textDict={column}
                                                                   moveCard={this.moveCard}
                                                                   updateColumnShow={this.updateColumnShow}/> : ''
                                        )}
                                    </DndProvider>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={8} style={{
                        display: this.state.selectionList ? "flex" : "None",
                        flexDirection: "row",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        paddingRight: "5px"
                    }}>
                        <Button onClick={() => this.closeSelection()} className="white-buttons" variant="light"
                                style={{width: "150px", height: "44px"}}>
                            Cancel
                        </Button>
                        <Button className="blue-buttons" onClick={this.selectAllClicked}>
                            Select All
                        </Button>
                        <Button className="blue-buttons" style={{display: this.state.adminUser ? "block" : "none"}}>
                            Remove
                        </Button>
                    </Col>
                </Row>
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>

                <table style={{width: "100%", marginTop: "20px", overflowX: "scroll", overflowY: "scroll"}}>
                    <thead style={{background: "var(--background-color)"}}>
                    <tr className="table-header-container" style={{
                        marginBottom: "20px",
                        cursor: "pointer",
                        padding: "0 20px",
                        position: "sticky",
                        top: 0,
                        zIndex: 70
                    }}>
                        {Object.values(this.state.columnList).map((column, i) => (
                            column.key !== 'status' && column.show && !column.disabled ? (
                                <th colSpan="1" className="table-header" key={i}
                                    onClick={() => {
                                        if (column.sortable) {
                                            this.state.urlQueryParams.set("sortDescending", !(this.state.urlQueryParams.get("sortBy") === column.key && this.state.sortDescending));
                                            this.state.urlQueryParams.set("sortBy", column.key);
                                            this.props.history.push({search: this.state.urlQueryParams.toString()});
                                            this.setState({
                                                sortBy: column.key,
                                                pageNumber: 1,
                                                isLoaded: false,
                                                sortDescending: !(this.state.urlQueryParams.get("sortBy") === column.key && this.state.sortDescending)
                                            });
                                        }
                                    }}
                                    style={{
                                        width: column.key === 'name' ? "300px" : '',
                                        left: column.key === 'name' ? "80px" : "",
                                        // position: column.key === 'name' ? "sticky" : "",
                                        minWidth: column.key === 'name' ? "300px" : '',
                                        maxWidth: this.getColumnWidth(column.key),
                                        whiteSpace:"wrap",
                                        color: this.state.sortBy === column.key ? "var(--text-color)" : "var(--text-color-faint)",
                                        fontWeight: this.state.sortBy === column.key ? 600 : 400
                                    }}>
                                    <div style={{display: "flex", flexDirection:"row"}}>
                                        <span style={{flex:1}}>{t(column.name)}{column.key === "name" ? <span style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px"
                                        }}>({this.state.patientsCount})</span> : ''}</span>
                                        <div className="header-sort-symbol" style={{
                                            visibility: (column.sortable && this.state.sortBy === column.key) ? "visible" : "hidden",
                                            transform: this.state.sortDescending ? "" : "rotate(180deg)",
                                            marginLeft: this.state.sortDescending ? "0" : "5px",
                                            marginRight: this.state.sortDescending ? "5px" : "0"
                                        }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z"
                                                      fill="var(--border-color)"/>
                                            </svg>
                                        </div>
                                        <div className='risk-tooltip-icon-pl' style={{
                                            display: column.key === 'name' && this.state.risk_tooltip ? 'flex' : 'none',
                                            alignSelf: "center",
                                            justifySelf: "center",
                                            flex:1,
                                            alignContent: "end",
                                            justifyContent: "end",
                                            textAlign: "end"
                                        }}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 style={{alignSelf: "flex-end", justifySelf: "end"}}>
                                                <path
                                                    d="M7 0.75C10.4518 0.75 13.25 3.54822 13.25 7C13.25 10.4518 10.4518 13.25 7 13.25C3.54822 13.25 0.75 10.4518 0.75 7C0.75 3.54822 3.54822 0.75 7 0.75Z"
                                                    stroke="var(--border-color)" strokeWidth="1.5"/>
                                                <line x1="7" y1="9.75" x2="7" y2="6.47727" stroke="var(--border-color)"
                                                      strokeWidth="1.5" strokeLinecap="round"/>
                                                <path d="M7 3.85969V4.00293" stroke="var(--border-color)"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"/>
                                            </svg>
                                            <div className="risk-tool-tip-pl">
                                                <RiskStatusTooltip/>
                                            </div>
                                        </div>
                                        <div className='risk-tooltip-icon-pl' style={{
                                            display: column.key === 'study_status' && this.state.study_status_tooltip ? 'flex' : 'none',
                                            alignSelf: "center",
                                            justifySelf: "center",
                                            alignContent: "end",
                                            justifyContent: "end",
                                            textAlign: "end"
                                        }}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 style={{alignSelf: "flex-end", justifySelf: "end"}}>
                                                <path
                                                    d="M7 0.75C10.4518 0.75 13.25 3.54822 13.25 7C13.25 10.4518 10.4518 13.25 7 13.25C3.54822 13.25 0.75 10.4518 0.75 7C0.75 3.54822 3.54822 0.75 7 0.75Z"
                                                    stroke="var(--border-color)" strokeWidth="1.5"/>
                                                <line x1="7" y1="9.75" x2="7" y2="6.47727" stroke="var(--border-color)"
                                                      strokeWidth="1.5" strokeLinecap="round"/>
                                                <path d="M7 3.85969V4.00293" stroke="var(--border-color)"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"/>
                                            </svg>
                                            <div className="risk-tool-tip-pl">
                                                <StudyStatusTooltip />
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            ) : (<></>)
                        ))}
                    </tr>
                    </thead>
                    <tbody className='tbody-pl'>
                    {this.state.isLoaded && this.state.patientList ? (
                        this.state.patientList.map((patient, h) => (
                            <tr onClick={() => this.openPatient(patient)} className="patient-list-row" key={h}>
                                {Object.values(this.state.columnList).map((column, i) => (
                                    column.key === 'name' ? (
                                        <td colSpan="1" className="table-content table-name-col" key={i} width="300px"
                                            style={{
                                                // position: "sticky",
                                                left: "80px", zIndex: 60, paddingLeft: "30px"}}>
                                            <Row className="patient-photo-list" style={{marginLeft: 0}}>
                                                <Col onClick={(event) => this.patientSelected(event, patient.uuid)}
                                                     xs={4} style={{
                                                    maxWidth: "48px",
                                                    minWidth: "48px",
                                                    marginRight: "23px",
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}>
                                                    <div style={{maxHeight: "38px", minHeight: "38px"}}>
                                                        <div
                                                            className={"patient-photo2"}
                                                            style={{
                                                                position: "relative",
                                                                top: 0,
                                                                height: "0",
                                                                background: "var(--border-color-two)",
                                                                display: (this.state.patientPhotoErrors.includes(h) || (this.state.selectionList && [0, 1].includes(this.state.selectionList[patient.uuid]))) && Boolean(patient.photo) && (patient.photo !== "") ? "none" : "block"
                                                            }}>
                                                            <div style={{width:"38px", height:"38px", background: "var(--border-color-two)", borderRadius:"50%", textAlign:"center", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                                                                <UserIcon style={{width:"25px", height:"25px"}} fill="var(--background-color)" />
                                                            </div>
                                                        </div>
                                                        <img
                                                            className={"patient-photo2"}
                                                            src={Boolean(patient.photo) && patient.photo !== "" ? patient.photo : null}
                                                            alt="" onLoad={() => this.photoError(h)}
                                                            style={{
                                                                minWidth: this.state.selectionList && [0, 1].includes(this.state.selectionList[patient.uuid]) ? "0" : "38px",
                                                                maxWidth: this.state.selectionList && [0, 1].includes(this.state.selectionList[patient.uuid]) ? "0" : "38px",
                                                                width: "38px",
                                                                maxHeight: "38px",
                                                                minHeight: "38px",
                                                                height: "38px",
                                                                borderRadius: "50%",
                                                                objectFit: "cover",
                                                                display: Boolean(patient.photo) && this.state.patientPhotoErrors.includes(h) ? "block" : "none"
                                                            }}/>
                                                    </div>
                                                    <FormCheck readOnly
                                                               className={"list-checkbox2"}
                                                               checked={this.state.selectionList && [0, 1].includes(this.state.selectionList[patient.uuid]) ? this.state.selectionList[patient.uuid] : 0}
                                                               style={{
                                                                   visibility: this.state.selectionList && [0, 1].includes(this.state.selectionList[patient.uuid]) ? "visible" : "hidden",
                                                                   alignSelf: "center"
                                                               }}/>
                                                </Col>
                                                <Col xs={8} style={{textAlign: "start"}}>
                                                    <Row style={{
                                                        color: "var(--text-color)",
                                                        fontWeight: "600",
                                                        maxWidth: "100%",
                                                        minWidth: "157px",
                                                        whiteSpace:"nowrap"
                                                    }}>{helpers.getPatientName(`${patient.last_name ? (patient.last_name + (patient.first_name ? ", ": "")) : ""}${patient.first_name ?  (patient.first_name) : ""}`, 48)}</Row>
                                                    <Row className="mrn-container"
                                                         style={{color: "var(--text-color-faint)"}}>
                  <span onClick={(event) => {
                      navigator.clipboard.writeText(patient.mrn);
                      toast.info("MRN copied");
                      event.stopPropagation();
                  }} style={{
                      padding: "0",
                      margin: "0",
                      display: patient.mrn ? "flex" : "none",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "start"
                  }}>MRN {patient.mrn}
                      <svg className="mrn-copy-img" width="11" height="10" viewBox="0 0 11 10" fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                           style={{height: "10px", width: "10px", marginLeft: "5px"}}>
                      <path
                          d="M7.25 3.23403V2.5C7.25 1.55719 7.25 1.08579 6.95711 0.792893C6.66421 0.5 6.19281 0.5 5.25 0.5H3C2.05719 0.5 1.58579 0.5 1.29289 0.792893C1 1.08579 1 1.55719 1 2.5V4.75C1 5.69281 1 6.16421 1.29289 6.45711C1.58579 6.75 2.05719 6.75 3 6.75H3.5"
                          stroke="var(--border-color)" strokeLinecap="round"/>
                      <rect x="3.75" y="3.25" width="6.25" height="6.25" rx="1" stroke="var(--border-color)"/>
                    </svg>
                  </span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </td>
                                    ) : (
                                        column.key !== 'status' && column.show && !column.disabled ? (
                                            <td colSpan="1" key={i} className="table-content" style={{
                                                width: "max-content",
                                                justifyContent: column.key === "seg" ? "space-around" : (column.key === "rx_group" ? "" :""),
                                                wordBreak: column.key === "pdx" ? "break-word" : '',
                                                color: "var(--text-color)"
                                            }}>
                                                {this.getDisplay(patient[column.key], column.key, patient.uuid)}
                                            </td>
                                        ) : (<></>)
                                    )
                                ))}
                            </tr>
                        ))
                    ) : <tr style={{display: "none"}}/>}
                    <tr style={{
                        display: (this.state.error || !this.state.isLoaded || this.state.emptyList || this.state.networkError) ? "" : "none",
                        height: "60vh",
                        width: "100%",
                        background: "var(--background-color)",
                        color: "var(--text-color)",
                        textAlign: "center",
                        opacity: (this.state.error || this.state.emptyList || this.state.networkError) ? 1 : 0
                    }}>
                        <td colSpan={Object.keys(this.state.columnList).length}>
                            <div>{this.state.emptyList ? "No Patients Found" : (this.state.networkError ? "Network Error: Please check your internet connection." : "Server Error, Please contact admin.")}</div>
                        </td>
                    </tr>
                    <tr style={{height: "65px", width: "100%", background: "var(--background-color)"}}>
                        <td colSpan={Object.keys(this.state.columnList).length}
                            style={{borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px"}}>
                            <div style={{alignContent: "center", display: "flex", flexDirection: 'row'}}>
                                <div style={{
                                    width: "max-content",
                                    height: "26px",
                                    flex: 1,
                                    marginLeft: "20px",
                                    justifyContent: "center",
                                    display: "flex",
                                    borderRadius: "100px",
                                    boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                    justifyItems: "center",
                                    alignContent: "start"
                                }}>
                                    <svg onClick={() => this.previousPage()} style={{
                                        opacity: this.state.pageNumber > 1 ? "1" : "0.25",
                                        alignSelf: "center",
                                        cursor: this.state.pageNumber > 1 ? "pointer" : "default",
                                        justifySelf: "center",
                                        margin: "7px 10px 7px 10px"
                                    }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.76928 10.5355C8.02528 10.5355 8.28128 10.4375 8.47628 10.2425C8.86728 9.85253 8.86728 9.21953 8.47628 8.82853L5.64828 6.00053L8.47628 3.17153C8.86728 2.78153 8.86728 2.14753 8.47628 1.75753C8.08528 1.36753 7.45328 1.36753 7.06228 1.75753L3.52728 5.29353C3.13628 5.68353 3.13628 6.31653 3.52728 6.70753L7.06228 10.2425C7.25728 10.4375 7.51328 10.5355 7.76928 10.5355Z"
                                              fill="var(--darkest-gray)"/>
                                    </svg>
                                    {[...Array(this.getMaxPageCount(false))].map((pn, i) =>
                                        <div key={i} onClick={() => this.setState({
                                            pageNumber: i + 1,
                                            isLoaded: false,
                                        })} style={{
                                            width: "8px",
                                            cursor: "pointer",
                                            height: "8px",
                                            background: this.state.pageNumber === (i + 1) ? "var(--border-color-fainter)" : "var(--background-color-faint)",
                                            borderRadius: "100px",
                                            margin: "5px",
                                            alignSelf: "center",
                                            justifySelf: "center"
                                        }}/>)}
                                    <svg onClick={() => this.nextPage()} style={{
                                        transform: "rotate(180deg)",
                                        opacity: this.state.pageNumber < this.getMaxPageCount() ? "1" : "0.25",
                                        cursor: this.state.pageNumber < this.getMaxPageCount() ? "pointer" : "default",
                                        alignSelf: "center",
                                        justifySelf: "center",
                                        margin: "7px 15px 7px 10px"
                                    }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.76928 10.5355C8.02528 10.5355 8.28128 10.4375 8.47628 10.2425C8.86728 9.85253 8.86728 9.21953 8.47628 8.82853L5.64828 6.00053L8.47628 3.17153C8.86728 2.78153 8.86728 2.14753 8.47628 1.75753C8.08528 1.36753 7.45328 1.36753 7.06228 1.75753L3.52728 5.29353C3.13628 5.68353 3.13628 6.31653 3.52728 6.70753L7.06228 10.2425C7.25728 10.4375 7.51328 10.5355 7.76928 10.5355Z"
                                              fill="var(--darkest-gray)"/>
                                    </svg>
                                </div>
                                <div style={{
                                    alignSelf: "end",
                                    justifySelf: "end",
                                    alignContent: "end",
                                    justifyitems: "end",
                                    textAlign: "end",
                                    flex: 10,
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%"
                                }}>
                                    <span style={{
                                        alignSelf: "center",
                                        opacity: this.state.isLoaded ? "1" : "0",
                                        justifySelf: "center",
                                        flex: 1,
                                        marginRight: "20px",
                                        fontWeight: 400,
                                        color: "var(--text-color-faint)",
                                        fontSize: "14px",
                                        lineHeight: "22px"
                                    }}>{`${this.props.t("Showing")}: ${((this.state.pageNumber - 1) * this.state.pageSize) + 1}-${this.state.pageNumber * this.state.pageSize > this.state.patientsCount ? this.state.patientsCount : this.state.pageNumber * this.state.pageSize} of ${this.state.patientsCount}`}</span>
                                    {[25, 50, 100].map((page_size, p_i) =>
                                      <span key={p_i} onClick={() => this.setState({
                                        pageSize: page_size,
                                        isLoaded: false,
                                        pageNumber: 1
                                    })} className="result-page-limit" style={{
                                        background: this.state.pageSize === page_size ? "var(--background-color-faint-two)" : "var(--background-color)",
                                        color: this.state.pageSize === page_size ? "var(--text-color-faint)" : "var(--text-color-faintest)",
                                        marginRight: "6px"
                                    }}>{page_size}</span>)}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    getColumnWidth(key) {
        switch (key){
            case "pdx":
                return "220px"
            case "therapy":
                return "200px"
            case "birthday":
            case "birthdate":
                return "140px"
            case "reports":
                return "260px"
            case "last_active":
                return "220px"
            case "name":
                return "300px"
            case "tx_status":
                return "140px"
            case "rx_group":
                return "100px"
            case "as_decision_status":
                return "180px"
            case "account_creation_date":
            case "created_at":
                return "120px"
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
        patients: dispatch.patients
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(PatientList)));