import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {AccountResponse} from "../../utils/types";
import {createSelector} from 'reselect';
import * as helpers from '../../helpers/helpers';
import {fetcher} from "../../utils/axios";
import * as Constants from "../../constants/Constants";
import {toast} from "react-toastify";

type State = {
    data: { [query: string]: { [key: string]: any; } };    // storing json
    // data: { [query: string]: { [key: string]: any; } };    // storing json
    error: string | null;
    current_query: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    /**
     * {"query from graphql": {data: "list of patients", timestamp: "timestamp of the request"}}
     * Realistically, 5 min refresh rate is more than enough. But realtime implementation would be there always.
     * hence Expectation --> As user refreshes the page, it will refresh the data
     */
};

const initialState: State = {data: {}, status: 'idle', error: null, current_query: null};

const patientsSlice = createSlice({
    name: "patients",
    initialState,
    reducers: {
        setCurrentQuery(state: State, action: PayloadAction<{ query: string; }>) {
            state.current_query = action.payload.query;
        },
        setStatus(state: State, action: PayloadAction<{ status: 'idle' | 'loading' | 'succeeded' | 'failed'; }>) {
            state.status = action.payload.status;
        },
        clearData(state: State) {
            state.data = {};
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPatients.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchPatients.fulfilled, (state, action) => {
                state.status = 'succeeded';
                let dataObj: any = {};
                if (Boolean(state.data)) {
                    dataObj = state.data;
                }
                dataObj[action.payload.query] = action.payload.data;
                state.data = dataObj;
            })
            .addCase(fetchPatients.rejected, (state, action) => {
                state.status = 'failed';
                if (Boolean(action.error.message)) {
                    state.error = action.error.message === "Network Error" ? String(action.error.message) : "Something went wrong. Please contact Admin";   // todo translation
                }
            })
    }
});

export const fetchPatients = createAsyncThunk('fetchPatients', async (query: string) => {
    console.debug("Getting Patients");
    // @ts-ignore
    const response = await fetcher(Constants.GRAPHQL_API, query)
    console.debug("Got Patients", response);
    return {query, data: response.data};
})

export default patientsSlice;