import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../utils/types";
import { createSelector } from 'reselect';
import * as helpers from '../../helpers/helpers';
import * as Constants from '../../constants/Constants';
import {reportAction} from "../../utils/axios";

type State = {
  token: string | null;
  refresh: string | null;
  careportal_web_url: string;
  loginTimestamp: number;
  exitTimestamp: number | null;
  account: AccountResponse | null;
  username: string | null;
  redirectAfterLogin: string;
  isSamlUser: boolean;
  language: string;
  colorTheme: string;
  userAcquired: boolean;        // ignore name. This is the indicator that user is acquired. Could be removed in future
  showLoginSameUser: boolean;   // ignore name. This is the indicator for partial logout
};

const initialState: State = { token: null, refresh: null, account: null, username: null, userAcquired: false,
  isSamlUser: false, showLoginSameUser: false, loginTimestamp: Date.now(), exitTimestamp: null, language: "en-us",
  redirectAfterLogin: Constants.PATIENT_LIST, colorTheme: "light", careportal_web_url: (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "")};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(
      state: State,
      action: PayloadAction<{ refresh: string; token: string; gateway: { careportal_web: string; } | null }>
    ) {
      state.refresh = action.payload.refresh;
      state.token = action.payload.token;
      state.careportal_web_url = action.payload.gateway && action.payload.gateway.careportal_web ? action.payload.gateway.careportal_web : (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "");
      state.userAcquired = true;
      state.showLoginSameUser = false;
      state.loginTimestamp = Date.now();
      state.exitTimestamp = null;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
      state.language = action.payload.user.language;
      localStorage.setItem("lng", action.payload.user.language);
      state.exitTimestamp = null;
    },
    setUserPhoto(state: State, action: PayloadAction<string>) {
      if (state.account) state.account.user.photo = action.payload;
    },
    removeRefresh(state: State) {
      state.refresh = null;
    },
    setUsername(state: State, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setRedirectAfterLogin(state: State, action: PayloadAction<string>) {
      state.redirectAfterLogin = action.payload;
    },
    setLanguage(state: State, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    setColorTheme(state: State, action: PayloadAction<string>) {
      state.colorTheme = action.payload;
    },
    clearUsername(state: State) {
      state.username = null;
    },
    clearRedirectAfterLogin(state: State) {
      state.redirectAfterLogin = Constants.PATIENT_LIST;
    },
    setUserStrategySaml(state: State, action: PayloadAction<boolean>) {
      state.isSamlUser = action.payload;
    },
    setLogout(state: State) {
      state.account = null;
      state.refresh = null;
      state.token = null;
      state.loginTimestamp = Date.now();
      state.exitTimestamp = null;
      state.userAcquired = false;
      state.showLoginSameUser = false;
    },
    setPartialLogout(state: State) {
      state.refresh = null;
      state.token = null;
      state.showLoginSameUser = true;
      state.exitTimestamp = null;
    },
    setUserExited(state: State) {
      state.exitTimestamp = Date.now();
    },
    resetLoginTimestamp(state: State) {
      state.loginTimestamp = Date.now();
      state.exitTimestamp = null;
    },
  },
});

export const isPartiallyLoggedOutSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => !Boolean(auth.token)
)

export const isStaleLoginSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => {
      if (Boolean(auth.exitTimestamp)) return (auth.exitTimestamp! - auth.loginTimestamp) > (1000 * 60 * 5);
      return false;
    }     // check if it has been 5 min since last login or token refresh
)

export const usernameSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.username
)
export const languageSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.language
)
export const organizationSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth?.account?.organization
)

export const colorThemeSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.colorTheme
)

export const userSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.account ? auth.account?.user : {}
)

export const isUserAcquiredIn = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.userAcquired
)

export const showPartialLoginSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.showLoginSameUser
)

export const isSamlUserSelector = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.isSamlUser
)

export const getRefreshToken = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.refresh
)
export const getUserType = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.account && auth.account?.user ? auth.account?.user.account_type : {}
)

export const getAccessToken = createSelector(
    (state: any) => state.auth,
    (auth: State) => auth.token
)

export const getComponentsAccess = createSelector(
    (state: any) => state.auth,
    (auth: State) => helpers.getComponentsFromJwt(auth.token)
)

export default authSlice;