import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Row, Col, Container} from "reactstrap";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import { connect } from 'react-redux'
import BackButton from '../BackButton'
import Select, { components } from 'react-select'
import * as helpers from '../../helpers/helpers'
import PageNotFound from '../../pages/PageNotFound'
import * as Constants from '../../constants/Constants'
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download.svg'
import { fetcher } from '../../utils/axios'
import { toast } from 'react-toastify'
import HashLoader from 'react-spinners/HashLoader'
import PatientOnboardingFormEs from './PatientOnboardingFormEs'
import patPDF from '../../assets/PROWAVE1 Patient Instruction Sheet BLANK_English.pdf'
import patPDFSpanish from '../../assets/PROWAVE1 Patient Instruction Sheet BLANK_Spanish.pdf'
import { PDFDocument, rgb } from 'pdf-lib';


const reactSelectStyles = {
    container: (base, state) =>({
        ...base,
        maxHeight:"52px",
        height:"52px",
        alignContent:"center",
        color: "var(--wave-blue)",
        fontWeight: 600,
        margin: "0 40px 0 0",
        borderRadius: "10px",
        border: "1px solid var(--text-color-faintest)",
        background: "var(--background-color)",
    }),
    ValueContainer: (provided, state) => ({
        ...provided,
        fontWeight: 600,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontWeight: 600,
    }),
    IndicatorsContainer: (provided, state) => ({
        ...provided,
    }),
    control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        padding: "6px",
        backgroundColor: "transparent"
    }),
    Control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        margin:0,
        padding:0,
        backgroundColor: "transparent"
    }),
    menu: base => ({
        ...base,
        outline:0,
        zIndex:110
    }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "var(--border-color-fainter)" :"var(--background-color-faintest)",
        color: "var(--text-color)",
        opacity: state.isFocused ? 0.9: 1,
        zIndex:110
    }),
    indicatorSeparator: (base, state)=>({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "var(--text-color)",
    })
};

class ScreenPatient extends React.Component {
    componentRef = null;
    printButtonRef = null;
    constructor(props) {
        super(props);
        let defaultQuestions = [{
            question: <><span style={{fontWeight: 600}}>{this.props.t("Please confirm that the patient meets each of these PRO-WAVE1 participation requirements.")}</span><br />({this.props.t('Select all that apply')})</>,
            type: "multiple_choice",
            choices: [
                this.props.t("Patient is male, and older than 18 years of age"),
                this.props.t("Patient’s life expectancy is > 6 months (according to the Treating Physician)"),
                this.props.t("Treating Physician is not aware of a condition or abnormality that could compromise the patient's safety or the quality of the study data"),
                this.props.t(`Patient is NOT… A patient with a localized prostate cancer entering in (A) watch and wait, (B) active surveillance, or (C) local treatment with curative intent`),
                this.props.t("Patient is NOT… A patient for whom only supportive care is indicated"),
                this.props.t("Patient is NOT… currently participating in another app-based clinical trial"),
            ],
            answer: {
                [this.props.t("Patient is male, and older than 18 years of age")]: false,
                [this.props.t("Patient’s life expectancy is > 6 months (according to the Treating Physician)")]: false,
                [this.props.t("Treating Physician is not aware of a condition or abnormality that could compromise the patient's safety or the quality of the study data")]: false,
                [this.props.t(`Patient is NOT… A patient with a localized prostate cancer entering in (A) watch and wait, (B) active surveillance, or (C) local treatment with curative intent`)]: false,
                [this.props.t("Patient is NOT… A patient for whom only supportive care is indicated")]: false,
                [this.props.t("Patient is NOT… currently participating in another app-based clinical trial")]: false,
            },
            checkValidation: function isValid(answer, choices){
                try{
                    if (Object.keys(answer).length < choices.length) return false
                    for (let k in answer){
                        if (!Boolean(answer[k])) return false
                    }
                    return true
                }
                catch (err){
                    console.error(err);
                    return false
                }
            }
        },
            {
                question: <span style={{fontWeight: 600}}>{this.props.t("Please confirm which of these histological or cytological diagnoses currently applies to the patient?")}</span>,
                type: "options_dropdown",
                placeholder: this.props.t("Select Diagnoses"),
                choices: [
                    { label: this.props.t("Non-Metastatic Prostate Cancer"), value: "nmPC"},
                    { label: this.props.t("Metastatic Castration-Resistant Prostate Cancer"), value: "mCRPC"},
                    { label: this.props.t("Metastatic Hormone-Sensitive Prostate Cancer"), value: "mHSPC"},
                ],
                checkValidation: function isValid(answer){
                    try{
                        return Boolean(answer);
                    }
                    catch (err){
                        console.error(err);
                        return false
                    }
                }
            },
            {
                question: <span style={{fontWeight: 600}}>{this.props.t("Please indicate which of these systemic therapies the patient will initiate or has already started as treatment for their prostate cancer?")}</span>,
                type: "options_dropdown",
                placeholder: this.props.t("Select Therapy"),
                choices: [
                    { label: this.props.t("Abiraterone"), value: "Abiraterone"},
                    { label: this.props.t("Apalutamide"), value: "Apalutamide"},
                    { label: this.props.t("Bicalutamide"), value: "Bicalutamide"},
                    { label: this.props.t("Cabazitaxel"), value: "Cabazitaxel"},
                    { label: this.props.t("Darolutamide"), value: "Darolutamide"},
                    { label: this.props.t("Degarelix"), value: "Degarelix"},
                    { label: this.props.t("Docetaxel"), value: "Docetaxel"},
                    { label: this.props.t("Enzalutamide"), value: "Enzalutamide"},
                    { label: this.props.t("Flutamide"), value: "Flutamide"},
                    { label: this.props.t("Goserelin Acetate"), value: "Goserelin Acetate"},
                    { label: this.props.t("Leuprolide"), value: "Leuprolide"},
                    { label: this.props.t("Lutesium PSMA"), value: "Lutesium PSMA"},
                    { label: this.props.t("Mitoxantrone Hydrochloride"), value: "Mitoxantrone Hydrochloride"},
                    { label: this.props.t("Nilutamide"), value: "Nilutamide"},
                    { label: this.props.t("Olaparib"), value: "Olaparib"},
                    { label: this.props.t("Orgovyx (Relugolix)"), value: "Orgovyx (Relugolix)"},
                    { label: this.props.t("Provenge"), value: "Provenge"},
                    { label: this.props.t("Radium 223"), value: "Radium 223"},
                    { label: this.props.t("Rucaparib Camsylate"), value: "Rucaparib Camsylate"},
                    { label: this.props.t("Other"), value: "Other"},
                ],
                checkValidation: function isValid(answer){
                    try{
                        return Boolean(answer);
                    }
                    catch (err){
                        console.error(err);
                        return false
                    }
                }
            }
        ];
        this.state = {
            error: null,
            isLoaded: true,
            registrationCode: "",
            numPages: 0,
            defaultQuestions: defaultQuestions,
            questions: defaultQuestions
        };
    }

    reactToPrintContent = () => {
        return this.componentRef;
    };

    componentDidMount() {
        if (this.state.isLoaded) {
            return;
        }
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            return;
        }
    }

    async downloadPdf(registrationCode){
        if (!registrationCode){
            // 9dbff6159cd6 - no code generated after patient was screened
            toast.error(this.props.t("Something went wrong. Reference Code: 9dbff6159cd6"));
            return;
        }
        // const formPdfBytes = await fetch(patPDF).then(res => res.arrayBuffer())
        // const pdfDoc = await PDFDocument.load(formPdfBytes)
        // let page = pdfDoc.getPage(0)
        // const text = this.state.registrationCode || "000000"
        // const textSize = 30
        // Array.from(Array(text.length).keys()).forEach(r => {
        //     page.drawText(text[r], {
        //         x: 100 + (r * 49),
        //         y: 441,
        //         size: textSize,
        //         color: rgb(0,0,0),
        //     })
        // })
        // const pdfBytes = await pdfDoc.save()
        // await this.saveFile(new Blob([pdfBytes], {type: "application/pdf"}), "PROWAVE1 Patient Instruction Sheet BLANK_English.pdf")

        const formPdfBytesSpanish = await fetch(patPDFSpanish).then(res => res.arrayBuffer())
        const pdfDocSpanish = await PDFDocument.load(formPdfBytesSpanish)
        let pageSpanish = pdfDocSpanish.getPage(0)
        const textSpanish = this.state.registrationCode || "000000"
        const textSizeSpanish = 30
        Array.from(Array(textSpanish.length).keys()).forEach(r => {
            pageSpanish.drawText(textSpanish[r], {
                x: 100 + (r * 49),
                y: 443,
                size: textSizeSpanish,
                color: rgb(0,0,0),
            })
        })
        const pdfBytesSpanish = await pdfDocSpanish.save()
        await this.saveFile(new Blob([pdfBytesSpanish], {type: "application/pdf"}), "PROWAVE1 Patient Instruction Sheet BLANK_Spanish.pdf")
    }

    saveFile = async (blob, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    updateSelection(question, questionIndex, temp){
        this.setState({questions: [...this.state.questions.slice(0, questionIndex), {...question, answer: temp.value}, ...this.state.questions.slice(questionIndex+1)]})
    }

    loadQuestion(question, questionIndex){
        let out = [<Row style={{margin:"0 10px 0 0", padding:0}}>
            <Col style={{padding:"10px 30px", fontSize:"14px", lineHeight:"22px"}}>
                {question.question}
            </Col>
        </Row>]
        switch(question.type){
            case "multiple_choice":
                question.choices.forEach(c => {
                    out.push(<Row style={{margin:"0 10px 0 0", padding:0}}>
                        <Col xs={1} style={{padding:"5px 35px", fontSize:"14px", lineHeight:"22px"}}>
                            <input checked={question.answer[c]}
                                   onChange={event => {this.setState({questions: [...this.state.questions.slice(0, questionIndex), {...question, answer: {...this.state.questions[questionIndex].answer, [c]: !Boolean(question.answer[c])}}, ...this.state.questions.slice(questionIndex+1)]})}}
                                   type={"checkbox"} style={{width:"22px", height:"22px"}} />
                        </Col>
                        <Col style={{padding:"5px 35px 5px 0px", fontSize:"14px", lineHeight:"22px"}}>
                            {c}
                        </Col>
                    </Row>)
                })
              out.push(<br />)
                return out;
            case "options_dropdown":
                out.push(<Row style={{margin:"0 10px 0 0", padding:0}}>
                    <Col style={{padding:"5px 35px", fontSize:"14px", lineHeight:"22px"}}>
                        <Select styles={reactSelectStyles}
                                isSearchable={false}
                                value={question.answer ? [{ value:question.answer, label: this.props.t(question.answer) }]: ""}
                                components={{Placeholder: ({ children, ...props }) => (
                                      <components.Placeholder {...props}>
                                          <span style={{color:"var(--text-color-faintest)", fontWeight:600}}>{question.placeholder ? question.placeholder: 'Select'}</span>
                                      </components.Placeholder>
                                    )}}
                                options={question.choices}
                                onChange={(temp) => this.updateSelection(question, questionIndex, temp)}
                        />
                    </Col>
                </Row>)
                out.push(<br />)
                return out;
            default:
                return out
        }
    }

    checkAnswers(){
        for (let q of this.state.questions){
            if (!q.checkValidation(q.answer, q.choices)) return false
        }
        return true
    }

    sendRequest(){
        if (!this.state.isLoaded){
            return;
        }
        if (this.state.registrationCode){
            toast.error(this.props.t("Please refresh the page to screen another patient"));
        }
        if (!this.props.auth.account?.user?.location_code){
            toast.info("Please Contact Admin. Red Code: 1b2dfee6c98d")
        }
        if (!this.checkAnswers()){
            return;
        }
        this.setState({isLoaded: false});
        var data = JSON.stringify({
            query: `mutation{
                    screen_patient (location: "${this.props.auth.account.user.location_code}", pdx: "${this.state.questions[1].answer}", therapy: "${this.state.questions[2].answer}")
                    }`,
            variables: {}
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              if(response.errors || !response.data.screen_patient){
                  console.debug("Got Error", response.errors);
                  toast.error(this.props.t("Unable to send request"));
                  this.setState({isLoaded: true});
                  return;
              }
              toast.info(this.props.t("Patient Screened Successfully"));
              this.setState({isLoaded: true, registrationCode: response.data?.screen_patient, questions: this.state.defaultQuestions})
              this.downloadPdf(response.data?.screen_patient);
          })
          .catch((err) => {
              this.setState({isLoaded: true});
              toast.error(this.props.t("Unable to send request"));
          });
    }


    render = () => {
        let components = helpers.getComponentsFromJwt(this.props.auth.token)
        if (!this.props.auth.account?.user?.location_code && !this.state.error){
            this.setState({error: "Please contact admin. Ref Code: 1b2dfee6c98d"})
        }
        if (! components.screen_patients){
            return <PageNotFound referenceCode={"a2389c6dac59"} />
        }
        return (
            <Container style={{
                display: "flex",
                alignContent: "center",
                justifySelf: "center",
                paddingTop:"50px",
                flexDirection: "column",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                width:"892px",
                paddingBottom: "50px"
            }}>
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>
                <Row style={{marginRight: "0", marginLeft: "0"}}>
                    <Col className="page-tittle" style={{
                        paddingLeft: "0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "start"
                    }}>
                        <span style={{
                            fontSize: "18px",
                            lineHeight: "26px",
                            cursor: "pointer",
                            margin: "0 10px",
                            color: 'var(--text-color)',
                            display:"flex",
                            flexDirection:"row",
                            justifyContent:"start",
                            alignItems:"center"
                        }}>
                            <BackButton />
                            {this.props.t("Screen Patient")}
                        </span>
                    </Col>
                    <Col xs={1}>
                        {this.state.isLoaded && this.state.registrationCode ? <Button
                          onClick={() => this.downloadPdf(this.state.registrationCode)}
                          variant="light" style={{
                            width:"50px", height:"50px",
                            marginRight: 0, borderRadius: '50%', background: 'var(--background-color)'
                        }}>
                            <DownloadIcon style={{ position: 'relative', top: '-3px' }} fill={'var(--text-color)'}
                                          stroke={'var(--text-color)'}/>
                        </Button> : ""}
                    </Col>
                </Row>
                {this.state.error ? <Row><Col style={{margin:"20px 30px 0 30px", padding:"5px 10px 5px 30px", color:"var(--wave-clay)", fontWeight:600, fontsize:"16px"}}>{this.state.error}</Col></Row>: ""}
                {this.state.registrationCode ? <div ref={(ref) => {this.componentRef = ref}} style={{padding:0, margin:0}}>
                    <PatientOnboardingFormEs code={this.state.registrationCode} />
                </div>: ""}
                {!this.state.registrationCode ? <Row style={{
                    borderRadius: '10px',
                    margin: '20px 10px 10px 10px',
                    padding: 0,
                    width: '792px',
                    maxWidth: '792px',
                    background: 'var(--background-color)'
                }}>
                    <Row style={{ padding: '20px', margin: '20px 0 0 0' }}>
                        <Col style={{
                            paddingLeft: '0',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'start'
                        }}>
                        <span style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: 'var(--text-color)',
                            paddingLeft: '10px',
                            fontSize: '18px', lineHeight: '26px'
                        }}>
                            {this.props.t('PRO-WAVE1 Patient Qualifications Form')}
                        </span>
                        </Col>
                    </Row>
                    {this.state.questions.map((q, i) => this.loadQuestion(q, i))}
                    <Row style={{
                        fontWeight: 600,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTop: '1px solid var(--border-color-faintest)',
                        padding: '10px 20px',
                        margin: '20px 0 0 0',
                        width: '100%'
                    }}>
                        <Col xs={3} style={{ margin: '10px 0' }}>
                            <Button onClick={() => this.setState({ questions: this.state.defaultQuestions })}
                                    variant="light" style={{
                                minWidth: '150px',
                                height: '44px',
                                padding: '8px',
                                borderRadius: '100px',
                                border: '1px solid var(--border-color-faint)',
                                background: 'var(--background-color)',
                                textAlign: 'center',
                                marginLeft: '0',
                                color: 'var(--text-color-faint)',
                                fontWeight: 600
                            }}>{this.props.t('Cancel')}</Button>
                        </Col>
                        <Col xs={3} style={{ margin: '10px 0' }}>
                            <Button onClick={() => this.sendRequest()} variant="light" style={{
                                minWidth: '150px',
                                height: '44px',
                                padding: '8px',
                                borderRadius: '100px',
                                textAlign: 'center',
                                border: '0',
                                marginLeft: '0',
                                background: this.checkAnswers() ? 'var(--bs-primary)' : 'var(--border-color-faint)',
                                color: 'var(--background-color)',
                                fontWeight: 600
                            }}>{this.props.t('Next')}</Button>
                        </Col>
                    </Row>
                </Row>: ""}

            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(ScreenPatient)));