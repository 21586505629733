import { Col, Row, Container } from 'reactstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as helpers from '../../helpers/helpers'

export default function ChltReport (props) {
  const { i18n } = useTranslation()
  function t(trans){
    return i18n.t(trans, { lng: "es" })
  }
  let data = props.report && props.report.data ? (typeof props.report.data === "string" ? JSON.parse(props.report.data): props.report.data) : {}
  let answers = {}
  const answerList = (data.answers ? data.answers : [])
  answerList.forEach(a => {
    answers[a.question_id] = a.value
  })

  let pageNumbers = {
    "Q3":1, "Q8":2, "Q12":3, "Q17":4, "Q23":5, "Q28":6, "Q30":7
  }

  const questions = {
    "Q1": {
      question: "Doctors often recommend high calorie and high protein foods for cancer patients in treatment. Which is the highest in calories and protein?",
      options: [
        "French fries",
        "Cheeseburger",
        "Hard-boiled egg",
        "I Don’t Know"
      ]
    },
    "Q2": {
      question: "Shirley took two Lorazepam at 2 p.m. What time can she take the next dose?",
      options: [
        "6:00 PM (18:00h in the evening)",
        "7:00 PM (19:00h in the evening)",
        "8:00 PM (20:00h in the evening)",
        "I Don’t Know"
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q02_Image_English.png"
    },
    "Q3": {
      question: "Mr. Davis has had his tumor surgically removed. After his surgery, he will get chemotherapy. The chemotherapy is:",
      options: [
        "Neo-adjuvant",
        "Adjuvant",
        "I Don’t Know"
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q03_Image_English.png"
    },
    "Q4":{
      question: "The normal range for hemoglobin for a male is 13.3–17.2 g/dl. Joe’s hemoglobin is 9.7 g/dl. Is Joe within the normal range?",
      options: [
        "Yes",
        "No",
        "I Don’t Know",
      ]
    },
    "Q5":{
      question: "In people who develop oral cancers, 25% of these cases occur in the tongue. Oral cancer occurs in the tongue:",
      options: [
        "1 out of every 25 cases",
        "25 out of every 100 cases",
        "25 out of every 1000 cases",
        "I Don’t Know",
      ]
    },
    "Q6":{
      question: "Which side effect is more common for patients taking Tamoxifen?",
      options: [
        "Swelling",
        "Weight loss",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q06_Image_English.png"
    },
    "Q7":{
      question: "Chemotherapy Treatment A has a 92% success rate and a long-term complication rate of 15.5%. Treatment B has a 95.9% success rate and a long-term complication rate of 3.8%. Which treatment has a lower risk of long-term complications?",
      options: [
        "Treatment A",
        "Treatment B",
        "I Don’t Know",
      ]
    },
    "Q8":{
      question: "The purpose of palliative care is to cure cancer.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q9":{
      question: "A biopsy of a tumor is done to:",
      options: [
        "Remove it",
        "Diagnose it",
        "Treat it",
        "I Don’t Know",
      ]
    },
    "Q10":{
      question: "Above is Maria’s appointment card. Where should Maria go for her appointment?",
      options: [
        "Purple Clinic",
        "Yellow Clinic",
        "Green Clinic",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q10_Image_English.png"
    },
    "Q11":{
      question: "Pete has a temperature of 100.3°F. According to the chart, should he call his doctor?",
      options: [
        "Yes",
        "No",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q11_Image_English.png"
    },
    "Q12":{
      question: "If a patient has stage 1 cancer, it means the cancer is:",
      options: [
        "Localized",
        "In nearby organs",
        "In distant sites",
        "I Don’t Know",
      ]
    },
    "Q13":{
      question: "Ms. Rivera needs directions to get to her first appointment. The receptionist tells her to walk to the end of the hall and take a right turn, the first left turn, and then go to the first door on the left. If Ms. Rivera follows these directions, where will she end up?",
      options: [
        "Dr. Lee’s Office",
        "Dr. Marley’s Office",
        "Dr. Adams’ Office",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q13_Image_English.png"
    },
    "Q14":{
      question: "The degree to which a drug can have a beneficial effect is called:",
      options: [
        "Impotency",
        "Efficacy",
        "Dexterity",
        "I Don’t Know",
      ]
    },
    "Q15":{
      question: "Exposing a tumor to air during surgery causes the tumor to spread.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q16":{
      question: "Brand name drugs have the same active ingredients as generic drugs with a little extra that makes them better.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q17":{
      question: "The overall five-year survival rate for prostate cancer is 98%. This means that five years after treatment, 98% of prostate cancer patients will be expected to:",
      options: [
        "Be alive",
        "Be cancer-free",
        "Die",
        "I Don’t Know",
      ]
    },
    "Q18":{
      question: "An appointment card says not to eat or drink anything 9 hours prior to the appointment. Sally has an appointment at 11:15 AM on Friday. What time should she stop eating or drinking?",
      options: [
        "Thursday at 11:15 PM (23:15h in the night)",
        "Friday at 1:15 AM (01:15h in the morning)",
        "Friday at 2:15 AM (02:15h in the morning)",
        "I Don’t Know",
      ]
    },
    "Q19":{
      question: "Scientists estimate that smoking is responsible for 85% to 90% of lung cancer deaths. This means that 85% to 90% of smokers will get lung cancer.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q20":{
      question: "The role of a physical therapist is to talk to a patient about emotional needs.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q21":{
      question: "A tumor is considered “inoperable” when it cannot be treated with:",
      options: [
        "Radiation Therapy",
        "Surgery",
        "Chemotherapy",
        "I Don’t Know",
      ]
    },
    "Q22":{
      question: "When receiving radiation, patients should eat foods that are high in fiber and avoid eating foods containing lots of spices, caffeine or dairy products. Which of the following foods is best to eat when receiving radiation?",
      options: [
        "Curry",
        "Ice cream",
        "Bananas",
        "I Don’t Know",
      ]
    },
    "Q23":{
      question: "When a cancer has metastasized it means it has:",
      options: [
        "Spread to other parts of the body",
        "Spread to other parts of the originally affected organ",
        "Stopped spreading",
        "I Don’t Know",
      ]
    },
    "Q24":{
      question: "A benign tumor is cancerous.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q25":{
      question: "Sally will get radiation therapy once a day, Monday through Friday. If Sally has therapy for 4 weeks, how many times will she get radiation therapy?",
      options: [
        "5 times",
        "15 times",
        "20 times",
        "I Don’t Know",
      ]
    },
    "Q26":{
      question: "Of 100 people receiving treatment, half are expected to respond to the treatment. Of those who respond, half are expected to have complications. How many people who respond to treatment are expected to have complications?",
      options: [
        "25 people",
        "35 people",
        "50 people",
        "I Don’t Know",
      ]
    },
    "Q27":{
      question: "If patients get better by taking Medicine B twice a day, then if they take Medicine B 3 times a day, patients will get better faster.",
      options: [
        "True",
        "False",
        "I Don’t Know",
      ]
    },
    "Q28":{
      question: "If Ms. Liu wants to learn more about the side effects of radiation, which chapter is most likely to have this information?",
      options: [
        "Chapter 1",
        "Chapter 2",
        "Chapter 3",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q28_Image_English.png"
    },
    "Q29":{
      question: "Mrs. Bell takes her first pill at 10:00 AM. If she takes this medicine every 4 hours, when would she need to take her third pill?",
      options: [
        "At 2:00 PM (14:00 in the afternoon)",
        "At 4:00 PM (16:00 in the afternoon)",
        "At 6:00 PM (18:00 in the evening)",
        "I Don’t Know",
      ]
    },
    "Q30":{
      question: "If Mr. Anthon needs to meet his doctor in room202, which direction should he go?",
      options: [
        "Straight ahead",
        "Right",
        "Left",
        "I Don’t Know",
      ],
      image: "https://cdn.ttisystems.cloud/CHLT30_Q30_Image_English.png"
    }
  }

  let dateSubmitted = data.completed ? helpers.getFormattedDate(data.completed, "DD MMM YYYY", t) : ""
  return <Container style={{
    borderRadius: '10px',
    padding: 0,
    margin: 0,
    width: '792px',
    maxWidth: '792px',
    background: 'var(--background-color)',
    color: 'var(--text-color)'
  }}>
    <Row style={{ padding: '25px 50px', margin: '0' }}>
      <Col style={{
        paddingLeft: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start'
      }}>
        <Row>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{
              fontWeight: 600,
              color: 'var(--text-color)',
              fontSize: '12px', lineHeight: '18px'
            }}>
              {t("Patient")} <b style={{color:"var(--bs-primary)"}}>{props.patient ? ((props.patient.last_name ? (props.patient.last_name + (props.patient.first_name ? ", ": "")) : "") + (props.patient.first_name ? props.patient.first_name: "")): ""}</b>
          </span>
          </Col>
        </Row>

      </Col>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <span style={{
            fontWeight: 500,
            color: 'var(--text-color)',
            fontSize: '14px', lineHeight: '18px'
          }}>
            {t("Submitted")} <b style={{color:"var(--bs-primary)"}}>{dateSubmitted}</b>
          </span>
      </Col>
    </Row>
    <hr style={{ background: 'var(--border-color-faint)', height: '1px', padding:0, marginBottom:"10px", marginTop:0 }}/>
    <Row style={{ padding: '0px 50px 14px 50px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 500,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px',
          marginRight:"5px"
        }}>
          {t("Instructions")}:
        </span>
        {t("Answer each question without anyone else's intervention. If you're not sure of an answer, give your \"best guess\".")}
      </Col>
    </Row>
    {Object.keys(questions).map((q, k) => <Row style={{margin:"0 50px 10px 50px", padding:0}} key={k}>
      <Row style={{marginTop:"20px", marginBottom:"10px", backgroundColor:"var(--background-color-faint)", padding:"3px 10px", borderRadius:"10px"}}>
        <Col style={{margin:0, padding:0}} xs={1}>{q.substring(1)})</Col> <Col style={{paddingLeft:0, marginLeft:0, position:"relative", left:"-30px"}}>{t(questions[q].question)}</Col>
      </Row>
      {questions[q].image ? <Row style={{display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"10px"}}><img style={{justifySelf:"center", maxHeight:"170px", width:"auto", maxWidth:"500px"}} src={t(questions[q].image)} /></Row>: ""}
      {questions[q].options ? <Row>
        {questions[q].options.map((o, i) => <div key={i}>
          <span style={{width:"10px", color:"var(--bs-primary)", fontSize:"15px", position:"relative", visibility: answers[q] && String(i+1) === String(answers[q]) ? "visible": "hidden"}}> {"▶"} </span>{String.fromCharCode(97 + i)}) {t(o)}
          </div>)}
      </Row> : "Answer"}
      {["Q3", "Q8", "Q17", "Q23", "Q28", "Q30"].includes(q) ? <div style={{marginBottom:"50px", marginTop:"50px", width:'100%', textAlign:"center"}}>{t("Page")} {pageNumbers[q]}</div>: <br />}
      {["Q12"].includes(q) ? <div style={{marginBottom:"110px", marginTop:"50px", width:'100%', textAlign:"center"}}>{t("Page")} {pageNumbers[q]}</div>: <br />}
    </Row>)}
    <br />
  </Container>
}