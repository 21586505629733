import {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import { useTranslation } from 'react-i18next'

const ItemTypes = {
    CARD: 'card',
}

const style = {
    minHeight: "62px",
    display: "flex",
    cursor: "move",
    flexDirection: "row",
    alignItems: "center",
    width: "350px",
    borderBottom: "1px solid var(--border-color-fainter)",
    padding: "20px"
};

export const Card = ({id, textDict, updateColumnShow, index, moveCard}) => {
    const ref = useRef(null);
    const {t} = useTranslation();
    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return {id, index};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));
    return (<div ref={ref} style={{...style, opacity}} data-handler-id={handlerId}>
        <svg style={{flex: 1}} width="17" height="10" viewBox="0 0 17 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5L16 1.5" stroke='var(--border-color)' strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 8.5H16" stroke='var(--border-color)' strokeWidth="2" strokeLinecap="round"/>
        </svg>
        <span style={{marginLeft: "16px", flex: 10, textAlign: 'start'}}>{t(textDict.name)}</span>
        <svg onClick={() => updateColumnShow(textDict.key)} style={{
            display: textDict.show ? "block" : "none",
            alignSelf: "end",
            cursor: "pointer",
            textAlign: 'end',
            flex: 1
        }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="2.125" stroke='var(--border-color)' strokeWidth="1.75"/>
            <path
                d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z"
                stroke="var(--border-color)" strokeWidth="1.75"/>
        </svg>
        <svg onClick={() => updateColumnShow(textDict.key)} style={{
            display: textDict.show ? "none" : "block",
            alignSelf: "end",
            cursor: "pointer",
            textAlign: 'end',
            flex: 1
        }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.9202 12.7988C15.9725 12.5407 16 12.2736 16 12C16 9.79086 14.2091 8 12 8C11.7264 8 11.4593 8.02746 11.2012 8.07977L15.9202 12.7988ZM8.66676 9.78799C8.24547 10.4216 8 11.1821 8 12C8 14.2091 9.79086 16 12 16C12.8179 16 13.5784 15.7545 14.212 15.3332L8.66676 9.78799Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.9975 11.8761C14.9342 10.3177 13.6822 9.06571 12.1238 9.00244L14.9975 11.8761ZM9.39307 10.5143C9.14294 10.9523 9 11.4594 9 11.9999C9 13.6568 10.3431 14.9999 12 14.9999C12.5405 14.9999 13.0476 14.857 13.4856 14.6069L12.1227 13.244C12.0824 13.2479 12.0414 13.2499 12 13.2499C11.3096 13.2499 10.75 12.6903 10.75 11.9999C10.75 11.9585 10.752 11.9176 10.7559 11.8772L9.39307 10.5143Z"
                  fill="var(--border-color-fainter)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.4286 17.5492L15.1431 16.2637C14.1475 16.7906 13.0859 17.125 12.0007 17.125C10.4384 17.125 8.92489 16.4318 7.58687 15.4691C6.25576 14.5115 5.17206 13.3398 4.48811 12.5094C4.38868 12.3887 4.31287 12.2965 4.24976 12.215C4.18707 12.1341 4.15028 12.0806 4.12748 12.0424C4.11416 12.0201 4.10799 12.0068 4.10547 12.0008L4.10547 12L4.10547 11.9992C4.10799 11.9932 4.11416 11.9799 4.12748 11.9576C4.15028 11.9194 4.18707 11.8659 4.24976 11.785C4.31287 11.7035 4.38868 11.6113 4.48811 11.4906C5.15447 10.6816 6.20026 9.54861 7.48462 8.6052L6.2345 7.35508C4.89049 8.37983 3.81852 9.55093 3.13731 10.378C3.11407 10.4062 3.09016 10.4349 3.06578 10.4642C2.74944 10.844 2.35547 11.317 2.35547 12C2.35547 12.683 2.74944 13.156 3.06578 13.5358C3.09016 13.5651 3.11407 13.5938 3.13731 13.622C3.87345 14.5158 5.0659 15.8113 6.56485 16.8897C8.05689 17.9631 9.92732 18.875 12.0007 18.875C13.6305 18.875 15.1349 18.3116 16.4286 17.5492ZM8.90301 5.78096C9.86126 5.38081 10.9025 5.125 12.0007 5.125C14.0742 5.125 15.9446 6.03685 17.4366 7.11031C18.9356 8.18874 20.128 9.48424 20.8642 10.378C20.8874 10.4062 20.9113 10.4349 20.9357 10.4642C21.2521 10.844 21.646 11.317 21.646 12C21.646 12.683 21.252 13.156 20.9357 13.5358C20.9113 13.5651 20.8874 13.5938 20.8642 13.622C20.3791 14.211 19.6958 14.9744 18.8573 15.7352L17.6182 14.4962C18.4021 13.7924 19.0496 13.0725 19.5134 12.5094C19.6128 12.3887 19.6886 12.2965 19.7517 12.215C19.8144 12.1341 19.8512 12.0806 19.874 12.0424C19.8873 12.0201 19.8935 12.0068 19.896 12.0008L19.896 12L19.896 11.9992C19.8935 11.9932 19.8873 11.9799 19.874 11.9576C19.8512 11.9194 19.8144 11.8659 19.7517 11.785C19.6886 11.7035 19.6128 11.6113 19.5134 11.4906C18.8294 10.6602 17.7457 9.48854 16.4146 8.53086C15.0766 7.56822 13.5631 6.875 12.0007 6.875C11.414 6.875 10.8342 6.97277 10.2679 7.14586L8.90301 5.78096Z"
                  fill="var(--border-color-fainter)"/>
            <path d="M6 3L22 19" stroke="var(--border-color-fainter)" strokeWidth="1.75" strokeLinecap="round"/>
        </svg>

    </div>);
};
