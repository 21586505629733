import React, {useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import Button from "@restart/ui/Button";
import HashLoader from "react-spinners/HashLoader";
import {fetcher} from "../../utils/axios";
import * as Constants from "../../constants/Constants";
import {toast} from "react-toastify";
import Select, { components } from 'react-select';
import 'react-phone-number-input/style.css';
import './InvitePatient.css';
import PhoneInput from 'react-phone-number-input';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useSelector } from 'react-redux'
import { languageSelector, userSelector, organizationSelector } from '../../store/slices/auth'

const reactSelectStyles = {
  container: (base, state) =>({
    ...base,
    width:"100%",
    background: "var(--background-color-faintest)"
  }),
  control: (base, state)=>({
    ...base,
    border: 0,
    borderRadius: 0,
    outline:0,
    boxShadow: "none",
    background: "var(--background-color-faintest)"
  }),
  menu: base => ({
    ...base,
    outline:0,
    zIndex:110
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--border-color-fainter)" :"var(--background-color-faintest)",
    color: "black",
    opacity: state.isFocused ? 0.9: 1,
    height: "45px",
    zIndex:1000
  }),
  indicatorSeparator: (base, state)=>({
    ...base,
    display: "none"
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "var(--text-color)"
  })
};

export default function InvitePatientCard(props) {
  const {t} = useTranslation();
  let lang = useSelector(languageSelector);
  let org = useSelector(organizationSelector);
  let user = useSelector(userSelector);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState(lang === "de" ? "German": "English");
  const [registrationCode, setRegistrationCode] = useState(user.registration_code ? user.registration_code.code : "");
  const [isLoading, setLoading] = useState(false);

  let regCodes = props.registrationCodes;
  // todo change account_type to role
  if (user.account_type === "treating_hcp" && user.registration_code){
    regCodes = regCodes.filter(code => code.code === user.registration_code.code)
  }
  function updateSelection(opt){
    setRegistrationCode(opt.value);
  }

  function checkValidEmail() {
    if (!email) {
      return false;
    }
    let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      toast.error(t("please_enter_a_valid_email_"));
      return false;
    } else {
      return true;
    }
  }

  function sendMessage(){
    if (!checkValidEmail() && !phone){
      toast.error(t("Please provide the email address or phone number"));
      return;
    }
    if(!registrationCode) {
      toast.error(t("Please select a registration code"));
      return;
    }
    if(isLoading){
      return;
    }

    setLoading(true);
    let dataToSend = {invite_code: registrationCode}
    if (email) dataToSend.email = email
    if (phone) dataToSend.phone = phone
    let strategy = [...(dataToSend.phone ? ["SEND_SMS"]: []), ...(dataToSend.email ? ["SEND_EMAIL"]: [])]
    let lang = language === "English" ? "en" : "de"
    var data = JSON.stringify({
      query: `query ($data: String!, $strategy: [String!]) {
                  notification(data: $data, strategy: $strategy, language: "${lang}") {
                      success
                  }
                }`,
      variables: {
        data: JSON.stringify(dataToSend),
        strategy: strategy
      }
    });
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        setLoading(false);
        if(response.errors || !response.data.notification || !response.data.notification.success){
          console.debug("Got Error", response.errors);
          toast.error(t("Unable to send Message"));
          return;
        }
        setEmail("");
        props.closeBox();
        toast.info(t("Invitation sent successfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("Unable to send Invitation"));
      });
  }
  const Placeholder = ({ children, ...props }) => (
    <components.Placeholder {...props}>
      Select
    </components.Placeholder>
  );

  return <Container style={{
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    minWidth: "350px",
    background: "var(--background-color)",
    padding:0,
    borderRadius: "10px",
    boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)",
    textAlign: 'center',
    color: "var(--text-color)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }}>
    <div style={{height: 0, position: "relative", left:0, top:"175px"}}><HashLoader color="#4676F4" loading={isLoading} size={25} /></div>
    <div style={{
      margin: 0,
      textAlign: "center",
      marginBottom: "20px",
      borderRadius: "10px",
    }}>
      <Row style={{padding:"20px"}}>
        <Col style={{textAlign: "start"}}>
          {t("Enter your patient’s phone number or email address to invite them to the program.")}
        </Col>
      </Row>
      <Row style={{padding:"0 20px", textAlign:"start", marginBottom:"10px"}}>
        <Col>
          <span style={{fontWeight: 600}}>{t('SMS Invite')}</span>
        </Col>
      </Row>
      <Row style={{padding:"0 20px", marginBottom:"20px"}}>
        <Col>
          <PhoneInput
            style={{minHeight:"52px", width:"100%", outline:0, border: "1px solid var(--border-color-fainter)", borderRadius:"10px", padding:"10px 12px", margin: "0", resize:"none"}}
            placeholder={t("Enter Phone Number (Mobile)")}
            value={formatPhoneNumberIntl(phone)}
            defaultCountry={org.uid === 'e88c9e2a-6371-429e-ab83-3ead032e356d' ? 'DE' : 'US'}
            useNationalFormatForDefaultCountryValue={true}
            onChange={setPhone}/>
        </Col>
      </Row>
      <Row style={{padding:"0 20px", textAlign:"start", marginBottom:"10px"}}>
        <Col>
          <span style={{fontWeight: 600}}>{t("Email Invite")}</span>
        </Col>
      </Row>
      <Row style={{padding:"0 20px", marginBottom:"20px"}}>
        <Col>
          <input style={{minHeight:"52px", width:"100%", outline:0, border: "1px solid var(--border-color-fainter)", borderRadius:"10px", padding:"10px", margin: "0", resize:"none"}} placeholder={t("Enter Email Address")} value={email} onChange={(e) => !isLoading ? setEmail(e.target.value): ""} />
        </Col>
      </Row>
      <Row style={{padding:"0 20px", textAlign:"start", marginBottom:"10px"}}>
        <Col>
          <span style={{fontWeight: 600}}>{t("Registration Code")}</span>
        </Col>
      </Row>
      <Row style={{padding:"0 20px", marginBottom:"20px"}}>
        <Col>
          {(regCodes && regCodes.length > 0) ?
            <Select styles={reactSelectStyles}
                    isSearchable={false}
                    value={registrationCode ? [{ value: registrationCode, label: registrationCode }]: ""}
                    components={{Placeholder: ({ children, ...props }) => (
                        <components.Placeholder {...props}>
                          {t("Select")}
                        </components.Placeholder>
                      )}}
                    options={regCodes.map(code => {return { label: code.code, value: code.code }})}
                    onChange={(temp) => updateSelection(temp)}
            />
            :
            <input style={{minHeight:"52px", width:"100%", outline:0, border: "1px solid var(--border-color-fainter)", borderRadius:"10px", padding:"10px", margin: "0", resize:"none"}} placeholder={t("Enter Registration Code")} value={registrationCode} onChange={(e) => !isLoading ? setRegistrationCode(e.target.value): ""} />
          }
        </Col>
      </Row>
      <Row style={{padding:"0 20px", textAlign:"start", marginBottom:"10px"}}>
        <Col>
          <span style={{fontWeight: 600}}>{t("Language")}</span>
        </Col>
      </Row>
      <Row style={{padding:"0 20px", marginBottom:"20px"}}>
        <Col>
          <Select styles={reactSelectStyles}
                  isSearchable={false}
                  value={language ? [{ value: language, label: language }]: ""}
                  components={{Placeholder: ({ children, ...props }) => (
                      <components.Placeholder {...props}>
                        {t("Select")}
                      </components.Placeholder>
                    )}}
                  options={["English", ...(org.uid === "e88c9e2a-6371-429e-ab83-3ead032e356d" ? ["German"]: [])].map(lng => {return { label: lng, value: lng }})}
                  onChange={(temp) => setLanguage(temp.value)}
          />
        </Col>
      </Row>
      <div style={{background: "var(--background-color-faint)", height:"1px", width:"100%", margin:"20px 0", padding:0}} />
      <Row style={{fontWeight:600, alignItems:"center", justifyContent:"center", position: "relative", bottom:"0px", padding:"0 20px"}}>
        <Col xs={6}>
          <Button onClick={() => props.closeBox()} variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", border:"1px solid var(--border-color-faint)", background:"var(--background-color)", textAlign:"center", marginLeft:"0", color:"var(--text-color-faint)", fontWeight:600}}>{t("Cancel")}</Button>
        </Col>
        <Col xs={6}>
          <Button onClick={sendMessage} variant="light" style={{minWidth:"150px", height:"44px", padding:"8px", borderRadius:"100px", textAlign:"center", border: "0", marginLeft:"0", background: email || phone ? "var(--bs-primary)": "var(--border-color-faint)", color: "var(--background-color)", fontWeight:600}}>{t("Send")}</Button>
        </Col>
      </Row>
    </div>
  </Container>
}