import Button from "@restart/ui/Button";
import React from "react";
import {useHistory} from "react-router-dom";


export default function BackButton(props){
    let history = useHistory();
    return <Button onClick={() => history.goBack()} className="white-buttons" variant="light" style={{width:"44px", height:"44px", padding:"8px", textAlign:"center", border: "0", marginLeft:"0"}}>
        <svg style={{transform:"rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z" fill="var(--text-color)"/>
        </svg>
    </Button>
}