import React, { useRef, useState } from 'react'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import * as helpers from "../../helpers/helpers";
import * as Constants from "../../constants/Constants";
import { useTranslation } from 'react-i18next';
import { fetcher, reportAction } from '../../utils/axios';
import { uploadToS3 } from '../../utils/files';
import {Button} from 'react-bootstrap';
import "../../pages/AsJourney.css";
import DateTimePicker from 'react-datetime-picker';
import HashLoader from 'react-spinners/HashLoader'
import { useSelector } from 'react-redux'
import { getAccessToken } from '../../store/slices/auth'

const borderOnHover = {
    borderLeft: "2px solid transparent",
    borderRight: "2px solid transparent",
    color: "var(--wave-blue)",
    fontWeight: 600,
    "&:hover": {
        borderLeft: "2px solid #DE6D5E8D",
        borderRight: "2px solid #DE6D5E8D",
    },
    "&:focus": {
        borderLeft: "2px solid #DE6D5E8D",
        borderRight: "2px solid #DE6D5E8D",
    }
}

const reactSelectStyles = {
    container: (base, state) =>({
        ...base,
        width:"100%",
        maxHeight:"32px",
        margin:0,
        padding:0,
        color: "var(--wave-blue)",
        fontWeight: 600,
        background: "var(--background-color-faintest)",
        ...borderOnHover
    }),
    ValueContainer: (provided, state) => ({
        ...provided,
        height: '32px',
        padding: '0 6px',
        color: "var(--wave-blue)",
        fontWeight: 600,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "var(--wave-blue)",
        fontWeight: 600,
        width:"max-content"
    }),
    IndicatorsContainer: (provided, state) => ({
        ...provided,
        height: '32px',
    }),
    control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        margin:0,
        padding:0,
        maxHeight: "32px",
        minHeight: "32px",
        backgroundColor: "transparent"
    }),
    Control: (base, state)=>({
        ...base,
        border: 0,
        borderRadius: 0,
        outline:0,
        boxShadow: "none",
        margin:0,
        minHeight: "32px",
        padding:0,
        backgroundColor: "transparent"
    }),
    menu: base => ({
        ...base,
        outline:0,
        zIndex:110,
        width:"max-content",
        minWidth:"100%"
    }),
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "var(--border-color-fainter)" :"var(--background-color-faintest)",
        color: "var(--text-color)",
        opacity: state.isFocused ? 0.9: 1,
        zIndex:110,
        whiteSpace: "nowrap"
    }),
    indicatorSeparator: (base, state)=>({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: "var(--wave-blue)",
    })
};


export default function TableDivAs(props){
    let token = useSelector(getAccessToken);
    let user_components = helpers.getComponentsFromJwt(token)
    const {t} = useTranslation();
    const [mriUploadRef, updateRef] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);
    if (!props.group.table_rows[props.cr.key][props.cval.key])
        return <td style={{border: "1px solid var(--border-color)"}} />;
    const options = []
    if (props.group.table_rows[props.cr.key][props.cval.key].options)
        props.group.table_rows[props.cr.key][props.cval.key].options.forEach(opt => {
            options.push({value: opt, label: t(opt) + (props.group.table_rows[props.cr.key][props.cval.key].suffix ? " " + t(props.group.table_rows[props.cr.key][props.cval.key].suffix): "")});
        })
    function updateSelection(opt){
        props.updateInput({ target: { value: opt.value, id: "#"+props.group.table_rows[props.cr.key][props.cval.key].key } });
    }
    function deleteMRI(mriUid) {
        setIsLoaded(false);
        let data = JSON.stringify({
            query: `mutation ($user_id: String!, $mri_uid: String!) {
                            mri_delete(uuid: $user_id, mri_uid: $mri_uid){
                                status
                            }
                        }`,
            variables: {user_id: props.patient_id, mri_uid: mriUid}
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              if (!response.errors && response.data && response.data.mri_delete) {
                  toast.info("Deleting MRI initiated")
                  setTimeout(() => props.refreshPage(), 5000);
              } else {
                  setIsLoaded(true);
                  throw Error("Error delete MRI");
              }
          })
          .catch((err) => {
              setIsLoaded(true);
              toast.error("Error delete MRI");
          });
    }
    async function uploadFile(fileObject, dataKey){
        // const [type] = fileObject.type.split('/');
        // if (!type || type !== 'image') {
        //     toast.error("You can only upload image files.");
        //     return;
        // }
        setIsLoaded(false);
        if (fileObject.length > 0){
            const fileType = fileObject[0].type.split('/');
            if (fileType.length > 1  && fileType[1] === "pdf"){
                const fp = await uploadToS3({fileType: "application/pdf", fileContents: fileObject[0]});
                setTimeout(() => updateTaskInformation(fp, dataKey), 5000);
                return
            }
        }

        const zip = require('jszip')();
        for (let file = 0; file < fileObject.length; file++) {
            // Zip file with the file name.
            zip.file(fileObject[file].name, fileObject[file]);
        }
        zip.generateAsync({type: "blob"}).then(async (content) => {
            const fp = await uploadToS3({fileType: "application/zip", fileContents: content});
            setTimeout(() => updateTaskInformation(fp, dataKey), 5000);
        }).catch(err => {
            setIsLoaded(true);
            toast.error(t("Error, Please contact Admin")+ ", " + t("Unable to upload file") + ".");
        });

        if (!isLoaded) {
            toast.error(t("Please Wait"))
            return;
        }
    }
    async function uploadMriFiles(fileObject){
        // const [type] = fileObject.type.split('/');
        // if (!type || type !== 'image') {
        //     toast.error("You can only upload image files.");
        //     return;
        // }
        setIsLoaded(false);

        const zip = require('jszip')();
        for (let file = 0; file < fileObject.length; file++) {
            // Zip file with the file name.
            zip.file(fileObject[file].name, fileObject[file]);
        }
        let reportedOutcomes = {}
        reportAction("ACTION_UPLOAD_MRI_TO_S3", props.patient_id, `S3 Upload Started At ${Math.floor(Date.now()/1000)}`)
        zip.generateAsync({type: "blob"}).then(async (content) => {
            let startTime = Date.now()
            let lastTime = startTime
            let lastProgress = 0
            let speeds = []
            setIsLoaded(true);
            const fp = await uploadToS3({fileType: "application/zip", fileContents: content}, (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                props.updateMriUploadProgress(progress);
                let currentTime = Date.now()
                // console.debug('MRI progress', progress)
                try{
                    let currentInterval = Math.floor(progress / 10)
                    if (!reportedOutcomes?.[`${currentInterval}`]){
                        reportedOutcomes[`${currentInterval}`] = true
                        let speed = (progressEvent.loaded-lastProgress)/(currentTime-lastTime)
                        speeds.push(speed)
                        // console.log("Current speed", speed, "kbps")
                        // console.log("Avg speed", avg(speeds), "kbps")
                        reportAction(`ACTION_UPLOAD_MRI_TO_S3_${progress?.toFixed()}_PERCENT`, props.patient_id, `CurrentSpeed_${speed.toFixed(2)}kbps`)
                        lastTime = currentTime
                        lastProgress = progressEvent.loaded
                    }
                }
                catch (err){
                    console.error("Error report MRI Progress", err)
                }

            }, "raw_mri");
            reportAction(`ACTION_UPLOAD_MRI_TO_S3_COMPLETED`, props.patient_id, `MaxSpeed_${Math.max(...speeds).toFixed(2)}kbps__AvgSpeed_${avg(speeds).toFixed(2)}kbps__TotalTime_${Math.floor((Date.now() - startTime)/1000)}seconds__mri_${fp}`)
            console.debug("MRI Uploaded", fp)
            setTimeout(() => props.uploadMri(fp), 1000);
        }).catch(err => {
            reportAction(`ACTION_CREATE_MRI_ZIP_FAILED`, props.patient_id, `Error Occurred when zipping the folder`)
            setIsLoaded(true);
            toast.error(t("Error, Please contact Admin")+ ", " + t("Unable to upload file") + ".");
        });

        if (!isLoaded) {
            toast.error(t("Please Wait"))
            return;
        }
    }
    function avg(array) {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
            sum += array[i];
        }
        return sum / array.length
    }
    function updateTaskInformation(fp, dataKey){
        let data = JSON.stringify({
            query: `mutation ($taskUid: String!, $data: String) {
                      update_task(uid: $taskUid, data: $data){
                          uid
                      }
                    }`,
            variables: {
                taskUid: dataKey.toLowerCase().includes("mri") ? props.mriReportTaskUid: props.biopsyTaskUid,
                data: JSON.stringify({[dataKey.toLowerCase().includes("mri") ? "mri_report": "biopsy"]: fp})
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              if (!response.errors && response.data && response.data.update_task) {
                  reportAction((dataKey.toLowerCase().includes("mri") ? "ACTION_UPLOADED_MRI_REPORT": "ACTION_UPLOADED_BIOPSY_REPORT"), props.patient_id);
                  toast.info(t("Updated"));
                  props.refreshPage();
              } else {
                  setIsLoaded(true);
                  console.debug("Failed updating", response);
                  toast.error(t("Failed to update"))
              }
          })
          .catch((err) => {
              setIsLoaded(true);
              console.debug(err, "error updating");
              toast.error(t("Unable to upload file"));
          });
    }

    function getInputBasedOnDataType(comp){
        if (comp.key.includes("img_material_")){
            let imgType = comp.key.substring("img_material_".length)
            imgType = imgType.substring(0, imgType.indexOf("_"))
            if (imgType === "dce" && props.inputs["mqr_scan_type"] && props.inputs["mqr_scan_type"].toLowerCase() === "biparametric" || comp.key.includes("dummy_key")){
                return <span style={{paddingLeft:"10px"}}>{t("N/A")}</span>;
            }
        }
        if (comp.key.includes("re_eval_mp_mri_part_two_mr_pi_score_")){
            if (!props.inputs["number_of_pi_rads_lesions"]){
                return "";
            }
            if (props.inputs["number_of_pi_rads_lesions"] === "None" || (props.inputs["number_of_pi_rads_lesions"] && parseInt(comp.key.substring(37, 38)) > parseInt(props.inputs["number_of_pi_rads_lesions"]))){
                return "";
            }
            if (props.inputs["mqr_scan_type"] && props.inputs["mqr_scan_type"].toLowerCase() === "biparametric" && comp.key.includes("dce")){
                return <span style={{paddingLeft:"10px"}}>{t("N/A")}</span>;
            }
        }
        let readOnlyField = !comp.permissions.includes("w") || props.locked;
        if (readOnlyField && !props.locked){
            if (["psa_s_psa_level_0", "psa_s_date_of_test_0"].includes(comp.key)){
                if (!comp.suffix){
                    comp.suffix = "Enter"
                }
                readOnlyField = false
            }
        }
        switch(comp.data_type){
            default:
                return <><span style={{width: comp.suffix ? "": "100%"}} className={"table_input_text"}>{props.inputs[comp.key]}</span>
                    { comp.suffix ?
                  <span>{comp.suffix}</span> : ""
                }</>
            case "date":
                if (readOnlyField){
                    // if(props.inputs[comp.key])
                    //     return <span style={{width:"100%"}} className={"table_input_text"}>{props.inputs[comp.key]}</span>
                    return <span style={{width:"100%"}} className={"table_input_text"}>{![0, "0", null, undefined, ""].includes(props.inputs[comp.key]) ? helpers.getFormattedDate(((typeof props.inputs[comp.key] === "number" && props.inputs[comp.key] > 0) || (!props.inputs[comp.key].includes("-") && !props.inputs[comp.key].includes(" ")) ? parseInt(props.inputs[comp.key]) :props.inputs[comp.key]), "DD MMM YY", t): ""}</span>
                }
                let dateValue = null;
                try{
                    if (props.inputs[comp.key] && typeof props.inputs[comp.key] === "string" && !props.inputs[comp.key].includes(" ")){
                        dateValue = new Date(0);
                        dateValue.setUTCSeconds(parseInt(props.inputs[comp.key]));
                    }
                    else if (props.inputs[comp.key])
                        dateValue = new Date(props.inputs[comp.key]);
                }
                catch (err){
                    dateValue = null;
                }

                return <span style={{marginLeft:"15px", fontWeight:600, color:"var(--wave-blue)"}}>{!["Invalid date", "Fecha inválida", "Ungültiges Datum"].includes(helpers.getFormattedDate(dateValue, "DD MMM ", t)) ? helpers.getFormattedDate(dateValue, "DD MMM ", t) : "-- ---"} <DateTimePicker className={"table_input_text"} style={{width:"100%", ...borderOnHover, border:0}} format={"yyyy"} clearIcon={null} maxDate={new Date()} onChange={(value) => {
                    props.updateInput({
                        target: {
                            value: `${comp.key === "psa_s_date_of_test_0" ? Math.floor(new Date(value).getTime()/1000): value}`,
                            id: '#' + comp.key
                        }
                    })
                }} value={dateValue} id={"#" + comp.key} /></span>;
                // return <input type={"date"} style={{width:"100%", ...(readOnlyField ? {} : borderOnHover)}} className={"table_input_text"} placeholder={!comp.permissions.includes("w") || props.locked ? "" :comp.placeholder} value={props.inputs[comp.key]} id={"#" + comp.key} onChange={(e) => props.updateInput(e)} readOnly={!comp.permissions.includes("w") || props.locked} />
            case "dropdown-text":
                let placeholder = Boolean(comp.key.includes("img_material_") && props.inputs && props.inputs["default_value_img_material"]) ? props.inputs["default_value_img_material"] : (comp.placeholder ? comp.placeholder: 'Select');
                if (props.locked && comp.key.includes("img_material_")) return <span style={{width:"100%"}} className={"table_input_text"}>{props.inputs[comp.key] ? t(props.inputs[comp.key]) : t(props.inputs["default_value_img_material"])}</span>
                if (props.locked) return <span style={{width:"100%"}} className={"table_input_text"}>{t(props.inputs[comp.key])}
                    { comp.suffix ?
                      <span style={{marginLeft:"5px"}}>{t(comp.suffix)}</span> : ""
                    }
                </span>
                return <Select styles={reactSelectStyles}
                        isSearchable={false}
                        value={(comp.key && props.inputs[comp.key]) ? [{ value: props.inputs[comp.key], label: t(props.inputs[comp.key]) + (comp.suffix ? ( " " + comp.suffix) :"")}]: ""}
                        components={{Placeholder: ({ children, ...props }) => (
                              <components.Placeholder {...props}>
                                  <span style={{color:"var(--wave-blue)", fontWeight:600}}>{t(placeholder)}</span>
                              </components.Placeholder>
                            )}}
                        options={options}
                        onChange={(temp) => updateSelection(temp)}
                />
            case "big-text":
                return <span style={{display:"flex", minHeight:"80px"}}><textarea style={{width: comp.suffix ? "": "100%", ...(readOnlyField ? {} : borderOnHover)}} className={"table_input_text"} placeholder={readOnlyField ? "" : t(comp.placeholder)} value={comp.key === "dummy_key" ? t("N/A") : (props.inputs[comp.key] ? props.inputs[comp.key]: "")} id={"#" + comp.key} onChange={(e) => props.updateInput(e)} readOnly={readOnlyField} />
                    { comp.suffix ?
                      <span>{t(comp.suffix)}</span> : ""
                    }</span>
            case "text":
                let textValue = comp.key === "dummy_key" ? t("N/A") :props.inputs[comp.key]
                if(props.inputs[comp.key] && comp.key === "psa_s_psa_level_0"){
                    textValue = textValue.replaceAll(" ng/mL", "")
                    textValue = textValue.replaceAll("ng/mL", "")
                }
                return <span style={{display:"flex", flexDirection:"row", whiteSpace:"nowrap"}}><input type={"text"} style={{width: comp.suffix && props.inputs[comp.key] ? `min(100%, ${(textValue + (comp.suffix ? t(comp.suffix) : "")).length*10.5}px)`: "100%", ...(readOnlyField ? {} : borderOnHover)}} className={"table_input_text"} placeholder={readOnlyField ? "" : t(comp.placeholder)} value={readOnlyField ? t(textValue) :textValue} id={"#" + comp.key} onChange={(e) => props.updateInput(e)} readOnly={readOnlyField} />
                    { comp.suffix && (props.inputs[comp.key] || ["iief_total_score", "iief_erectile_function", "iief_orgasmic_function", "iief_sexual_desire", "iief_intercourse_satisfaction", "iief_overall_satisfaction", "iief_ipss_s_total_score"].includes(comp.key)) ?
                      <span style={{alignSelf:"center", justifySelf:"end", flex:1, marginRight: "5px", padding:0}}>{t(comp.suffix)}</span> : ""
                    }
                    { (props.inputs[comp.key] && comp.key === "psa_s_psa_level_0" && !textValue?.includes("ng/mL")) ?
                      <span style={{alignSelf:"center", justifySelf:"end", flex:1, marginRight: "5px", padding:0}}>{t(comp.suffix || "ng/mL")}</span> : ""
                    }</span>
            case "checkmark":
            case "boolean":
                return <div className={"table_input_text"} style={{cursor:"pointer", fontSize:"18px", height:"37px", width:"37px", background: props.inputs[comp.key] === "1" ? "var(--text-color)": "var(--background-color)",  color: props.inputs[comp.key] === "1" ? "var(--background-color)": "var(--text-color-fainter)"}} onClick={(e) => !props.locked ? props.updateInput({target: {id:("#" + comp.key), value: (props.inputs[comp.key] ? (props.inputs[comp.key] === "1" ? "0" : "1") : "1") } }) : ""} ><span style={{position:"relative", left:"2px", top:"2px"}}>✓</span></div>
            case "number":
                return <span style={{display:"flex", flexDirection:"row"}}><input type={"number"} step={0.01} style={{width: comp.suffix ? "": "100%", flex:2, ...(readOnlyField ? {} : borderOnHover)}} min={comp.options[0]} max={comp.options[1]} className={"table_input_text"} placeholder={!comp.permissions.includes("w") || props.locked ? "" : t("Enter")} value={props.inputs[comp.key]} id={"#" + comp.key}
                                                                                  onChange={(e) => { (comp.options[0] <= e.target.value && e.target.value <= comp.options[1]) ? props.updateInput(e): toast.error("Invalid Value: " + e.target.value)}} readOnly={!comp.permissions.includes("w") || props.locked} />
                    { comp.suffix ?
                      <span style={{alignSelf:"center", justifySelf:"end", flex:1, marginRight: "5px"}}>{comp.suffix}</span> : ""
                    }
                </span>
            case "integer":
                return <span style={{display:"flex", flexDirection:"row"}}><input type={"number"} step={1} style={{width: comp.suffix ? "": "100%", flex:2, ...(readOnlyField ? {} : borderOnHover)}} min={comp.options[0]} max={comp.options[1]} className={"table_input_text"} placeholder={!comp.permissions.includes("w") || props.locked ? "" : t("Enter")} value={parseInt(props.inputs[comp.key])} id={"#" + comp.key}
                                                                                  onChange={(e) => { (comp.options[0] <= e.target.value && e.target.value <= comp.options[1]) ? props.updateInput({ ...e, target: {...e.target, id: "#" + comp.key, value: `${parseInt(e.target.value)}`} }): toast.error("Invalid Value: " + e.target.value)}} readOnly={!comp.permissions.includes("w") || props.locked} />
                    { comp.suffix ?
                      <span style={{alignSelf:"center", justifySelf:"end", flex:1, marginRight: "5px"}}>{comp.suffix}</span> : ""
                    }
                </span>
            case "pdf":
                if(props.inputs[comp.key] || props.locked){
                    let reportName = "generic";
                    let reportType = "img"
                    switch (comp.key.toLowerCase()){
                        case "pd_uploads_biopsy_report_data":
                            reportName = "biopsy_pdf";
                            if (props.inputs.biopsy && props.inputs.biopsy.includes("."))
                                reportType = props.inputs.biopsy.substring(props.inputs.biopsy.lastIndexOf(".")+1)
                            break;
                        case "pd_uploads_mri_report_data":
                            reportName = "mri_pdf";
                            if (props.inputs.mri_report && props.inputs.mri_report.includes("."))
                                reportType = props.inputs.mri_report.substring(props.inputs.mri_report.lastIndexOf(".")+1)
                            break;
                    }
                    let MainComponent = <a style={{marginLeft:"10px", color:"var(--text-color)", textDecoration:"underline", display: props.inputs[comp.key] ? "": "none"}} href={Constants.PATIENT_STATUS+ "/" + props.patient_id + Constants.VIEW_REPORT + `?type=${reportType}&report_name=` + reportName + "&patient_id=" + props.patient_id + "&file=" +encodeURIComponent(props.inputs[comp.key])}>{t('View Report')}</a>
                    if (comp.key === "pd_uploads_mri_report_data" && props.inputs["mri_qr_code"]){
                        return <span>{MainComponent}<a style={{marginLeft:"10px", color:"var(--text-color)", textDecoration:"underline"}} href={Constants.PATIENT_STATUS+ "/" + props.patient_id + Constants.VIEW_REPORT + "?type=img&report_name=qr_code&patient_id=" + props.patient_id + "&file=" +encodeURIComponent(props.inputs["mri_qr_code"])}>{t('View QR Code')}</a></span>
                    }
                    return MainComponent
                }
                else{
                    return <input multiple onChange={(e) => uploadFile(e.target.files, comp.key)} style={{marginLeft:"10px", color:"var(--text-color)", display: props.locked ? "none" : ""}} type={'file'} placeholder={"Upload File"} readOnly={props.locked} />
                }
            case "mri":
                    if (!isLoaded) return <div style={{position:"relative", left:"40px"}}><HashLoader color="#4676F4"
                                                      loading={true}
                                                           size={15}/></div>
                return props.inputs.mri_uid ? <>
                    {
                        (user_components?.["ohif_v3"] || 0) ?
                          <>
                              <a
                                  onClick={() => reportAction("VIEWED_MRI", props.patient_id)}
                                  href={process.env.REACT_APP_BASE_URL + "/mri-viewer/viewer/" + props.inputs.mri_uid}
                                  className={'table_input_text'}
                                  target={"_blank"}
                                  style={{ textDecoration: "underline", width:"100%", display:"none" }}>{t("View MRI")}<sup>v2</sup></a>
                              <a
                                  onClick={() => reportAction("VIEWED_MRI", props.patient_id)}
                                  href={process.env.REACT_APP_BASE_URL + "/mri-viewer/v3?StudyInstanceUIDs=" + props.inputs.mri_uid}
                                  className={'table_input_text'}
                                  target={"_blank"}
                                  style={{ textDecoration: "underline", width:"100%" }}>{t("View MRI")}<sup>v3</sup></a>
                          </>
                    :
                        <a
                        onClick={() => reportAction("VIEWED_MRI", props.patient_id)}
                        href={process.env.REACT_APP_BASE_URL + "/mri-viewer/viewer/" + props.inputs.mri_uid}
                        className={'table_input_text'}
                        target={"_blank"}
                        style={{ textDecoration: "underline", width:"100%" }}>{t("View MRI")}</a>
                    }
                      <Button onClick={() => deleteMRI(props.inputs.mri_uid)} variant={'light'} style={{color:"red", display: props.locked ? "none": ""}}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z"
                                  fill={"var(--wave-clay)"}/>
                          </svg>
                      </Button>
                      </> :
                      props.inputs.mriStatus === "upload_processing" ? <span onClick={() => mriUploadRef.click()}
                                                                             className={'table_input_text'} style={{
                          width:"100%",
                          color: "var(--wave-blue)",
                          fontWeight: 600,
                          cursor: "pointer",
                          display:"flex", flexDirection:"row"
                        }}>{t("Processing")}<div style={{position:"relative", left:"20px", top:"10px"}}><HashLoader color="#4676F4"
                                                                                                        loading={true}
                                                                                                        size={15}/></div></span> :
                      <span onClick={() => mriUploadRef.click()}
                            className={'table_input_text'} style={{
                          width:"100%",
                          color: "var(--wave-blue)",
                          fontWeight: 600,
                          cursor: "pointer",
                          display:"flex", flexDirection:"row"
                      }}><input style={{ display: "none" }} type={'file'}
                                ref={inp => updateRef(inp)}
                                webkitdirectory={""} directory={""}
                                onChange={e => uploadMriFiles(e.target.files)} />{props.locked ? "" :
                        (<>{t(props.inputs.uploadStartTime > 0 && props.inputs.uploadProgress > 0 ? "Uploading" :'Upload MRI')} <span onClick={(e) => e.stopPropagation()} className={"tooltip-container-as"} style={{display: props.inputs.mriError && !props.inputs.uploadStartTime && !props.inputs.uploadProgress ? "flex": "none", flexDirection:"row", justifyContent:"center", marginLeft:"10px", marginRight:"10px", alignSelf:"center", alignItems:"center",fontSize:"8px", border:"1px solid var(--wave-clay)", background:"transparent", borderRadius:"7px", width:"14px", height:"14px"}}>i<div style={{position:"relative", width:0, height:0}}><span className={"tooltip-as"}>{props.inputs.mriError}</span></div></span></>)} {props.inputs.uploadStartTime > 0 && props.inputs.uploadProgress > 0 ? `${Math.floor(props.inputs.uploadProgress)}%` : ""}</span>
        }
    }
    return <td colSpan={props.group.table_rows[props.cr.key][props.cval.key].col_span}
               style={{
                   border: "1px solid var(--border-color)",
                   width: `${Math.floor(props.group.table_rows[props.cr.key][props.cval.key].col_width / 12 * 100)}%`,
                   padding:0}} >
        {getInputBasedOnDataType(props.group.table_rows[props.cr.key][props.cval.key])}
    </td>
}