import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../store";
import * as Constants from "../constants/Constants";
import authSlice from "../store/slices/auth";

const ProtectedRoute = (props: RouteProps) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  if (auth.token && auth.account) {
    if (props.path === Constants.LOGIN || props.path === "/") {
      return <Redirect to={Constants.PATIENT_LIST} />;
    }
    return <Route {...props} />;
  }
  else if (!auth.token && auth.account){    // idle time logged out
    return <Route {...props} />;
  }
  else if (!auth.token || !auth.account) {
    if (props.path === Constants.LOGIN)
      return <Route {...props} />;
    if(props.path !== Constants.LOGOUT && props.location && props.location?.pathname){
      dispatch(authSlice.actions.setRedirectAfterLogin(props.location?.pathname));
    }
    return <Redirect to={Constants.LOGIN} />;
  } else {
    return <div>Something went wrong. Please contact Admin.</div>;
  }
};

export default ProtectedRoute;