import { Col, Row, Container } from 'reactstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as helpers from '../../helpers/helpers'

export default function FactPReport (props) {
  const { i18n } = useTranslation()
  function t(trans){
    return i18n.t(trans, { lng: "es" })
  }
  let data = props.report && props.report.data ? (typeof props.report.data === "string" ? JSON.parse(props.report.data): props.report.data) : {}
  let properties = props.report && props.report.properties ? (typeof props.report.properties === "string" ? JSON.parse(props.report.properties): props.report.properties) : {}
  let answers = {}
  const answerList = (data.answers ? data.answers : [])
  answerList.forEach(a => {
    answers[a.question_id] = a.value
  })
  let survey = {
    "sections": [
      {
        "name": "Physical Well-being",
        "tag": "physical"
      },
      {
        "name": "Social/Family Well-being",
        "tag": "social"
      },
      {
        "name": "Emotional Well-being",
        "tag": "emotional"
      },
      {
        "name": "Functional Well-being",
        "tag": "functional"
      },
      {
        "name": "Additional Concerns",
        "tag": "additional"
      }
    ],
    "data": [
      {
        "id": "Q1",
        "question": "I have a lack of energy.",
        "instruction": "",
        "prefix": "GP1",
        "type": "single_choice",
        "section_title": "Physical Well-being (1/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q2",
        "question": "I have nausea.",
        "instruction": "",
        "prefix": "GP2",
        "type": "single_choice",
        "section_title": "Physical Well-being (2/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q3",
        "question": "Because of my physical condition, I have trouble meeting the needs of my family.",
        "instruction": "",
        "prefix": "GP3",
        "type": "single_choice",
        "section_title": "Physical Well-being (3/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q4",
        "question": "I have pain.",
        "instruction": "",
        "prefix": "GP4",
        "type": "single_choice",
        "section_title": "Physical Well-being (4/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q5",
        "question": "I am bothered by side effects of treatment.",
        "instruction": "",
        "prefix": "GP5",
        "type": "single_choice",
        "section_title": "Physical Well-being (5/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q6",
        "question": "I feel ill.",
        "instruction": "",
        "prefix": "GP6",
        "type": "single_choice",
        "section_title": "Physical Well-being (6/7)",
        "section_tag" : "physical",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q7",
        "question": "I am forced to spend time in bed.",
        "instruction": "",
        "prefix": "GP7",
        "type": "single_choice",
        "section_title": "Physical Well-being (7/7)",
        "section_tag" : "physical",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q8",
        "question": "I feel close to my friends.",
        "instruction": "",
        "prefix": "GS1",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (1/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q9",
        "question": "I get emotional support from my family.",
        "instruction": "",
        "prefix": "GS2",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (2/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q10",
        "question": "I get support from my friends.",
        "instruction": "",
        "prefix": "GS3",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (3/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q11",
        "question": "My family has accepted my illness.",
        "instruction": "",
        "prefix": "GS4",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (4/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q12",
        "question": "I am satisfied with family communication about my illness.",
        "instruction": "",
        "prefix": "GS5",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (5/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q13",
        "question": "I feel close to my partner (or the person who is my main support).",
        "instruction": "",
        "prefix": "GS6",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (6/7)",
        "section_tag" : "social",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q14",
        "question": "I am satisfied with my sex life.",
        "instruction": "(Please answer regardless of your current level of sexual activity)",
        "prefix": "GS7",
        "type": "single_choice",
        "section_title": "Social/Family Well-being (7/7)",
        "section_tag" : "social",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q15",
        "question": "I feel sad.",
        "instruction": "",
        "prefix": "GE1",
        "type": "single_choice",
        "section_title": "Emotional Well-being (1/6)",
        "section_tag" : "emotional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q16",
        "question": "I am satisfied with how I am coping with my illness.",
        "instruction": "",
        "prefix": "GE2",
        "type": "single_choice",
        "section_title": "Emotional Well-being (2/6)",
        "section_tag" : "emotional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q17",
        "question": "I am losing hope in the fight against my illness.",
        "instruction": "",
        "prefix": "GE3",
        "type": "single_choice",
        "section_title": "Emotional Well-being (3/6)",
        "section_tag" : "emotional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q18",
        "question": "I feel nervous.",
        "instruction": "",
        "prefix": "GE4",
        "type": "single_choice",
        "section_title": "Emotional Well-being (4/6)",
        "section_tag" : "emotional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q19",
        "question": "I worry about dying.",
        "instruction": "",
        "prefix": "GE5",
        "type": "single_choice",
        "section_title": "Emotional Well-being (5/6)",
        "section_tag" : "emotional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q20",
        "question": "I worry that my condition will get worse.",
        "instruction": "",
        "prefix": "GE6",
        "type": "single_choice",
        "section_title": "Emotional Well-being (6/6)",
        "section_tag" : "emotional",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q21",
        "question": "I am able to work (include work at home).",
        "instruction": "",
        "prefix": "GF1",
        "type": "single_choice",
        "section_title": "Functional Well-being (1/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q22",
        "question": "My work (include work at home) is fulfilling.",
        "instruction": "",
        "prefix": "GF2",
        "type": "single_choice",
        "section_title": "Functional Well-being (2/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q23",
        "question": "I am able to enjoy life.",
        "instruction": "",
        "prefix": "GF3",
        "type": "single_choice",
        "section_title": "Functional Well-being (3/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q24",
        "question": "I have accepted my illness.",
        "instruction": "",
        "prefix": "GF4",
        "type": "single_choice",
        "section_title": "Functional Well-being (4/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q25",
        "question": "I am sleeping well.",
        "instruction": "",
        "prefix": "GF5",
        "type": "single_choice",
        "section_title": "Functional Well-being (5/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q26",
        "question": "I am enjoying the things I usually do for fun.",
        "instruction": "",
        "prefix": "GF6",
        "type": "single_choice",
        "section_title": "Functional Well-being (6/7)",
        "section_tag" : "functional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q27",
        "question": "I am content with the quality of my life right now.",
        "instruction": "",
        "prefix": "GF7",
        "type": "single_choice",
        "section_title": "Functional Well-being (7/7)",
        "section_tag" : "functional",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q28",
        "question": "I am losing weight.",
        "instruction": "",
        "prefix": "C2",
        "type": "single_choice",
        "section_title": "Additional Concerns (1/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q29",
        "question": "I have a good appetite.",
        "instruction": "",
        "prefix": "C6",
        "type": "single_choice",
        "section_title": "Additional Concerns (2/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q30",
        "question": "I have aches and pains that bother me.",
        "instruction": "",
        "prefix": "P1",
        "type": "single_choice",
        "section_title": "Additional Concerns (3/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q31",
        "question": "I have certain parts of my body where I experience pain.",
        "instruction": "",
        "prefix": "P2",
        "type": "single_choice",
        "section_title": "Additional Concerns (4/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q32",
        "question": "My pain keeps me from doing things I want to do.",
        "instruction": "",
        "prefix": "P3",
        "type": "single_choice",
        "section_title": "Additional Concerns (5/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q33",
        "question": "I am satisfied with my present comfort level.",
        "instruction": "",
        "prefix": "P4",
        "type": "single_choice",
        "section_title": "Additional Concerns (6/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q34",
        "question": "I am able to feel like a man.",
        "instruction": "",
        "prefix": "P5",
        "type": "single_choice",
        "section_title": "Additional Concerns (7/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q35",
        "question": "I have trouble moving my bowels.",
        "instruction": "",
        "prefix": "P6",
        "type": "single_choice",
        "section_title": "Additional Concerns (8/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q36",
        "question": "I have difficulty urinating.",
        "instruction": "",
        "prefix": "P7",
        "type": "single_choice",
        "section_title": "Additional Concerns (9/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q37",
        "question": "I urinate more frequently than usual.",
        "instruction": "",
        "prefix": "BL2",
        "type": "single_choice",
        "section_title": "Additional Concerns (10/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q38",
        "question": "My problems with urinating limit my activities.",
        "instruction": "",
        "prefix": "P8",
        "type": "single_choice",
        "section_title": "Additional Concerns (11/12)",
        "section_tag" : "additional",
        "end_of_section": false,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q39",
        "question": "I am able to have and maintain an erection.",
        "instruction": "",
        "prefix": "BL5",
        "type": "single_choice",
        "section_title": "Additional Concerns (12/12)",
        "section_tag" : "additional",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "title": "Very Much",
            "value": 4
          },
          {
            "id": 2,
            "title": "Quite a Bit",
            "value": 3
          },
          {
            "id": 3,
            "title": "Somewhat",
            "value": 2
          },
          {
            "id": 4,
            "title": "A little bit",
            "value": 1
          },
          {
            "id": 5,
            "title": "Not at all",
            "value": 0
          }
        ]
      },
      {
        "id": "Q40",
        "question": "You have completed the Survey!\nPlease indicate whether you completed this survey \"with help from someone\" or \"on your own\".",
        "instruction": "",
        "type": "popup",
        "section_title": "Congratulations!",
        "section_tag": "additional",
        "end_of_section": true,
        "answers": [
          {
            "id": 1,
            "order": "",
            "title": "On My Own",
            "value": 1
          },
          {
            "id": 2,
            "order": "",
            "title": "With Help From Someone",
            "value": 2
          }
        ]
      }
    ]
  }

  let sectionedQuestions = {}
  survey.data.forEach(q => {
    if (sectionedQuestions[q.section_tag])
      sectionedQuestions[q.section_tag] = [...sectionedQuestions[q.section_tag], q]
    else{
      sectionedQuestions[q.section_tag] = [q]
    }
  })

  if (sectionedQuestions.additional)
    delete sectionedQuestions.additional

  const answerTitles = [
    "Not at all", "A little bit", "Somewhat", "Quite a bit", "Very Much"
  ]

  let dateSubmitted = properties.completed ? helpers.getFormattedDate(properties.completed, "DD MMM YYYY", t) : "";

  let lastQuestion = survey.data[39]

  return <Container style={{
    borderRadius: '10px',
    padding: 0,
    margin: 0,
    width: '792px',
    maxWidth: '792px',
    background: 'var(--background-color)',
    color: 'var(--text-color)'
  }}>
    <Row style={{ padding: '25px 50px', margin: '0' }}>
      <Col style={{
        paddingLeft: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start'
      }}>
        <Row>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{
              fontWeight: 500,
              color: 'var(--text-color)',
              fontSize: '14px', lineHeight: '18px'
            }}>
              {t("Patient")} <b style={{color:"var(--bs-primary)"}}>{props.patient ? ((props.patient.last_name ? (props.patient.last_name + (props.patient.first_name ? ", ": "")) : "") + (props.patient.first_name ? props.patient.first_name: "")): ""}</b>
          </span>
          </Col>
        </Row>

      </Col>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <span style={{
            fontWeight: 500,
            color: 'var(--text-color)',
            fontSize: '14px', lineHeight: '18px'
          }}>
            {t("Submitted")} <b style={{color:"var(--bs-primary)"}}>{dateSubmitted}</b>
          </span>
      </Col>
    </Row>
    <hr style={{ background: 'var(--border-color-faint)', height: '1px', padding:0, marginBottom:"10px", marginTop:0 }}/>
    <Row style={{ padding: '0px 50px 14px 50px', margin: '0' }}>
      <Col style={{ padding: 0, margin: 0 }}>
        <span style={{
          fontWeight: 600,
          color: 'var(--text-color)',
          fontSize: '14px', lineHeight: '22px',
          marginRight:"5px"
        }}>
          {t("Instructions")}:
        </span>
        {t("Answer each question without anyone else's intervention. If you're not sure of an answer, give your \"best guess\".")}
      </Col>
    </Row>
    {Object.keys(sectionedQuestions).map((section, k) => <Row style={{margin:`0 40px ${k === 1 ? "250px" :"10px"} 40px`, padding:0}} key={k}>
      <Row style={{margin: "20px 10px 0px 10px", padding:"5px 0", backgroundColor: "var(--background-color)"}}>
        <Col xs={7} style={{marginBottom:"10px", padding:"3px", margin:0, justifyItems:"center", display:"flex", flexDirection:"row", alignItems:'center'}}>
          <span style={{paddingLeft:0, marginLeft:0, fontWeight:600}}>{t(sectionedQuestions[section][0].section_title)}</span>
        </Col>
        <Col xs={5} style={{margin:0, padding:0}}>
          <Row style={{margin:0, padding:0}}>
            {["0", "1", "2", "3", "4"].map((o, i) => <Col style={{padding:0, margin:0, textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:'end'}} key={i}>
              <div style={{alignItems:"flex-end", flexDirection:"column", display:"flex", borderRadius:"50%", color:"var(--text-color)", fontSize:"14px", fontWeight:600}}> {t(answerTitles[i])}  </div>
            </Col>)}
          </Row>
        </Col>
        <br />
      </Row>
      {sectionedQuestions[section].map((q, j) => <Row style={{margin: "0 10px 0px 10px", padding:"5px 0", backgroundColor: j%2 === 0 ? "var(--background-color-faint)": "var(--background-color)"}} key={j}>
        <Col xs={7} style={{marginBottom:"10px", padding:"3px", margin:0, justifyItems:"center", display:"flex", flexDirection:"row", alignItems:'center'}}>
          <span style={{width:"20px", marginRight:"20px", marginLeft:"5px", fontSize:"12px"}}>{q.prefix}</span><span style={{paddingLeft:0, marginLeft:0, fontWeight:500, fontSize:"15px"}}>{t(q.question)}<span style={{color:"var(--wave-blue)", fontWeight:600}}>{String(answers[q.id]) === "-1" ? (" [ X ] " + t("Prefer not to answer")): ""}</span></span>
        </Col>
        {q.answers ? <Col xs={5} style={{margin:0, padding:0, display:'flex', flexDirection:"column", justifyContent:"center", alignContent:"center"}}>
          <Row style={{margin:0, padding:0}}>
          {q.answers.reverse().map((o, i) => <Col style={{padding:0, margin:0, textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center"}} key={i}>
            <div style={{width:"25px", height:"25px", justifyItems:"center", justifyContent:"center", alignContent:"center", alignItems:"center", flexDirection:"column", display:"flex", borderRadius:"50%", background: String(answers[q.id]) === String(o.value) ? "var(--bs-primary)": "transparent", color: String(answers[q.id]) === String(o.value) ? "var(--background-color)": "var(--text-color)", fontSize:"15px"}}> {o.value}  </div>
          </Col>)}
          </Row>
        </Col> : ""}
        <br />
      </Row>)}
    </Row>)}

    <Row style={{margin:"40px 50px 10px 50px", padding:0}}>
      <Row style={{marginBottom:"10px", backgroundColor:"var(--background-color-faint)", padding:"3px 10px", borderRadius:"10px"}}>
        <Col style={{paddingLeft:0, marginLeft:0}}>{t("Please indicate whether you completed this survey \"with help from someone\" or \"on your own\".")}</Col>
      </Row>
      <Row>
        {[t("On My Own"), t("With Help From Someone")].map((o, i) => <div key={i}>
          <span style={{width:"10px", color:"var(--bs-primary)", fontSize:"15px", position:"relative", visibility: String(answers["Q40"]) === String(i+1) ? "visible": "hidden"}}> {"▶"} </span>{String.fromCharCode(97 + i)}) {o}
        </div>)}
      </Row>
      <br />
    </Row>
    <br />
  </Container>
}