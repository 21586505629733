import './AsJourney.css';
import React from 'react';
import * as Constants from '../constants/Constants';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {Row, Col, Container} from "reactstrap";
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {ReactComponent as DownloadIcon} from '../assets/icons/Download.svg';
import { fetcher, reportAction } from '../utils/axios'
import StageForm from "../components/AsJourneyReport/StageForm";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import AsAssessmentOverview from "../components/PatientProfile/AsAssessmentOverview";
import AsAssessmentStatusRow from "../components/PatientProfile/AsAssessmentStatusRow";
import * as helpers from '../helpers/helpers';
import ReactToPrint from "react-to-print";
import debounce from 'lodash.debounce';


class AsJourneyReportPrint extends React.Component {
  componentRef = null;
  printButtonRef = null;
  constructor(props) {
    super(props);
    this.state = {
      error: this.props.t("Unable to load the Page"),
      isLoaded: Boolean(this.props.asJourney),
      pageNumber: 1,
      numPages: null,
      report: null,
      showAsJourneyList: false,
      showOverlay: false,
      fetchData: true,
      defaultAsJourneyList: !this.props.removeAsJourneyList,
      messagePatient: this.props.messagePatientButton,
      showMessagePatientCard: false,
      reportList: [],
      colorTheme: "light",
      asJourney: this.props.asJourney ? this.props.asJourney : {stages:[{},{},{},{}]},
      uploadProgress: 0,
      mriUid: null,
      patient: {uuid: this.props.match.params.patientIdentifier},
      showPrintVersion: false,
      uploadStartTime: 0
    };
    this.hideOverlay = this.hideOverlay.bind(this);
    this.getInputs = this.getInputs.bind(this);
    this.fileUploadRef = React.createRef();
    this.patientDataRef = React.createRef();
    this.expertUrologistOneRef = React.createRef();
    this.expertUrologistTwoRef = React.createRef();
    this.expertRadiologistRef = React.createRef();
    this.AsJourneyPrintRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.isLoaded) {
      return;
    }
    reportAction("DOWNLOADED_AS_JOURNEY", this.props.match.params.patientIdentifier)
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.state.isLoaded) {
      return;
    }
    if (this.state.fetchData) {
      this.setState({fetchData: false});
      this.getMriInfo();
      var data = JSON.stringify({
        query: `query ($patient_id: String!) {
                patient(id: $patient_id){
                    uuid
                    first_name
                    last_name
                    photo
                    gender
                    birthdate
                    autoimmune
                    neurological
                    cancer
                    marital_status
                    education
                    kids
                    relatives
                    chronic_conditions
                    care{
                        expert_radiologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                        expert_urologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                    }
                    as_decision_status{
                        title
                        status
                    }
                    tx_status{
                        title
                        status
                        as_year
                    }
                 
          }
                          journeys(patient_id: $patient_id) {
                              uid
                              state
                              locked
                              as_decision_phase
                              organization_id
                              authors{
                                uuid
                                first_name
                                last_name
                                photo
                                account_type
                                speciality
                                campus{
                                    name
                                }
                                contact{
                                    contact_type
                                    phone
                                    email
                                    address{
                                        first_line
                                        unit
                                        zipcode
                                        city
                                    }
                                }
                              }
                              inputs{
                                  key
                                  value
                                  updated_at
                                  author
                                  form_uid
                              }
                              stages{
                                  uid
                                  title
                                  status
                                  editor
                                  editor_type
                                  active
                                  forms{
                                    uid
                                    hide
                                    hide_save_button
                                    submit_key
                                    sub_title
                                    status
                                    structure
                                    authors
                                    updated_at
                                    inputs
                                    task{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                    }
                                  }
                                  tasks{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                        action
                                    }
                              }
                          }
                        }`,
        variables: {
          patient_id: this.props.match.params.patientIdentifier
        }
      });
      fetcher(Constants.GRAPHQL_API, data)
        .then((response) => {
          if (!response.data || !response.data.journeys) {
            console.debug("Got Error", response.errors);
            this.setState({error: this.props.t("Unable to load the Page"), isLoaded: true});
            toast.error(this.props.t("Unable to load the Page"));
            return;
          }
          let patient;
          let personal_data;
          if(response.data.patient){
            patient = {...this.state.patient, ...response.data.patient, ...response.data.patient.care};
            let ed = [];
            if (patient.cancer){
              ed.push(patient.cancer)
            }
            if (patient.neurological){
              ed.push(patient.neurological)
            }
            if (patient.autoimmune){
              ed.push(patient.autoimmune)
            }
            personal_data = {
              pd_birthdate_gender: (patient.birthdate ? (patient.birthdate + ` (${helpers.getAge(patient.birthdate)} yrs)`) : "") + ", " + (patient.gender ? patient.gender : "Male"),
              pd_comorbid_conditions: patient.chronic_conditions ? patient.chronic_conditions.filter(pr => ![null, undefined, ""].includes(pr)).join("; "): "",
              pd_existing_diseases: ed.filter(pr => ![null, undefined, ""].includes(pr)).join("; "),
              pd_family_status: ((patient.marital_status ? patient.marital_status: "") + (![undefined, null].includes(patient.kids) ? `; ${patient.kids} child${patient.kids > 1 ? "ren":""}`:"")),
              pd_family_history: patient.relatives ? ("Prostate Cancer - " + patient.relatives.filter(pr => ![null, undefined, ""].includes(pr)).join(",")): "",
            }
          }
          let assessment = null;
          response.data.journeys.forEach(j => {
            j.stages.map(stage => {
              stage.forms.map(form => {
                form.form = JSON.parse(form.structure);
              })
            })
            if (j.uid === this.props.match.params.asJourneyId) assessment = j;

          })
          if (!assessment) throw Error("Invalid Report ID");
          if (patient.expert_urologist) assessment.expert_urologist = patient.expert_urologist.uuid
          if (patient.expert_radiologist) assessment.expert_radiologist = patient.expert_radiologist.uuid
          let inputs = {};
          let inputs_updated_at = {};
          if(assessment.inputs && assessment.inputs.length > 0){
            assessment.inputs.forEach(inp => {
              inputs[inp.key] = inp.value
              inputs_updated_at[inp.key] = inp.updated_at
            })
          }
          let refs = [this.patientDataRef, this.expertUrologistOneRef, this.expertRadiologistRef, this.expertUrologistTwoRef]
          for(let o = 0; o < 4; o++){
            assessment.stages[o].ref = refs[o]
          }
          this.setState({isLoaded: true, patient: patient, asJourney: assessment, asJourneyList: response.data.journeys, ...inputs, ...personal_data, inputs_updated_at});
          setTimeout(this.printButtonRef.click(), 500);
        })
        .catch((err) => {
          console.debug(err);
          this.setState({error: this.props.t("Unable to load the Page."), isLoaded: true});
          toast.error(this.props.t("Unable to load the Page."));
        });

    }
  }

  openReportListOverlay(toggle) {
    if (toggle) {
      this.setState({
        showAsJourneyList: !this.state.showAsJourneyList,
        showOverlay: !this.state.showAsJourneyList
      });
      return
    }
    this.setState({showAsJourneyList: true, showOverlay: true});
  }

  uploadMri(mriFile, mriTaskUid) {
    if (!this.state.isLoadedMri) {
      toast.info("Please wait");
    }
    this.setState({isLoadedMri: false, uploadStartTime: Math.floor(Date.now() / 1000)});
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.props.auth.token);
    var formdata = new FormData();

    formdata.append("operations", `{\"query\":\"mutation ($file: Upload!, $user_id: String!, $mri_task_uid: String!) { mri_upload(mri_file: $file, uuid: $user_id, mri_task_uid: $mri_task_uid){status}}\",\"variables\":{\"file\":null, \"user_id\":\"${this.props.match.params.patientIdentifier}\",\"mri_task_uid\":\"${mriTaskUid}\"}}`);
    formdata.append("map", "{\"0\": [\"variables.file\"]}");
    formdata.append("0", mriFile, "");

    fetcher(Constants.GRAPHQL_API, formdata, {
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        this.setState({uploadProgress: progress});
      }
    })
      .then(result => {
        if (result.errors) {
          throw Error(result.errors);
        }
        this.setState({isLoadedMri: true});
        toast.info("MRI uploaded successfully.");
        this.getMriInfo();
      })
      .catch(error => {
        console.log(error);
        this.setState({isLoadedMri: true});
        toast.error("Unable to upload, please contact admin");
      });
  }


  getMriInfo() {
    let data = JSON.stringify({
      query: `mutation ($user_id: String!) {
                        resource_access(uuid: $user_id, resource_type:"mri"){
                            mris{
                                uid
                                status
                                error
                                created
                            }
                        }
                    }`,
      variables: {user_id: this.props.match.params.patientIdentifier}
    });
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        if (!response.errors && response.data && response.data.resource_access) {
          if (response.data.resource_access.mris && response.data.resource_access.mris.length > 0) {
            let mri = response.data.resource_access.mris.find(m => m.status === "upload_success")
            if (mri)
              this.setState({
                mriUid: mri.uid,
                pd_uploads_mri_upload_date: mri.created,
                mriStatus: mri.status,
                mriError: mri.error
              })
          }
        } else {
          // throw Error("Error retrieving patient information");
        }
      })
      .catch((err) => {
        this.setState({isLoadedMri: true});
        toast.error("Error retrieving patient information.");
      });
  }
  getInputs(inputs){
    let result = [];
    let keys = inputs ? inputs : Object.keys(this.state.asJourney.inputs ? this.state.asJourney.inputs : {});
    Object.keys(this.state).forEach(item => {
      if (keys.includes(item)){
        result.push({ key: item, value: this.state[item] });
      }
    })
    return result;
  }

  submitForm(frm){
    if(!this.state.isLoaded){
      toast.info("Please wait");
      return;
    }
    this.setState({isLoaded: false});
    let data = JSON.stringify({
      query: `mutation ($journey_input: journey_update_input) {
                      update_journey(journey_input: $journey_input) {
                          uid
                          inputs{
                              key
                              value
                              updated_at
                          }
                      }
                    }`,
      variables: {
        journey_input: {
          patient_id: this.props.match.params.patientIdentifier,
          journey_id: this.props.match.params.asJourneyId,
          form_id: frm.uid,
          data: this.getInputs(frm.inputs)
        }
      }
    });
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        this.setState({isLoaded: true});
        if (!response.errors && response.data && response.data.update_journey && response.data.update_journey.uid) {
          toast.info(this.props.t("Updated"));
          window.location.reload();
        } else {
          throw Error("Error updating form");
        }
      })
      .catch((err) => {
        this.setState({isLoaded: true});
        console.error(err);
        toast.error(this.props.t("Error updating form."));
      });
  }

  submitFormDirect(frm, patientIdentifier, asJourneyId){
    if (frm.inputs.expert_urologist_decision_one || frm.inputs.expert_urologist_decision_one_comments ||
      frm.inputs.expert_urologist_decision_two || frm.inputs.expert_urologist_decision_two_comments ||
      frm.inputs.expert_radiologist_decision || frm.inputs.expert_radiologist_decision_comments){
      return;
    }
    let data = JSON.stringify({
      query: `mutation ($journey_input: journey_update_input) {
                      update_journey(journey_input: $journey_input) {
                          uid
                          inputs{
                              key
                              value
                              updated_at
                          }
                      }
                    }`,
      variables: {
        journey_input: {
          patient_id: patientIdentifier,
          journey_id: asJourneyId,
          form_id: frm.uid,
          data: Object.keys(frm.inputs).map(inp => ({key: inp, value: frm.inputs[inp]}))
        }
      }
    });
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        // this.setState({isLoaded: true});
        if (!response.errors && response.data && response.data.update_journey && response.data.update_journey.uid) {
          // toast.info(this.props.t("Updated"));
          // window.location.reload();
        } else {
          throw Error("Error updating form");
        }
      })
      .catch((err) => {
        // this.setState({isLoaded: true});
        console.error(err);
        // toast.error(this.props.t("Error updating form."));
      });
  }

  hideOverlay() {
    this.setState({showAsJourneyList: false, showOverlay: false})
  }

  reactToPrintContent = () => {
    return this.componentRef;
  };

  render = () => {
    let debounceSubmitFormDirect = debounce(this.submitFormDirect, 1000);
    let mriTaskUid = "";
    if(this.state.asJourney.stages[0].tasks){
      let mriDicomTask = this.state.asJourney.stages[0].tasks.find(t => (t.action ? t.action.toUpperCase() : "") === "UPLOAD_MRI_DICOM");
      if (mriDicomTask && mriDicomTask.uid){
        mriTaskUid = mriDicomTask.uid;
      }
    }
    let journeyRefs = {
      // patientDataRef: this.state.asJourney.stages[0].ref,
      // fileUploadRef: this.state.asJourney.stages[0].ref,
      // expertUrologistOneRef: this.state.asJourney.stages[1].ref,
      // expertRadiologistRef: this.state.asJourney.stages[2].ref,
      // expertUrologistTwoRef: this.state.asJourney.stages[3].ref
    };
    return (
      <Container style={{
        background: "var(--background-color-faint)",
        width:"100%",
        color: "var(--text-color)",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        zIndex: 10000
      }}>
        {/*<div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"*/}
        {/*                                                                                    loading={!this.state.isLoaded}*/}
        {/*                                                                                    size={35}/></div>*/}
        <ReactToPrint
          content={this.reactToPrintContent}
          documentTitle="report"
          onAfterPrint={() => this.props.history.goBack()}
          trigger={() => <Button
            ref={(ref) => this.printButtonRef = ref}
            variant="light" style={{
            marginRight: 0,
            display: "None"
          }}>
            <DownloadIcon style={{position: "relative", top: "-3px"}} fill={"var(--text-color)"}
                          stroke={"var(--text-color)"}/>
          </Button>}
        />
        {this.state.isLoaded && this.state.asJourney && this.state.asJourney.stages[0] && this.state.asJourney.stages[0].forms ?
          <div ref={(ref) => {this.componentRef = ref}}>
            <div style={{
              marginRight: "0",
              marginLeft: "0",
              maxWidth: "calc(max(min(100vw, 1800px), 1440px))",
              width: "842px",
              marginBottom: "20px",
              background: "var(--background-color)",
              boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "10px",
              padding: 0
            }}
            >
              <div style={{margin:0, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom:"1px solid var(--border-color-faintest)", padding:"20px"}}>
                <div style={{}}>
                  <AsAssessmentStatusRow assessment={{ ...this.state.asJourney, tx_status: this.state.patient.tx_status }} patient={this.state.patient} expansions={{}} hideExpansionArrow={true} />
                </div>
              </div>
              <div style={{margin:0, padding:"0 20px"}}>
                <div style={{ margin: '0', padding: '20px' }}>
                  <AsAssessmentOverview
                    assessment={{ ...this.state.asJourney, inputs: { ...this.state } }}
                    backgroundColor={'transparent'}
                    patientIdentifier={this.props.match.params.patientIdentifier}
                    goToRef={(toPart) => journeyRefs[toPart] ? journeyRefs[toPart].current.scrollIntoView({
                      behavior: 'smooth',
                      inline: 'nearest'
                    }) : ''}
                  />
                </div>
                {this.state.asJourney.stages.slice(0,4).map((stage, j) =>
                  <div><div key={j} style={{margin: "0 0 20px 0", background: "var(--background-color-faintest)", padding: "20px"}}>
                    <span className={"section_title"}>{this.props.t(stage.title)}</span>
                    {
                      stage && stage.forms ?
                        stage.forms.map((frm, i) =>
                          frm && !frm.hide ? <><StageForm key={i}
                                                        ref={stage.ref} inputs={this.state} patient_id={this.props.match.params.patientIdentifier}
                                                        asJourney={this.state.asJourney} frm={frm} stageIndex={j}
                                                        locked={true} editor={stage.editor} editorType={stage.editor_type}
                                                        uploadMri={(e) => this.uploadMri(e.target.files[0], mriTaskUid)}
                                                        inputs_updated_at={this.state.inputs_updated_at}
                                                        updateInput={(e) => {
                                                          if (!e.target.id.substring(1).includes('psa_s_prostate_density_')){
                                                            this.setState({ [e.target.id.substring(1)]: e.target.value });
                                                            debounceSubmitFormDirect({ uid: frm.uid, inputs:{ [e.target.id.substring(1)]: e.target.value }}, this.props.match.params.patientIdentifier, this.props.match.params.asJourneyId);
                                                          }
                                                        }
                                                        }
                                                         onClick={() => this.submitForm(frm)}/>
                            {["mri_overall_rating","mri_overall_rating2"].includes(frm.submit_key) ? <div className="pagebreak" style={{height:"20px"}} />: ""}
                            {["iief_ipss_s_"].includes(frm.submit_key) ? <div style={{height:"20px"}} />: ""}
                          </>: "")
                        : ""
                    }
                  </div>
                    {j === 3 ? "": <div className="pagebreak" style={{ height: '20px' }}/>}
                  </div>) }
              </div>
            </div>
          </div> :
          ""
        }


      </Container>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    auth: dispatch.auth,
  };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(AsJourneyReportPrint)));