import React, { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as helpers from "../helpers/helpers";
import PageNotFound from "./PageNotFound";
import { reportAction } from '../utils/axios'

const pageNotFoundRef = "0c197ce00aee";

class PatientMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HarComponent: "Loading..."
        }
        // TODO Translations
    }

    componentDidMount() {
        reportAction("VIEWED_PATIENT_METRICS");
        let HarComponent = PageNotFound; // TODO TTI default
        if (Boolean(this.props.auth)) {
            let components = helpers.getComponentsFromJwt(this.props.auth.token);
            HarComponent = React.lazy(() =>
              import(`../components/PatientMetrics/${components.patient_metrics || "PatientMetrics"}.js`)
            );
        }
        this.setState({HarComponent: HarComponent});
    }

    render = () => {
        const HarComponent = this.state.HarComponent;
        return <Suspense fallback={<div>Loading...</div>}><HarComponent referenceCode={pageNotFoundRef} /></Suspense>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(PatientMetrics)));