import './AsJourney.css';
import React from 'react';
import * as Constants from '../constants/Constants';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {Row, Col, Container} from "reactstrap";
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {ReactComponent as DownloadIcon} from '../assets/icons/Download.svg';
import { fetcher, reportAction } from '../utils/axios'
import StageForm from "../components/AsJourneyReport/StageForm";
import {toast} from "react-toastify";
import HashLoader from "react-spinners/HashLoader";
import {withTranslation} from "react-i18next";
import MessagePatientCard from "../components/PatientProfile/MessagePatient";
import AsAssessmentOverview from "../components/PatientProfile/AsAssessmentOverview";
import AsAssessmentStatusRow from "../components/PatientProfile/AsAssessmentStatusRow";
import * as helpers from '../helpers/helpers';
import debounce from 'lodash.debounce';


class AsJourneyReport extends React.Component {
    componentRef = null;
    constructor(props) {
        super(props);
        let urlQueryParams = new URLSearchParams(this.props.location.search)
        this.state = {
            error: this.props.t("Unable to load the Page"),
            isLoaded: false,
            pageNumber: 1,
            numPages: null,
            report: null,
            showAsJourneyList: false,
            showOverlay: false,
            fetchData: true,
            defaultAsJourneyList: !this.props.removeAsJourneyList,
            messagePatient: this.props.messagePatientButton,
            showMessagePatientCard: false,
            reportList: [],
            colorTheme: urlQueryParams.get("theme") ? urlQueryParams.get("theme"): "light",
            asJourney: {stages:[{},{},{},{}]},
            uploadProgress: 0,
            mriUid: null, mriProgress:0, mriFileSize:1,
            patient: {uuid: this.props.match.params.patientIdentifier},
            showPrintVersion: false,
            uploadStartTime: 0
        };
        this.hideOverlay = this.hideOverlay.bind(this);
        this.getInputs = this.getInputs.bind(this);
        this.fileUploadRef = React.createRef();
        this.patientDataRef = React.createRef();
        this.expertUrologistOneRef = React.createRef();
        this.expertUrologistTwoRef = React.createRef();
        this.expertRadiologistRef = React.createRef();
        this.AsJourneyPrintRef = React.createRef();
    }

    componentDidMount() {
        localStorage.setItem("MRI_STATUS", "NA");
        if (this.state.isLoaded) {
            return;
        }
        reportAction("VIEWED_AS_JOURNEY", this.props.match.params.patientIdentifier)
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            return;
        }
        if (this.state.fetchData) {
            this.setState({fetchData: false});
            this.getMriInfo();
            var data = JSON.stringify({
                query: `query ($patient_id: String!) {
                patient(id: $patient_id){
                    uuid
                    first_name
                    last_name
                    photo
                    gender
                    birthdate
                    age
                    autoimmune
                    neurological
                    cancer
                    marital_status
                    education
                    kids
                    relatives
                    chronic_conditions
                    profile_survey_as_submitted_at
                    care{
                        expert_radiologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                        expert_urologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                    }
                    as_decision_status{
                        title
                        status
                    }
                    tx_status{
                        title
                        status
                        as_year
                    }
                 
          }
                          journeys(patient_id: $patient_id) {
                              uid
                              state
                              locked
                              as_decision_phase
                              organization_id
                              created_at
                              authors{
                                uuid
                                first_name
                                last_name
                                photo
                                account_type
                                speciality
                                campus{
                                    name
                                }
                                contact{
                                    contact_type
                                    phone
                                    email
                                    address{
                                        first_line
                                        unit
                                        zipcode
                                        city
                                    }
                                }
                              }
                              inputs{
                                  key
                                  value
                                  updated_at
                                  author
                                  form_uid
                              }
                              stages{
                                  uid
                                  title
                                  status
                                  editor
                                  editor_type
                                  active
                                  forms{
                                    uid
                                    hide
                                    hide_save_button
                                    submit_key
                                    sub_title
                                    status
                                    structure
                                    authors
                                    updated_at
                                    inputs
                                    task{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                    }
                                  }
                                  tasks{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                        action
                                    }
                              }
                          }
                        }`,
                variables: {
                    patient_id: this.props.match.params.patientIdentifier
                }
            });
            fetcher(Constants.GRAPHQL_API, data)
                .then((response) => {
                    if (!response.data || !response.data.journeys) {
                        console.debug("Got Error", response.errors);
                        this.setState({error: this.props.t("Unable to load the Page"), isLoaded: true});
                        toast.error(this.props.t("Unable to load the Page"));
                        return;
                    }
                    let patient;
                    let personal_data;
                    if(response.data.patient){
                        patient = {...this.state.patient, ...response.data.patient, ...response.data.patient.care};
                        let ed = [];
                        if (patient.cancer){
                            ed.push(patient.cancer)
                        }
                        if (patient.neurological){
                            ed.push(patient.neurological)
                        }
                        if (patient.autoimmune){
                            ed.push(patient.autoimmune)
                        }
                        personal_data = {
                            pd_birthdate_gender: (patient.birthdate ? (helpers.getFormattedBirthdate(patient.birthdate, this.props.t) + ` (${helpers.getAge(patient.birthdate)} ${this.props.t("yrs")}),`) : "") + this.props.t(patient.gender ? patient.gender : "Male"),
                            pd_age_gender: (patient.age ? (patient.age + ", ") : "") + this.props.t(patient.gender ? patient.gender : "Male"),
                            pd_comorbid_conditions: patient.chronic_conditions ? patient.chronic_conditions.filter(pr => ![null, undefined, ""].includes(pr)).map(p => this.props.t(p)).join("; "): "",
                            pd_existing_diseases: ed.filter(pr => ![null, undefined, ""].includes(pr)).map(p => this.props.t(p)).join("; "),
                            pd_family_status: ((patient.marital_status ? this.props.t(patient.marital_status): "") + (![undefined, null].includes(patient.kids) ? `; ${patient.kids} ${this.props.t(`child${patient.kids > 1 ? 'ren' : ''}`)}`:"")),
                            pd_family_history: patient.relatives ? (`${this.props.t("Prostate Cancer")} - ` + patient.relatives.filter(pr => ![null, undefined, ""].includes(pr)).map(p => this.props.t(p)).join(",")): "",
                        }
                    }
                    let assessment = null;
                    response.data.journeys.forEach(j => {
                        j.stages.map(stage => {
                            stage.forms.map(form => {
                                form.form = JSON.parse(form.structure);
                            })
                        })
                        if (j.uid === this.props.match.params.asJourneyId) assessment = j;

                    })
                    if (!assessment) throw Error("Invalid Report ID");
                    if (patient.expert_urologist) assessment.expert_urologist = patient.expert_urologist.uuid
                    if (patient.expert_radiologist) assessment.expert_radiologist = patient.expert_radiologist.uuid
                    let inputs = {};
                    let inputs_updated_at = {};
                    if(assessment.inputs && assessment.inputs.length > 0){
                        assessment.inputs.forEach(inp => {
                            inputs[inp.key] = inp.value
                            inputs_updated_at[inp.key] = inp.updated_at
                        })
                    }
                    let refs = [this.patientDataRef, this.expertUrologistOneRef, this.expertRadiologistRef, this.expertUrologistTwoRef]
                    for(let o = 0; o < 4; o++){
                      assessment.stages[o].ref = refs[o]
                    }
                    this.setState({isLoaded: true, patient: patient, asJourney: assessment, asJourneyList: response.data.journeys, ...inputs, ...personal_data, inputs_updated_at});
                })
                .catch((err) => {
                    console.debug(err);
                    this.setState({error: this.props.t("Unable to load the Page."), isLoaded: true});
                    toast.error(this.props.t("Unable to load the Page."));
                });

        }
    }

    openReportListOverlay(toggle) {
        if (toggle) {
            this.setState({
                showAsJourneyList: !this.state.showAsJourneyList,
                showOverlay: !this.state.showAsJourneyList
            });
            return
        }
        this.setState({showAsJourneyList: true, showOverlay: true});
    }

    uploadMri(mriFile, mriTaskUid) {
        if (!this.state.isLoadedMri) {
            toast.info("Please wait");
        }
        this.setState({isLoadedMri: false, uploadStartTime: Math.floor(Date.now() / 1000)});
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.auth.token);
        localStorage.setItem("MRI_STATUS", "UPLOADING")

        var data = JSON.stringify({
            query: `mutation ($file_name: String, $patient_id: String!, $mri_task_uid: String){ 
                mri_upload(mri_s3_file_name: $file_name, uuid: $patient_id, mri_task_uid: $mri_task_uid){
                    status
                }
            }`,
            variables: {
                patient_id: this.props.match.params.patientIdentifier,
                mri_task_uid: mriTaskUid,
                file_name: mriFile
            }
        });

        fetcher(Constants.GRAPHQL_API, data)
          .then(result => {
              if (result.errors) {
                  throw Error(result.errors);
              }
              setTimeout(function(){
                  localStorage.setItem("MRI_STATUS", "UPLOADED")
              }, 5000);
              this.setState({isLoadedMri: true, uploadStartTime: 0, uploadProgress: 0, mriStatus: "upload_processing"});
              toast.info("MRI uploaded successfully.");
              this.getMriInfo();

          })
          .catch(error => {
              console.error(error);
              setTimeout(function(){
                  localStorage.setItem("MRI_STATUS", "UPLOAD_FAILED")
              }, 5000);

              this.setState({isLoadedMri: true});
              toast.error("Unable to upload, please contact admin");
          });
    }


    getMriInfo() {
        let data = JSON.stringify({
            query: `mutation ($user_id: String!) {
                        resource_access(uuid: $user_id, resource_type:"mri"){
                            mris{
                                uid
                                status
                                error
                                created
                                progress
                            }
                        }
                    }`,
            variables: {user_id: this.props.match.params.patientIdentifier}
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              if (!response.errors && response.data && response.data.resource_access) {
                  if (response.data.resource_access.mris && response.data.resource_access.mris.length > 0) {
                      let mri = response.data.resource_access.mris.find(m => m.status === "upload_success")
                      if (mri) {
                          this.setState({
                              mri_uid: mri.uid,
                              pd_uploads_mri_upload_date: mri.created,
                              mriStatus: mri.status,
                              mriError: mri.error
                          })
                          return;
                      }
                      let statusObjMri = response.data.resource_access.mris.find(m => m.uid === "");
                      if (statusObjMri){
                          this.setState({
                              mriStatus: statusObjMri.status,
                              mriError: statusObjMri.error,
                              mriProgress: statusObjMri.progress
                          })
                          if (statusObjMri.status === "upload_processing"){
                              setTimeout(() => this.getMriInfo(), 5000)
                          }
                      }
                  }
              } else {
                  // throw Error("Error retrieving patient information");
              }
          })
          .catch((err) => {
              console.debug("Error getting mri", err)
              this.setState({isLoadedMri: true});
              // toast.error("Error retrieving patient information.");
          });
    }
    getInputs(inputs){
        let result = [];
        let keys = inputs ? inputs : Object.keys(this.state.asJourney.inputs ? this.state.asJourney.inputs : {});
        Object.keys(this.state).forEach(item => {
            if (keys.includes(item)){
                result.push({ key: item, value: this.state[item] });
            }
        })
        return result;
    }

    submitForm(frm){
        if(!this.state.isLoaded){
            toast.info("Please wait");
            return;
        }
        this.setState({isLoaded: false});
        let data = JSON.stringify({
            query: `mutation ($journey_input: journey_update_input) {
                      update_journey(journey_input: $journey_input) {
                          uid
                          inputs{
                              key
                              value
                              updated_at
                          }
                      }
                    }`,
            variables: {
                journey_input: {
                    patient_id: this.props.match.params.patientIdentifier,
                    journey_id: this.props.match.params.asJourneyId,
                    form_id: frm.uid,
                    data: this.getInputs(frm.inputs)
                }
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              this.setState({isLoaded: true});
              if (!response.errors && response.data && response.data.update_journey && response.data.update_journey.uid) {
                  toast.info(this.props.t("Updated"));
                  window.location.reload();
              } else {
                  throw Error("Error updating form");
              }
          })
          .catch((err) => {
              this.setState({isLoaded: true});
              console.error(err);
              toast.error(this.props.t("Error updating form."));
          });
    }

    submitFormDirect(frm, patientIdentifier, asJourneyId){
        if (frm.inputs.expert_urologist_decision_one || frm.inputs.expert_urologist_decision_one_comments ||
              frm.inputs.expert_urologist_decision_two || frm.inputs.expert_urologist_decision_two_comments ||
              frm.inputs.expert_radiologist_decision || frm.inputs.expert_radiologist_decision_comments){
            return;
        }
        let data = JSON.stringify({
            query: `mutation ($journey_input: journey_update_input) {
                      update_journey(journey_input: $journey_input) {
                          uid
                          inputs{
                              key
                              value
                              updated_at
                          }
                      }
                    }`,
            variables: {
                journey_input: {
                    patient_id: patientIdentifier,
                    journey_id: asJourneyId,
                    form_id: frm.uid,
                    data: Object.keys(frm.inputs).map(inp => ({key: inp, value: frm.inputs[inp]}))
                }
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              // this.setState({isLoaded: true});
              if (!response.errors && response.data && response.data.update_journey && response.data.update_journey.uid) {
                  // toast.info(this.props.t("Updated"));
                  // window.location.reload();
              } else {
                  throw Error("Error updating form");
              }
          })
          .catch((err) => {
              // this.setState({isLoaded: true});
              console.error(err);
              // toast.error(this.props.t("Error updating form."));
          });
    }
    updateTaskPsa(frm){
        if (frm.inputs.expert_urologist_decision_one || frm.inputs.expert_urologist_decision_one_comments ||
              frm.inputs.expert_urologist_decision_two || frm.inputs.expert_urologist_decision_two_comments ||
              frm.inputs.expert_radiologist_decision || frm.inputs.expert_radiologist_decision_comments){
            return;
        }
        let data = JSON.stringify({
            query: `mutation ($data: String) {
                      update_task(uid: "${frm?.task?.uid}", data: $data) {
                          uid
                      }
                    }`,
            variables: {
                data: JSON.stringify({ ...(frm.inputs.psa_s_psa_level_0 ? { level: `${frm.inputs.psa_s_psa_level_0}` } : {}), ...(frm?.inputs?.psa_s_date_of_test_0 ? { date: frm.inputs.psa_s_date_of_test_0 } : {}) })
            }
        });
        fetcher(Constants.GRAPHQL_API, data)
          .then((response) => {
              if (!response.errors && response.data && response.data.update_task && response.data.update_task.uid) {
                  // toast.info(this.props.t("Updated"));
                  // window.location.reload();
              } else {
                  toast.info(this.props.t("Error updating form"));
                  throw Error("Error updating form");
              }
          })
          .catch((err) => {
              // this.setState({isLoaded: true});
              console.error(err);
              // toast.error(this.props.t("Error updating form."));
          });
    }

    hideOverlay() {
        this.setState({showAsJourneyList: false, showOverlay: false})
    }

    reactToPrintContent = () => {
        return this.componentRef;
    };

    render = () => {
        let debounceSubmitFormDirect = debounce(this.submitFormDirect, 1000);
        let mriTaskUid = "";
        if(this.state.asJourney.stages[0].tasks){
            let mriDicomTask = this.state.asJourney.stages[0].tasks.find(t => (t.action ? t.action.toUpperCase() : "") === "UPLOAD_MRI_DICOM");
            if (mriDicomTask && mriDicomTask.uid){
                mriTaskUid = mriDicomTask.uid;
            }
        }
        let journeyRefs = {
            patientDataRef: this.state.asJourney.stages[0].ref,
            fileUploadRef: this.state.asJourney.stages[0].ref,
            expertUrologistOneRef: this.state.asJourney.stages[1].ref,
            expertRadiologistRef: this.state.asJourney.stages[2].ref,
            expertUrologistTwoRef: this.state.asJourney.stages[3].ref
        };
        return (
            <div data-theme={this.state.colorTheme} style={{
                background: "var(--background-color-faint)",
                width:"100%",
                height:"100%",
                minHeight: "100vh",
                color: "var(--text-color)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
                fontWeight: 400,
                fontSize: "14px",
                padding:0,
                margin:0,
                lineHeight: "22px"
            }}>
                <div style={{height: 0, position: "absolute", left: "50%", top: "50%"}}><HashLoader color="#4676F4"
                                                                                                    loading={!this.state.isLoaded}
                                                                                                    size={35}/></div>
                <div onClick={this.hideOverlay} style={{
                    position: "absolute",
                    width: "100%",
                    height: "100vh",
                    background: "rgba(0, 0, 0, 0.05)",
                    left: 0,
                    top: 0,
                    display: this.state.showOverlay ? "block" : "none",
                    zIndex: 80
                }}/>
                <Row style={{
                    marginRight: "0",
                    marginLeft: "0",
                    maxWidth: "calc(max(100vw, 1440px))",
                    width: "842px",
                    marginTop: "40px",
                    marginBottom: "30px"
                }}>
                    <Col className="page-tittle" style={{
                        paddingLeft: "0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "start"
                    }}>
                        <Button onClick={() => this.props.history.goBack()} className="white-buttons-round-wr"
                                variant="light" style={{marginLeft: "0"}}>
                            <svg style={{transform: "rotate(90deg)"}} width="24" height="24" viewBox="0 0 18 18"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z"
                                      fill="var(--border-color)"/>
                            </svg>
                        </Button>
                        <span style={{
                            alignSelf: "center",
                            justifySelf: "center",
                            marginLeft: "15px",
                            fontSize: "18px",
                            lineHeight: "26px"
                        }}>{this.props.reportTitle ? this.props.t(this.props.reportTitle) : this.props.t("Assessment")}</span>
                    </Col>
                    <Col style={{
                        display: this.state.selectionList ? "None" : "flex",
                        flexDirection: "row",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        paddingRight: "5px"
                    }}>
                        {this.state.messagePatient ? <Button
                            onClick={() => this.setState({showMessagePatientCard: !this.state.showMessagePatientCard})}
                            className={"white-buttons-wr"} style={{
                            color: "var(--text-color)",
                            background: "var(--background-color)",
                            fontWeight: 600,
                            padding: "11px 24px",
                            alignSelf: "end",
                            justifySelf: "end",
                            height: "44px",
                        }}>{this.props.t("Message Patient")}</Button> : ""}
                        <div onMouseEnter={() => this.setState({showMessagePatientCard: true})}
                             className="message-patient-container" style={{
                            position: "relative",
                            background: "var(--background-color)",
                            width: "0",
                            height: "0",
                            right: `283px`,
                            top: "64px",
                            display: this.state.showMessagePatientCard ? "block" : "none",
                            zIndex: 100
                        }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "165px", top: "-42px"}}/>
                            <MessagePatientCard patient={{uuid: this.props.match.params.patientIdentifier}} closeMessageBox={() => this.setState({showMessagePatientCard: false})} />
                        </div>
                        {this.state.defaultAsJourneyList && !this.state.showPrintVersion ?
                            <Button onMouseEnter={() => this.openReportListOverlay(false)}
                                    onClick={() => this.openReportListOverlay(true)}
                                    className={this.state.isLoaded && this.state.reportList.length > 0 ? "white-buttons-wr" : ""}
                                    variant="light" style={{
                                padding: "6px 27px",
                                display: this.state.isLoaded && this.state.reportList.length > 0 ? "" : "none"
                            }}>
                                {this.props.t("date")}:<span style={{
                                fontWeight: 400,
                                marginLeft: "5px",
                                width: "max-content"
                            }}><b>{this.props.t("Assessment")}:</b> {this.props.t("Candidate")}</span>
                                <svg style={{justifySelf: "center", alignSelf: "center", marginLeft: "10px"}} width="14"
                                     height="14"
                                     viewBox="0 1 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.46466 4.23025C1.46466 3.97425 1.56266 3.71825 1.75766 3.52325C2.14766 3.13225 2.78066 3.13225 3.17166 3.52325L5.99966 6.35125L8.82866 3.52325C9.21866 3.13225 9.85266 3.13225 10.2427 3.52325C10.6327 3.91425 10.6327 4.54625 10.2427 4.93725L6.70666 8.47225C6.31666 8.86325 5.68366 8.86325 5.29266 8.47225L1.75766 4.93725C1.56266 4.74225 1.46466 4.48625 1.46466 4.23025Z"
                                          fill="var(--border-color)"/>
                                </svg>
                            </Button> : ""}
                        <div onMouseEnter={() => this.setState({showAsJourneyList: true})}
                             onMouseLeave={this.hideOverlay}
                             style={{
                                 position: "relative",
                                 background: "var(--background-color)",
                                 width: "0",
                                 height: "0",
                                 left: `-292px`,
                                 top: "64px",
                                 display: this.state.showAsJourneyList ? "block" : "none",
                                 zIndex: 100
                             }}>
                            <div className="triangle-with-shadow"
                                 style={{position: "absolute", left: "123px", top: "-42px"}}/>
                            <div style={{
                                background: "var(--background-color)",
                                boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                borderRadius: "10px",
                                width: "300px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    justifyItems: "center",
                                    width: "100%"
                                }}>
                                    <Col style={{
                                        minHeight: "52px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "100%",
                                        boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "10px"
                                    }}>
                                        {this.state.asJourneyList ? this.state.asJourneyList.map((rpt, j) => (
                                            <div onClick={() => {
                                                this.props.history.push(`${Constants.PATIENT_STATUS}/${this.props.match.params.patientIdentifier}${Constants.AS_JOURNEY}/${rpt.uid}`);
                                                this.setState({isLoaded: false, fetchData: true});
                                                this.hideOverlay();
                                            }} key={j}
                                                 className={`page-list-titles ${this.props.match.params.asJourneyId === rpt.uid ? "page-list-titles-selected" : ""}`}
                                                 style={{
                                                     display: "flex",
                                                     flexDirection: "row",
                                                     alignItems: "center",
                                                     width: "100%",
                                                     padding: "15px 20px",
                                                     margin: 0,
                                                     cursor: "pointer"
                                                 }}>
                                                {"AS Y1"}
                                            </div>
                                        )) : ""}
                                    </Col>
                                </div>
                            </div>
                        </div>
                        <Button
                            onClick={() => {
                                let urlQueryParams = new URLSearchParams(this.props.location.search)
                                urlQueryParams.set("theme", this.state.colorTheme === 'light' ? 'dark' : 'light')
                                this.props.history.push({search: urlQueryParams.toString()})
                                this.setState({ colorTheme: this.state.colorTheme === 'light' ? 'dark' : 'light' })
                            }}
                            className={"white-buttons-wr"} style={{
                            color: "var(--text-color)",
                            background: "var(--background-color)",
                            fontWeight: 600,
                            padding: "11px 24px",
                            alignSelf: "end",
                            justifySelf: "end",
                            height: "44px",
                        }}>{this.props.t("Mode")}: <span style={{fontWeight: 400, paddingLeft:"5px"}}>{this.props.t(this.state.colorTheme[0].toUpperCase() + this.state.colorTheme.substring(1))}</span></Button>

                        <Button
                          className={this.state.isLoaded ? "white-buttons-round-wr" : ""}
                          variant="light" style={{
                            marginRight: 0,
                            display: this.state.isLoaded ? "" : "none"
                        }}
                          onClick={() => this.props.history.push({pathname: `/patient/${this.props.match.params.patientIdentifier}${Constants.AS_JOURNEY}/${this.props.match.params.asJourneyId}${Constants.AS_JOURNEY_FULL_REPORT_PRINT}`, state: {asJourney: this.state.asJourney}})} >
                            <DownloadIcon style={{position: "relative", top: "-3px"}} fill={"var(--text-color)"}
                                          stroke={"var(--text-color)"}/>
                        </Button>

                    </Col>
                </Row>
                {this.state.isLoaded && this.state.asJourney && this.state.asJourney.stages[0] && this.state.asJourney.stages[0].forms ?
                  <div>
                      <Row style={{
                          marginRight: "0",
                          marginLeft: "0",
                          maxWidth: "calc(max(min(100vw, 1800px), 1440px))",
                          width: "842px",
                          marginBottom: "20px",
                          background: "var(--background-color)",
                          boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                          borderRadius: "10px",
                          padding: 0
                      }}
                      >
                          <Row style={{margin:0, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom:"1px solid var(--border-color-faintest)", padding:"20px"}}>
                              <div style={{}}>
                                  <AsAssessmentStatusRow assessment={{ ...this.state.asJourney, tx_status: this.state.patient.tx_status }} patient={this.state.patient} expansions={{}} hideExpansionArrow={true} patientIdentifier={this.props.match.params.patientIdentifier} />
                              </div>
                          </Row>
                          <Row style={{margin:0, padding:"0 20px"}}>
                              <div style={{ margin: '0', padding: '20px' }}>
                                  <AsAssessmentOverview
                                    assessment={{ ...this.state.asJourney, inputs: { ...this.state } }}
                                    backgroundColor={'transparent'}
                                    colorTheme={this.state.colorTheme}
                                    patientIdentifier={this.props.match.params.patientIdentifier}
                                    goToRef={(toPart) => journeyRefs[toPart] ? journeyRefs[toPart].current.scrollIntoView({
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    }) : ''}
                                  />
                              </div>
                              {this.state.asJourney.stages.slice(0,4).map((stage, j) =>
                                <><div key={j} style={{margin: "0 0 20px 0", background: "var(--background-color-faintest)", padding: "20px"}}>
                                    <span className={"section_title"}>{this.props.t(stage.title)}</span>
                                    {
                                        stage && stage.forms ?
                                          stage.forms.map((frm, i) =>
                                            frm && !frm.hide ? <StageForm key={i}
                                                                          ref={stage.ref} inputs={this.state} patient_id={this.props.match.params.patientIdentifier}
                                                                          asJourney={this.state.asJourney} frm={frm} stageIndex={j}
                                                                          locked={this.state.showPrintVersion || this.state.asJourney.locked || this.props.auth.account.user.account_type.toLowerCase() === "treating_hcp"} editor={stage.editor} editorType={stage.editor_type}
                                                                          token={this.props.auth.token}
                                                                          uploadMri={(file) => this.uploadMri(file, mriTaskUid)}
                                                                          updateMriUploadProgress={(progress) => {
                                                                              if (this.state.uploadStartTime <= 0 && progress > 0){
                                                                                  this.setState({uploadProgress: progress, uploadStartTime: Math.floor(Date.now() / 1000)})
                                                                                  return
                                                                              }
                                                                              this.setState({uploadProgress: progress})
                                                                          }}
                                                                          inputs_updated_at={this.state.inputs_updated_at}
                                                                          updateInput={(e) => {
                                                                                  if (!e.target.id.substring(1).includes('psa_s_prostate_density_')){
                                                                                      if (["psa_s_date_of_test_0", "psa_s_psa_level_0"].includes(e.target.id.substring(1))) {
                                                                                          let value = e.target.value
                                                                                          if (e.target.id.substring(1)==="psa_s_psa_level_0") {
                                                                                              value = e.target.value?.replaceAll(" ng/mL", "")
                                                                                              value += ' ng/mL'
                                                                                          }
                                                                                          this.setState({ [e.target.id.substring(1)]: value });
                                                                                          this.updateTaskPsa({
                                                                                              uid: frm.uid,
                                                                                              inputs: { [e.target.id.substring(1)]: value }, ...(frm.task ? { task: frm.task } : {})
                                                                                          })
                                                                                      }
                                                                                      else {
                                                                                          this.setState({ [e.target.id.substring(1)]: e.target.value });
                                                                                          debounceSubmitFormDirect({
                                                                                              uid: frm.uid,
                                                                                              inputs: { [e.target.id.substring(1)]: e.target.value }
                                                                                          }, this.props.match.params.patientIdentifier, this.props.match.params.asJourneyId)
                                                                                      }
                                                                                  }
                                                                              }
                                                                          }
                                                                          onClick={() => this.submitForm(frm)}/>: "")
                                          : ""
                                    }
                                </div>
                                    <div className="pagebreak" />
                                </>) }
                          </Row>
                      </Row>
                  </div> :
                  !this.state.showPrintVersion ? <Row style={{height: '300px', width: '100%'}}>
                {this.state.isLoaded ?
                    <Col style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    alignContent: 'center',
                    marginTop: '150px'
                }}>
                {this.state.error}
                    </Col>
                    : ''}
                    </Row>:""
                }
                {this.state.showPrintVersion ? <>
                    <div ref={(ref) => {this.componentRef = ref}}>
                        <Row style={{
                            marginRight: '0',
                            marginLeft: '0',
                            maxWidth: 'calc(max(min(100vw, 1800px), 1440px))',
                            width: '842px',
                            marginBottom: '20px',
                            background: 'var(--background-color)',
                            boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.05)',
                            borderRadius: '10px',
                            padding: 0
                        }}
                        >
                            <Row style={{
                                margin: 0,
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                borderBottom: '1px solid var(--border-color-faintest)',
                                padding: '20px'
                            }}>
                                <div style={{}}>
                                    <AsAssessmentStatusRow
                                      assessment={{ ...this.state.asJourney, tx_status: this.state.patient.tx_status }}
                                      patient={this.state.patient} expansions={{}} hideExpansionArrow={true}/>
                                </div>
                            </Row>
                            <Row style={{ margin: 0, padding: '0 20px' }}>
                                {this.state.asJourney.stages.slice(0, 4).map((stage, j) =>
                                  <>
                                      <div key={j} style={{
                                          margin: '0 0 20px 0',
                                          background: 'var(--background-color-faintest)',
                                          padding: '20px'
                                      }}>
                                          <span className={'section_title'}>{this.props.t(stage.title)}</span>
                                          {
                                              stage && stage.forms ?
                                                stage.forms.map((frm, i) =>
                                                  frm && !frm.hide ? <StageForm key={i}
                                                                                ref={stage.ref} inputs={this.state}
                                                                                patient_id={this.props.match.params.patientIdentifier}
                                                                                asJourney={this.state.asJourney}
                                                                                frm={frm} stageIndex={j}
                                                                                locked={true} editor={stage.editor}
                                                                                editorType={stage.editor_type}
                                                                                uploadMri={(e) => this.uploadMri(e.target.files[0], mriTaskUid)}
                                                                                updateInput={(e) => {
                                                                                    if (!e.target.id.substring(1).includes('psa_s_prostate_density_')) {
                                                                                        this.setState({ [e.target.id.substring(1)]: e.target.value })
                                                                                        debounceSubmitFormDirect({
                                                                                            uid: frm.uid,
                                                                                            inputs: { [e.target.id.substring(1)]: e.target.value }
                                                                                        }, this.props.match.params.patientIdentifier, this.props.match.params.asJourneyId)
                                                                                    }
                                                                                }
                                                                                }
                                                                                onClick={() => this.submitForm(frm)}/> : '')
                                                : ''
                                          }
                                      </div>
                                      <div className="pagebreak">.</div>
                                  </>)}
                            </Row>
                        </Row>
                    </div>
                </>
                : ""}

            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(AsJourneyReport)));