import './ActivityLogs.css'
import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import * as helpers from '../helpers/helpers'
import debounce from 'lodash.debounce'

import { Button } from 'react-bootstrap'
import HashLoader from 'react-spinners/HashLoader'
import ReactGA from 'react-ga4'
import { withTranslation } from 'react-i18next'
import { fetchPatients } from '../store/slices/patients'
import { ReactComponent as SearchIcon } from '../assets/icons/Search.svg'
import PractitionerCard from '../components/PractitionerCard'
import { reportAction } from '../utils/axios'

const accounts_types = [
  { account_type: 'expert_urologist', title_translation_key: 'Expert Urologists', disabled: false, visible: true },
  { account_type: 'expert_radiologist', title_translation_key: 'Expert Radiologists', disabled: false, visible: true },
  { account_type: 'treating_hcp', title_translation_key: 'Treating HCPs', disabled: false, visible: true },
  { account_type: 'support_team', title_translation_key: 'Support Teams', disabled: false, visible: true },
  { account_type: null, title_translation_key: 'Unsorted', disabled: true, visible: false }
]

const column_list = {
  created_at: { name: 'Date & Time', key: 'created_at', show: true, sortable: true, disabled: false },
  user: { name: 'User', key: 'user', show: true, sortable: true, disabled: false },
  action: { name: 'Action', key: 'action', show: true, sortable: true, disabled: false }
}

class ActivityLogs extends React.Component {
  constructor (props) {
    super(props)

    // TODO change filters implementation according to server
    let defaultFilters = {
      age: [0, 100],
      gender: { male: false, female: false },
      status: { riskPriority: false, watchWait: false },
      physician: {},
      pdx: [1, 2, 3, 4, 5],
      pdx_selected: 1,
      campus: {},
      campusShowDetails: false,
      clinicalStudy: {},
      clinicalStudyShowDetails: false
    }
    this.state = {
      error: null,
      networkError: false,
      emptyList: false,
      isLoaded: false,
      fetchData: false,
      patientsCount: 0,
      eventList: null,
      pageSize: 50,
      pageNumber: 1,
      columnList: column_list,
      selectionList: null,
      start_date: Math.floor(Date.now()/1000) - (60*60*10),
      end_date: Math.floor(Date.now()/1000),
      sortBy: Object.values(column_list)[0].key,
      sortDescending: true,
      patientPhotoErrors: [],
      patientSearchPhotoErrors: [],
      searching: false,   // do not update this from any other function
      searchText: '',     // do not update this from any other function
      searchResults: [],
      searchResultCount: 0,
      isSearchLoading: false,
      adminUser: false,
      showOverlay: false,
      patient_list_search: 1,
      filters: { ...defaultFilters },
      defaultFilters,
      newRequests: {},
      accountTypes: accounts_types,
      urlQueryParams: new URLSearchParams(this.props.location.search),
      currentPageSelection: accounts_types[2].account_type
    }
    this.searchInput = null
    this.hideOverlay = this.hideOverlay.bind(this)
  }

  componentDidMount () {
    // ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
    let query = this.prepareQuery()
    console.log(query)
    console.log('end time', Math.floor(Date.now() / 1000))
    this.setState({ newRequests: { [query]: 'started' } })
    this.props.dispatch(fetchPatients(query))
    reportAction("VIEWED_ACTIVITY_LOG");
    this.componentDidUpdate()
  }

  prepareQuery () {
    return JSON.stringify({
      query: `query($start_date: Int, $end_date: Int, $offset:Int, $limit:Int) {
                    events(start_date: $start_date, end_date: $end_date, limit: $limit, offset: $offset) {
                        uid
                        action
                        created_at
                        updated_at
                        patient{
                            uuid
                            first_name
                            last_name
                            photo
                        }
                        user{
                          uuid
                          first_name
                          last_name
                          photo
                          account_type
                          speciality
                          campus{
                              name
                          }
                          contact{
                            contact_type
                            phone
                            email
                            address{
                                first_line
                                unit
                                zipcode
                                city
                            }
                        }
                      }
                    }
                }`,
      variables: {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        limit: this.state.pageSize,
        offset: (this.state.pageNumber - 1) * this.state.pageSize
      }
    })
  }

  setPatientListFromReducer (data) {
    if (!Boolean(data)) data = this.prepareQuery()
    let state_update = {
      emptyList: true,
      isLoaded: true,
      fetchData: false,
      eventList: [],
      error: null,
      networkError: null
    }
    let dataObj = this.props.patients.data[data]
    let allEvents = dataObj && dataObj.events ? dataObj.events : []
    let processedEvents = []
    allEvents.forEach(event => {
      if (event.action.includes("ACTIVE_SESSION")){
        return;
      }
      let user = Object.assign({}, event.user)
      if (user.campus && typeof user.campus === 'object') {
        user.campus = user.campus.name
      }
      if (user && user.contact)
        user.contact.forEach(cnt => {
          if (cnt.contact_type === 'primary') {
            user.email = cnt.email
          } else if (cnt.contact_type === 'office') {
            user.phone = cnt.phone
            user.address = `${cnt.address.first_line}${(cnt.address.unit ? (' #' + cnt.address.unit) : '')}${cnt.address.city ? ' ,' + cnt.address.city : ''}${cnt.address.zipcode ? ' ' + cnt.address.zipcode : ''}`
          }
        })
      processedEvents.push({ ...event, user })
    })
    if (processedEvents) {
      // if(this.state.sortBy){
      //     allEvents.sort((a, b) =>{
      //         if(this.state.sortBy === "name"){
      //             if (a.last_name < b.last_name) return this.state.sortDescending ? -1 : 1
      //             else if (a.last_name > b.last_name) return this.state.sortDescending ? 1 : -1
      //             else return 0
      //         }
      //         if (a[this.state.sortBy] < b[this.state.sortBy]) return this.state.sortDescending ? -1 : 1
      //         else if (a[this.state.sortBy] > b[this.state.sortBy]) return this.state.sortDescending ? 1 : -1
      //         else return 0
      //     });
      // }
      this.setState({
        ...state_update,
        emptyList: false,
        eventList: processedEvents,
        patientsCount: processedEvents.length,
      })
    } else {
      this.setState(state_update)
    }
  }

  componentDidUpdate () {
    var updatedQueryParam = false
    var updatedState = {}
    if (!this.state.urlQueryParams.get('sortDescending')) {
      this.state.urlQueryParams.set('sortDescending', this.state.sortDescending)
      updatedQueryParam = true
    } else if (this.state.urlQueryParams.get('sortDescending') !== String(this.state.sortDescending)) {
      updatedState['sortDescending'] = this.state.urlQueryParams.get('sortDescending') === 'true'
      this.state.urlQueryParams.set('sortDescending', updatedState['sortDescending'])
      updatedQueryParam = true
    }
    if (this.state.urlQueryParams.get('sortBy') !== this.state.sortBy) {
      if (this.state.columnList[this.state.urlQueryParams.get('sortBy')] && this.state.columnList[this.state.urlQueryParams.get('sortBy')].sortable) {
        updatedState['sortBy'] = this.state.urlQueryParams.get('sortBy')
      } else if (this.state.urlQueryParams.get('sortBy') !== this.state.sortBy) {
        this.state.urlQueryParams.set('sortBy', this.state.sortBy)
        updatedQueryParam = true
      }
    }
    if (updatedQueryParam) this.props.history.push({ search: this.state.urlQueryParams.toString() })
    if (Object.keys(updatedState).length > 0) {
      this.setState(updatedState)
    }

    if (this.state.isLoaded || Object.keys(updatedState).length > 0 || updatedQueryParam) {
      return
    }
    let data = this.prepareQuery()
    let fetchDataNow = false
    if (!(data in this.state.newRequests) || (data in this.state.newRequests && !['succeeded', 'started'].includes(this.state.newRequests[data]))) {
      fetchDataNow = true
    }
    if (this.state.fetchData || fetchDataNow) {
      this.setState({ fetchData: false, newRequests: { ...this.state.newRequests, [data]: 'started' } })
      this.props.dispatch(fetchPatients(data))
    }
    if (this.props.patients.status === 'loading') {
      if (data in this.state.newRequests && this.state.newRequests[data] === 'succeeded') {
        this.setPatientListFromReducer(data)
      }
      return
    }
    if (this.props.patients.status === 'failed') {
      toast.error(this.props.patients.error)
      this.setState({
        isLoaded: true,
        fetchData: false,
        error: this.props.patients.error,
        eventList: null,
        emptyList: false
      })
      return
    }

    if (this.props.patients.status === 'succeeded') {
      let newReqs = this.state.newRequests
      for (let key in newReqs) {
        if (['failed', 'started'].includes(newReqs[key]) && key in this.props.patients.data) {
          newReqs[key] = 'succeeded'
        }
      }
      this.setState({ newRequests: { ...newReqs } })
      if (this.props.patients.data && data in this.props.patients.data) {
        this.setPatientListFromReducer(data)
        return
      } else {
        this.setState({ fetchData: true })
        return
      }
    }
  }

  closeSelection () {
    this.setState({ selectionList: null })
  }

  resetFilter () {
    this.setState({ filters: { ...this.state.defaultFilters }, isLoaded: false })
  }

  getMaxPageCount (real_count = true) {
    let count = parseInt(this.state.patientsCount / this.state.pageSize) + 1 * (this.state.patientsCount % this.state.pageSize > 0)
    if (real_count) return count
    if (count < 30) {
      return count
    } else return 30
  }

  hideOverlay () {
    this.setState({
      showGroups: false,
      showOverlay: false,
      showColumnSettings: false
    })
  }

  nextPage () {
    if (this.state.pageNumber < this.getMaxPageCount()) {
      this.setState({ pageNumber: this.state.pageNumber + 1, isLoaded: false })
    }
  }

  previousPage () {
    if (this.state.pageNumber > 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1, isLoaded: false })
    }
  }

  patientSelected (event, mrn) {
    if (process.env.REACT_APP_WALGREENS_ORG_UUID === this.state.clientId) return
    let selectionList = this.state.selectionList
    if (selectionList === null) {
      selectionList = {}
    }
    if (selectionList[mrn] === 1) {
      selectionList[mrn] = 0
    } else {
      selectionList[mrn] = 1
    }
    this.setState({ selectionList: selectionList })
    event.stopPropagation()
  }

  photoError (index) {
    let pe = this.state.patientPhotoErrors
    pe.push(index)
    this.setState({ patientPhotoErrors: pe })
  }

  getDisplay (values, key, eventUid) {
    if ((typeof (values) === 'string') && !['created_at', 'action'].includes(key)) {
      return values
    } else if (values === undefined || values === null) {
      return ''
    }
    if (key === 'created_at') {
      var la = new Date(0)
      la.setUTCSeconds(values)
      return <span style={{marginLeft:"10px"}}>{helpers.getFormattedDate(la, 'HH:mm A DD MMM YY', this.props.t)}</span>
    } else if (key === 'action') {
      let action = this.props.t(values);
      // switch(values){
      //   case "VIEWED_PATIENT_PROFILE":
      //     action = "Profile Viewed for"
      //     break;
      //   case "VIEWED_AS_JOURNEY":
      //     action = "Journey Viewed for"
      //     break;
      //   case "VIEWED_ACTIVITY_LOG":
      //     action = "Viewed Activity Logs"
      //     break;
      //   case "VIEWED_STAFF_ACCOUNTS":
      //     action = "Viewed Staff Accounts"
      //     break;
      //   case "VIEWED_HELP_AND_RESOURCES":
      //     action = "Viewed Help And Resources"
      //     break;
      //   case "ACTION_LOG_IN":
      //     action = "User Logged In"
      //     break;
      //   case "ACTION_LOG_OUT":
      //     action = "User Logged Out"
      //     break;
      // }
      let h = eventUid
      let e = this.state.eventList.find(event => event.uid === eventUid)

      let patient = {}
      if (e && e.patient) {
        patient = e.patient
      } else
        return action

      return <div style={{display:"flex", flexDirection:"row"}}>
          <Row><Col style={{alignContent:"center", justifyContent:"center", display:"flex", flexDirection:"column", marginRight:"10px"}}>{action}</Col></Row>
          <Row className="patient-photo-list" style={{ marginLeft: 0 }}>
            <Col
              xs={4} style={{
              maxWidth: '48px',
              minWidth: '48px',
              marginRight: '23px',
              display: 'flex',
              flexDirection: 'row'
            }}>
              <div style={{ maxHeight: '38px', minHeight: '38px' }}>
                <div
                  className={'patient-photo2'}
                  style={{
                    position: 'relative',
                    top: 0,
                    height: '0',
                    display: this.state.patientPhotoErrors.includes(h) && Boolean(patient.photo) && (patient.photo !== '') ? 'none' : 'block'
                  }}>
                  <svg width="38" height="38" viewBox="0 0 100 100"
                       fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                          fill="var(--border-color-two)"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M59.64 67.5H40.3575C37.4025 67.5 35 65.095 35 62.14C35 55.4475 40.445 50 47.14 50H52.8575C59.5525 50 65 55.4475 65 62.14C65 65.095 62.595 67.5 59.64 67.5ZM50 32.5C52.755 32.5 55 34.7425 55 37.5C55 40.2575 52.755 42.5 50 42.5C47.2425 42.5 45 40.2575 45 37.5C45 34.7425 47.2425 32.5 50 32.5ZM56.155 45.325C58.48 43.4925 60 40.6875 60 37.5C60 31.9775 55.5225 27.5 50 27.5C44.4775 27.5 40 31.9775 40 37.5C40 40.6875 41.5175 43.4925 43.8425 45.325C35.9525 46.8625 30 53.8025 30 62.14C30 67.8625 34.6375 72.5 40.3575 72.5H59.64C65.36 72.5 70 67.8625 70 62.14C70 53.8025 64.045 46.8625 56.155 45.325Z"
                          fill="var(--background-color)"/>
                  </svg>
                </div>
                <img
                  className={'patient-photo2'}
                  src={Boolean(patient.photo) && patient.photo !== '' ? patient.photo : null}
                  alt="" onLoad={() => this.photoError(h)}
                  style={{
                    minWidth: '38px',
                    maxWidth: '38px',
                    width: '38px',
                    maxHeight: '38px',
                    minHeight: '38px',
                    height: '38px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    display: Boolean(patient.photo) && this.state.patientPhotoErrors.includes(h) ? 'block' : 'none'
                  }}/>
              </div>
            </Col>
            <Col xs={8} style={{ textAlign: 'start' }}>
              <Row style={{
                color: 'var(--text-color)',
                fontWeight: '600',
                maxWidth: '100%',
                minWidth: '157px',
              }}>{helpers.getPatientName(`${patient.last_name ? (patient.last_name + (patient.first_name ? ', ': "")) : ''} ${patient.first_name ? patient.first_name : ''}`, 48)}</Row>
            </Col>
          </Row>
      </div>
    } else if (typeof (values) === 'object') {
      if (key === 'user') {
        return <div className="person-card-container" style={{ padding: '5px' }}><span
          style={{ borderBottom: '1px solid var(--dark-gray' }}>
              {values.last_name}, {values.first_name.slice(0, 1)}
            </span>
          <div style={{ position: 'relative', height: 0, background: 'transparent', left: '-100px' }}>
            <div className="person-card-container-tool-tip"
                 style={{ background: 'transparent', border: 0, boxShadow: 0 }}><PractitionerCard person={values}/>
            </div>
          </div>
        </div>
      }
    }
  }

  openColumnSettings () {
    this.setState({
      showColumnSettings: !this.state.showColumnSettings,
      showGroups: false,
      showOverlay: !this.state.showColumnSettings
    })
  }

  dbse = debounce((text) => this.getSearchResults(text), 500)

  searchPatients (e) {
    this.setState({ searchText: e.target.value, isSearchLoading: true })
    this.dbse(e.target.value)
  }

  getSearchResults (text) {
    this.setState({ searchText: text, isLoaded: false })
  }

  startSearching () {
    ReactGA.event({
      category: 'Engagement',
      action: 'button-click',
      label: 'activity-log-search-icon', // optional
      nonInteraction: false, // optional, true/false
    })
    if (this.searchInput)
      this.searchInput.focus()
    this.setState({
      searching: true,
      showOverlay: false,
      showColumnSettings: false,
      showGroups: false
    })
  }

  render = () => {
    const t = this.props.t
    return (
      <div className="staff-accounts-container">
        <div onClick={this.hideOverlay} style={{
          position: 'fixed',
          width: '100%',
          height: '100vh',
          background: 'rgba(0, 0, 0, 0.05)',
          left: 0,
          top: 0,
          display: this.state.showOverlay ? 'block' : 'none',
          zIndex: 80
        }}/>
        <Row style={{ marginRight: '0', marginLeft: '0', maxWidth: 'calc(max(min(100vw, 1800px), 1440px))' }}>
          <Col xs={8} className="sa-page-tittle" style={{
            paddingLeft: '0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'start'
          }}>
                        <span style={{ alignSelf: 'center', justifySelf: 'center', marginLeft: '15px' }}>
                            {t('Activity Log')}
                        </span>
          </Col>
          <Col xs={4} style={{
            display: this.state.selectionList ? 'None' : 'flex',
            flexDirection: 'row',
            textAlign: 'end',
            justifyContent: 'flex-end',
            paddingRight: '5px'
          }}>
            <div style={{
              display: this.state.searching ? 'flex' : 'none',
              justifyItems: 'end',
              alignItems: 'end',
              alignContent: 'end',
              borderRadius: '20px',
              background: 'var(--background-color)',
              boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.05)',
              zIndex: 110
            }}>
              <SearchIcon style={{
                width: '18px', height: '18px', position: 'relative', alignSelf: 'center', marginLeft: '15px',
                display: this.state.searchText || this.state.searching ? 'block' : 'none'
              }}
                          fill={'var(--background-color)'} stroke={'var(--text-color)'}/>
              <input autoFocus
                     className={this.state.searchText.length > 0 || this.state.searching ? 'top-search-input-focused' : 'top-search-input'}
                     onFocus={() => this.setState({ searching: true })}
                     value={this.state.searchText}
                     ref={(inpt) => {
                       this.searchInput = inpt
                     }}
                     onChange={(e) => this.searchPatients(e)}/>
              <span onClick={() => this.setState({
                searching: false,
                searchText: '',
                showOverlay: false,
                showColumnSettings: false,
                searchResults: []
              })}
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      right: 0,
                      padding: '11px 24px 11px 0',
                      fontSize: '14px',
                      lineHeight: '22px',
                      fontWeight: 400,
                      color: '--dark-gray',
                      display: this.state.searchText || this.state.searching ? '' : 'none',
                      marginLeft: '10px'
                    }}>{t('Cancel')}</span>
            </div>
            <div
              style={{ display: (this.state.searchText || this.state.searching) || (!this.state.patient_list_search) ? 'none' : '' }}>
              <Button onClick={() => this.setState({ searching: true })} className="white-buttons-round pl-search-icon"
                      variant="light" style={{
                width: '44px',
                height: '44px',
                padding: '8px',
                textAlign: 'center',
                display: this.state.searchText || this.state.searching ? 'none' : ''
              }}>
                <SearchIcon style={{ width: '18px', height: '18px', position: 'relative', top: '-1px' }}
                            fill={'var(--background-color)'} stroke={'var(--text-color)'}/>
              </Button>
            </div>
          </Col>
        </Row>
        <div style={{ height: 0, position: 'absolute', left: '50%', top: '50%' }}><HashLoader color="#4676F4"
                                                                                              loading={!this.state.isLoaded}
                                                                                              size={35}/></div>

        <table style={{ width: '100%', marginTop: '20px', overflowX: 'scroll', overflowY: 'scroll' }}>
          <thead style={{ background: 'var(--background-color)' }}>
          <tr className="table-header-container" style={{
            marginBottom: '20px',
            cursor: 'pointer',
            padding: '0 20px',
            position: 'sticky',
            top: 0,
            zIndex: 70
          }}>
            {Object.values(this.state.columnList).map((column, i) => (
              column.key !== 'status' && column.show && !column.disabled ? (
                <th colSpan="1" className="table-header" key={i}
                    onClick={() => {
                      if (column.sortable) {
                        this.state.urlQueryParams.set('sortDescending', !(this.state.urlQueryParams.get('sortBy') === column.key && this.state.sortDescending))
                        this.state.urlQueryParams.set('sortBy', column.key)
                        this.props.history.push({ search: this.state.urlQueryParams.toString() })
                        this.setState({
                          sortBy: column.key,
                          pageNumber: 1,
                          isLoaded: false,
                          sortDescending: !(this.state.urlQueryParams.get('sortBy') === column.key && this.state.sortDescending)
                        })
                      }
                    }}
                    style={{
                      maxWidth: column.key === 'created_at' ? '300px' : '',
                      position: column.key === 'created_at' ? 'sticky' : '',
                      minWidth: column.key === 'created_at' ? '300px' : '',
                      width: column.key === 'user' ? '220px' : (column.key === 'created_at' ? '300px' : ''),
                      color: this.state.sortBy === column.key ? 'var(--text-color)' : 'var(--text-color-faint)',
                      fontWeight: this.state.sortBy === column.key ? 600 : 400
                    }}>
                  <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <span>{t(column.name)}</span>
                    <div className="header-sort-symbol" style={{
                      visibility: (column.sortable && this.state.sortBy === column.key) ? 'visible' : 'hidden',
                      transform: this.state.sortDescending ? '' : 'rotate(180deg)',
                      marginLeft: this.state.sortDescending ? '0' : '5px',
                      marginRight: this.state.sortDescending ? '5px' : '0'
                    }}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z"
                              fill="var(--border-color)"/>
                      </svg>
                    </div>
                  </div>
                </th>
              ) : (<></>)
            ))}
          </tr>
          </thead>
          <tbody className="tbody-sa">
          {this.state.isLoaded && this.state.eventList ? (
            this.state.eventList.map((event, h) => (
              <tr className="staff-accounts-row" key={h}>
                {Object.values(this.state.columnList).map((column, i) => (
                  column.key === 'name' ? (
                    <td colSpan="1" className="table-content table-name-col" key={i} width="300px"
                        style={{ position: 'sticky', left: '80px', zIndex: 60, paddingLeft: '30px' }}>
                      <Row className="patient-photo-list" style={{ marginLeft: 0 }}>
                        <Col onClick={(event) => this.patientSelected(event, event.uid)}
                             xs={4} style={{
                          maxWidth: '48px',
                          minWidth: '48px',
                          marginRight: '23px',
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                          <div style={{ maxHeight: '38px', minHeight: '38px' }}>
                            <div
                              className={'patient-photo2'}
                              style={{
                                position: 'relative',
                                top: 0,
                                height: '0',
                                display: this.state.patientPhotoErrors.includes(h) && Boolean(event.photo) && (event.photo !== '') ? 'none' : 'block'
                              }}>
                              <svg width="38" height="38" viewBox="0 0 100 100"
                                   fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                                      fill="var(--border-color-two)"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M59.64 67.5H40.3575C37.4025 67.5 35 65.095 35 62.14C35 55.4475 40.445 50 47.14 50H52.8575C59.5525 50 65 55.4475 65 62.14C65 65.095 62.595 67.5 59.64 67.5ZM50 32.5C52.755 32.5 55 34.7425 55 37.5C55 40.2575 52.755 42.5 50 42.5C47.2425 42.5 45 40.2575 45 37.5C45 34.7425 47.2425 32.5 50 32.5ZM56.155 45.325C58.48 43.4925 60 40.6875 60 37.5C60 31.9775 55.5225 27.5 50 27.5C44.4775 27.5 40 31.9775 40 37.5C40 40.6875 41.5175 43.4925 43.8425 45.325C35.9525 46.8625 30 53.8025 30 62.14C30 67.8625 34.6375 72.5 40.3575 72.5H59.64C65.36 72.5 70 67.8625 70 62.14C70 53.8025 64.045 46.8625 56.155 45.325Z"
                                      fill="var(--background-color)"/>
                              </svg>
                            </div>
                            <img
                              className={'patient-photo2'}
                              src={Boolean(event.photo) && event.photo !== '' ? event.photo : null}
                              alt="" onLoad={() => this.photoError(h)}
                              style={{
                                minWidth: '38px',
                                maxWidth: '38px',
                                width: '38px',
                                maxHeight: '38px',
                                minHeight: '38px',
                                height: '38px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                display: Boolean(event.photo) && this.state.patientPhotoErrors.includes(h) ? 'block' : 'none'
                              }}/>
                          </div>
                        </Col>
                        <Col xs={8} style={{ textAlign: 'start' }}>
                          <Row style={{
                            color: 'var(--text-color)',
                            fontWeight: '600',
                            maxWidth: '100%',
                            minWidth: '157px',
                          }}>{helpers.getPatientName(`${event.last_name ? (event.last_name + ', ') : ''} ${event.first_name ? event.first_name : ''}`, 48)}</Row>
                        </Col>
                      </Row>
                    </td>
                  ) : (
                    column.show && !column.disabled ? (
                      <td colSpan="1" key={i} className="table-content" style={{
                        width: column.key === 'campus' ? '220px' : (column.key === 'phone' ? '220px' : (column.key === 'email' ? '300px' : '')),
                        justifyContent: column.key === 'seg' ? 'space-around' : '',
                        wordBreak: column.key === 'pdx' ? 'break-word' : ''
                      }}>
                        {this.getDisplay(event[column.key], column.key, event.uid)}
                      </td>
                    ) : (<></>)
                  )
                ))}
              </tr>
            ))
          ) : <tr style={{ display: 'none' }}/>}
          <tr style={{
            display: (this.state.error || !this.state.isLoaded || this.state.emptyList || this.state.networkError) ? '' : 'none',
            height: '60vh',
            width: '100%',
            background: 'var(--background-color)',
            textAlign: 'center',
            borderRadius: '10px',
            opacity: (this.state.error || this.state.emptyList || this.state.networkError) ? 1 : 0
          }}>
            <td colSpan={Object.keys(this.state.columnList).length} style={{ borderRadius: '10px' }}>
              <div>{t(this.state.emptyList ? 'No Activities found' : (this.state.networkError ? 'Network Error' : 'Server Error'))}</div>
            </td>
          </tr>
          <tr style={{height: "65px", width: "100%", background: "var(--background-color)"}}>
            <td colSpan={Object.keys(this.state.columnList).length}
                style={{borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px"}}>
              <div style={{alignContent: "center", display: "flex", flexDirection: 'row'}}>
                <div style={{
                  width: "max-content",
                  height: "26px",
                  flex: 1,
                  marginLeft: "20px",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "100px",
                  boxShadow: "0px 3px 15px 2px rgba(0, 0, 0, 0.05)",
                  justifyItems: "center",
                  alignContent: "start"
                }}>
                  <svg onClick={() => this.previousPage()} style={{
                    opacity: this.state.pageNumber > 1 ? "1" : "0.25",
                    alignSelf: "center",
                    cursor: this.state.pageNumber > 1 ? "pointer" : "default",
                    justifySelf: "center",
                    margin: "7px 10px 7px 10px"
                  }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.76928 10.5355C8.02528 10.5355 8.28128 10.4375 8.47628 10.2425C8.86728 9.85253 8.86728 9.21953 8.47628 8.82853L5.64828 6.00053L8.47628 3.17153C8.86728 2.78153 8.86728 2.14753 8.47628 1.75753C8.08528 1.36753 7.45328 1.36753 7.06228 1.75753L3.52728 5.29353C3.13628 5.68353 3.13628 6.31653 3.52728 6.70753L7.06228 10.2425C7.25728 10.4375 7.51328 10.5355 7.76928 10.5355Z"
                          fill="var(--darkest-gray)"/>
                  </svg>
                  {[...Array(this.getMaxPageCount(false))].map((pn, i) =>
                    <div key={i} onClick={() => this.setState({
                      pageNumber: i + 1,
                      isLoaded: false,
                    })} style={{
                      width: "8px",
                      cursor: "pointer",
                      height: "8px",
                      background: this.state.pageNumber === (i + 1) ? "var(--border-color-fainter)" : "var(--background-color-faint)",
                      borderRadius: "100px",
                      margin: "5px",
                      alignSelf: "center",
                      justifySelf: "center"
                    }}/>)}
                  <svg onClick={() => this.nextPage()} style={{
                    transform: "rotate(180deg)",
                    opacity: this.state.pageNumber < this.getMaxPageCount() ? "1" : "0.25",
                    cursor: this.state.pageNumber < this.getMaxPageCount() ? "pointer" : "default",
                    alignSelf: "center",
                    justifySelf: "center",
                    margin: "7px 15px 7px 10px"
                  }} width="12" height="12" viewBox="0 0 12 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.76928 10.5355C8.02528 10.5355 8.28128 10.4375 8.47628 10.2425C8.86728 9.85253 8.86728 9.21953 8.47628 8.82853L5.64828 6.00053L8.47628 3.17153C8.86728 2.78153 8.86728 2.14753 8.47628 1.75753C8.08528 1.36753 7.45328 1.36753 7.06228 1.75753L3.52728 5.29353C3.13628 5.68353 3.13628 6.31653 3.52728 6.70753L7.06228 10.2425C7.25728 10.4375 7.51328 10.5355 7.76928 10.5355Z"
                          fill="var(--darkest-gray)"/>
                  </svg>
                </div>
                <div style={{
                  alignSelf: "end",
                  justifySelf: "end",
                  alignContent: "end",
                  justifyitems: "end",
                  textAlign: "end",
                  flex: 10,
                  marginLeft: "20px",
                  marginRight: "20px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%"
                }}>
                                    <span style={{
                                      alignSelf: "center",
                                      opacity: this.state.isLoaded ? "1" : "0",
                                      justifySelf: "center",
                                      flex: 1,
                                      marginRight: "20px",
                                      fontWeight: 400,
                                      color: "var(--text-color-faint)",
                                      fontSize: "14px",
                                      lineHeight: "22px"
                                    }}>{`${this.props.t("Showing")}: ${((this.state.pageNumber - 1) * this.state.pageSize) + 1}-${this.state.pageNumber * this.state.pageSize > this.state.patientsCount ? this.state.patientsCount : this.state.pageNumber * this.state.pageSize} of ${this.state.patientsCount}`}</span>
                  <span onClick={() => this.setState({
                    pageSize: 25,
                    isLoaded: false,
                    pageNumber: 1
                  })} className="result-page-limit" style={{
                    background: this.state.pageSize === 25 ? "var(--background-color-faint-two)" : "var(--background-color)",
                    color: this.state.pageSize === 25 ? "var(--text-color-faint)" : "var(--text-color-faintest)",
                    marginRight: "6px"
                  }}>{t("25")}</span>
                  <span onClick={() => this.setState({
                    pageSize: 50,
                    isLoaded: false,
                    pageNumber: 1
                  })} className="result-page-limit" style={{
                    background: this.state.pageSize === 50 ? "var(--background-color-faint-two)" : "var(--background-color)",
                    color: this.state.pageSize === 50 ? "var(--text-color-faint)" : "var(--text-color-faintest)",
                    marginRight: "6px"
                  }}>{t("50")}</span>
                  <span onClick={() => this.setState({
                    pageSize: 100,
                    isLoaded: false,
                    pageNumber: 1
                  })} className="result-page-limit" style={{
                    background: this.state.pageSize === 100 ? "var(--background-color-faint-two)" : "var(--background-color)",
                    color: this.state.pageSize === 100 ? "var(--text-color-faint)" : "var(--text-color-faintest)"
                  }}>{t("100")}</span>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    auth: dispatch.auth,
    patients: dispatch.patients
  }
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(ActivityLogs)))