import React, { useState } from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import HashLoader from 'react-spinners/HashLoader'
import { fetcher } from '../../utils/axios'
import * as Constants from '../../constants/Constants'
import { toast } from 'react-toastify'
import Select, { components } from 'react-select'
import 'react-phone-number-input/style.css'
import * as helpers from '../../helpers/helpers'


const reactSelectStyles = {
  container: (base, state) => ({
    ...base,
    width: '100%',
    background: 'var(--background-color-faintest)'
  }),
  control: (base, state) => ({
    ...base,
    border: 0,
    borderRadius: 0,
    outline: 0,
    boxShadow: 'none',
    background: 'var(--background-color-faintest)'
  }),
  menu: base => ({
    ...base,
    outline: 0,
    zIndex: 110
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? 'var(--border-color-fainter)' : 'var(--background-color-faintest)',
    color: 'black',
    opacity: state.isFocused ? 0.9 : 1,
    zIndex: 1000
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: 'var(--text-color)'
  })
}

export default function SurveyTaskCompletionCard (props) {
  const { t } = useTranslation()
  let location = props.location
  const [message, setMessage] = useState('')
  const [rating, setRating] = useState('')
  const [isLoading, setLoading] = useState(false)

  let ecogOptions = [
    { code: '1', label: <span><b style={{ color: 'var(--bs-primary)' }}>1</b> — {t('Not useful at all')}</span> },
    { code: '2', label: <span><b style={{ color: 'var(--bs-primary)' }}>2</b> — {t('Not very useful')}</span> },
    { code: '3', label: <span><b style={{ color: 'var(--bs-primary)' }}>3</b> — {t('Somewhat useful')}</span> },
    { code: '4', label: <span><b style={{ color: 'var(--bs-primary)' }}>4</b> — {t('Useful')}</span> },
    { code: '5', label: <span><b style={{ color: 'var(--bs-primary)' }}>5</b> — {t('Very useful')}</span> },
  ]

  function updateSelection (opt) {
    setRating(opt.code)
  }


  function sendMessage () {
    if (isLoading) {
      return
    }

    setLoading(true)
    let satPair = helpers.getSaturdayPair(Math.floor(Date.now()/1000), 6)
    let dates = helpers.getSaturdayPair(Math.floor(satPair[0].getTime()/1000), 6)
    dates = [helpers.getUTCFormattedDate(dates[0].getTime()/1000), helpers.getUTCFormattedDate(dates[1].getTime()/1000)]
    let dateRange = helpers.getFormattedDate(dates[0], "DD MMM", (lang) => {return "en"})
    if (helpers.getFormattedDate(dates[0], "YYYY") !== helpers.getFormattedDate(dates[1], "YYYY"))
      dateRange += helpers.getFormattedDate(dates[0], "YY", (lang) => {return "en"})
    dateRange += " - "
    dateRange += helpers.getFormattedDate(dates[1], "DD MMM YY", (lang) => {return "en"})
    let week = dateRange
    console.debug("submitting survey", week)
    var data = JSON.stringify({
      query: `
    mutation{
        org_survey(location: "${location}", week: "${week}", rating: "${rating}", comment:"${message}")
    }
`,
      variables: {}
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        setLoading(false)
        if (response.errors || !response.data.org_survey) {
          console.error('Got Error', response.errors)
          toast.error(t('Unable to process request'))
          return
        }
        console.debug("org survey", response.data.org_survey, week)
        localStorage.setItem(week+location, "COMPLETED")
        props.closeBox(true)
      })
      .catch((err) => {
        console.error("Error in processing", err)
        setLoading(false)
        toast.error(t('Unable to process request'))
      })
  }

  return <Container style={{
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    minWidth: '350px',
    background: 'var(--background-color)',
    padding: 0,
    borderRadius: '10px',
    boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.0900136)',
    textAlign: 'center',
    color: 'var(--text-color)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }}>
    <div style={{ height: 0, position: 'relative', left: 0, top: '225px', zIndex:"90" }}><HashLoader color="#4676F4"
                                                                                        loading={isLoading} size={25}/>
    </div>
    <div style={{
      margin: 0,
      textAlign: 'center',
      marginBottom: '20px',
      borderRadius: '10px',
    }}>
      <Row style={{ padding: '20px' }}>
        <Col style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center'
        }}>
                            <span
                              style={{ fontWeight: 600 }}>{helpers.getFormattedDate(Date.now() / 1000, 'DD MMM YY - hh:mm A')}</span>
        </Col>
      </Row>
      <Row style={{ padding: '0 20px', textAlign: 'start', marginBottom: '20px' }}>
        <Col>
          <span
            style={{ fontWeight: 400 }}>{t('To complete your last Patient "Check In" Task for this week, please answer the question below.')}</span>
        </Col>
      </Row>
      <Row style={{ padding: '0 20px', textAlign: 'start', marginBottom: '10px' }}>
        <Col>
          <span
            style={{ fontWeight: 600 }}>{t('How useful was the PRO-WAVE1 platform to you this week?')}</span>
        </Col>
      </Row>
      <Row style={{ padding: '0 20px', marginBottom: '20px', textAlign: 'start' }}>
        <Col>
          <Select styles={reactSelectStyles}
                  isSearchable={false}
                  value={rating ? [{
                    value: rating,
                    label: ecogOptions.find(e => e.code === rating).label
                  }] : ''}
                  components={{
                    Placeholder: ({ children, ...props }) => (
                      <components.Placeholder {...props}>
                        <span
                          style={{ color: 'var(--bs-primary)', fontWeight: 600 }}>{t('Select Rating')}</span> ({t("Optional")})
                      </components.Placeholder>
                    )
                  }}
                  options={ecogOptions}
                  onChange={(temp) => updateSelection(temp)}
          />
        </Col>
        {rating ? <Col xs={1}
                           onClick={() => setRating('')}
                           style={{
                             cursor: 'pointer',
                             justifyContent: 'center',
                             alignContent: 'center',
                             alignSelf: 'center',
                             paddingLeft: 0,
                             color: 'var(--wave-clay)'
                           }}>
          <span style={{ fontSize: '19px', fontWeight: 600 }}>x</span>
        </Col> : ''}
      </Row>
      <Row style={{ padding: '0 20px', marginBottom: '20px' }}>
        <Col>
          <textarea style={{
            minHeight: '174px',
            width: '100%',
            outline: 0,
            border: '1px solid var(--border-color-fainter)',
            borderRadius: '10px',
            padding: '10px',
            margin: '0',
            resize: 'none'
          }} placeholder={t('Type Comment') + ' (' + t("Optional") +')...'} value={message}
                    onChange={(e) => !isLoading ? setMessage(e.target.value) : ''}/>
        </Col>
      </Row>
      <div style={{
        background: 'var(--background-color-faint)',
        height: '1px',
        width: '100%',
        margin: '20px 0',
        padding: 0
      }}/>
      <Row style={{
        fontWeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        bottom: '0px',
        padding: '0 20px'
      }}>
        <Col xs={6}>
          <Button onClick={() => props.closeBox()} variant="light" style={{
            minWidth: '150px',
            height: '44px',
            padding: '8px',
            borderRadius: '100px',
            border: '1px solid var(--border-color-faint)',
            background: 'var(--background-color)',
            textAlign: 'center',
            marginLeft: '0',
            color: 'var(--text-color-faint)',
            fontWeight: 600
          }}>{t('Cancel')}</Button>
        </Col>
        <Col xs={6}>
          <Button onClick={sendMessage} variant="light" style={{
            minWidth: '150px',
            height: '44px',
            padding: '8px',
            borderRadius: '100px',
            textAlign: 'center',
            border: '0',
            marginLeft: '0',
            background: 'var(--bs-primary)',
            color: 'var(--background-color)',
            fontWeight: 600
          }}>{t('Send')}</Button>
        </Col>
      </Row>
    </div>
  </Container>
}