import './Login.css';
import React from 'react';
import LoginBackground from '../assets/backgrounds/login.png';
import * as Constants from '../constants/Constants';
import authSlice from "../store/slices/auth";
import axios from "axios";
import {Link} from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { Container, Row, Col } from "reactstrap";
import { withRouter } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import LogoAndBackground from "../components/WaveLogoBlueBack";


class Register extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: "",
            showPasswordChecks: false,
            isLoaded: false,
            username: "",
            password: "",
            confirmedPassword: "",
            usernameError: false,
            passwordError: false,
            showPassword: false,
            passwordChecks: [
            {
                "regex": /^(?=.{8,})/,
                "status": false,
                "text": this.props.t("minimum_8_characters")
            },
            {
                "regex": /^(?=.*[!@#$%^&*])/,
                "status": false,
                "text": this.props.t("one_special_character")
            },
            {
                "regex": /^(?=.*[A-Z])/,
                "status": false,
                "text": this.props.t("one_uppercase_and_lowercase_character")
            },
            {
                "regex": /^(?=.*[0-9])/,
                "status": false,
                "text": this.props.t("one_number")
            }
        ]
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateConfirmedPassword = this.updateConfirmedPassword.bind(this);
    }

  componentDidMount(){
    if(this.props.auth && this.props.auth.token){
    this.props.history.push("/");
    window.location.reload();
    }
    if(this.state.isLoaded){
        return;
    }
    this.componentDidUpdate();
    }
  
    componentDidUpdate() {
    }

    handleRegister() {
        if (!this.checkValidEmail() || !this.checkValidPassword()){
            return;
        }
        this.setState({error: ""})
        axios
            .post(`${process.env.REACT_APP_API_URL}${Constants.REGISTER_API}`, { username: this.state.username, password: this.state.password })
            .then((res) => {
            this.props.dispatch(
                authSlice.actions.setAuthTokens({
                token: res.data.access,
                refreshToken: res.data.refresh,
                })
            );
            this.props.dispatch(authSlice.actions.setAccount(res.data.user));
            this.setState({isLoaded: false});
            this.props.history.push("/");
            })
            .catch((err) => {
            this.setState({error: err.toString()});
            });
  };

  checkValidEmail(){
    if (this.state.username === ""){
        return;
    }
    let re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.username)){
        this.setState({error: this.props.t("please_enter_a_valid_email_"), usernameError: true});
        return false;
    }
    else{
        this.setState({error: "", usernameError: false});
        return true;
    }
}

checkValidPassword(){
    if (this.state.password === "" && this.state.confirmedPassword === ""){
        return true;
    }
    if (this.state.password !== this.state.confirmedPassword){
        this.setState({error: this.props.t("passwords_do_not_match_"), passwordError: true});
        return false;
    }
    let passwird_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!passwird_regex.test(this.state.password)){
        this.setState({error: this.props.t("please_enter_a_valid_password_"), passwordError: true});
        return false;
    }
    else{
        this.setState({error: "", passwordError: false});
        return true;
    }
}

updateUsername(event){
    if(event.target.value === ""){
        this.setState({error: "", usernameError: false, username: ""});
    }
    else{
        this.setState({username: event.target.value});
    }
}

updateConfirmedPassword(event){
    this.setState({confirmedPassword: event.target.value});
}

updatePassword(event){
    this.setState({password: event.target.value});
}

  render = () => {
  return (
    <Container className="login-container">
      <Row style={{padding:"0", margin:"0"}}>
      <Col xs={6} style={{paddingRight:"0"}}>
            <LogoAndBackground />
        </Col>
        <Col xs={6} style={{padding:"0", margin:"0", display:"flex", alignItems:"center", justifyContent:"space-around"}}>
            <div style={{position:"absolute", alignSelf:"center", justifySelf:"center", display:"flex", flexDirection:"column", textAlign:"center", alignItems:"center", justifyItems:"center", color:"var(--text-color)"}}>
                <span style={{fontSize:"22px", fontWeight:"600", lineHeight:"30px", color:"var(--text-color)", alignSelf:"center"}}>{this.props.t("login_to_continue")}</span>
                <span style={{color:`var(--text-color)`, fontWeight:"400", fontSize:"14px", lineHeight:"22px", marginTop:"30px", minHeight:"22px", maxWidth:"max(25vw, 375px)"}}>{this.props.t("please_enter_your_wave_health_account_email_and_create_a_secure_8_digit_password_")}</span>
                <span style={{color:`var(--wave-clay)`, fontWeight:"400", fontSize:"14px", lineHeight:"22px", marginTop:"30px", minHeight:"22px", maxWidth:"max(25vw, 375px)"}}>{this.state.error}</span>
                <label style={{fontSize:"14px", fontWeight:"600", lineHeight:"22px", color:"var(--text-color)", alignSelf:"start", marginTop:"10px"}}>{this.props.t("email")}</label>
                <div style={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center"}}>
                    <input type="email" autoFocus 
                        className={this.state.usernameError ? "incorrect-input-field" : "input-field"} 
                        placeholder={this.props.t("enter_your_email")}
                        value={this.state.username}
                        onChange={this.updateUsername}
                        onBlur={() => setTimeout(function () {this.checkValidEmail()}.bind(this), 200)} />
                    <span onClick={() => this.setState({username: "", usernameError: false})} style={{position: "absolute", right:"20px", visibility:this.state.username.length > 0 ? "visible": "hidden", cursor:"pointer"}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z" fill={this.state.usernameError ? "var(--wave-clay)" : "var(--border-color)"}/>
                        </svg>
                    </span>
                </div>
                <label style={{width:"100%", fontSize:"14px", fontWeight:"600", lineHeight:"22px", color:"var(--text-color)", alignSelf:"start", marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <span style={{textAlign:"start", justifySelf:"start", flex:1}}>Password</span> 
                </label>
                <div className="password-field-register" style={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center"}}>
                    <input className={`${this.state.passwordError ? "incorrect-" : ""}input-field`} style={{paddingRight:"80px"}}
                            type={this.state.showPassword ? "text": "password"} placeholder={this.props.t("enter_your_password")}
                            value={this.state.password}
                            onFocus={() => this.setState({showPasswordChecks: true})}
                            onBlur={() => this.setState({showPasswordChecks: false})}
                            onChange={this.updatePassword} />
                    <span style={{position: "absolute", cursor:"pointer",
                            right:"40px", 
                            visibility:this.state.password.length > 0 ? "visible": "hidden", 
                            fontSize:"12px", 
                            lineHeight:"16px"}}
                            onClick={() => this.setState({showPassword: !this.state.showPassword})} >
                        {this.state.showPassword ? "Hide": "Show"}
                    </span>
                    <span onClick={() => this.setState({confirmedPassword: "", password: "", error:this.state.passwordError ? "": this.state.error, passwordError: false})} style={{position: "absolute", right:"20px", visibility: this.state.password.length > 0 ? "visible": "hidden", cursor:"pointer"}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z" 
                            fill={this.state.passwordError ? "var(--wave-clay)" : "var(--border-color)"}/>
                        </svg>
                    </span>
                </div>
                <div className={ this.state.showPasswordChecks ? "password-checks": "password-checks-hidden"} style={{fontSize:"12px", lineHeight:"16px", fontWeight:"400", justifyContent:"start", alignContent:"start", alignSelf:"start"}}>
                    {this.state.passwordChecks.map((chk, i) => (
                        <div key={i} style={{padding:"5px 0", display:"flex", flexDirection:"row", justifyContent:"start", alignContent:"start", alignSelf:"center"}}>
                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{alignSelf:"center", width:"22px", visibility: chk.regex.test(this.state.password) ? "visible": "hidden"}}>
                                <path d="M1.663 5.64591L4.80589 8.28311L10.0803 1.99732" stroke="var(--bs-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span style={{color: chk.regex.test(this.state.password) ? "var(--text-color)": "var(--text-color-faintest)"}}>{chk.text}</span>
                        </div>
                    ))}
                </div>
                <label style={{width:"100%", fontSize:"14px", fontWeight:"600", lineHeight:"22px", color:"var(--text-color)", alignSelf:"start", marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <span style={{textAlign:"start", justifySelf:"start", flex:1}}>{this.props.t("confirm_password")}</span> 
                </label>
                <div style={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center"}}>
                    <input className={this.state.passwordError ? "incorrect-input-field" : "input-field"} style={{paddingRight:"80px"}}
                            type={this.state.showPassword ? "text": "password"} placeholder="Confirm your password"
                            onBlur={() => setTimeout(function () {this.checkValidPassword()}.bind(this), 200)}
                            value={this.state.confirmedPassword}
                            onChange={this.updateConfirmedPassword} />
                    <span style={{position: "absolute", cursor:"pointer",
                            right:"40px", 
                            visibility:this.state.confirmedPassword.length > 0 ? "visible": "hidden", 
                            fontSize:"12px", 
                            lineHeight:"16px"}}
                            onClick={() => this.setState({showPassword: !this.state.showPassword})} >
                        {this.state.showPassword ? "Hide": "Show"}
                    </span>
                    <span onClick={() => this.setState({confirmedPassword: "", password: "", error:this.state.passwordError ? "": this.state.error, passwordError: false})} style={{position: "absolute", right:"20px", visibility:this.state.confirmedPassword.length > 0 ? "visible": "hidden", cursor:"pointer"}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7.4 6L11.7 1.7C12.1 1.3 12.1 0.7 11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L6 4.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6L0.3 10.3C-0.1 10.7 -0.1 11.3 0.3 11.7C0.5 11.9 0.7 12 1 12C1.3 12 1.5 11.9 1.7 11.7L6 7.4L10.3 11.7C10.5 11.9 10.7 12 11 12C11.3 12 11.5 11.9 11.7 11.7C12.1 11.3 12.1 10.7 11.7 10.3L7.4 6Z" 
                            fill={this.state.passwordError ? "var(--wave-clay)" : "var(--border-color)" }/>
                        </svg>
                    </span>
                </div>
                <Button onClick={() => this.handleRegister()} 
                    style={{width:"100%", flexGrow:"1", height:"44px", marginTop:"30px", fontWeight:"600", fontSize:"14px", lineHeight:"22px", color:"white", background: this.state.username.length > 0 && this.state.password.length > 0 && this.state.confirmedPassword.length > 0 ? "var(--bs-primary)": "var(--border-color-faint)", borderRadius:"100px", boxShadow:"none"}} variant="light">
                    {this.props.t("next")}
                </Button>
                <div style={{fontWeight:"400", fontSize:"14px", lineHeight:"22px", marginTop:"30px"}}>
                    {this.props.t("already_have_an_account_log_in")} <Link className="plain-a" to={Constants.LOGIN} style={{fontWeight:"600", cursor: "pointer", color:`var(--bs-primary)`}}>{this.props.t("log_in")}</Link>
                </div>
            </div>
        </Col>
      </Row>
    </Container>
  );
  }
}

export default withTranslation()(withRouter(Register));