import React from 'react';
import authSlice from "../store/slices/auth";
import {connect} from "react-redux";
import * as Constants from '../constants/Constants';
import { Container} from "reactstrap";
import { withRouter } from 'react-router-dom';
import patientsSlice from "../store/slices/patients";
import { reportAction } from '../utils/axios'


class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: "",
            isLoaded: false,
        };
    }

    componentDidMount(){
        reportAction("ACTION_LOG_OUT");
        setTimeout(() => {
            this.props.dispatch(authSlice.actions.setLogout());
            this.props.dispatch(patientsSlice.actions.clearData());
            this.props.dispatch(authSlice.actions.setRedirectAfterLogin(Constants.PATIENT_LIST));
            if (this.props.auth && this.props.auth.isSamlUser){
                window.open(process.env.REACT_APP_WALGREENS_LOGOUT_URL,"_self")
            }
        }, 1000);

    }

    render = () => {
        return (
        <Container>
          
        </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default connect(mapDispatchToProps)(withRouter(Login));