import { Col, Row } from 'reactstrap'
import { Button } from 'react-bootstrap'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TableHeaderAs from './TableHeader'
import TableDivAs from './TableDivAs'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/slices/auth'
import PractitionerCard from '../PractitionerCard'
import {getFormattedDate} from "../../helpers/helpers"

export default function StageForm (props) {
  let user = useSelector(userSelector);
  let userType = user.account_type;
  let userUuid = user.uuid ? user.uuid: "00000";
  let mriTaskUid = "";
  if(props.asJourney.stages[0].tasks){
    let mriDicomTask = props.asJourney.stages[0].tasks.find(t => (t.action ? t.action.toUpperCase() : "") === "UPLOAD_MRI_DICOM");
    if (mriDicomTask && mriDicomTask.uid){
      mriTaskUid = mriDicomTask.uid;
    }
  }

  let mriReportTaskUid = "";
  if(props.asJourney.stages[0].tasks){
    let mriReportTask = props.asJourney.stages[0].tasks.find(t => (t.action ? t.action.toUpperCase() : "") === "UPLOAD_MRI");
    if (mriReportTask && mriReportTask.uid){
      mriReportTaskUid = mriReportTask.uid;
    }
  }
  let biopsyTaskUid = "";
  if(props.asJourney.stages[0].tasks){
    let biopsyTask = props.asJourney.stages[0].tasks.find(t => (t.action ? t.action.toUpperCase() : "") === "UPLOAD_BIOPSY");
    if (biopsyTask && biopsyTask.uid){
      biopsyTaskUid = biopsyTask.uid;
    }
  }
  if (props.inputs.brd_cancer_core_length && props.inputs.brd_core_length){
    props.inputs.brd_cancer_core_ratio = Math.floor((parseInt(props.inputs.brd_cancer_core_length) / parseInt(props.inputs.brd_core_length)) * 100);
  }
  if (props.inputs.psa_s_prostate_volume_0 && props.inputs.psa_s_psa_level_0){
    // todo needs more testing, remove after demo/testing
    try{
      let psa = Array.from(props.inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
      if (psa.length > 0) {
        let vol = Array.from(props.inputs.psa_s_prostate_volume_0).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
        vol = vol.join("")
        psa = psa.join("")
        props.inputs.psa_s_prostate_density_0 = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6);
        // props.inputs.psa_s_prostate_volume_0 = parseFloat(vol);
      }
    }
    catch (error){
      console.debug("System Error");
    }
  }

  if (props.inputs.re_eval_mp_mri_base_line_mri_prostate_volume && props.inputs.psa_s_psa_level_0){
    try{
      let psa = Array.from(props.inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
      if (psa.length > 0) {
        let vol = Array.from(`${props.inputs.re_eval_mp_mri_base_line_mri_prostate_volume}`).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
        vol = vol.join("")
        psa = psa.join("")
        props.inputs.re_eval_mp_mri_mr_prostate_density = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6);
      }
    }
    catch (error){
      console.debug("System Error");
    }
  }

  const {t} = useTranslation();

  if (props.inputs.iief_ipss_s_quality_of_life_score){
    props.inputs.iief_ipss_s_quality_of_life_score = t(props.inputs.iief_ipss_s_quality_of_life_score);
  }
  let showSaveButton = false;
  if (props.frm.hide_save_button || props.asJourney.locked || props.hideSaveButton){
    showSaveButton = false;
  }
  else if (![props.asJourney.expert_urologist, props.asJourney.expert_radiologist].includes(userUuid) && userType !== "support"){
    showSaveButton = false;
  }
  // else if (["psa_s_", "pd_uploads_", "pd_uploads_2"].includes(props.frm.submit_key) && !props.inputs.expert_radiologist_decision){
  //   showSaveButton = true;
  // }
  else if (props.editorType === "patient" || userType === "support" ? true : (userType === props.editorType)){
    if (!["iief_ipss_s_"].includes(props.frm.submit_key))
      showSaveButton = props.asJourney.stages[props.stageIndex].active && props.asJourney.stages[props.stageIndex].status !== 'COMPLETED'
    if (["psa_s_"].includes(props.frm.submit_key)){
      showSaveButton = false;
    }
  }

  let listOfImpFormSubmitKeys = ["expert_urologist_decision_one", "expert_urologist_decision_two", "expert_radiologist_decision"]
  let journeyLocked = props.locked || (["pd_"].includes(props.frm.submit_key)) || ((![props.asJourney.expert_urologist, props.asJourney.expert_radiologist].includes(userUuid) || userType === "support") && listOfImpFormSubmitKeys.includes(props.frm.submit_key)) || (props.editorType === "patient" || userType === "support" ? false : (userType !== props.editorType)) || props.asJourney.locked || !(props.asJourney.stages[props.stageIndex].active || (["psa_s_", "pd_uploads_", "iief_ipss_s_"].includes(props.frm.submit_key) && !props.inputs.expert_radiologist_decision && !props.locked))
  let formInputs = props.frm.inputs
  let formLastUpdatedAt = 0;
  formInputs.forEach(f => {
    if (props.inputs[f] && props.inputs_updated_at[f] && props.inputs_updated_at[f] > formLastUpdatedAt){
      formLastUpdatedAt = props.inputs_updated_at[f];
    }
  })
  let status = t(props.frm.status);
  if ("pd_" === props.frm.submit_key && props.frm.status === "Submitted By Patient" && props.asJourney?.created_at){
    status = t("Submitted") + " " + getFormattedDate(props.asJourney?.created_at, " DD MMM YY ", t) + " / " + t("Patient")
  }
  else if ("psa_s_" === props.frm.submit_key && props.frm.status === "Submitted By Patient"){
    status = t("Submitted") + " " + getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) + " / " + t("Patient")
    formLastUpdatedAt = 0;
  }
  else if (["pd_uploads_"].includes(props.frm.submit_key) && formLastUpdatedAt > 0){
    let status_part_one = "Submitted"
    let pd_inputs = []
    if (props?.asJourney?.authors && props?.asJourney?.inputs){
      pd_inputs = props?.asJourney?.inputs.filter(i => ["biopsy", "mri_report"].includes(i.key));
      if (pd_inputs.length > 0 )
        props.frm.authors = pd_inputs.filter(i => props?.asJourney?.authors.find(k => k.uuid === i.author)).map(i => i.author)
      status = "Updated"
    }
    if (props.frm.status === "Submitted By Patient"){
      status_part_one = "Submitted"
    }
    status = t(status_part_one) + " " + getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) + ((!props.frm.authors || props.frm.authors.length < 1 || (pd_inputs.length > 0 && props.frm.authors.length !== pd_inputs.length)) ? " / Patient " : "")
    formLastUpdatedAt = 0;
  }
  else if (["iief_ipss_s_"].includes(props.frm.submit_key)){
    let status_part_one = "Submitted"
    let i_inputs = props.asJourney?.inputs.filter(i => i.key.includes("iief_") || i.key === "iief" || i.key === "ips")
    let i_authors = []
    if (i_inputs.length > 0){
      i_inputs.forEach(f => {
        if (f?.updated_at > formLastUpdatedAt){
          if (!["dummy_key", "psa_s_date_of_test_0", "psa_s_prostate_density_0"].includes(f))
            formLastUpdatedAt = f?.updated_at;
        }
      })
      i_authors = i_inputs.filter(i => Boolean(i.author)).map(i => i.author)
      if (i_authors.length > 0){
        status_part_one = "Updated"
      }
    }
    if (props.frm.status !== "Submitted By Patient"){
      status_part_one = "Incomplete"
    }
    status = t(status_part_one) + " " + getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) + " / " + t("Patient")
    formLastUpdatedAt = 0;
  }
  else if (props.frm.submit_key === "img_material_"){
    if (formLastUpdatedAt < props?.inputs_updated_at?.["default_value_img_material"]){
      formLastUpdatedAt = props?.inputs_updated_at?.["default_value_img_material"]
    }
  }

  let authors = ""
  if (props.frm.authors && props.frm.authors.length > 0 || (props.frm.submit_key === "img_material_" && props.asJourney?.inputs?.find(i => i.key === "default_value_img_material")?.author)){
    let formAuthors = props.asJourney.authors.filter(a => props.frm.authors.includes(a.uuid) || (props.frm.submit_key === "img_material_" && a.uuid === props.asJourney?.inputs?.find(i => i.key === "default_value_img_material")?.author));
    authors = <span style={{display:"flex", flexDirection:"row", alignContent:"center", justifyContent:"center"}}>
      {formAuthors.map((author, i) => <div key={i} className="person-card-container" style={{ paddingLeft: '5px', paddingRight: '5px'}}><span
        style={{ borderBottom: '1px solid var(--dark-gray' }}>
              {author.last_name}, {author.first_name.slice(0, 1)} {( i !== formAuthors.length-1) ? "/": ""}
            </span>
        <div style={{ position: 'relative', height: 0, background: 'transparent', left: '-100px' }}>
          <div className="person-card-container-tool-tip"
               style={{ background: 'transparent', border: 0, boxShadow: 0 }}><PractitionerCard person={author}/></div>
        </div>
      </div>)
      }
    </span>;
  }
  return <div ref={props.ref} style={{
    opacity: props.asJourney.stages[props.stageIndex].active || props.asJourney.stages[props.stageIndex].status === 'COMPLETED' || props.locked ? 1 : 0.4,
    margin: '20px 0'
  }}>
    <Row style={{ padding: 0, margin: 0 }}>
      <Col style={{ textAlign: 'start', padding: 0, margin: 0 }}>
        <span className={'sub_title'}>{t(props.frm.sub_title)}</span>
      </Col>
      <Col style={{ textAlign: 'end', padding: 0, margin: 0 }}>
        <span className={'as_status'} style={{display:"flex", flexDirection:"row", textAlign:"end", justifyContent:"end"}}>
          {status}{formLastUpdatedAt > 0 ? getFormattedDate(formLastUpdatedAt, " DD MMM YY ", t) : ""}{props.frm.status && props.frm.status.toLowerCase().includes("patient") && authors !== "" ? " /" : (authors !== "" ? ` /`: "")}{authors}</span>
      </Col>
    </Row>
    <div style={{ margin: '11px 0 0 0', padding: 0, display: 'flex', flexDirection: 'row' }}>
      {props.frm.form.map((group, group_i) => getFormToDisplay(group_i, group, journeyLocked, mriReportTaskUid, biopsyTaskUid, mriTaskUid, props.asJourney, props.inputs, props.patient_id, props.uploadMri, props.updateInput, t, props.token, props.updateMriUploadProgress))}
    </div>
    {showSaveButton ?
      <Button onClick={props.onClick} className={'blue-buttons'}
              style={{ marginTop: '15px' }}>{t("save")}</Button> : ''}
  </div>
}


function getFormToDisplay (group_i, group, locked, mriReportTaskUid, biopsyTaskUid, mriTaskUid, journey, inputs, patient_id, uploadMri, updateInput, t, token, updateMriUploadProgress) {
  return <div className={'individual_tables'} key={group_i} style={{ flex: 1 }}>
    <table style={{ width: '100%', padding: 0, margin: 0 }}>
      <tr>
        <th/>
        {group.column_headers.map((ch, ic) => (
          <TableHeaderAs key={ic} column_header={ch} inputs={inputs}/>
        ))}</tr>
      {group.row_headers.map((cr, ir) => (
        <tr key={ir}>
          <td
            colSpan={cr.col_span}
            className={'row_header'}
            style={{
              border: cr.visible ? '1px solid var(--border-color)' : '',
              visibility: cr.visible,
              width: cr.visible ? `${Math.floor((cr.key === "scan_type" ? 5: (cr.key === "number_of_positive_cores"? 7 : cr.col_width)) / 12 * 100)}%` : 0,
              padding: cr.visible ? '' : 0,
              textAlign: cr.reverse ? "end" : "start"
            }}>{t(cr.value)}</td>
          {group.column_headers.map((cval, cval_i) => (
            group.table_rows[cr.key][cval.key] ? <TableDivAs key={cval_i} cval={cval} group={group} locked={locked} refreshPage={() => window.location.reload()}
                                                             cr={cr} inputs={inputs} patient_id={patient_id} uploadMri={uploadMri} updateMriUploadProgress={updateMriUploadProgress} mriReportTaskUid={mriReportTaskUid} biopsyTaskUid={biopsyTaskUid} token={token}
                                                             updateInput={updateInput}/> : ''
          ))}
        </tr>
      ))}
    </table>
  </div>
}
