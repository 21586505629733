import './WaveAsSummaryReport.css'
import React from 'react'
import * as Constants from '../../constants/Constants'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Container } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download.svg'
import ChariteLogoPng from '../../assets/icons/bayde_logos/charite_logo.png'
import VivantesLogoPng from '../../assets/icons/bayde_logos/vivantes_logo.png'
import { fetcher, reportAction } from '../../utils/axios'
import StageForm from '../../components/AsJourneyReport/StageForm'
import { toast } from 'react-toastify'
import HashLoader from 'react-spinners/HashLoader'
import { withTranslation } from 'react-i18next'
import MessagePatientCard from '../../components/PatientProfile/MessagePatient'
import AsAssessmentOverview from '../../components/PatientProfile/AsAssessmentOverview'
import AsAssessmentStatusRow from '../../components/PatientProfile/AsAssessmentStatusRow'
import * as helpers from '../../helpers/helpers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactToPrint from 'react-to-print'
import { Base64 } from 'js-base64';

class AsJourneySummaryReport extends React.Component {
  componentRef = null

  constructor (props) {
    super(props)
    let urlQueryParams = new URLSearchParams(this.props.location.search)
    this.state = {
      error: this.props.t('Unable to load the Page'),
      isLoaded: false,
      pageNumber: 1,
      numPages: null,
      report: null,
      showAsJourneyList: false,
      showOverlay: false,
      fetchData: true,
      defaultAsJourneyList: !this.props.removeAsJourneyList,
      messagePatient: this.props.messagePatientButton,
      showMessagePatientCard: false,
      reportList: [],
      colorTheme: urlQueryParams.get('theme') ? urlQueryParams.get('theme') : 'light',
      asJourney: { stages: [{}, {}, {}, {}] },
      uploadProgress: 0,
      mriUid: null, mriProgress: 0, mriFileSize: 1,
      patient: { uuid: this.props.match.params.patientIdentifier },
      showPrintVersion: false,
      uploadStartTime: 0
    }
    this.hideOverlay = this.hideOverlay.bind(this)
    this.getInputs = this.getInputs.bind(this)
    this.fileUploadRef = React.createRef()
    this.patientDataRef = React.createRef()
    this.expertUrologistOneRef = React.createRef()
    this.expertUrologistTwoRef = React.createRef()
    this.expertRadiologistRef = React.createRef()
    this.AsJourneyPrintRef = React.createRef()
  }

  componentDidMount () {
    localStorage.setItem('MRI_STATUS', 'NA')
    if (this.state.isLoaded) {
      return
    }
    reportAction('VIEWED_AS_JOURNEY', this.props.match.params.patientIdentifier)
    this.componentDidUpdate()
  }

  componentDidUpdate () {
    console.debug('Journey', this.state.asJourney)
    if (this.state.isLoaded) {
      return
    }
    if (this.state.fetchData) {
      this.setState({ fetchData: false })
      this.getMriInfo()
      var data = JSON.stringify({
        query: `query ($patient_id: String!) {
                patient(id: $patient_id){
                    uuid
                    first_name
                    last_name
                    photo
                    gender
                    birthdate
                    age
                    autoimmune
                    neurological
                    cancer
                    marital_status
                    education
                    kids
                    relatives
                    chronic_conditions
                    care{
                        expert_radiologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                        expert_urologist{
                            uuid
                            first_name
                            last_name
                            photo
                            account_type
                            speciality
                            campus{
                                name
                            }
                            contact{
                                contact_type
                                phone
                                email
                                address{
                                    first_line
                                    unit
                                    zipcode
                                    city
                                }
                            }
                        }
                    }
                    as_decision_status{
                        title
                        status
                    }
                    tx_status{
                        title
                        status
                        as_year
                    }
                 
          }
                          journeys(patient_id: $patient_id) {
                              uid
                              state
                              locked
                              as_decision_phase
                              organization_id
                              authors{
                                uuid
                                first_name
                                last_name
                                photo
                                account_type
                                speciality
                                campus{
                                    name
                                }
                                contact{
                                    contact_type
                                    phone
                                    email
                                    address{
                                        first_line
                                        unit
                                        zipcode
                                        city
                                    }
                                }
                              }
                              inputs{
                                  key
                                  value
                                  updated_at
                                  author
                                  form_uid
                              }
                              stages{
                                  uid
                                  title
                                  status
                                  editor
                                  editor_type
                                  active
                                  forms{
                                    uid
                                    hide
                                    hide_save_button
                                    submit_key
                                    sub_title
                                    status
                                    structure
                                    authors
                                    updated_at
                                    inputs
                                    task{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                    }
                                  }
                                  tasks{
                                        uid
                                        group_ids
                                        status
                                        assignee
                                        action
                                    }
                              }
                          }
                        }`,
        variables: {
          patient_id: this.props.match.params.patientIdentifier
        }
      })
      fetcher(Constants.GRAPHQL_API, data)
        .then((response) => {
          if (!response.data || !response.data.journeys) {
            console.debug('Got Error', response.errors)
            this.setState({ error: this.props.t('Unable to load the Page'), isLoaded: true })
            toast.error(this.props.t('Unable to load the Page'))
            return
          }
          let patient
          let personal_data
          if (response.data.patient) {
            patient = { ...this.state.patient, ...response.data.patient, ...response.data.patient.care }
            let ed = []
            if (patient.cancer) {
              ed.push(patient.cancer)
            }
            if (patient.neurological) {
              ed.push(patient.neurological)
            }
            if (patient.autoimmune) {
              ed.push(patient.autoimmune)
            }
            personal_data = {
              pd_birthdate_gender: (patient.birthdate ? (helpers.getFormattedBirthdate(patient.birthdate, this.props.t) + ` (${helpers.getAge(patient.birthdate)} ${this.props.t('yrs')}),`) : '') + this.props.t(patient.gender ? patient.gender : 'Male'),
              pd_age_gender: (patient.age ? (patient.age + ', ') : '') + this.props.t(patient.gender ? patient.gender : 'Male'),
              pd_comorbid_conditions: patient.chronic_conditions ? patient.chronic_conditions.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join('; ') : '',
              pd_existing_diseases: ed.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join('; '),
              pd_family_status: ((patient.marital_status ? this.props.t(patient.marital_status) : '') + (![undefined, null].includes(patient.kids) ? `; ${patient.kids} ${this.props.t(`child${patient.kids > 1 ? 'ren' : ''}`)}` : '')),
              pd_family_history: patient.relatives ? (`${this.props.t('Prostate Cancer')} - ` + patient.relatives.filter(pr => ![null, undefined, ''].includes(pr)).map(p => this.props.t(p)).join(',')) : '',
            }
          }
          let assessment = null
          response.data.journeys.forEach(j => {
            j.stages.map(stage => {
              stage.forms.map(form => {
                form.form = JSON.parse(form.structure)
              })
            })
            if (j.uid === this.props.match.params.asJourneyId) assessment = j

          })
          if (!assessment) throw Error('Invalid Report ID')
          if (patient.expert_urologist) assessment.expert_urologist = patient.expert_urologist.uuid
          if (patient.expert_radiologist) assessment.expert_radiologist = patient.expert_radiologist.uuid
          let inputs = {}
          let inputs_updated_at = {}
          if (assessment.inputs && assessment.inputs.length > 0) {
            assessment.inputs.forEach(inp => {
              inputs[inp.key] = inp.value
              inputs_updated_at[inp.key] = inp.updated_at
            })
          }
          let refs = [this.patientDataRef, this.expertUrologistOneRef, this.expertRadiologistRef, this.expertUrologistTwoRef]
          for (let o = 0; o < 4; o++) {
            assessment.stages[o].ref = refs[o]
          }
          if (inputs.psa_s_prostate_volume_0 && inputs.psa_s_psa_level_0) {
            try {
              let psa = Array.from(inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= '9'.charCodeAt(0) && '0'.charCodeAt(0) <= s.charCodeAt(0)) || s === '.')
              if (psa.length > 0) {
                let vol = Array.from(inputs.psa_s_prostate_volume_0).filter(s => (s.charCodeAt(0) <= '9'.charCodeAt(0) && '0'.charCodeAt(0) <= s.charCodeAt(0)) || s === '.')
                vol = vol.join('')
                psa = psa.join('')
                inputs.psa_s_prostate_density_0 = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6)
                // props.inputs.psa_s_prostate_volume_0 = parseFloat(vol);
              }
            } catch (error) {
              console.debug('System Error')
            }
          }
          inputs["img_material_dce_b_value"] = "N/A"
          inputs["img_material_t2w_b_value"] = "N/A"
          inputs["img_material_t1wpool_b_value"] = "N/A"
          inputs["img_material_t2w_temporal_resolution"] = "N/A"
          inputs["img_material_dwi_temporal_resolution"] = "N/A"
          inputs["img_material_t1wpool_temporal_resolution"] = "N/A"
          if (inputs.brd_cancer_core_length && inputs.brd_core_length){
            inputs.brd_cancer_core_ratio = Math.floor((parseInt(inputs.brd_cancer_core_length) / parseInt(inputs.brd_core_length)) * 100);
          }
          if (inputs?.re_eval_mp_mri_base_line_mri_prostate_volume && inputs.psa_s_psa_level_0){
            try{
              let psa = Array.from(inputs.psa_s_psa_level_0).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
              if (psa.length > 0) {
                let vol = Array.from(`${inputs.re_eval_mp_mri_base_line_mri_prostate_volume}`).filter(s => (s.charCodeAt(0) <= "9".charCodeAt(0) && "0".charCodeAt(0) <= s.charCodeAt(0)) || s === ".")
                vol = vol.join("")
                psa = psa.join("")
                inputs.re_eval_mp_mri_mr_prostate_density = parseFloat(parseFloat(psa) / parseFloat(vol)).toFixed(6);
              }
            }
            catch (error){
              console.debug("System Error");
            }
          }
          this.setState({
            isLoaded: true,
            patient: patient,
            asJourney: assessment,
            asJourneyList: response.data.journeys, ...inputs, ...personal_data,
            inputs_updated_at
          })
        })
        .catch((err) => {
          console.debug(err)
          this.setState({ error: this.props.t('Unable to load the Page.'), isLoaded: true })
          toast.error(this.props.t('Unable to load the Page.'))
        })

    }
  }

  openReportListOverlay (toggle) {
    if (toggle) {
      this.setState({
        showAsJourneyList: !this.state.showAsJourneyList,
        showOverlay: !this.state.showAsJourneyList
      })
      return
    }
    this.setState({ showAsJourneyList: true, showOverlay: true })
  }

  uploadMri (mriFile, mriTaskUid) {
    if (!this.state.isLoadedMri) {
      toast.info('Please wait')
    }
    this.setState({ isLoadedMri: false, uploadStartTime: Math.floor(Date.now() / 1000), mriFileSize: mriFile.size })
    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + this.props.auth.token)
    var formdata = new FormData()
    localStorage.setItem('MRI_STATUS', 'UPLOADING')
    formdata.append('operations', `{\"query\":\"mutation ($file: Upload!, $user_id: String!, $mri_task_uid: String!) { mri_upload(mri_file: $file, uuid: $user_id, mri_task_uid: $mri_task_uid){status}}\",\"variables\":{\"file\":null, \"user_id\":\"${this.props.match.params.patientIdentifier}\",\"mri_task_uid\":\"${mriTaskUid}\"}}`)
    formdata.append('map', '{"0": ["variables.file"]}')
    formdata.append('0', mriFile, '')

    fetcher(Constants.GRAPHQL_API, formdata, {
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        this.setState({ uploadProgress: progress })
      }
    })
      .then(result => {
        if (result.errors) {
          throw Error(result.errors)
        }
        setTimeout(function () {
          localStorage.setItem('MRI_STATUS', 'UPLOADED')
        }, 5000)
        this.setState({ isLoadedMri: true, uploadStartTime: 0, uploadProgress: 0, mriStatus: 'upload_processing' })
        toast.info('MRI uploaded successfully.')
        this.getMriInfo()

      })
      .catch(error => {
        console.error(error)
        setTimeout(function () {
          localStorage.setItem('MRI_STATUS', 'UPLOAD_FAILED')
        }, 5000)

        this.setState({ isLoadedMri: true })
        toast.error('Unable to upload, please contact admin')
      })
  }

  getMriInfo () {
    let data = JSON.stringify({
      query: `mutation ($user_id: String!) {
                        resource_access(uuid: $user_id, resource_type:"mri"){
                            mris{
                                uid
                                status
                                error
                                created
                                progress
                            }
                        }
                    }`,
      variables: { user_id: this.props.match.params.patientIdentifier }
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((response) => {
        if (!response.errors && response.data && response.data.resource_access) {
          if (response.data.resource_access.mris && response.data.resource_access.mris.length > 0) {
            let mri = response.data.resource_access.mris.find(m => m.status === 'upload_success')
            if (mri) {
              this.setState({
                mriUid: mri.uid,
                pd_uploads_mri_upload_date: mri.created,
                mriStatus: mri.status,
                mriError: mri.error
              })
              return
            }
            let statusObjMri = response.data.resource_access.mris.find(m => m.uid === '')
            if (statusObjMri) {
              this.setState({
                mriStatus: statusObjMri.status,
                mriError: statusObjMri.error,
                mriProgress: statusObjMri.progress
              })
              if (statusObjMri.status === 'upload_processing') {
                setTimeout(() => this.getMriInfo(), 5000)
              }
            }
          }
        } else {
          // throw Error("Error retrieving patient information");
        }
      })
      .catch((err) => {
        console.debug('Error getting mri', err)
        this.setState({ isLoadedMri: true })
        // toast.error("Error retrieving patient information.");
      })
  }

  getInputs (inputs) {
    let result = []
    let keys = inputs ? inputs : Object.keys(this.state.asJourney.inputs ? this.state.asJourney.inputs : {})
    Object.keys(this.state).forEach(item => {
      if (keys.includes(item)) {
        result.push({ key: item, value: this.state[item] })
      }
    })
    return result
  }

  hideOverlay () {
    this.setState({ showAsJourneyList: false, showOverlay: false })
  }

  reactToPrintContent = () => {
    return this.componentRef
  }

  downloadFile(text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', text);
    pom.setAttribute('download', "report.pdf");

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }
  }

  getFormattedReportHTML(patient, journey, t, state){
    let lastUpdatedTime = 0;
    if (journey.inputs && journey.inputs.length > 0)
      journey.inputs.forEach(input => {
        if (input.updated_at && input.updated_at > lastUpdatedTime){
          lastUpdatedTime = input.updated_at;
        }
      })
    return `
<!DOCTYPE html>
<html lang="en">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap" rel="stylesheet">
<style>
    body{
        margin:0;
        padding:0;
    }
    .page{
        font-family: 'Work Sans', sans-serif;
        font-size: 10px;
        color: #191919;
        min-height: 841px;
        height:100vh;
        margin-bottom: 20px;
        background-color: white;
        page-break-after: always !important;
    }
    @media screen {
        div.page{
            width:700px;
        }
        div.div-footer {
            border-top: 2px solid #F3F3F3;
            margin-top: 20px;
        }
    }
    @media print {
        div.div-footer {
            position: fixed;
            bottom: 0;
            left:0;
            border-top: 2px solid #F3F3F3;
            margin-top: 20px;
        }
    }
    .bordered-table{
        border: 1px solid #191919;
        border-spacing: 0 !important;
        border-collapse: collapse;
    }
    .td-header{
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        background: #F3F3F3;
        border-right: 1px solid #191919;
        padding: 5px;
    }
    tr{
        border-bottom: 1px solid #191919;
    }
    .td-column{
        padding: 5px;
        width: 50%
    }
    .section{
        padding-left:15px;
        padding-right:15px;
    }
    .section-title{
        padding-left:15px;
        padding-right:15px;
        height: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 15px;
        line-height: 20px;
    }
    @media print {
        @page {
            size: A4 portrait;
            margin: 15px 0 0 0;
        }
    }
</style>
<head>
    <meta charset="UTF-8">
    <title>${t("Summary")} ${t("for")} ${patient?.last_name + (patient?.first_name ? ", ": "") + patient?.first_name}</title>
</head>
<body style="">
<div class="page">
    <div class="section" style="display: flex;flex-direction: row; height:48px; border-bottom: 2px solid #F3F3F3">
        <div style="align-self: start; flex:2; display:flex; flex-direction: row; align-items: center; height:100%">
            <span style="font-style: normal;
            height: 20px;
            padding: 0 5px;
            font-weight: 600;
            line-height: 16px;
            background: #F3F3F3;
            border: 1px solid #191919;
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-radius: 2px;
            box-sizing: border-box;">
                ${t(patient?.tx_status?.title)}</span>
            <span style="
              flex-direction: row;
              justify-content: center;
              align-self: center;
              padding: 0 5px;font-weight: 600; margin-left: 10px; display:${patient?.last_name || patient?.first_name ? "flex": "none"}">
                ${t("Summary")} ${t("for")} ${patient?.last_name + (patient?.first_name ? ", ": "") + patient?.first_name}
            </span>
        </div>
        <div style="margin:20px 0 20px 20px; display: flex; flex-direction: row-reverse; flex:2;">
            <div style="align-self: center">
                <span style="font-style: normal; font-weight: 600; white-space: nowrap">${journey.state === "COMPLETED" ? t("Completed") + " " + helpers.getFormattedDate(lastUpdatedTime, " DD MMM YY "): (t(journey.state ? t(journey.state) : t('In Progress')))}</span>
                <span style="padding-left:5px;display:${patient?.care?.expert_radiologist || patient?.care?.expert_urologist ? "": "none"}"> ${t("By")} </span>
                <span style="padding-left:5px;padding-right:5px;display:${patient?.care?.expert_urologist ? "": "none"}">${helpers.getPractitionerName(patient?.care?.expert_urologist?.first_name, patient?.care?.expert_urologist?.last_name)}</span>
                <span>/</span>
                <span style="padding-left:5px;display:${patient?.care?.expert_radiologist ? "": "none"}"> ${helpers.getPractitionerName(patient?.care?.expert_radiologist?.first_name, patient?.care?.expert_radiologist?.last_name)} </span>
            </div>
        </div>
    </div>
    <div class="section-title">
        ${t("Patient Data")}
    </div>
    <div class="section" style="display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1;margin-right: 5px;">
            <tbody>
                <tr class="tr-row">
                    <td class="td-header td-column">${t("First Name")}</td>
                    <td class="td-column">${patient?.first_name ? patient?.first_name : ""}</td>
                </tr>
                <tr>
                    <td class="td-header td-column">${t("Last Name")}</td>
                    <td class="td-column">${patient?.last_name ? patient?.last_name : ""}</td>
                </tr>
            </tbody>
        </table>
        <table class="bordered-table" style="flex: 1">
            <tbody>
                <tr>
                    <td class="td-header td-column">${t("Age")}</td>
                    <td class="td-column">${patient?.age ? patient?.age : ""}</td>
                </tr>
                <tr>
                    <td class="td-header td-column">${t('Family Status')}</td>
                    <td class="td-column">${state?.pd_family_status ? state?.pd_family_status : ""}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
                <tr>
                    <td class="td-header" style="width: 24.8%">${t('Family History')}</td>
                    <td style="padding:5px">${state.pd_family_history ? state.pd_family_history : ""}</td>
                </tr>
                <tr>
                    <td class="td-header" style="width: 24.8%">${t('Existing Diseases')}</td>
                    <td style="padding:5px">${state.pd_existing_diseases ? state.pd_existing_diseases : ""}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="section-title">
        ${t('Clinical Parameters')}
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1">
            <tbody>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('PSA Level')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_0 ? state?.psa_s_psa_level_0: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_1 ? state?.psa_s_psa_level_1: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_2 ? state?.psa_s_psa_level_2: ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state?.psa_s_psa_level_3 ? state?.psa_s_psa_level_3: ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Date of Test')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_0 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_0), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_1 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_1), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_2 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_2), 'DD MMM YY', t) : ''}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_date_of_test_3 ? helpers.getFormattedDate(parseInt(state.psa_s_date_of_test_3), 'DD MMM YY', t) : ''}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Prostate Volume')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_0 ? (state.psa_s_prostate_volume_0 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_1 ? (state.psa_s_prostate_volume_1 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_2 ? (state.psa_s_prostate_volume_2 + " mL") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_volume_3 ? (state.psa_s_prostate_volume_3 + " mL") : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 24.8%">${t('Prostate Density')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_0 ? (state.psa_s_prostate_density_0 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_1 ? (state.psa_s_prostate_density_1 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_2 ? (state.psa_s_prostate_density_2 + " ng/mL²") : ""}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 18.75%">${state.psa_s_prostate_density_3 ? (state.psa_s_prostate_density_3 + " ng/mL²") : ""}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 2; margin-right: 5px ">
            <tbody>
            <tr>
                <td class="td-header" style="width: 70%">${t('Date of Biopsy')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.brd_date_of_biopsy ? helpers.getFormattedDate(state.brd_date_of_biopsy.includes(" ") ? state.brd_date_of_biopsy: parseInt(state.brd_date_of_biopsy), 'DD MMM YY', t) : ''}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 70%">${t('Gleason Score')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.brd_gleason_score ? state.brd_gleason_score : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 70%">${t('ISUP')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.brd_isup_score ? state.brd_isup_score : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 70%">${t('Number of Cores')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.brd_number_of_cores ? state.brd_number_of_cores : ""}</td>
            </tr>
            </tbody>
        </table>
        <table class="bordered-table" style="flex: 3">
            <tbody>
            <tr>
                <td class="td-header" style="width: 80%">${t('Number of Positive Cores')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 20%">${state.brd_number_of_positive_cores ? state.brd_number_of_positive_cores : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 80%">${t('Cancer Core Length')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 20%">${state.brd_cancer_core_length ? (state.brd_cancer_core_length + " mm") : ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 80%">${t('Cancer Core Ratio')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 20%">${![null, undefined, ""].includes(state.brd_cancer_core_ratio) ? (state.brd_cancer_core_ratio + " %"):""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 80%">${t('Intraductal PCa')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 20%">${state.brd_intraductal_pca ? state.brd_intraductal_pca : ""}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 2; margin-right: 5px ">
            <tbody>
            <tr>
                <td class="td-header" style="width: 70%">${t('Date of MRI')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.date_of_mp_mri ? helpers.getFormattedDate(state.date_of_mp_mri.includes(" ") ? state.date_of_mp_mri: parseInt(state.date_of_mp_mri), 'DD MMM YY', t) : ''}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 70%">${t('Primary PI-RADS')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${t(state.primary_pi_rads)}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 70%">${t('Number of PI-RADS Lesions')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 30%">${state.number_of_pi_rads_lesions ? state.number_of_pi_rads_lesions : ""}</td>
            </tr>
            </tbody>
        </table>
        <div style="flex:3"></div>
    </div>
    <div class="section-title">
        ${t('Recommendations')}
    </div>

    <div class="section" style="margin-top:7px; display: flex; flex-direction: row;">
        <table class="bordered-table" style="flex: 1; margin-right: 5px ">
            <tbody>
            <tr>
                <td class="td-header" style="width: 75%">${t('Radiology Recommendation')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 25%">${state?.inputs_updated_at?.expert_radiologist_decision ? helpers.getFormattedDate(state?.inputs_updated_at?.expert_radiologist_decision, "DD MMM YY", t): ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 75%">${t('Is the patient an AS candidate?')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 25%">${t(this.state.expert_radiologist_decision)}</td>
            </tr>
            <tr>
                <td colspan="2" style="padding:5px; border-right: 1px solid #191919; width: 100%; height: 110px; vertical-align: top">
                    <b>${t('Radiologist Comments')}:</b> ${state.expert_radiologist_decision_comments ? state.expert_radiologist_decision_comments : ""}
                </td>
            </tr>
            </tbody>
        </table>
        <table class="bordered-table" style="flex: 1; margin-right: 5px">
            <tbody>
            <tr>
                <td class="td-header" style="width: 75%">${t('Urology Recommendation')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 25%">${state?.inputs_updated_at?.expert_urologist_decision_two ? helpers.getFormattedDate(state?.inputs_updated_at?.expert_urologist_decision_two, "DD MMM YY", t): ""}</td>
            </tr>
            <tr>
                <td class="td-header" style="width: 75%">${t('Is the patient an AS candidate?')}</td>
                <td style="padding:5px; border-right: 1px solid #191919; width: 25%">${t(state.expert_urologist_decision_two)}</td>
            </tr>
            <tr>
                <td colspan="2" style="padding:5px; border-right: 1px solid #191919; width: 100%; height: 110px; vertical-align: top">
                    <b>${t('Urologist Comments')}:</b> ${state.expert_urologist_decision_two_comments ? state.expert_urologist_decision_two_comments : ""}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="div-footer" style="width:100%">
        <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start; margin:15px">
            <div style="flex:1; text-align: start; display: flex; flex-direction: column">
                <span><b>Unterstützt durch</b></span>
                <span>Klinik für Urologie</span>
                <span>Vivantes Klinikum Am Urban Berlin</span>
                <span>Vivantes Klinikum Im Friedrichshain</span>
                <span>Vivantes Humboldt-Klinikum</span>
            </div>
            <div style="flex:1; text-align: center;display:flex; flex-direction: column; align-items: center">
                <img src="https://cdn.ttisystems.cloud/vivantes_logo.png" style="width: 70px; height:auto" />
                <img src="https://cdn.ttisystems.cloud/charite_logo.png" style="width: 70px; height:auto" />
            </div>
            <div style="flex:1; text-align: end; display: flex; flex-direction: column">
                <span>Klinik für Radiologie</span>
                <span>Charité-Universitätsmedizin</span>
            </div>
        </div>
    </div>
</div>
</body>
</html>
`
  }

  getReportPDF(bodyContent){
    this.setState({isLoaded:false})
    var data = JSON.stringify({
      query: `mutation($content: String!){
                        pdf_create(page_size:"A4" content: $content)
                    }
            `,
      variables: {
        content: bodyContent
      }
    })
    fetcher(Constants.GRAPHQL_API, data)
      .then((res) => {
        this.setState({isLoaded:true})
        if(res?.data?.pdf_create){
          this.downloadFile(res?.data?.pdf_create)
        }
        else
          toast.error(this.props.t("Something went wrong"));
      })
      .catch((err) => {
        console.debug(err)
        this.setState({ error: this.props.t('Unable to load the Page.'), isLoaded: true })
        toast.error(this.props.t('Unable to load the Page.'))
      })
  }

  render = () => {
    return (
      <div data-theme={this.state.colorTheme} style={{
        background: 'var(--background-color-faint)',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        color: 'var(--text-color)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px',
        padding: 0,
        margin: 0,
        lineHeight: '22px'
      }}>
        <div style={{ height: 0, position: 'absolute', left: '50%', top: '50%' }}><HashLoader color="#4676F4"
                                                                                              loading={!this.state.isLoaded}
                                                                                              size={35}/></div>
        <div onClick={this.hideOverlay} style={{
          position: 'absolute',
          width: '100%',
          height: '100vh',
          background: 'rgba(0, 0, 0, 0.05)',
          left: 0,
          top: 0,
          display: this.state.showOverlay ? 'block' : 'none',
          zIndex: 80
        }}/>
        <Row style={{
          marginRight: '0',
          marginLeft: '0',
          maxWidth: 'calc(max(100vw, 1440px))',
          width: '842px',
          marginTop: '40px',
          marginBottom: '30px'
        }}>
          <Col className="page-tittle" style={{
            paddingLeft: '0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'start'
          }}>
            <Button onClick={() => this.props.history.goBack()} className="white-buttons-round-wr"
                    variant="light" style={{ marginLeft: '0' }}>
              <svg style={{ transform: 'rotate(90deg)' }} width="24" height="24" viewBox="0 0 18 18"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.00019 2.44481C9.41494 2.44481 9.75019 2.78006 9.75019 3.19481V12.4393L12.2199 9.96956C12.5132 9.67706 12.9872 9.67706 13.2804 9.96956C13.4267 10.1166 13.5002 10.3078 13.5002 10.4998C13.5002 10.6918 13.4267 10.8838 13.2804 11.0301L9.53044 14.7801C9.23719 15.0733 8.76319 15.0733 8.46994 14.7801L4.71994 11.0301C4.42669 10.7376 4.42669 10.2628 4.71994 9.96956C5.01319 9.67706 5.48719 9.67706 5.78044 9.96956L8.25019 12.4393V3.19481C8.25019 2.78006 8.58544 2.44481 9.00019 2.44481Z"
                      fill="var(--border-color)"/>
              </svg>
            </Button>
            <span style={{
              alignSelf: 'center',
              justifySelf: 'center',
              marginLeft: '15px',
              fontSize: '18px',
              lineHeight: '26px'
            }}>{this.props.reportTitle ? this.props.t(this.props.reportTitle) : this.props.t('Assessment')}</span>
          </Col>
          <Col style={{
            display: this.state.selectionList ? 'None' : 'flex',
            flexDirection: 'row',
            textAlign: 'end',
            justifyContent: 'flex-end',
            paddingRight: '5px'
          }}>
            {this.state.messagePatient ? <Button
              onClick={() => this.setState({ showMessagePatientCard: !this.state.showMessagePatientCard })}
              className={'white-buttons-wr'} style={{
              color: 'var(--text-color)',
              background: 'var(--background-color)',
              fontWeight: 600,
              padding: '11px 24px',
              alignSelf: 'end',
              justifySelf: 'end',
              height: '44px',
            }}>{this.props.t('Message Patient')}</Button> : ''}
            <div onMouseEnter={() => this.setState({ showMessagePatientCard: true })}
                 className="message-patient-container" style={{
              position: 'relative',
              background: 'var(--background-color)',
              width: '0',
              height: '0',
              right: `283px`,
              top: '64px',
              display: this.state.showMessagePatientCard ? 'block' : 'none',
              zIndex: 100
            }}>
              <div className="triangle-with-shadow"
                   style={{ position: 'absolute', left: '165px', top: '-42px' }}/>
              <MessagePatientCard patient={{ uuid: this.props.match.params.patientIdentifier }}
                                  closeMessageBox={() => this.setState({ showMessagePatientCard: false })}/>
            </div>
            {this.state.defaultAsJourneyList && !this.state.showPrintVersion ?
              <Button onMouseEnter={() => this.openReportListOverlay(false)}
                      onClick={() => this.openReportListOverlay(true)}
                      className={this.state.isLoaded && this.state.reportList.length > 0 ? 'white-buttons-wr' : ''}
                      variant="light" style={{
                padding: '6px 27px',
                display: this.state.isLoaded && this.state.reportList.length > 0 ? '' : 'none'
              }}>
                {this.props.t('date')}:<span style={{
                fontWeight: 400,
                marginLeft: '5px',
                width: 'max-content'
              }}><b>{this.props.t('Assessment')}:</b> {this.props.t('Candidate')}</span>
                <svg style={{ justifySelf: 'center', alignSelf: 'center', marginLeft: '10px' }} width="14"
                     height="14"
                     viewBox="0 1 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.46466 4.23025C1.46466 3.97425 1.56266 3.71825 1.75766 3.52325C2.14766 3.13225 2.78066 3.13225 3.17166 3.52325L5.99966 6.35125L8.82866 3.52325C9.21866 3.13225 9.85266 3.13225 10.2427 3.52325C10.6327 3.91425 10.6327 4.54625 10.2427 4.93725L6.70666 8.47225C6.31666 8.86325 5.68366 8.86325 5.29266 8.47225L1.75766 4.93725C1.56266 4.74225 1.46466 4.48625 1.46466 4.23025Z"
                        fill="var(--border-color)"/>
                </svg>
              </Button> : ''}
            <div onMouseEnter={() => this.setState({ showAsJourneyList: true })}
                 onMouseLeave={this.hideOverlay}
                 style={{
                   position: 'relative',
                   background: 'var(--background-color)',
                   width: '0',
                   height: '0',
                   left: `-292px`,
                   top: '64px',
                   display: this.state.showAsJourneyList ? 'block' : 'none',
                   zIndex: 100
                 }}>
              <div className="triangle-with-shadow"
                   style={{ position: 'absolute', left: '123px', top: '-42px' }}/>
              <div style={{
                background: 'var(--background-color)',
                boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.05)',
                borderRadius: '10px',
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start'
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                  justifyItems: 'center',
                  width: '100%'
                }}>
                  <Col style={{
                    minHeight: '52px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10px'
                  }}>
                    {this.state.asJourneyList ? this.state.asJourneyList.map((rpt, j) => (
                      <div onClick={() => {
                        this.props.history.push(`${Constants.PATIENT_STATUS}/${this.props.match.params.patientIdentifier}${Constants.AS_JOURNEY}/${rpt.uid}`)
                        this.setState({ isLoaded: false, fetchData: true })
                        this.hideOverlay()
                      }} key={j}
                           className={`page-list-titles ${this.props.match.params.asJourneyId === rpt.uid ? 'page-list-titles-selected' : ''}`}
                           style={{
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'center',
                             width: '100%',
                             padding: '15px 20px',
                             margin: 0,
                             cursor: 'pointer'
                           }}>
                        {'AS Y1'}
                      </div>
                    )) : ''}
                  </Col>
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                let urlQueryParams = new URLSearchParams(this.props.location.search)
                urlQueryParams.set('theme', this.state.colorTheme === 'light' ? 'dark' : 'light')
                this.props.history.push({ search: urlQueryParams.toString() })
                this.setState({ colorTheme: this.state.colorTheme === 'light' ? 'dark' : 'light' })
              }}
              className={'white-buttons-wr'} style={{
              color: 'var(--text-color)',
              background: 'var(--background-color)',
              fontWeight: 600,
              padding: '11px 24px',
              alignSelf: 'end',
              justifySelf: 'end',
              height: '44px',
            }}>{this.props.t('Mode')}: <span style={{
              fontWeight: 400,
              paddingLeft: '5px'
            }}>{this.props.t(this.state.colorTheme[0].toUpperCase() + this.state.colorTheme.substring(1))}</span></Button>

            <Button
              className="white-buttons-round-wr"
              ref={(ref) => this.printButtonRef = ref}
              onClick={() => this.state.isLoaded ? this.getReportPDF(Base64.encode(this.getFormattedReportHTML(this.state.patient, this.state.asJourney, this.props.t, this.state))): ""}
              variant="light" style={{
              marginRight: 0,
              display: this.state.isLoaded ? '' : 'none'
            }}>
              <DownloadIcon style={{ position: 'relative', top: '-3px' }} fill={'var(--text-color)'}
                            stroke={'var(--text-color)'}/>
            </Button>

          </Col>
        </Row>
        {this.state.isLoaded && this.state.asJourney && this.state.asJourney.stages[0] && this.state.asJourney.stages[0].forms ?
          <div ref={(ref) => {this.componentRef = ref}}>
            <Row
              style={{
                width: '856px',
                minHeight: '1210px',
                color: 'var(--text-color)',
                marginBottom: '20px',
                background: 'var(--background-color)',
                boxShadow: '0px 3px 15px 2px rgba(0, 0, 0, 0.05)',
                borderRadius: '10px',
                padding: 0,
                margin:0
              }}>
              <Row style={{
                margin: 0,
                // maxWidth: "calc(max(min(100vw, 1800px), 1440px))",
                alignContent: 'start'
              }}
              >
                <Row style={{
                  margin: 0,
                  height: 'max-content',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  borderBottom: '1px solid var(--border-color-faintest)',
                  padding: '20px'
                }}>
                  <div style={{}}>
                    <AsAssessmentStatusRow
                      assessment={{ ...this.state.asJourney, tx_status: this.state.patient.tx_status }}
                      patient={this.state.patient} expansions={{}} hideExpansionArrow={true}/>
                  </div>
                </Row>

                <Row style={{ minWidth: '100%', alignSelf: 'start', flex: 1, margin: '0 0 0 0', padding: '0 20px' }}>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <span className={'summary-section-header'}>{this.props.t('Patient Data')}</span>
                  </Row>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <Col style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('First Name')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.patient?.first_name}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Last Name')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.patient?.last_name}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                    <Col style={{ paddingRight: 0, marginRight: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Age')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.patient?.age}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Family Status')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.pd_family_status}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <Col style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'27%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Family History')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.pd_family_history}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'27%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Existing Diseases')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.pd_existing_diseases}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </Row>

                <Row style={{ minWidth: '100%', margin: '0 0 10px 0', padding: '0 20px' }}>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <span className={'summary-section-header'}>{this.props.t('Clinical Parameters')}</span>
                  </Row>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <Col style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'27%'}
                                         className={'summary-table-cell-header'}>{this.props.t('PSA Level')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.psa_s_psa_level_0}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.psa_s_psa_level_1}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.psa_s_psa_level_2}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.psa_s_psa_level_3}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'27%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Date of Test')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_date_of_test_0 ? helpers.getFormattedDate(parseInt(this.state.psa_s_date_of_test_0), 'DD MMM YY', this.props.t) : ''}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_date_of_test_1 ? helpers.getFormattedDate(parseInt(this.state.psa_s_date_of_test_1), 'DD MMM YY', this.props.t) : ''}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_date_of_test_2 ? helpers.getFormattedDate(parseInt(this.state.psa_s_date_of_test_2), 'DD MMM YY', this.props.t) : ''}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_date_of_test_3 ? helpers.getFormattedDate(parseInt(this.state.psa_s_date_of_test_3), 'DD MMM YY', this.props.t) : ''}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'27%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Prostate Volume')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_prostate_volume_0 ? (this.state.psa_s_prostate_volume_0 + " mL") : ""}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_prostate_volume_1 ? (this.state.psa_s_prostate_volume_1 + " mL") : ""}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_prostate_volume_2 ? (this.state.psa_s_prostate_volume_2 + " mL") : ""}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.psa_s_prostate_volume_3 ? (this.state.psa_s_prostate_volume_3 + " mL") : ""}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'29%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Prostate Density')}</TableCell>
                              <TableCell className={'summary-table-cell'}
                                         style={{ minWidth: '150px' }}>{this.state.psa_s_prostate_density_0 ? (this.state.psa_s_prostate_density_0 + " ng/mL²") : ""}</TableCell>
                              <TableCell className={'summary-table-cell'}
                                         style={{ minWidth: '150px' }}>{this.state.psa_s_prostate_density_1 ? (this.state.psa_s_prostate_density_1 + " ng/mL²") : ""}</TableCell>
                              <TableCell className={'summary-table-cell'}
                                         style={{ minWidth: '150px' }}>{this.state.psa_s_prostate_density_2 ? (this.state.psa_s_prostate_density_2 + " ng/mL²") : ""}</TableCell>
                              <TableCell className={'summary-table-cell'}
                                         style={{ minWidth: '150px' }}>{this.state.psa_s_prostate_density_3 ? (this.state.psa_s_prostate_density_3 + " ng/mL²") : ""}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </Row>

                <Row style={{ minWidth: '100%', margin: '0 0 10px 0', padding: '0 20px' }}>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <Col xs={5} style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Date of Biopsy')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.brd_date_of_biopsy ? helpers.getFormattedDate(this.state.brd_date_of_biopsy.includes(" ") ? this.state.brd_date_of_biopsy: parseInt(this.state.brd_date_of_biopsy), 'DD MMM YY', this.props.t) : ''}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Gleason Score')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.brd_gleason_score}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('ISUP')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.brd_isup_score}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Number of Cores')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.brd_number_of_cores}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                    <Col style={{paddingLeft:"10px", paddingRight: 0, marginRight: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'80%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Number of Positive Cores')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.brd_number_of_positive_cores}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'80%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Cancer Core Length')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.brd_cancer_core_length ? (this.state.brd_cancer_core_length + " mm") : ""}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'80%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Cancer Core Ratio')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{![null, undefined, ""].includes(this.state.brd_cancer_core_ratio) ? (this.state.brd_cancer_core_ratio + " %"):""}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'80%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Intraductal PCa')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state.brd_intraductal_pca}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                  <Row style={{ minWidth: '100%', margin: '0 0 10px 0', padding: '5px 0' }}>
                    <Col xs={5} style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Date of MRI')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.date_of_mp_mri ? helpers.getFormattedDate(this.state.date_of_mp_mri.includes(" ") ? this.state.date_of_mp_mri: parseInt(this.state.date_of_mp_mri), 'DD MMM YY', this.props.t) : ''}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Primary PI-RADS')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.props.t(this.state.primary_pi_rads)}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'70%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Number of PI-RADS Lesions')}</TableCell>
                              <TableCell
                                className={'summary-table-cell'}>{this.state.number_of_pi_rads_lesions}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </Row>

                <Row style={{ minWidth: '100%', margin: '0 0 10px 0', padding: '0 20px' }}>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <span className={'summary-section-header'}>{this.props.t('Recommendations')}</span>
                  </Row>
                  <Row style={{ minWidth: '100%', margin: 0, padding: '5px 0' }}>
                    <Col style={{ margin: 0, padding: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Radiology Recommendation')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state?.inputs_updated_at?.expert_radiologist_decision ? helpers.getFormattedDate(this.state?.inputs_updated_at?.expert_radiologist_decision, "DD MMM YY", this.props.t): ""}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Is the patient an AS candidate?')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.props.t(this.state.expert_radiologist_decision)}</TableCell>
                            </TableRow>
                            <TableRow style={{ minHeight: '100px', margin: 0 }}>
                              <TableCell colSpan={2} className={'summary-table-cell'} style={{ height: '100px' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                  <b>{this.props.t('Radiologist Comments')}:</b><br />
                                  {this.state.expert_radiologist_decision_comments}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                    <Col style={{ paddingRight: 0, marginRight: 0 }}>
                      <TableContainer>
                        <Table sx={{}}>
                          <TableBody>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Urology Recommendation')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.state?.inputs_updated_at?.expert_urologist_decision_two ? helpers.getFormattedDate(this.state?.inputs_updated_at?.expert_urologist_decision_two, "DD MMM YY", this.props.t): ""}</TableCell>
                            </TableRow>
                            <TableRow style={{ height: '30px', margin: 0 }}>
                              <TableCell width={'55%'}
                                         className={'summary-table-cell-header'}>{this.props.t('Is the patient an AS candidate?')}</TableCell>
                              <TableCell className={'summary-table-cell'}>{this.props.t(this.state.expert_urologist_decision_two)}</TableCell>
                            </TableRow>
                            <TableRow style={{ minHeight: '100px', margin: 0 }}>
                              <TableCell colSpan={2} className={'summary-table-cell'} style={{ height: '100px' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                  <b>{this.props.t('Urologist Comments')}:</b><br />
                                  {this.state.expert_urologist_decision_two_comments}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Row style={{
                minWidth: '100%',
                margin: '10px 0 10px 0',
                padding: '15px 20px',
                alignSelf: 'end',
                justifySelf: 'end',
                flex: 1,
                fontSize:"12px", lineHeight:"14px",
                borderTop:'1px solid var(--border-color-faintest)'
              }}>
                <Col style={{textAlign:"start"}}>
                  <b>Unterstützt durch</b>
                  <br />Klinik für Urologie
                  <br />Vivantes Klinikum Am Urban Berlin
                  <br />Vivantes Klinikum Im Friedrichshain
                  <br />Vivantes Humboldt-Klinikum
                </Col>
                <Col style={{textAlign:"center", display:"flex", flexDirection:"column", alignContent:'center', justifyItems:"center", flexWrap:"wrap"}}>
                  <img src={VivantesLogoPng} style={{width:"100px"}} />
                  <img src={ChariteLogoPng} style={{width:"100px"}} />
                </Col>
                <Col style={{textAlign:"end"}}>
                  Klinik für Radiologie<br />
                  Charité-Universitätsmedizin
                </Col>
              </Row>
            </Row>
          </div> :
          <Row style={{ height: '300px', width: '100%' }}>
            {this.state.isLoaded ?
              <Col style={{
                fontSize: '18px',
                textAlign: 'center',
                alignContent: 'center',
                marginTop: '150px'
              }}>
                {this.state.error}
              </Col>
              : ''}
          </Row>
        }

      </div>
    )
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    auth: dispatch.auth,
  }
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(AsJourneySummaryReport)))