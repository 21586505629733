import React, { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as helpers from "../helpers/helpers";
import PageNotFound from "./PageNotFound";
import { reportAction } from '../utils/axios'

class HelpAndResources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HarComponent: "Loading..."
        }
    }

    componentDidMount() {
        reportAction("VIEWED_HELP_AND_RESOURCES");
        let HarComponent = PageNotFound; // TODO TTI default help & resources (update getComponentsFromJwt as well)
        if (Boolean(this.props.auth)) {
            let components = helpers.getComponentsFromJwt(this.props.auth.token);
            if (Boolean(components.help_and_resources) && ["WagHelpAndResources", "BayGHelpAndResources", "BayESHelpAndResources"].includes(components.help_and_resources)) {
                HarComponent = React.lazy(() =>
                    import(`../components/HelpAndResources/${components.help_and_resources}.js`)
                );
            }
        }
        this.setState({HarComponent: HarComponent});
    }

    render = () => {
        const HarComponent = this.state.HarComponent;
        return <Suspense fallback={<div>{this.props.t('loading')}...</div>}><HarComponent /></Suspense>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        auth: dispatch.auth,
    };
}

export default withTranslation()(withRouter(connect(mapDispatchToProps)(HelpAndResources)));